import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from "@angular/material";
import { CrudService } from "src/app/core/services/data/crud.service";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { ORDER, PROCESSING_STATUS_5, PROCESSING_STATUS_1, PROCESSING_STATUS_8, PROCESSING_STATUS_9, ORDER_ITEM_TYPE_6, ORDER_ITEM_TYPE_3, ORDER_ITEM_TYPE_5, PROCESSING_STATUS_10, PROCESSING_STATUS_4 } from 'src/app/core/constants/configuration/order-constants.config';
import { VIEW_MODULE_NAME, pageSizeOptions, ASC } from 'src/app/core/constants/configuration/common.constant';
import { Order } from "src/app/core/models/order/order.model";
import { Task } from "src/app/core/models/order/task.model";
import { StudentInfo } from "src/app/core/models/eos/student-info.model";
import {
  allOrdersEndpoint,
  allStudentsEndpoint,
  getByOrderCodeTaskEndpoint,
  getProcessingStatusEndpoint,
  requestToCancelOrderItemEndpoint,
  updateTaskStatusEndpoint,
  getShippingDetailsEndpoint,
  orderUpdateShippingAddressEndpoint,
  reformEosOrderEndpoint,
  allTxnHistoryEndpoint,
  approveWaitlistEndpoint,
  getOrderNotesByCosCodeEndpoint,
  svApproveEndpoint,
} from 'src/app/core/constants/endpoints.constant';
import { ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { homeRoutingPermissions, PROGRAM_DIRECTOR, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from "src/app/core/services/util/user.service";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { GuardianShippingDetails } from '../../../../core/models/eos/guardian-shipping-details.model';
import { DataCustomizerService } from '../../../../core/services/util/data-customizer.service';
import { TxnHistoryModel } from 'src/app/core/models/order/txn-history-model';
import { TxnHistoryItemDto } from '../../shared/txn-history/txn-history-item-dto';
import { PaginationDto } from '../../../../core/models/dto/pagination-model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { QueryDto } from '../../../../core/models/dto/query-dto';
import { SortDto } from '../../../../core/models/dto/sort-dto';
import { Page } from 'src/app/core/models/page.model';
import { OrderTxnHistoryDto } from 'src/app/core/models/dto/order-txn-history-dto';
import { orderTxnHistoryTab } from 'src/app/core/constants/configuration/asset-constants.config';
import { OrderNotes } from 'src/app/core/models/order/order-notes.model';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {
	unsubscribe: Subject<any> = new Subject();
  orderDetails: Order;
  taskDetails: Task[] = [];
  studentInfo: StudentInfo;
  orderNotes: any;
  shippingDetails: any[] = [];
  processingStatus: any[] = [];
  displayTxnHistoryItems: TxnHistoryModel[] = [];
  itemType: string = orderTxnHistoryTab;
  txnHistoryItemsCount: number;
  filter: PaginationDto;
  sortedBy: string;
  sortOrder: string;
  queryParameters: QueryDto;
  keyword: string;
  pageIndex: number;
  processedTotal: string = "$ 0.00";
  unprocessedTotal: string = "$ 0.00";
  isOnlineAccount:  Boolean;

  type1 : string = "ORDER_ITEM_TYPE_2";
  type2 : string = "ORDER_ITEM_TYPE_3";

  constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private route: ActivatedRoute,
    private userService: UserService,
    private queryService: QueryService,
    private router: Router
	) {
		this.userService.checkRolePermission(ORDERS_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(ORDER, VIEW_MODULE_NAME, false, null);
  }

  ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
    this.getOrderDetails(this.route.snapshot.paramMap.get('id'));

    // Set initial values for filters:
    this.filter = new PaginationDto(
      0,
      0,
      pageSizeOptions[0],
      '1'
    );
    this.sortedBy = "dateModified";
    this.sortOrder = ASC;
    this.keyword = "";
    this.pageIndex = this.filter.pageIndex;
  }

  formatAmount(value: number): string {
    if(!value) return "$" + 0.00;
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  getOrderDetails(id: string) {
    this.crudService.getById<Order>(allOrdersEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.orderDetails = response;
        this.processedTotal = this.formatAmount(this.orderDetails.processedTotal);
        this.unprocessedTotal = this.formatAmount(this.orderDetails.unprocessedTotal);
        this.getStudentById(this.orderDetails.studentId);
        this.getOrderNotesByCosCode(this.orderDetails.code);
        this.getTxnHistoryItems(
          new TxnHistoryItemDto(
            new QueryDto(
              this.filter,
              this.sortedBy,
              this.sortOrder,
              this.keyword
            ),
            response.code,
            "ORDER"
          )
        );
      }, this.errorHandlerService.handleError);
  }

  isOrderRefundedAndAdjusted() {
    return (
      this.orderDetails.withdrawType === "refund" &&
      !this.orderDetails.orderItems.some((item) => item.adjustedPrice !== null) // checks if an orderItem had adjustedPrice, returns false if adjusted
    );
  }

  getOrderNotesByCosCode(code: string): void {
    this.crudService.getAll<OrderNotes>(getOrderNotesByCosCodeEndpoint.concat(`?cosOrderCode=${code}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.orderNotes = response;
      }, this.errorHandlerService.handleError);
  }

  getTxnHistoryItems(dto: TxnHistoryItemDto) {
    // Retrieve as PageDto
    // Convert values of PageDto to txn-history-items:
    this.crudService.getById<Page<TxnHistoryModel>>(
      allTxnHistoryEndpoint
        .concat('/orders')
        .concat(`/${dto.itemCode}`)
        .concat(this.queryService
          .buildBaseQuery(
            dto.query.pagination.pageIndex,
            dto.query.pagination.pageSize,
            dto.query.sortedBy,
            dto.query.sortOrder,
            dto.query.keyword
          )
        )
        .concat(`&itemType=${dto.itemType}`)
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          response
          // re-initialize txnHistoryItems upon search:
          this.displayTxnHistoryItems = [];
          response.content.forEach(
            txnHistoryItem => {
              this.displayTxnHistoryItems.push(
                txnHistoryItem
              );
            }
          );
          // Set total:
          this.txnHistoryItemsCount = response.totalElements;
          this.pageIndex = dto.query.pagination.pageIndex;
        },
        this.errorHandlerService.handleError
      );
  }

  getStudentById(id: string): void {
    this.crudService.getById<StudentInfo>(allStudentsEndpoint.concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
        this.getProcessingStatus();
      }, this.errorHandlerService.handleError);
  }
  
  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
        this.getTaskDetails();
			}, this.errorHandlerService.handleError);
  }

  getTaskDetails() {
    this.crudService.getAllBy<Task>(getByOrderCodeTaskEndpoint.concat(`?code=${this.orderDetails.code}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.taskDetails = response;
				this.getGuardianShippingDetails();
      }, this.errorHandlerService.handleError);
  }

  getGuardianShippingDetails(): void {
    this.shippingDetails = [];
    this.crudService
      .getAllBy<GuardianShippingDetails>(getShippingDetailsEndpoint.concat(`/${this.studentInfo.studentDetails.id}`))
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          response.forEach(shippingDetail => {
            this.shippingDetails.push(
              this.dataCustomizerService.formatShippingDetailDisplay(shippingDetail))
          });
          const shippingDetail = {
            ...this.orderDetails.shippingDetails,
            check: true,
            addressLine: this.orderDetails.shippingDetails ? this.orderDetails.shippingDetails.line : "",
            addressName: this.orderDetails.shippingDetails ? this.orderDetails.shippingDetails.name : "",
          };
          let match = this.shippingDetails.find(detail => this.doesShippingAddressExists(detail, shippingDetail));
          if(match) this.shippingDetails.forEach(detail => { if(match.id === detail.id) detail.check = true; });
          else this.shippingDetails.push(shippingDetail);
				}
    	}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  doesShippingAddressExists(shippingDetail: any, currentShippingDetail: any) {
    return shippingDetail.addressLine === currentShippingDetail.addressLine &&
      shippingDetail.addressName.trim() === currentShippingDetail.addressName.trim() &&
      shippingDetail.city === currentShippingDetail.city &&
      shippingDetail.email === currentShippingDetail.email &&
      shippingDetail.phone === currentShippingDetail.phone &&
      shippingDetail.state === currentShippingDetail.state &&
      shippingDetail.zipCode === currentShippingDetail.zipCode
  }

  undoRequestToCancel(selectedItemsCodes: string[]) {
    this.requestToCancelSelectedItems(selectedItemsCodes, false);
  }

  requestToCancel(selectedItemsCodes: string[]) {
    this.requestToCancelSelectedItems(selectedItemsCodes, true);
  }
  
  requestToCancelSelectedItems(selectedItemsCodes: string[], toCancel: boolean): void {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.getById<any>(requestToCancelOrderItemEndpoint.concat(`?orderId=${this.orderDetails.id}&selectedItems=${selectedItemsCodes}&toCancel=${toCancel ? 'TRUE' : 'FALSE'}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        this.openSuccessDialog(null, `Successfully ${toCancel ? 'submitted a' : 'cancelled the'} Request to Cancel.`);
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  onCancelSelected(selectedItems: any) {
    let lastMatch = null;
    this.taskDetails.forEach((task, index) => {
      const taskToUpdate = this.setTaskToUpdate(selectedItems, task, PROCESSING_STATUS_5);
      if (taskToUpdate.orderItemAssoc.length > 0) lastMatch = index;
    });
    this.taskDetails.forEach((task, index) => {
      this.updateTaskStatus(selectedItems, task, index, lastMatch, "Successfully cancelled the selected items.", PROCESSING_STATUS_5);
    });
  }

  updateTaskStatus(selectedItems: any, task: Task, index: number, lastMatch: number, message: string, newStatus: string) {
    this.loaderMessagingService.showPageLoader(true);
    const taskToUpdate = this.setTaskToUpdate(selectedItems, task, newStatus);
    if (taskToUpdate.orderItemAssoc.length > 0) {
      this.crudService
        .edit<any>(updateTaskStatusEndpoint, { tasks: [taskToUpdate] })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: any) => {
          if (response) {
            if (index === lastMatch) {
              this.openSuccessDialog(null, message)
            }
          }
        }, this.errorHandlerService.handleError, this.handleCompletion);
    }
  }

  setTaskToUpdate(selectedItems: any, task: Task, newStatus: string) {
    let taskToUpdate = task;
    let selectedTaskItems = [];
    selectedItems.forEach(item => {
      task.orderItemAssoc.slice().forEach(itemAssoc => {
        if (item.id === itemAssoc.txnOrderItemId) {
          selectedTaskItems.push({...itemAssoc, status: newStatus});
        }
      });
    });
    taskToUpdate.orderItemAssoc = selectedTaskItems;
    return taskToUpdate;
  }

  openSuccessDialog(title: string, message: string): void {
    const dialogRef = this.dialog.open(DialogBoxComponent);
    dialogRef.componentInstance.dialogTitle = title ? title : 'Success';
    dialogRef.componentInstance.contentMessage = message;
    dialogRef.afterClosed().subscribe(() => {
      this.getOrderDetails(this.route.snapshot.paramMap.get('id'));
    });
  }

  getNewShippingDetails(details) {
    if (details) {
      this.loaderMessagingService.showPageLoader(true);
      this.crudService
        .add<any>(orderUpdateShippingAddressEndpoint, {
          ...details,
          name: details.addressName,
          line: details.addressLine,
          order: this.orderDetails,
        })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (response) {
            this.openSuccessDialog(null, "Successfully updated the shipping details.")
          }
        }, this.errorHandlerService.handleError, this.handleCompletion);
    }
  }

  onReformEosOrder() {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<any>(reformEosOrderEndpoint.concat(`?orderCode=${this.orderDetails.code}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response.status === "Success") {
          this.openSuccessDialog(null, "Successfully reformed the EOS order of this order.")
        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  get isUserTeacher(): boolean {
    return this.userService.hasRole([TEACHER]);
  }

  get isOrderHstApproved(): boolean {
    return this.orderDetails.status === PROCESSING_STATUS_1;
  }

  get isWaitlisted(): boolean {
    return this.orderDetails.status === PROCESSING_STATUS_8;
  }

  get isSvApprove(): boolean {
    return this.orderDetails.status === PROCESSING_STATUS_9;
  }

  get canProgramDirectorDoAction(): boolean {
    if (this.userService.hasRole([PROGRAM_DIRECTOR])) {
      return this.userService.getLoginUser().username === this.orderDetails.orderItems[0].curriculum.assignedTo;
    } else return true;
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };

  receivePageOnChange(value: PaginationDto) {
    // change the value of pageFilter:
    this.filter = value;
    this.getTxnHistoryItems(
      new TxnHistoryItemDto(
        new QueryDto(
          this.filter,
          this.sortedBy,
          this.sortOrder,
          this.keyword
        ),
        this.orderDetails.code,
        "ORDER"
      )
    );
  }

  receiveSortOnChange(value: SortDto) {
    this.sortOrder = value.sortOrder;
    this.sortedBy = value.sortedBy;
    this.getTxnHistoryItems(
      new TxnHistoryItemDto(
        new QueryDto(
          this.filter,
          this.sortedBy,
          this.sortOrder,
          this.keyword
        ),
        this.orderDetails.code,
        "ORDER"
      )
    );
  }

  receiveSearchSortOnChange(keyword: string) {
    this.keyword = keyword;
    // Upon search: Reset page index and pagesize to default:
    this.filter.pageIndex = 0;
    this.filter.pageSize = pageSizeOptions[0];
    this.getTxnHistoryItems(
      new TxnHistoryItemDto(
        new QueryDto(
          this.filter,
          this.sortedBy,
          this.sortOrder,
          this.keyword
        ),
        this.orderDetails.code,
        "ORDER"
      )
    );
  }

  approveClass(selectedItems: any) {
    this.loaderMessagingService.showPageLoader(true);
      this.crudService
        .edit<any>(approveWaitlistEndpoint, selectedItems)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: any) => {
          if (response) {
              this.openSuccessDialog(null, "Successfully Approved the Waitlisted Item/s");
          }
        }, this.errorHandlerService.handleError, this.handleCompletion);
    }

    svApproveClass(details: any) {
      this.loaderMessagingService.showPageLoader(true);
        this.crudService
          .edit<any>(svApproveEndpoint, {orderCode: details.orderCode, approveNotes: details.approveNotes, classStartDate: details.classStartDate})
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((response: any) => {
            if (response) {
              this.openSuccessDialog(null, "Successfully SV Approved Order");
            }
          }, this.errorHandlerService.handleError, this.handleCompletion);
    }

    hasOnlineAccount(){
      if(this.orderDetails != null && this.orderDetails.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_6).length > 0){
        return true;
      } else{
        return false;
      }
    }

    hasWorkBook(){
      if(this.orderDetails.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_3).length > 0){
        return true;
      } else{
        return false;
      }
    }

    hasFeeDepositReference(){
      if(this.orderDetails.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_5).length > 0){
        return true;
      } else{
        return false;
      }
    }

    onRequestToWithdraw(selectedItems: any[]){
      let lastMatch = null;
      this.taskDetails.forEach((task, index) => {
        const taskToUpdate = this.setTaskToUpdate(selectedItems, task, PROCESSING_STATUS_10);
        if (taskToUpdate.orderItemAssoc.length > 0) lastMatch = index;
      });
      this.taskDetails.forEach((task) => {
        task.status = PROCESSING_STATUS_10;
        task.assignedTo = null;
      });
      this.taskDetails.forEach((task, index) => {
        this.updateTaskStatus(selectedItems, task, index, lastMatch, "Successfully requested to withdraw the item.", PROCESSING_STATUS_10);
      });
    }
  
    onUndoRequestToWithdraw(selectedItems: any[]){
      let lastMatch = null;
      this.taskDetails.forEach((task, index) => {
        const taskToUpdate = this.setTaskToUpdate(selectedItems, task, PROCESSING_STATUS_4);
        if (taskToUpdate.orderItemAssoc.length > 0) lastMatch = index;
      });
      this.taskDetails.forEach((task) => {
        task.status = PROCESSING_STATUS_4;
      });
      this.taskDetails.forEach((task, index) => {
        this.updateTaskStatus(selectedItems, task, index, lastMatch, "Successfully undone the request to withdraw the item.", PROCESSING_STATUS_4);
      });
    }
}
