import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'edit-video-dialog',
  templateUrl: './edit-video-dialog.component.html',
  styleUrls: ['./edit-video-dialog.component.scss']
})
export class EditVideoDialogComponent implements OnInit {

  constructor(
    public dlDialogRef: MatDialogRef<EditVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  private reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public confirmTitle: string;
  public cancelLabel: string;
  public confirmLabel: string;
  public reactiveForm = new FormGroup({
    title: new FormControl(this.data.title, [Validators.required]),
    description: new FormControl(this.data.description, [Validators.required]),
    url: new FormControl(this.data.url, [Validators.required, Validators.pattern(this.reg)]),
  })
  
  onClick(){
    if(this.reactiveForm.valid){
      this.data.title = this.reactiveForm.get('title').value;
      this.data.description = this.reactiveForm.get('description').value;
      this.data.url = this.reactiveForm.get('url').value;
      this.dlDialogRef.close(this.data);
    }
  }
}
