import { Injectable } from "@angular/core";
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from "@angular/forms";
import { BundleMainForm } from "../../models/form/bundle-main-form.model";
import { BundleCurriculumForm } from "../../models/form/bundle-curriculum-form.model";
import { CurriculumForm } from "../../models/form/curriculum-form.model";
import { ResourceMainForm } from "../../models/form/resource-main-form.model";
import { ResourceGroupForm } from "../../models/form/resource-group-form-model";
import { PageForm } from "../../models/form/page-form.model";
import {
  NEXT,
  PREV,
  PER_YEAR,
  STATUS_1,
  regexAlphanumericFormat,
  regexAlphanumericFormatCharLimit,
  regexFloatingNumericFormat,
  PERIOD_TYPE_1,
  regexNumericFormat,
  INACTIVE,
  ACTIVE
} from "../../constants/configuration/common.constant";
import { CurriculumMainForm } from "../../models/form/curriculum-main-form.model";
import * as moment from "moment";
import { CurriculumClassForm } from "../../models/form/curriculum-class-form.model";
import { HqtMainForm } from "../../models/form/hqt-main-form.model";
import { RESOURCE_TYPE_CODE_1 } from "../../constants/configuration/resource-constants.config";
import { BUNDLE_TYPE_1 } from "../../constants/configuration/bundle-constants.config";
import { ModelMainForm } from "../../models/form/model-main-form.model";
import { AssetMainForm } from "../../models/form/asset-main-form.model";
import { MfaForm } from "../../models/form/mfa-form.model";

@Injectable({ providedIn: "root" })
export class FormUtil {
  constructor(private fb: FormBuilder) {}

  createBundleMainForm(): FormGroup {
    return this.fb.group({
      name: [
        "",
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      code: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      description: [
        "",
        Validators.compose([Validators.pattern(regexAlphanumericFormatCharLimit)])
      ],
      price: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      periodCheckbox: [true],
      activePeriodType: [PER_YEAR, Validators.compose([Validators.required])],
      syCode: [null, Validators.compose([Validators.required])],
      startDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
      availability: null
    });
  }

  initBundleMainForm(values: BundleMainForm): BundleMainForm {
    return {
      name: (values && values.name) || "",
      code: (values && values.code) || "",
      description: (values && values.description) || "",
      price: (values && values.price) || 0,
      periodCheckbox: values
        ? values.activePeriodType === PER_YEAR
          ? true
          : false
        : true,
      activePeriodType: (values && values.activePeriodType) || "",
      syCode: (values && values.syCode) || null,
      startDate: (values && moment(values.startDate).toDate()) || null,
      endDate: (values && moment(values.endDate).toDate()) || null,
      availability: (values && values.availability) || ""
    };
  }

  createHqtMainForm(): FormGroup {
    return this.fb.group({
      name: [
        "",
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      status: [STATUS_1, Validators.compose([Validators.required])]
    });
  }

  initHqtMainForm(values: HqtMainForm): HqtMainForm {
    return {
      name: (values && values.name) || "",
      email: (values && values.email) || "",
      status: (values && values.status) || ""
    };
  }

  createResourceMainForm(): FormGroup {
    return this.fb.group({
      type: [null, Validators.compose([Validators.required])],
      code: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      onlineAccountType: ["", Validators.compose([Validators.required])],
      name: [
        "",
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      description: [
        "",
        Validators.compose([Validators.pattern(regexAlphanumericFormat)])
      ],
      nonconsumable: [false, Validators.compose([Validators.required])],
      price: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      deposit: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ]
    });
  }

  createOrderResourceForm(): FormGroup {
    return this.fb.group({
      type: [null, Validators.compose([Validators.required])],
      code: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      name: [
        "",
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      description: [
        "",
        Validators.compose([Validators.pattern(regexAlphanumericFormat)])
      ],
      nonconsumable: [false, Validators.compose([Validators.required])],
      price: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      deposit: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ]
    });
  }

  initResourceMainForm(values: ResourceMainForm): ResourceMainForm {
    return {
      type: (values && values.type) || RESOURCE_TYPE_CODE_1,
      code: (values && values.code) || "",
      onlineAccountType: (values && values.onlineAccountType) || "",
      name: (values && values.name) || "",
      description: (values && values.description) || "",
      nonconsumable: values && values.nonconsumable,
      price: (values && values.price) || 0,
      deposit: (values && values.deposit) || 0
    };
  }

  createResourceGroupForm(): FormGroup {
    return this.fb.group({
      isRequired: [null, Validators.compose([Validators.required])],
      type: [null, Validators.compose([Validators.required])],
      code: [null, Validators.compose([Validators.required])]
    });
  }

  initResourceGroupForm(values: ResourceGroupForm): ResourceGroupForm {
    return {
      isRequired: (values && values.isRequired) || false,
      type: (values && values.type) || "",
      code: (values && values.code) || "",
      disabled: false,
      name: ""
    };
  }

  createBundleCurriculumForm(): FormGroup {
    return this.fb.group({
      minimumClasses: [
        0,
        Validators.compose([
          Validators.required,
          Validators.pattern(regexNumericFormat),
          Validators.min(0)
        ])
      ],
      maximumClasses: [
        0,
        Validators.compose([
          Validators.required,
          Validators.pattern(regexNumericFormat),
          Validators.min(0)
        ])
      ],
      bundleType: [BUNDLE_TYPE_1, Validators.compose([Validators.required])],
      curriculums: this.fb.array([this.createCurriculumsForm()])
    });
  }

  initBundleCurriculumForm(values: BundleCurriculumForm): BundleCurriculumForm {
    return {
      minimumClasses: (values && values.minimumClasses) || 0,
      maximumClasses: (values && values.maximumClasses) || 0,
      bundleType: (values && values.bundleType) || BUNDLE_TYPE_1,
      curriculums:
        values.curriculums.length > 0
          ? values.curriculums.map(values => this.initCurriculumsForm(values))
          : []
    };
  }

  createCurriculumsForm(): FormGroup {
    return this.fb.group({
      id: [null],
      name: ["", Validators.compose([Validators.required])],
      code: ["", Validators.compose([Validators.required])],
      minimumClasses: [
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ],
      classes: [null],
      curriculumCodeName: ["", Validators.compose([Validators.required])]
    });
  }

  initCurriculumsForm(values: CurriculumForm): CurriculumForm {
    return {
      id: (values && values.id) || null,
      name: (values && values.name) || "",
      code: (values && values.code) || "",
      minimumClasses: (values && values.minimumClasses) || 0,
      classes: (values && values.classes) || null,
      curriculumCodeName:
        values.code && values.name ? values.name + " (" + values.code + ")": "",
      scopes: (values && values.scopes) || null,
    };
  }
  createMfaForm(): FormGroup {
    return this.fb.group({
      otp: [false, Validators.compose([Validators.required])]
    });
  }

  createModelMainForm(): FormGroup {
    return this.fb.group({
      code: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      name: [
        null,
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      consumable: [false, Validators.compose([Validators.required])],
      price: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      deposit: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      hasDeposit: [false, Validators.compose([Validators.required])],
    });
  }
  
  createAssetMainForm(forUpdate: boolean): FormGroup {
    return this.fb.group({
      code: [
        null,
        Validators.compose([
          Validators.required,
          //Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      modelCodeName: ["", Validators.compose([Validators.required])],
      status: ["", Validators.compose([Validators.required])] ? forUpdate : ["", Validators.compose([Validators.min(0)])],
      description: ["", Validators.compose([Validators.min(0)])],
      loanedTo: ["", Validators.compose([Validators.required])] ? forUpdate : ["", Validators.compose([Validators.min(0)])],
      scopeName: [null]
    });
  }

  initModelMainForm(values: ModelMainForm): ModelMainForm {
    return {
      code: (values && values.code) || null,
      name: (values && values.name) || "",
      consumable: (values && values.consumable) === true ? true : false,
      price: (values && values.price) || 0,
      deposit: (values && values.deposit) || 0,
      hasDeposit: (values && values.hasDeposit) === true ? true : false,
    };
  }

  initMfaForm(values: MfaForm): MfaForm {
    return {
      otp: (values && values.otp) || null,
    };
  }

  initAssetMainForm(values: AssetMainForm): AssetMainForm {
    return {
      code: (values && values.code) || null,
      modelCodeName: (values && values.modelCodeName) || null,
      status: (values && values.status) || null,
      description: (values && values.description) || null,
      loanedTo: (values && values.loanedTo) || null,
      scopeName: (values && values.scopeName) || null,
    };
  }

  createCurriculumMainForm(): FormGroup {
    return this.fb.group({
      name: [
        null,
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      description: [
        null,
        Validators.compose([Validators.pattern(regexAlphanumericFormat)])
      ],
      startDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
      syCode: [null, Validators.compose([Validators.required])],
      status: [ACTIVE, Validators.compose([Validators.required])],
      deposit: [
        0
      ],
      hasDeposit: [
      ],
      fee: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      perClassCost: [
        0,
        Validators.compose([
          Validators.required,
          Validators.pattern(regexFloatingNumericFormat)])
      ],
      bookRequired: [
      ],
      scopes: [],
      assignedTo: [
        null,
        Validators.compose([Validators.pattern(regexAlphanumericFormat)])
      ],
    });
  }

  initCurriculumMainForm(values: CurriculumMainForm): CurriculumMainForm {
    return {
      name: (values && values.name) || "",
      description: (values && values.description) || "",
      startDate: (values && moment(values.startDate).toDate()) || null,
      endDate: (values && moment(values.endDate).toDate()) || null,
      syCode: (values && values.syCode) || null,
      status: (values && values.status) === STATUS_1 ? ACTIVE : INACTIVE,
      deposit: (values && values.deposit) || 0,
      hasDeposit: (values && values.hasDeposit) || false,
      fee: (values && values.fee) || 0,
      perClassCost: (values && values.perClassCost) || 0,
      bookRequired: (values && values.bookRequired) || false,
      scopes: ((values && values.scopes)) || [],
      assignedTo: (values && values.assignedTo) || "",
    };
  }
  

  createClassForm(): FormGroup {
    return this.fb.group({
      curriculumCode: [null, Validators.compose([Validators.required])],
      name: [
        null,
        Validators.compose([
          Validators.required,
          // Validators.pattern(regexAlphanumericFormat)
        ])
      ],
      courseCode: [
        null,
        Validators.compose([Validators.pattern(regexAlphanumericFormat)])
      ],
      description: [
        null,
        Validators.compose([Validators.pattern(regexAlphanumericFormatCharLimit)])
      ],
      subsequentCourse: [null],
      subject: [null, Validators.compose([Validators.required])],
      status: [STATUS_1, Validators.compose([Validators.required])],
      fromGradeLevel: [null, Validators.compose([Validators.required])],
      toGradeLevel: [null, Validators.compose([Validators.required])],
      activePeriodType: [
        PERIOD_TYPE_1,
        Validators.compose([Validators.required])
      ],
      hqtName: [null],
      hqtUsername: [null],
      deposit: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      fee: [
        0,
        Validators.compose([Validators.pattern(regexFloatingNumericFormat)])
      ],
      maxSlot: [null, [Validators.compose([Validators.required]),
        Validators.compose([Validators.min(1)]),
        Validators.compose([Validators.pattern(regexNumericFormat)])]
      ],
      section: [null, Validators.compose([Validators.required])],
      sectionSchoolCode: [null],
      schoolSourceId: [null, Validators.compose([Validators.required])],
      classSourcedId: [null],

      keywords: [
        null,
        Validators.compose([Validators.pattern(regexAlphanumericFormat)])
      ]
    });
  }

  initClassForm(values: CurriculumClassForm): CurriculumClassForm {
    return {
      curriculumCode: values.curriculumCode,
      name: values.name ? values.name : "",
      courseCode: values.courseCode ? values.courseCode : "",
      description: values.description ? values.description : "",
      subject: values.subject ? values.subject : "",
      status: values.status ? values.status : STATUS_1,
      fromGradeLevel: values.fromGradeLevel ? values.fromGradeLevel : "",
      toGradeLevel: values.toGradeLevel ? values.toGradeLevel : "",
      activePeriodType: values.activePeriodType
        ? values.activePeriodType
        : PERIOD_TYPE_1,
      hqtName: "",
      hqtUsername: values.hqtUsername ? values.hqtUsername : "",
      deposit: values.deposit ? values.deposit : 0,
      fee: values.fee ? values.fee : 0,
      maxSlot: values.maxSlot,
      section: values.section,
      sectionSchoolCode: values.sectionSchoolCode,
      schoolSourceId: values.schoolSourceId,
      classSourcedId: values.classSourcedId,
      keywords: values.keywords ? values.keywords : "",
      subsequentCourse: values.subsequentCourse
    };
  }

  // for managing form wizard
  setPageForm(label: string, navLength: number, activeIndex: number): PageForm {
    let isFirst: boolean = false;
    let isLast: boolean = false;

    if (label === NEXT && activeIndex + 1 < navLength) activeIndex++;
    else if (label === PREV && activeIndex - 1 >= 0) activeIndex--;

    if (activeIndex === 0) isFirst = true;
    else if (activeIndex + 1 >= navLength) isLast = true;

    return { activeIndex, isFirst, isLast };
  }

  createShippingDetailForm(): FormGroup {
    return this.fb.group({
      check: [""],
      addressName: [
        "",
        Validators.pattern(regexAlphanumericFormat)
      ],
      addressLine: [
        "",
        Validators.pattern(regexAlphanumericFormat)
      ],
      city: [
        "",
        Validators.pattern(regexAlphanumericFormat)
      ],
      state: [""],
      zipCode: [
        "",
        Validators.pattern(regexFloatingNumericFormat)
      ],
      phone: [
        "",
        Validators.pattern(regexAlphanumericFormat)
      ],
      email: [
        "",
        Validators.email
      ],
    });
  }
  
  createSpecialistForm(): FormGroup {
    return this.fb.group({
      specialist: [
        "",
        Validators.email
      ],
    });
  }

  createTaskResourceForm(): FormGroup {
    return this.fb.group({
      workbook: [""],
      consumable: [""],
      trackingId: [""],
      asset: [
        "",
        Validators.required
      ],
    });
  }

  createTaskResourceFormRequiredTrackingId(): FormGroup {
    return this.fb.group({
      workbook: [""],
      trackingId: [
        "",
        Validators.required
      ],
      asset: [
        "",
        Validators.required
      ],
    });
  }
  
  createDownloadOnlineAccountForm(): FormGroup {
    return this.fb.group({
      // onlineAccount: [
      //   "",
      // ],
      status: [new FormArray([
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(false)
      ])
      ],
      reportType: [
        "",
      ],
      dateFrom: [
        "",
      ],
      dateTo: [
        "",
      ],
      reportSelection: [
        "",
      ],
      curriculumCode: ["", Validators.compose([Validators.min(0)])],
      report: [
        null,
        Validators.required
      ],
      course:[""],
      // className: [
      //   null,
      //   Validators.required
      // ],
      selectedActivePeriodType: [
        "",
      ],
    });
  }
}
