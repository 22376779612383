import { QueryDto } from "src/app/core/models/dto/query-dto";

export class CourseHistoryItemDto {
    private _query: QueryDto;
    private _code: string;

    constructor(
        queryDto: QueryDto,
        code: string,
    ) {
        this._query = queryDto;
        this._code = code;
    }

    get query() {
        return this._query;
    }

    get code() {
        return this._code;
    }
}