export const allBundlesEndpoint: string = "/bundles";
export const allCurriculumsEndpoint: string = "/curriculums";
export const allReportsEndpoint: string = "/report";
export const getReportCOSProcessor: string = "/report/get-cos-processor";
export const allClassesEndpoint: string = "/classes";
export const allResourcesEndpoint: string = "/resources";
export const allHqtEndpoint: string = "/hqt";
export const allOrdersEndpoint: string = "/orders";
export const allTasksEndpoint: string = "/tasks";
export const allStudentsEndpoint: string = "/students";
export const allModelsEndpoint: string = "/inventory/models";
export const allAssetsEndpoint: string ="/inventory/models/assets";
export const allTxnHistoryEndpoint: string = "/txn-history";
export const oldEndpoint: string = "/old";
export const oldOrdersEndpoint: string = "/old" + allOrdersEndpoint;
export const oldTasksEndpoint: string = "/old" + allTasksEndpoint;
export const oldModelsEndpoint: string = "/old" + allModelsEndpoint;
export const allVideosEndpoint: string = "/videos";
export const allCartSessionEndpoint: string = "/cart-session"

export const downloadCurriculumsEndpoint: string = "/curriculums/download";
export const downloadClassesEndpoint: string = "/classes/download";
export const downloadBundlesEndpoint: string = "/bundles/download";
export const downloadResourcesEndpoint: string = "/resources/download";
export const downloadModelsEndpoint: string = "/inventory/models/download";
export const downloadAssetsEndpoint: string = "/inventory/models/assets/download";
export const downloadShippingDtls: string = "/orders/print-shipping-dtls"
export const downloadOrdersEndpoint: string = "/orders/download";
export const downloadCurriculumTemplateEndpoint = "/curriculums/download/template";
export const downloadClassTemplateEndpoint = "/classes/download/template";
export const downloadBundleTemplateEndpoint = "/bundles/download/template";
export const downloadModelTemplateEndpoint = "/inventory/models/download/template";
export const downloadAssetTemplateEndpoint = "/inventory/models/assets/download/template";
export const downloadResourceTemplateEndpoint = "/resources/download/template";
export const downloadCurriculumRolloverTemplateEndpoint: string = "/orders/download/template";

export const downloadModelErrorEndpoint: string =
  "/inventory/models/download/error";
export const downloadAssetErrorEndpoint: string =
"/inventory/models/assets/download/error";

export const accessTokenEndpoint: string = "/oauth/token";
export const revokeTokenEndpoint: string = "/oauth/revoke";
export const currentUserEndpoint: string = "/oauth/user";
export const preLoginEndpoint: string = "/pre-login";

export const uploadCurriculumTemplateEndpoint = "/curriculums/upload";
export const uploadClassTemplateEndpoint = "/classes/upload";
export const uploadBundleTemplateEndpoint = "/bundles/upload";
export const uploadModelTemplateEndpoint = "/inventory/models/upload";
export const uploadAssetTemplateEndpoint = "/inventory/models/assets/upload";
export const uploadResourceTemplateEndpoint = "/resources/upload";
export const uploadCurriculumRolloverTemplateEndpoint = "/orders/upload";

export const uploadStatusEndpoint: string = "/uploads/status";

export const createCurriculum: string = "/curriculums";
export const getCountByFilter: string = "/count";
export const getSchoolYearsWithHistory: string = "/history";

export const getActivePeriodStatusEndpoint: string = "/active-period-status";
export const getStatusEndpoint: string = "/status";
export const getProcessingStatusEndpoint: string = "/order-status";
export const getSchoolYearsEndpoint: string = "/ref-school-year/active";
export const getAllSchoolYearsEndpoint: string = "/ref-school-year";
export const getActiveSchoolYearEndpoint: string = "/ref-school-year/active-sy";
export const getCountByStatus: string ="/count-by-status";
export const getOrderDepositItemsEndpoint: string = "/old/orders/deposit";

export const existsByName = "/exists-by-name?name=";
export const existsByCode = "/exists-by-code?code=";
export const existsByEmail = "/exists-by-email?email=";
export const modelErrorExist = "/inventory/models/download/error-exist";
export const assetErrorExist = "/inventory/models/assets/download/error-exist";
export const searchByCodeModelEndpoint: string = "/inventory/models/search";

export const getClassSubjectsEndpoint: string = allClassesEndpoint.concat(
  "/subjects"
);

export const getClassOrderedCount: string = allClassesEndpoint.concat(
  "/count-ordered-class"
);

export const getProcessedOrderedCount: string = getClassOrderedCount.concat(
  "/processed"
)

export const getProcessedNoWDOrderedCount: string = getClassOrderedCount.concat(
  "/processed-no-wd"
)

export const getOrderList: string = allOrdersEndpoint.concat(
  "/find-order-by-course-code"
);

export const getWaitlistedOrderedCount: string = getClassOrderedCount.concat(
  "/waitlisted"
)

export const allScopes: string = allCurriculumsEndpoint.concat(
  "/scope"
);

export const allGradeLevelsEndpoint: string = allClassesEndpoint.concat(
  "/grade-levels"
);

export const getBundleTypesEndpoint: string = allBundlesEndpoint.concat(
  "/types"
);
export const getResourceTypesEndpoint: string = allResourcesEndpoint.concat(
  "/types"
);
export const getResourceGroupsEndpoint: string = allResourcesEndpoint.concat(
  "/groups"
);
export const getResourceOnlineAccountsEndpoint: string = allResourcesEndpoint.concat(
  "/online-accounts"
);

export const getByCodeClassEndpoint: string = allClassesEndpoint.concat(
  "/get-by-code"
);

export const getMainCourseBySubsequentCourseCodeCourseEndpoint: string = allClassesEndpoint.concat(
  "/get-main-course-by-subsequent-course-code"
);

export const getByCodeCurriculumEndpoint: string = allCurriculumsEndpoint.concat(
  "/get-by-code"
);
export const getByCodeResourcesEndpoint: string = allResourcesEndpoint.concat(
  "/get-by-code"
);
export const getByCodeBundlesEndpoint: string = allBundlesEndpoint.concat(
  "/get-by-code"
);
export const getByCurriculumIdClassEndpoint: string = allClassesEndpoint.concat(
  "/get-by-curriculum-id"
);
export const getShippingStatesEndpoint: string = allOrdersEndpoint.concat(
  "/shipping-states"
);
export const getShippingDetailsEndpoint: string = allStudentsEndpoint.concat(
  "/shipping-details"
);
export const getOrderItemTypeEndpoint: string = allOrdersEndpoint.concat(
  "/order-item-type"
);
export const getOldOrderItemTypeEndpoint: string = oldOrdersEndpoint.concat(
  "/order-item-type"
);
export const getOrderTransformEndpoint: string = allOrdersEndpoint.concat(
  "/transform"
);
export const getByCodeOrderEndpoint: string = allOrdersEndpoint.concat(
  "/get-by-code"
);
export const getByOldCodeOrderEndpoint: string = oldOrdersEndpoint.concat(
  "/get-by-code"
);
export const getOrderTotalEndpoint: string = allOrdersEndpoint.concat(
  "/get-order-total"
);
export const getTaskGroupTypeEndpoint: string = allTasksEndpoint.concat(
  "/task-group-type"
);
export const requestToCancelOrderItemEndpoint: string = allOrdersEndpoint.concat(
  "/request-to-cancel"
);
export const orderAddBookItemEndpoint: string = allOrdersEndpoint.concat(
  "/add-book-item"
);
export const orderUpdateShippingAddressEndpoint: string = allOrdersEndpoint.concat(
  "/update-shipping-address"
);
export const orderUpdateMultipleBookTrackingIdsEndpoint: string = allOrdersEndpoint.concat(
  "/update-multiple-book-tracking-id"
);
export const getStudentOrderedClasses: string = allOrdersEndpoint.concat(
  "/get-student-ordered-classes"
);
export const getStudentWaitlistedClasses: string = allOrdersEndpoint.concat(
  "/get-student-wait-listed-ordered-classes"
);
export const getStudentItemsByItemTypeEndpoint: string = allOrdersEndpoint.concat(
  "/get-student-items-by-item-type"
);

export const getStudentOrderedOnlineAccountEndpoint: string = allOrdersEndpoint.concat(
  "/get-student-ordered-online-accounts"
);

export const getStudentOldItemsByItemTypeEndpoint: string = oldOrdersEndpoint.concat(
  "/get-student-items-by-item-type"
);
export const getStudentOldOrderedItems: string = oldEndpoint.concat(
  getStudentItemsByItemTypeEndpoint
);
export const getStudentOrderedWorkbooks: string = allOrdersEndpoint.concat(
  "/get-student-ordered-workbooks"
);
export const getAllStudentOrderedWorkbooks: string = allOrdersEndpoint.concat(
  "/get-all-student-workbooks"
);
export const getStudentOrderedOtherBooks: string = allOrdersEndpoint.concat(
  "/get-student-ordered-other-books"
);
export const getStudentOrderedInventoryBooks: string = allOrdersEndpoint.concat(
  "/get-student-ordered-inventory-books"
);
export const getStudentOrderedBundle: string = allOrdersEndpoint.concat(
  "/get-student-ordered-bundles"
);
export const getOrderBundleByItem: string = allOrdersEndpoint.concat(
  "/get-order-bundle-by-item"
);
export const reformEosOrderEndpoint: string = allOrdersEndpoint.concat(
  "/reform-eos-order"
);
export const getOldItemLatestStatusHistoryEndpoint: string = oldEndpoint.concat(allOrdersEndpoint.concat(
  "/get-item-latest-status-history"
));
export const getItemLatestStatusHistoryEndpoint: string = allTxnHistoryEndpoint.concat(
  "/get-item-latest-status-history"
);
export const getItemLatestHistoryByStatusEndpoint: string = allTxnHistoryEndpoint.concat(
  "/get-item-latest-history-by-status"
);
export const getItemCreatedStatusHistoryEndpoint: string = allTxnHistoryEndpoint.concat(
  "/get-item-created-status-history"
);
export const refundEndpoint: string = oldOrdersEndpoint.concat(
  "/refund"
);
export const getItemRefundHistoryEndpoint: string = allOrdersEndpoint.concat(
  "/get-item-refund-history"
);
export const adjustItemAmountEndpoint: string = allOrdersEndpoint.concat(
  "/adjust-item-amount"
);
export const getItemAdjustmentHistoryEndpoint: string = allOrdersEndpoint.concat(
  "/get-item-adjustment-history"
);
export const updateTaskStatusEndpoint: string = allTasksEndpoint.concat(
  "/update-status"
);
export const updateWTBTaskStatusEndpoint: string = allTasksEndpoint.concat(
  "/update-wtb-status"
);
export const withdrawItemEndpoint: string = allTasksEndpoint.concat(
  "/withdraw"
);
export const updateAssetRelatedTaskEndpoint: string = allAssetsEndpoint.concat(
  "/updateAssetRelatedTask"
);
export const checkInStatusEndpoint: string = allAssetsEndpoint.concat(
  "/checkInStatus"
)
export const getUserAssignedCurriculumEndpoint: string = allTasksEndpoint.concat(
  "/user-assigned-curriculum"
);
export const getByOrderCodeTaskEndpoint: string = allTasksEndpoint.concat(
  "/get-by-order-code"
);
export const getRefundListPerStudentEndpoint: string = allOrdersEndpoint.concat(
  "/get-refund-list-student"
);
export const updateOrderModifiedOnlyEndpoint: string = allOrdersEndpoint.concat(
  "/update-order-modified-only"
);
export const updateTaskModifiedOnlyEndpoint: string = allTasksEndpoint.concat(
  "/update-task-modified-only"
);
export const getByResourceIdEndpoint: string = allResourcesEndpoint.concat(
  "/get-by-resource-id"
);

export const approveWaitlistEndpoint: string = allTasksEndpoint.concat(
  "/approve-waitlist"
);

export const svApproveEndpoint: string = allTasksEndpoint.concat(
  "/sv-approve"
);

export const getOrderNotesByCosCodeEndpoint: string = allOrdersEndpoint.concat(
  "/get-order-notes-by-cos-code"
);

export const saveOrderNoteEndpoint: string = allOrdersEndpoint.concat(
  "/save-order-note"
);

export const addVideoEndpoint: string = allVideosEndpoint.concat(
  "/add"
);

export const editVideoEndpoint: string = allVideosEndpoint.concat(
  "/edit"
);

export const changeCourseEndpoint: string = allTasksEndpoint.concat(
  "/change-course"
);

export const requestToWithdrawItemEndpoint: string = allTasksEndpoint.concat(
  "/requestWithdraw"
);

export const undoRequestToWithdrawItemEndpoint: string = allTasksEndpoint.concat(
  "/cancelRequestWithdraw"
);

export const verifyWdRequestEndpoint: string = allTasksEndpoint.concat(
  "/verifyWithdrawRequest"
);

export const processWdRequestEndpoint: string = allTasksEndpoint.concat(
  "/processWithdrawRequest"
);

export const studentForSvApprove: string = allTasksEndpoint.concat(
  "/student-for-sv-approve"
);

export const getCountByCodeAndStatus: string = allOrdersEndpoint.concat(
  "/count-by-code-and-status"
);

export const approveWaitlistedTasksEndpoint: string = allTasksEndpoint.concat(
  "/approve-waitlisted-tasks"
);

export const getOrdersByTasksEndpoint: string = allTasksEndpoint.concat(
  "/get-orders-by-tasks"
);

export const changeCoursesEndpoint: string = allTasksEndpoint.concat(
  "/change-courses"
);

export const getWdRequestAssigneesEndpoint: string = allTasksEndpoint.concat(
  "/wd-request-assignees"
);

export const getCurriculumAssigneesEndpoint: string = allCurriculumsEndpoint.concat(
  "/get-curriculum-assignees"
);

export const toggleCoursesStatusEndpoint: string = allClassesEndpoint.concat(
  "/toggle-courses-status"
);

export const getCartSession: string = allCartSessionEndpoint.concat(
  "/get-cart-session"
);
