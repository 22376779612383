import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Subject } from 'rxjs';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { orderClassDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { tableDateFormat } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { Router } from '@angular/router';
import { CLASSES_PATH } from 'src/app/core/constants/routes.constant';
import { Class } from 'src/app/core/models/class.model';

@Component({
  selector: 'app-order-class-resource-list',
  templateUrl: './order-class-resource-list.component.html',
  styleUrls: ['./order-class-resource-list.component.scss'],
  // providers: [{provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop'}]
})
export class OrderClassResourceListComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() isModal: boolean;
  @Output() getSelectedClass = new EventEmitter<any>();
  @Output() getClickedClass = new EventEmitter<any>();

  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();
  isTeacherRole
  selected: any;

  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private router: Router,
    private userService: UserService
  ) {
    this.subscribeToLoaderMessagingService();
    this.isTeacherRole = this.userService.hasRole([TEACHER]);
  }
  
  ngOnInit() {
    this.dataProperties = orderClassDataProperties;
    this.headersLength = this.dataProperties.length;
  }

  formatDate(date: string): string {
    return moment(date).format(tableDateFormat)
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  isCheckboxDisabled(item){
    // let isCheckboxDisabled = false;
    // if(this.isTeacherRole && item.closed){
    // isCheckboxDisabled = true;
    // }
    // return isCheckboxDisabled;
  }

  onClassLinkClick($event: any, selectedItem: any) {
    
    // this.items.forEach(item => {
    //   if(item.id !== selectedItem.id) {
    //     item.check = false;
    //   }
    //   });

    // Emits the selected class item

    // this.getClickedClass.emit(selectedItem);

    // open in new window
    window.open(window.location.origin + location.pathname + '#/' + CLASSES_PATH + '/' + selectedItem.id, '_blank', 'location=0');
  }

  onCheckboxChange($event: any, selectedItem: any){
    this.getSelectedClass.emit({selectedItem: selectedItem, checked: $event.checked});

  }

  onModalCheckboxChange(item: any, selectedItem: any, index: any){
    this.selected = index;
    this.getSelectedClass.emit({selectedItem: selectedItem});

  }

  public onRemoveItem(item: any){
    this.items.forEach(course => {
      if(course.code === item.referenceCode){
        course.checked = false;
      }
    });
  }
}
