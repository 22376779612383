import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { STUDENTS_PATH, VIDEOS_PATH } from 'src/app/core/constants/routes.constant';
import { allStudentsEndpoint, allGradeLevelsEndpoint, allVideosEndpoint, addVideoEndpoint, editVideoEndpoint } from 'src/app/core/constants/endpoints.constant';
import { homeModuleName, pageSizeOptions, ASC, OPEN, CLOSE } from 'src/app/core/constants/configuration/common.constant';
import { videoListDataProperties } from 'src/app/core/constants/configuration/video-constants.config';
import { ADMIN, homeRoutingPermissions, PROGRAM_DIRECTOR, settingsRoutingPermissions, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Video } from 'src/app/core/models/video.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { QueryService } from 'src/app/core/services/util/query.service';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { MatDialog } from '@angular/material';
import { AddNewVideoDialogComponent } from 'src/app/shared/components/add-new-video-dialog/add-new-video-dialog.component';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { EditVideoDialogComponent } from 'src/app/shared/components/edit-video-dialog/edit-video-dialog.component';

@Component({
	selector: 'app-videos',
	templateUrl: './videos.component.html',
	styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit, OnDestroy {

	@ViewChild(FilterComponent) filterComponent: FilterComponent;
	videos: any[] = [];
	items: any[] = [];
	itemsCount: number = 0;
	pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
	keyword: string = "";
	filters: any[];
	unsubscribe: Subject<any> = new Subject();
	reference: any = {};
	selectedSyId: number;
	schoolYear: string;
	filterLabels: string[] = [];
	videoListDataProperties: DataProperties[] = videoListDataProperties;
	clearFilters: boolean = true;
	includeInactive: boolean = false;
	isType1: boolean = false;
	trigger: string = CLOSE;
  	selectedFilter: string;

	filtersKey: string = "videoFilters";
	selectedFilterKey: string = "selectedFilter";
	filterIcon: IconDefinition = faFilter;

	constructor(
		private crudService: CrudService,
		private dataCustomizerService: DataCustomizerService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private queryService: QueryService,
    	private userService: UserService,
    	private filterService: FilterService,
		private dialog: MatDialog
	) {
		this.userService.checkRolePermission(VIDEOS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
		// Uncomment to retain search keyword and filters for Videos tab upon switching to another tab
		let videoFilters = this.filterService.getFilter(this.filtersKey);
		if (videoFilters) {
			this.keyword = videoFilters.keyword || "";
			let localStorageFilter = videoFilters.filters ?
				this.filterService.objectToMap(videoFilters.filters) : null;
			if (localStorageFilter && localStorageFilter.has(this.selectedFilterKey)) {
				this.selectedFilter = localStorageFilter.get(this.selectedFilterKey);
			}
		}
	}

	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.initParams();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
  
	initParams(): void {
		this.filters = [
			{filterName: "All", filterValue: 'all'},
			{filterName: "Active", filterValue: 'active'},
			{filterName: "Inactive", filterValue: 'inactive'}
		];
		this.selectedFilter = this.selectedFilter || this.filters[0].filterValue;
		this.search();
	}

	search(): void {
		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		// this.items = [
		// 	{
		// 		videoId: 237,
		// 		title: "Test1",
		// 		description: "Test11",
		// 		url: "https://www.google.com/",
		// 		active: false,
		// 		isToggleStatus: false
		// 	},
		// 	{
		// 		videoId: 238,
		// 		title: "Test2",
		// 		description: "Test22",
		// 		url: "https://www.google.com/",
		// 		active: true,
		// 		isToggleStatus: false
		// 	},
		// ];
		let localStorageFilter = new Map<string, string>();
		localStorageFilter.set(this.selectedFilterKey, this.selectedFilter);
		this.filterService.setFilter(this.filtersKey, this.keyword, localStorageFilter);

		this.crudService
			.getAll<Video>(allVideosEndpoint.concat(this.queryService.buildVideoSearchQuery(
        		this.keyword, this.selectedFilter, this.pageIndex, this.pageSize)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.itemsCount = response.totalElements;
					this.videos = response.content;
					response.content.forEach(video =>
						this.items.push(this.dataCustomizerService.formatVideoListDetailDisplay(video))
					);
				}
			},
			this.errorHandlerService.handleError,
			this.handleCompletion
		);
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
	};
	
	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}
  
	filterOnChange(filters: string) {
		this.selectedFilter = filters;
		this.pageIndex = 0;
		this.search();
	}
  
	onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
	}

	onClickAdd(){
		const dialogRef = this.dialog.open(AddNewVideoDialogComponent, { autoFocus: false });
		dialogRef.componentInstance.confirmTitle = 'Add New Video';
		dialogRef.componentInstance.cancelLabel = "Cancel";
		dialogRef.componentInstance.confirmLabel = "Add";
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.loaderMessagingService.showListLoader(true);
				this.crudService
					.add<Video>(addVideoEndpoint, result)
					.pipe(takeUntil(this.unsubscribe))
					.subscribe(response => {
						if (response) {
							const dialogRef = this.dialog.open(DialogBoxComponent, { autoFocus: false })
							dialogRef.componentInstance.dialogTitle = 'Added New Video';
							dialogRef.componentInstance.contentMessage = 'Successfully added a new video.'
							dialogRef.afterClosed()
								.pipe(takeUntil(this.unsubscribe))
								.subscribe(result => {
										if (!result) {
											this.search();
										}
									},
									this.errorHandlerService.handleError
								);
						}
					},
					this.errorHandlerService.handleError,
					this.handleCompletion
				);
			}
		});
	}

	get canAddNewVideo(){
		return this.userService.hasRole([ADMIN]);
	}

    onEdit(item: any){
		const dialogRef = this.dialog.open(EditVideoDialogComponent, { autoFocus: false, data: item });
		dialogRef.componentInstance.confirmTitle = 'Edit Video';
		dialogRef.componentInstance.cancelLabel = "Cancel";
		dialogRef.componentInstance.confirmLabel = "Edit";
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.loaderMessagingService.showListLoader(true);
				this.crudService
					.add<Video>(editVideoEndpoint, result)
					.pipe(takeUntil(this.unsubscribe))
					.subscribe(response => {
						if (response) {
							const dialogRef = this.dialog.open(DialogBoxComponent, { autoFocus: false })
							dialogRef.componentInstance.dialogTitle = 'Edited Video';
							dialogRef.componentInstance.contentMessage = 'Successfully edited the video.'
						}
						this.search();
					},
					this.errorHandlerService.handleError,
					this.handleCompletion
				);
			}
		});
    }

    onToggle(item: any){
		item.toggleStatus = true;
		this.loaderMessagingService.showListLoader(true);
		this.crudService
			.add<Video>(editVideoEndpoint, item)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					if(item.active){
						const dialogRef = this.dialog.open(DialogBoxComponent, { autoFocus: false })
						dialogRef.componentInstance.dialogTitle = 'Deactivated Video';
						dialogRef.componentInstance.contentMessage = 'Successfully deactivated the video.'
					} else {
						const dialogRef = this.dialog.open(DialogBoxComponent, { autoFocus: false })
						dialogRef.componentInstance.dialogTitle = 'Activated Video';
						dialogRef.componentInstance.contentMessage = 'Successfully activated the video.'
					}
					this.search();
				}
			},
			this.errorHandlerService.handleError,
			this.handleCompletion
		);
    }

}
