import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, EventEmitter } from '@angular/core';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import {
	allOrdersEndpoint,
	getAllSchoolYearsEndpoint,
	getProcessingStatusEndpoint,
	getCountByStatus,
	getActiveSchoolYearEndpoint,
	oldOrdersEndpoint,
	downloadOrdersEndpoint,
	getClassSubjectsEndpoint
} from 'src/app/core/constants/endpoints.constant';
import { Subject } from 'rxjs';
import { fullYearFormat, ASC, SY_CODE, homeModuleName, pageSizeOptions, CLOSE, OPEN, DESC, PAGE_INDEX, PAGE_SIZE, FIRST_SEM, SECOND_SEM, ACTIVE_PERIOD_TYPE } from 'src/app/core/constants/configuration/common.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { QueryService } from "src/app/core/services/util/query.service";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import * as moment from 'moment';
import { ORDERS_PATH, ORDER_CREATE_PATH } from 'src/app/core/constants/routes.constant';
import { Router } from '@angular/router';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { ADMIN, ASSISTANT_DIRECTOR, homeRoutingPermissions, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, SUPERVISOR, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Order } from 'src/app/core/models/order/order.model';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { COMPLETED, HST_APPROVED, orderDataProperties, PENDING_ORDERS, PROCESSED, WAITLISTED, PROCESSING_STATUS_6, PROCESSING_STATUS_7, PROCESSING_STATUS_8, PROCESSING_STATUS_9, PROCESSING_STATUS_10, PROCESSING_STATUS_11, WD_REQUEST, VERIFIED_WD_REQUEST, PROCESSING_STATUS_12, PROCESSING_STATUS_13 } from 'src/app/core/constants/configuration/order-constants.config';
import { MatDialog } from '@angular/material';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { changeSize } from 'src/app/core/constants/animations.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
} from 'src/app/core/constants/configuration/order-constants.config';
import { FilterService } from "src/app/core/services/util/filter.service";
import SortUtil from 'src/app/shared/components/utils/sort-utils/sort-util';
import { FieldTransformationToEndpointImpl } from 'src/app/shared/components/utils/sort-utils/transform-as-query';
import { DownloadFiltersDialogComponent } from 'src/app/shared/components/download-filters-dialog/download-filters-dialog.component';
import { DownloadFiltersTypeEnum } from 'src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxErrorTitle, downloadErrorNoDataOrder } from 'src/app/core/constants/message.constant';
import { DownloadFilters } from 'src/app/shared/components/download-filters-dialog/models/download-filters';
import { baseServerUri, downloadFileExtension, fileNameOrder, xlsxFileMediaType } from 'src/app/core/constants/configuration/config.constant';
import { FileService } from 'src/app/core/services/data/file.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [changeSize]
})
export class OrdersComponent implements OnInit, FieldTransformationToEndpointImpl {
	
	@ViewChild(FilterComponent) filterComponent: FilterComponent;
	items: any[] = [];
	itemsCount = this.items.length;
	pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
	faFilter = faFilter;
	keyword: string = "";
	filters: Map<string, string> = new Map<string, string>();
	unsubscribe: Subject<any> = new Subject();
	reference: any = {};
	selectedSyId: number;
	schoolYear: string;
	filterLabels: string[] = [];
	orderDataProperties: DataProperties[] = orderDataProperties;
	schoolYears: any[] = [];
	processingStatus: any[] = [];
	clearFilters: boolean = true;
	sortedBy: string;
	sortOrder: string;
	isType1: boolean = false;
	trigger: string = CLOSE;
	statusFilter = new Map<string, string>();
	statusListCount = new Array<any>();
	filtersKey: string = "orderFilters";
	isRunning: boolean = false;
	selectedActivePeriodType: string = "";
	activePeriodTypes: any[] = [
		{display: "All", value: ""},
		{display: "1st Semester", value: FIRST_SEM},
		{display: "2nd Semester", value: SECOND_SEM}
	];
	showBanner: boolean = false;
	bannerMessage: string = "K12 curriculum is not currently available to order for the 2024/25 school year. It should be available soon. More information will be available in next week's Weekly Update.";

	constructor(
		private crudService: CrudService,
		private dataCustomizerService: DataCustomizerService,
		private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private queryService: QueryService,
		private router: Router,
		private userService: UserService,
		private filterService: FilterService,
		private downloadFileService: FileService
	) {
		this.userService.checkRolePermission(ORDERS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
		// Uncomment to retain search keyword and filters for Orders tab upon switching to another tab
		let orderFilters = this.filterService.getFilter(this.filtersKey);
		if (orderFilters) {
			this.keyword = orderFilters.keyword || "";
			this.filters = orderFilters.filters ? this.filterService.objectToMap(orderFilters.filters) : new Map<string, string>();
			if (orderFilters.filters.Size) {
				this.pageSize = orderFilters.filters.Size;
			}
			if (orderFilters.filters.Page) {
				this.pageIndex = orderFilters.filters.Page;
			}
		}
  }

  	ngOnInit() {
		this.setStatusDisplay(null);
		this.loaderMessagingService.showPageLoader(true);
		this.setReferenceSubjects();
		this.getSchoolYears();
	}

	onClickAdd() {
		this.router.navigateByUrl(ORDER_CREATE_PATH);
	}

	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}
  
	search() : void {
		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

		var syCode = this.filters.get("syCode");
		if(syCode != null && syCode == "2019-2020") {
			this.crudService
			.getAll<Order>(oldOrdersEndpoint.concat(this.queryService.buildOrderSearchQuery(
					this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
					if (response) {
						this.itemsCount = response.totalElements;
						response.content.forEach(order =>
							this.customFilter(order)
						);
					}
				},
				this.errorHandlerService.handleError,
				this.handleCompletion
			);
		} else {
			this.crudService
			.getAll<Order>(allOrdersEndpoint.concat(this.queryService.buildOrderSearchQuery(
					this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
					if (response) {
						this.itemsCount = response.totalElements;
						response.content.forEach(order => {
							this.customFilter(order);
						});
					}
				},
				this.errorHandlerService.handleError,
				this.handleCompletion
			);
		}
		
	}
	
	customFilter(order: any){

		let matchCount = 0;

		// For Course Name filter
		if (this.filters.has('courseName')){
			for(const orderItem of order.orderItems){
				if(orderItem.referenceName.toLowerCase().includes(this.filters.get('courseName').toLowerCase())){
					matchCount += 1;
				}
			}
			if(matchCount == 0){
				return;
			} else {
				matchCount = 0;
			}
		}

		// For Course Section filter
		if (this.filters.has('courseSection')){
			for(const orderItem of order.orderItems){
				if(orderItem.section.toLowerCase().includes(this.filters.get('courseSection').toLowerCase())){
					matchCount += 1;
				}
			}
			if(matchCount == 0){
				return;
			} else {
				matchCount = 0;
			}
		}

		// For Course Subject filter
		if (this.filters.has('courseSubject')){
			for(const orderItem of order.orderItems){
				if(this.dataCustomizerService.getSubjectName(orderItem.subject, this.reference.subjects).toLowerCase().includes(this.filters.get('courseSubject').toLowerCase())){
					matchCount += 1;
				}
			}
			if(matchCount == 0){
				return;
			} else {
				matchCount = 0;
			}
		}
		this.items.push(this.dataCustomizerService.createCustomizedOrder(order, this.getReferences()))
	}

	setReferenceSubjects() {
		this.crudService
		.getById<any>(getClassSubjectsEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
  
		  if (response) {
			this.reference.subjects = response;
		  }
  
		}, this.errorHandlerService.handleError);
	}

	getReferences() {
		return {
			processingStatus: this.processingStatus,
		}
	}

	onSetActivePeriodType() {
		if (this.filters.has(ACTIVE_PERIOD_TYPE)) {
			this.filters.delete(ACTIVE_PERIOD_TYPE);
		}

		this.setFilterLabels();
		this.getFilterCount();
		this.search();
	}
  
	setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
		  this.schoolYear = schoolYear.schoolYear;
		  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
		  this.setFilterLabels();
		  this.getFilterCount();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
						this.schoolYear = response.schoolYear;
						this.selectedSyId = this.schoolYears.find(sy =>
						String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
					
						if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
						this.setFilterLabels(); 
						this.getFilterCount();
						this.search();
					}
			}, this.errorHandlerService.handleError);
		}
	}
  
  setFilterLabels(): void {
		this.filterLabels = [];
		this.filters.forEach((value, key) => {
			if (value === "Completed") { value = "Processed" };
			let prop = this.orderDataProperties.find(property => property.property === key);
			if (prop && prop.label) this.filterLabels.push(`${prop.label}: ${value}`)
		});

		this.filters.set(SY_CODE, this.schoolYear);
		this.filters.set(ACTIVE_PERIOD_TYPE, this.selectedActivePeriodType);

		this.clearFilters = this.filters.size === 1;
		
		const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
		if (index !== -1) this.filterLabels.splice(index, 1);
		this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

		const activePeriodTypeIndex = this.filterLabels.findIndex(label => label.includes('Semester:'));
		if(activePeriodTypeIndex !== -1) {
			this.filterLabels.splice(activePeriodTypeIndex, 1);
		}
		const selectedActivePeriodTypeObj = this.activePeriodTypes.filter(activePeriodType => activePeriodType.value == this.selectedActivePeriodType);
		this.filterLabels.splice(1, 0, `Semester: ${selectedActivePeriodTypeObj[0].display}`);
  }
  
  getSchoolYears(): void {
		this.crudService
			.getById<any>(getAllSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.schoolYears = response;
				this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
				this.getProcessingStatus();
			}, this.errorHandlerService.handleError);
  }
  
  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
				this.initParams();
			}, this.errorHandlerService.handleError);
  }
  
  handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
  };
  
  initParams(): void {
		this.sortedBy = this.orderDataProperties[0].property;
		this.sortOrder = ASC;
		let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
		this.setSchoolYear(null);
  }
  
	onChangeFilterType(): void {
		this.isType1 = !this.isType1;
	}
  
	filterOnClick(): void {
		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];
			this.orderDataProperties.forEach(property => {
				if (property.filterable)
				data.push({
					label: property.label,
					prop: property.property,
					value: this.filters.get(property.property) || "",
				});
			});
			const modalStyle = { width: "30%" };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			});
		}
	}
  
	filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
		this.search();
	}
  
  	onClearFilters(): void {
		this.clearFilters = true;
		this.keyword = '';
		this.selectedActivePeriodType = '';
		this.filters.clear();
		this.filterOnChange(new Map<string, string>());
	}
  
  	onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.setPageSizeFilter(this.pageSize);
		this.setPageIndexFilter(this.pageIndex);
		this.search();
	}

	onFilterChange(event: any) {
		if(!this.isRunning){
			this.isRunning = true;
			this.statusFilter = event;
			this.trigger = OPEN;
			setTimeout(() =>{
				this.filterComponent.filterByStatus();
				this.isRunning = false;
			},500);
		}
	}

	getFilterCount() : void {
		let statusFilter : Map<string, string> = new Map<string, string>();
		let apiUrl = allOrdersEndpoint
		statusFilter.set(SY_CODE, this.schoolYear);
		if(this.schoolYear == "2019-2020") {
			apiUrl = oldOrdersEndpoint.concat(getCountByStatus);
		} else {
			apiUrl = allOrdersEndpoint.concat(getCountByStatus).concat("?syCode=" + this.schoolYear);
		}
		this.statusListCount = [];
		this.crudService
			.getById<Map<string, number>>(apiUrl)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus.reverse().forEach((status:any) => {
					let statusCount = {};
					statusCount = {
						name: status.name,
						code: status.code,
						count: response[status.code]
					}
					this.statusListCount.push(statusCount);
					this.statusListCount.sort((a, b) => a.code !== b.code ? a.code < b.code ? -1 : 1 : 0);
				});
				this.sortStatusListCount();
		}, this.errorHandlerService.handleError);
	}

	sortStatusListCount(){
		let sortedStatus = [{},{},{},{},{},{},{},{},{},{},{},{},{}];
		for(var status of this.statusListCount){
			switch(status.code){
				case PROCESSING_STATUS_1:
					sortedStatus.splice(0,1,status);
					break;
				case PROCESSING_STATUS_8:
					sortedStatus.splice(1,1,status);
					break;
				case PROCESSING_STATUS_2:
					sortedStatus.splice(2,1,status);
					break;
				case PROCESSING_STATUS_9:
					sortedStatus.splice(3,1,status);
					break;
				case PROCESSING_STATUS_4:
					sortedStatus.splice(4,1,status);
					break;
				case PROCESSING_STATUS_10:
					sortedStatus.splice(5,1,status);
					break;
				case PROCESSING_STATUS_11:
					sortedStatus.splice(6,1,status);
					break;
				case PROCESSING_STATUS_12:
					sortedStatus.splice(7,1,status);
					break;
				case PROCESSING_STATUS_13:
					sortedStatus.splice(8,1,status);
					break;
				case PROCESSING_STATUS_3:
					sortedStatus.splice(9,1,status);	
					break;	
				case PROCESSING_STATUS_6:
					sortedStatus.splice(10,1,status);	
					break;
				case PROCESSING_STATUS_7:
					sortedStatus.splice(11,1,status);	
					break;
				case PROCESSING_STATUS_5:
					sortedStatus.splice(12,1,status);	
					break;
			}
		}
		this.statusListCount = sortedStatus;

	}

	setPageIndexFilter(pageIndex: number): void {
		this.filters.set(PAGE_INDEX, String(pageIndex));
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}

	setPageSizeFilter(pageSize: number): void {
		this.filters.set(PAGE_SIZE, String(pageSize));
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}

	onToggleSort($event: any) {
		this.sortedBy = this.getSortedByAsQuery($event.sortedBy);
		this.sortOrder = $event.sortOrder;
		this.search();
	}

	// Transforms header as accepted parameter in the backend for search.
	getSortedByAsQuery(
		sortedBy: string
	): string {
		let response: string;
		switch(sortedBy) {
			case 'scope':
				response = 'description';
			break;
			case 'dateSubmitted':
				response = 'createdDate';
			break;
			case 'totalAmount':
				response = 'total';
			break;
			default:
				response = sortedBy;
			break;
		}
		return response;
	}

	setStatusDisplay(status){
		if(status == "Status: " + HST_APPROVED){
		  status = "Status: " + PENDING_ORDERS;
		} else if (status == "Status: " + COMPLETED){
		  status = "Status: " + PROCESSED;
		}
		if (status === "Completed") {
			return "Processed";
		  }
		else return status;
	  }
	
	// Exporting Orders to Excel
	openConfirmationDialog(){

		// Checks the count of items before user can proceed
		if(this.itemsCount > 0){
			
			// Opens the DownloadFiltersDialog
			const dialogRef = this.dialog.open(
				DownloadFiltersDialogComponent,
				{
					width: '450px',
					data: {
						schoolYears: this.schoolYears,
            			selectedSchoolYear: this.schoolYear,
						statuses: [PROCESSED, WAITLISTED, WD_REQUEST, VERIFIED_WD_REQUEST],
						type: DownloadFiltersTypeEnum.ORDER
					}
				}
			);
			
			// Do things after DownloadFiltersDialog closes 
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					result => {
						if (result) {

							// Get order count by filters
              				this.getOrderCountByFilters(result);

						}
					},
					this.errorHandlerService.handleError
				);

		} else {

			// Show error dialog
			const dialogRef = this.dialog.open(DialogBoxComponent);
			dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
			dialogRef.componentInstance.contentMessage = downloadErrorNoDataOrder;

		}

	}

	// Get order count by filters
	getOrderCountByFilters(filters: DownloadFilters){
		let selectedSchoolYearFilter = filters.statusName;
		let endpoint = '';
		let selectedStatusFilter = filters.status[0];

		if(filters.statusName == "2019-2020") {

			// Old endpoint for Order
			endpoint = oldOrdersEndpoint.concat(getCountByStatus);

		} else {

			// Current order count (by status) endpoint
			endpoint = allOrdersEndpoint
				.concat(getCountByStatus)
				.concat("?syCode=" + selectedSchoolYearFilter)

		}

		// Update statusListCount based on re-selected school year filter
		this.statusListCount = [];
		this.crudService
			.getById<Map<string, number>>(endpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				this.processingStatus.reverse().forEach((status:any) => {
					let statusCount = {};
					statusCount = {
						name: status.name,
						code: status.code,
						count: response[status.code]
					}
					this.statusListCount.push(statusCount);
					this.statusListCount.sort((a, b) => a.code !== b.code ? a.code < b.code ? -1 : 1 : 0);
				});

				// Check if item count is > 0 for the selected status filter
				// If item count is > 0 then proceed to download, else show error dialog
				switch(selectedStatusFilter){
					case PROCESSED:

						if(this.statusListCount.find(status => status.name == COMPLETED).count > 0){

							let statusCode = this.statusListCount.find(status => status.name == COMPLETED).code
							this.download(filters, statusCode);

						} else {

							const dialogRef = this.dialog.open(DialogBoxComponent);
							dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
							dialogRef.componentInstance.contentMessage = downloadErrorNoDataOrder;

						}

					break;
					case WAITLISTED:

						if(this.statusListCount.find(status => status.name == WAITLISTED).count > 0){

							let statusCode = this.statusListCount.find(status => status.name == WAITLISTED).code
							this.download(filters, statusCode);

						} else {

							const dialogRef = this.dialog.open(DialogBoxComponent);
							dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
							dialogRef.componentInstance.contentMessage = downloadErrorNoDataOrder;

						}

					break;

					case WD_REQUEST:

						if(this.statusListCount.find(status => status.name == WD_REQUEST).count > 0){

							let statusCode = this.statusListCount.find(status => status.name == WD_REQUEST).code
							this.download(filters, statusCode);

						} else {

							const dialogRef = this.dialog.open(DialogBoxComponent);
							dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
							dialogRef.componentInstance.contentMessage = downloadErrorNoDataOrder;

						}

					break;

					case VERIFIED_WD_REQUEST:

						if(this.statusListCount.find(status => status.name == VERIFIED_WD_REQUEST).count > 0){

							let statusCode = this.statusListCount.find(status => status.name == VERIFIED_WD_REQUEST).code
							this.download(filters, statusCode);

						} else {

							const dialogRef = this.dialog.open(DialogBoxComponent);
							dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
							dialogRef.componentInstance.contentMessage = downloadErrorNoDataOrder;

						}

					break;
					default:
						throw new Error('Invalid Download Filters Type');
				}

		}, this.errorHandlerService.handleError);

	}

	// Download data in Excel format from backend
	download(filters: DownloadFilters, statusCode: string){

		this.loaderMessagingService.showPageLoader(true);

		// Download endpoint for Order in backend
		const endpoint = baseServerUri
			.concat(downloadOrdersEndpoint)
			.concat(`?filters=${filters.statusName}`)
			.concat(`&status=${statusCode}`)

		this.downloadFileService.getFile(endpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					const blob = new Blob([response], {type: xlsxFileMediaType});
					saveAs(
					blob,
					fileNameOrder
						.concat(`_${filters.statusName}`)
						.concat(`_${filters.status[0]}`)
						.concat(downloadFileExtension)
					);
				},
				this.errorHandlerService.handleError,
				this.handleCompletion
			);

	}

	get canAddNewOrder(){
		return this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, TEACHER, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]);
	}
}