import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp, IconDefinition, faBell, faCogs, faCheckCircle, faTimesCircle, faClipboardList, faStamp, faUndo, faCoins, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'task-statistics',
  templateUrl: './task-statistics.component.html',
  styleUrls: ['./task-statistics.component.scss']
})
export class TaskStatisticsComponent implements OnInit {

	@Output() filterChange = new EventEmitter();
	@Input() statusListCount: any;
	filterStatus = new Map<string, string>();
	approvedIcon: IconDefinition = faThumbsUp;
	processingIcon: IconDefinition = faCogs;
	preCompletedIcon: IconDefinition = faBell;
	completedIcon: IconDefinition = faCheckCircle;
	canceledIcon: IconDefinition = faTimesCircle;
	clipboardList: IconDefinition = faClipboardList;
	clock: IconDefinition = faClock;
	svApprovalIcon: IconDefinition = faStamp;
	wdRequestIcon: IconDefinition = faUndo;
	verifyIcon: IconDefinition = faCheckCircle;
	finalizedWdRequestIcon: IconDefinition = faUndo;
	finalizedIcon: IconDefinition = faCoins;
	invoicedIcon: IconDefinition = faFileInvoiceDollar;

	constructor() { }

	ngOnInit() {
	}

	filter(value: string){
		this.filterStatus.set('status', value);
		this.filterChange.emit(this.filterStatus);
	  }

}
