import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/data/auth.service';
import { LoginMessagingService } from 'src/app/core/services/messaging/login-messaging.service';
import { Credential } from 'src/app/core/models/credential.model';
import { authorized, accessToken, loginUser, listFilters, bearerToken, unauthorized } from 'src/app/core/constants/configuration/config.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { AUTHORIZE_REDIRECT_PATH, AUTHORIZE_SUPERVISOR_REDIRECT_PATH } from 'src/app/core/constants/routes.constant';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { ADMIN, PROGRAM_DIRECTOR } from 'src/app/core/constants/configuration/role-constants.config';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { MfaPopupDialogComponent } from 'src/app/shared/components/mfa-popup-dialog/mfa-popup-dialog.component';
import { User } from 'src/app/core/models/user.model';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  unsubscribe: Subject<any> = new Subject();
  message: string;
  isProcessing: boolean;
  otpSuccess: boolean = false;
  otpErrorMessage: string = "";
  globalDialogRef: any;
  cosHolidayMessage: string = "";
  showNotice: boolean = true;

  constructor(
    private authService: AuthService,
    private loginMessagingService: LoginMessagingService,
    private router: Router,
    private gtag: GoogleAnalyticsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    //For Holiday Notice
    this.holidayNotice();
    localStorage.clear();
    this.loginMessagingService.sendMessage(unauthorized);
  }

  holidayNotice(message: string = this.cosHolidayMessage){
    if (this.showNotice && message.length > 0) {
      const dialogRef = this.dialog.open(DialogBoxComponent);
      dialogRef.componentInstance.dialogTitle = "Curriculum Ordering System Notice";
      dialogRef.componentInstance.contentMessage = message;
    }
  }

  openMFADialog(credential: Credential){
    let dialogRef = this.dialog.open(MfaPopupDialogComponent, {

    });

    this.globalDialogRef = dialogRef;
    dialogRef.componentInstance.email = credential.username;

    //Resend Verification Code
    dialogRef.componentInstance.resendCode.subscribe(() => {
      dialogRef.close();
      this.onLogin(credential);
    });

    //Validate
    dialogRef.componentInstance.onValidate.subscribe(() => {
      credential.otp = dialogRef.componentInstance.otp;
      this.onLogin(credential);
    });
  }

  onLogin(credential: Credential): void {
    this.isProcessing = true;

    this.authService
      .authenticate(credential)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          localStorage.setItem(accessToken, bearerToken.concat(response.access_token));
          this.getLoginUser();
          this.globalDialogRef.close();
        }

      }, (error: HttpErrorResponse) => {
        let statusDescription : string = error.error.error_description;
        let status = statusDescription.split(":")[0];
        

        if(status == "SENT"){
          this.openMFADialog(credential);
        } else if(status == "MATCH"){
          this.otpSuccess = true;
          this.globalDialogRef.close();
        } else if(status == "MISMATCH"){
          this.otpErrorMessage = statusDescription.split(":")[1];
          this.globalDialogRef.componentInstance.errorMessage = this.otpErrorMessage;
          this.globalDialogRef.componentInstance.mfaForm.get('otp').setErrors({ 'otpError': true });
        } else if(status.includes("CREDS_INVALID") || status == "FAILED"){
          this.message = statusDescription.split(":")[1];
        }
        this.isProcessing = false;
      });

  }

  getLoginUser(): void {
    this.authService.getLoginUser().pipe(takeUntil(this.unsubscribe)).subscribe(response => {

      if (response) {
        // Checks if user role is restricted from accessing COS (checks from HUB)
        if(this.isRoleAccessRestricted(response)) {
          this.logout(response.holidayMessage);
        } else {
          localStorage.setItem(listFilters, JSON.stringify({}));
          localStorage.setItem(loginUser, JSON.stringify(response));
          this.loginMessagingService.sendMessage(authorized);
          this.gtag.eventEmitter("login_", "login", "Logging Credentials", "click", 1);
          this.isProcessing = false;
          if(response.authorities[0].authority != ADMIN && response.authorities[0].authority != PROGRAM_DIRECTOR){
            this.router.navigateByUrl(AUTHORIZE_SUPERVISOR_REDIRECT_PATH);
          } else{
            this.router.navigateByUrl(AUTHORIZE_REDIRECT_PATH);
          }
        }
      }

    }, (error: HttpErrorResponse) => {
      this.message = error.error.error_description;
      this.isProcessing = false;
    });

  }

  isRoleAccessRestricted(response: User):boolean {
    return response && response.holidayMessage && response.holidayMessage.length > 0;
  }

  logout(holidayMessage: string = this.cosHolidayMessage): void {
    this.authService
      .logout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          localStorage.clear();
          this.loginMessagingService.sendMessage(unauthorized);
          this.holidayNotice(holidayMessage);
          this.isProcessing = false;
        }
      }, (error: HttpErrorResponse) => {
        this.message = error.error.error_description;
        this.isProcessing = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
