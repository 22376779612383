import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { pageSizeOptions, CLOSE, homeModuleName, ASC, fullYearFormat, OPEN, SY_CODE, ACTIVE, INACTIVE, SUCCESS } from 'src/app/core/constants/configuration/common.constant';
import { Page } from 'src/app/core/models/page.model';
import { Class } from 'src/app/core/models/class.model';
import { faFilter, faPlus, IconDefinition, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { QueryService } from 'src/app/core/services/util/query.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { FileService } from 'src/app/core/services/data/file.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { allClassesEndpoint, allCurriculumsEndpoint, getAllSchoolYearsEndpoint, getActiveSchoolYearEndpoint, downloadClassesEndpoint, getClassSubjectsEndpoint, allGradeLevelsEndpoint, getActivePeriodStatusEndpoint, getSchoolYearsWithHistory, getCountByFilter, toggleCoursesStatusEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { baseServerUri, xlsxFileMediaType, downloadFileExtension, fileNameClass } from 'src/app/core/constants/configuration/config.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxErrorTitle,
  downloadErrorNoDataClass,
  downloadConfirmationClass
} from 'src/app/core/constants/message.constant';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { classDataProperties } from 'src/app/core/constants/configuration/class-constants.config';
import { ADMIN, homeRoutingPermissions, PROGRAM_DIRECTOR, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { CLASS_CREATE_PATH, CLASS_UPDATE_PATH, CLASSES_PATH, ORDER_CREATE_PATH } from 'src/app/core/constants/routes.constant';
import { changeSize } from 'src/app/core/constants/animations.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";
import { DownloadFiltersDialogComponent } from 'src/app/shared/components/download-filters-dialog/download-filters-dialog.component';
import { DownloadFiltersTypeEnum } from 'src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum';
import { DownloadFilters } from 'src/app/shared/components/download-filters-dialog/models/download-filters';
import { ReferenceSchoolYearDTO } from 'src/app/core/models/dto/referennce-school-year-dto';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { ListComponent } from 'src/app/shared/components/list/list.component';
import { DialogMessagingService } from 'src/app/core/services/messaging/dialog-messaging.service';

@Component({
  selector: 'app-class-list',
  templateUrl: './class-list.component.html',
  styleUrls: ['./class-list.component.scss'],
  animations: [changeSize]
})
export class ClassListComponent implements OnInit {
  @ViewChild(ListComponent) listComponent: ListComponent;
  curriculums: any[] = [];
  unsubscribe: Subject<any> = new Subject();
  items: any[] = [];
  pageSize: number = pageSizeOptions[0];
  pageIndex: number = 0;
  classes: Page<Class>;
  faFilter = faFilter;
  filters: Map<string, string> = new Map<string, string>();
  itemsCount: number;
  keyword: string = "";
  sortOrder: string;
  sortedBy: string;
  trigger: string = CLOSE;
  isType1: boolean = false;
  filterLabels: string[] = [];
  clearFilters: boolean = true;
  schoolYears: any[] = [];
  selectedSyId: number;
  schoolYear: string;
  classDataProperties: DataProperties[] = classDataProperties;
  reference: any = {};
	filtersKey: string = "classFilters";
  schoolYearHistory: ReferenceSchoolYearDTO[];
  activeSchoolYear: string;
  addIcon: IconDefinition = faPlus;
  checkedItems: any[] = [];
  toggleOnIcon: IconDefinition = faToggleOn;
  toggleOffIcon: IconDefinition = faToggleOff;
  showBanner: boolean = false;
  bannerMessage: string = "K12 curriculum is not currently available to order for the 2024/25 school year. It should be available soon. More information will be available in next week's Weekly Update.";

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private downloadFileService: FileService,
    private loaderMessagingService: LoaderMessagingService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private filterService: FilterService,
    private dialogMessagingService: DialogMessagingService,
	) {
		this.userService.checkRolePermission(CLASSES_PATH, homeRoutingPermissions);
    this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
    // Uncomment to retain search keyword and filters for Course tab upon switching to another tab
    let classFilters = this.filterService.getFilter(this.filtersKey);
		if (classFilters) {
			this.keyword = classFilters.keyword || "";
			this.filters = classFilters.filters ? this.filterService.objectToMap(classFilters.filters) : new Map<string, string>();
		}
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.setReferenceSubjects();
    this.orderCourseListener();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    window.removeEventListener("storage", this._listener, false);
  }

  initParams(): void {
    this.sortedBy = this.classDataProperties[1].property;
    this.sortOrder = ASC;
    let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
    this.setSchoolYear(null);
    this.getSchoolYersFromCurrentToEarliest();
  }

  onSearch(keyword: string): void {
    this.keyword = keyword;
    this.pageIndex = 0;
    this.search();
  }

  search() : void {
    // Clears current course selection
    this.checkedItems = [];
    this.listComponent.clearCheckedItems();

    this.loaderMessagingService.showListLoader(true);
    this.items = [];
    this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

    this.crudService
      .getAll<Class>(allClassesEndpoint.concat(this.queryService.buildClassSearchQuery(
            this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          if (response) {
            this.itemsCount = response.totalElements;
            response.content.forEach(clazz => {
              this.items.push(this.dataCustomizerService.createCustomizedOrderClass(clazz, this.reference));
            });
          }
          this.getCurriculums();
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );


  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.search();
  }

  onLinkClick(id: string): void {

    // open in new window
    window.open(window.location.href + '/' + id + '?canOrderCourse=true', '_blank', 'location=0');
  }

  onEditClick(id: string): void {
    this.router.navigate([CLASS_UPDATE_PATH, id]);
  }

  onClickAdd() {
    this.router.navigateByUrl(CLASS_CREATE_PATH);
  }
  
  download(
    filters: DownloadFilters
  ): void {

    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri
      .concat(downloadClassesEndpoint)
      .concat(this.queryService
        .buildDownloadQueryParams(filters)
      );
    this.downloadFileService.getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response], {type: xlsxFileMediaType});
          saveAs(
            blob,
            fileNameClass
              .concat(`_${filters.statusName}`)
              .concat(downloadFileExtension)
          );
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );

    }
  
  openConfirmationDialog(): void {
    if (this.itemsCount > 0) {
			const dialogRef = this.dialog.open(
				DownloadFiltersDialogComponent,
				{
					width: '450px',
					data: {
						schoolYears: this.schoolYearHistory,
            selectedSchoolYear: this.schoolYear,
						statuses: [],
						type: DownloadFiltersTypeEnum.CLASS
					}
				}
			);
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					result => {
						if (result) {
              this.getClassCountByFilters(result);
						}
					},
					this.errorHandlerService.handleError
				);
		} else {
			const dialogRef = this.dialog.open(DialogBoxComponent);
			dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
			dialogRef.componentInstance.contentMessage = downloadErrorNoDataClass;
		}
  }

  getClassCountByFilters(filters: DownloadFilters) {
    const endpoint = allClassesEndpoint
			.concat(getCountByFilter)
      .concat(this.queryService.buildDownloadQueryParams(filters));
    this.crudService.getById<number>(
      endpoint
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          if (response > 0) {
            this.download(filters);
          } else {
            const dialogRef = this.dialog.open(DialogBoxComponent);
            dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
            dialogRef.componentInstance.contentMessage = downloadErrorNoDataClass;
          }
        }
      );
  }

  getCurriculums(): void {
		this.crudService
			.getById<any>(allCurriculumsEndpoint+"/all?syCode=" + this.schoolYear)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.curriculums = response;
			}, this.errorHandlerService.handleError);
	}

  filterOnClick(): void {

    if (!this.isType1) {
      this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
    } else {
      this.trigger = CLOSE;
      let data = [];

      this.classDataProperties.forEach(property => {

        if (property.filterable)
          data.push({
            label: property.label,
            prop: property.property,
            value: this.filters.get(property.property) || "",
          });

      });

      const modalStyle = { width: "30%" };
      const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
      dialogRef.componentInstance.curriculums = this.curriculums;
      dialogRef.afterClosed().subscribe(filters => {
        if (filters) this.filterOnChange(filters);
      });
    }

  }

  filterOnChange(filters: Map<string, string>) {
    this.filters = new Map<string, string>(filters);
    this.setFilterLabels();
    this.pageIndex = 0;
    this.search();
  }

  onToggleSort($event: any) {
    this.sortedBy = $event.sortedBy;
    this.sortOrder = $event.sortOrder;
    this.search();
  }

  onEditClassClick(id: string): void {
    this.router.navigate([CLASS_UPDATE_PATH, id]);
  }

  onChangeFilterType(): void {
    this.isType1 = !this.isType1;
  }

  setFilterLabels(): void {

    this.filterLabels = [];

    this.filters.forEach((value, key) =>
      this.filterLabels.push(`${this.classDataProperties.find(property => property.property === key).label}: ${value}`));

    this.filters.set(SY_CODE, this.schoolYear);
    
    this.clearFilters = this.filters.size === 1;

    const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
    if (index !== -1) this.filterLabels.splice(index, 1);

    this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

  }

  onClearFilters(): void {
    this.clearFilters = true;
    this.keyword = '';
    this.filters.clear();
    this.filterOnChange(new Map<string, string>());
  }

  getSchoolYears(): void {

    this.crudService
      .getById<any>(getAllSchoolYearsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.schoolYears = response;
        this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
        this.initParams();
      }, this.errorHandlerService.handleError);

  }

  setSchoolYear(schoolYear: any): void {
    this.selectedSyId = 0;
    if(schoolYear != undefined || schoolYear != null) {
      this.selectedSyId = schoolYear.id;
      this.schoolYear = schoolYear.schoolYear;
      if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
      this.setFilterLabels();
      this.search();
    } else {
      this.crudService
      .getById<any>(getActiveSchoolYearEndpoint)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.activeSchoolYear = response.schoolYear;
		      this.schoolYear = response.schoolYear;
          this.selectedSyId = this.schoolYears.find(sy =>
            String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
          if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
          this.setFilterLabels();
          this.search();
				}
    	}, this.errorHandlerService.handleError);
    }
  }

  private setReferenceSubjects(): void {

    this.crudService
      .getById<any>(getClassSubjectsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          this.reference.subjects = response;
          this.setReferenceGradeLevels();
        }

      }, this.errorHandlerService.handleError);

  }

  private setReferenceGradeLevels(): void {

    this.crudService
      .getById<any>(allGradeLevelsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          this.reference.gradeLevels = response;
          this.setReferencePeriodTypes();
        }

      }, this.errorHandlerService.handleError);

  }

  private setReferencePeriodTypes(): void {

    this.crudService
      .getById<any>(getActivePeriodStatusEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {

        if (response) {
          this.reference.periodTypes = response;
          this.getSchoolYears();
        }

      }, this.errorHandlerService.handleError);

  }

  getSchoolYersFromCurrentToEarliest() {
    const endpoint = getAllSchoolYearsEndpoint
			.concat(getSchoolYearsWithHistory);
		this.crudService.getAllBy<ReferenceSchoolYearDTO>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					this.schoolYearHistory = response;
				}
			)
  }

  canEdit(): Boolean {
		if(this.activeSchoolYear) {
			return this.selectedSyId >= this.schoolYears.find(sy =>
				String(sy.schoolYear).trim() === this.activeSchoolYear.trim()).id;
		}	
		return false;
	}

  get canAddClass(): Boolean {
    return this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]);
  }

  orderCourseListener() {
    if (window.addEventListener) {
      window.addEventListener("storage", this._listener, false);
    }
  }

  private _listener = () => {
		let createOrder = localStorage.getItem('createOrder');
    if(createOrder){
      // Redirect
      this.router.navigateByUrl(ORDER_CREATE_PATH);
    }
  }

	get canOrderCourse():boolean {
    return this.checkedItems && this.checkedItems.length > 0 && this.checkedItems.every((item:any) => {
      return this.checkedItems[0].curriculum.code === item.curriculum.code;
    });
	}

	onOrderCourse(){
		// Remove saved objects in localstorage
		localStorage.removeItem('courseOrderDetails');
		localStorage.removeItem('curriculumOrderDetails');
		localStorage.removeItem('createOrder');

    // Get class curriculum details
    let curriculumOrderDetails: any = '';
    this.crudService
    .getById<Class>(allClassesEndpoint.concat(`/${this.checkedItems[0].id}`))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(response => {
      if (response) {
        curriculumOrderDetails = this.dataCustomizerService.createCustomizedCurriculum(response.classCurriculum);

        // Put the object into localStorage
        localStorage.setItem('curriculumOrderDetails', JSON.stringify(curriculumOrderDetails));
        localStorage.setItem('courseOrderDetails', JSON.stringify(this.checkedItems));
        localStorage.setItem('createOrder', 'true');

        // Fire storage event
        window.dispatchEvent(new Event('storage'));
      }
    }, this.errorHandlerService.handleError);
	}

  onToggleCourseStatus() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = this.areAllSelectedCoursesActive ? `Deactivate Selected Course${this.checkedItems.length > 1 ? 's' : ''}` : (this.areAllSelectedCoursesInactive ? `Activate Selected Course${this.checkedItems.length > 1 ? 's' : ''}` : `Activate / Deactivate Selected Course${this.checkedItems.length > 1 ? 's' : ''}`);
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to ${this.areAllSelectedCoursesActive ? "deactivate" : (this.areAllSelectedCoursesInactive ? "activate" : "activate / deactivate")} ${this.checkedItems.length} selected course${this.checkedItems.length > 1 ? 's' : ''}?`;
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.loaderMessagingService.showListLoader(true);
        this.loaderMessagingService.showPageLoader(true);
        this.crudService
        .add<Class[]>(toggleCoursesStatusEndpoint, this.checkedItems)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: Class[]) => {
          if (response) {
            this.dialogMessagingService.sendMessage({
              status: SUCCESS,
              value: `Successfully ${this.areAllSelectedCoursesActive ? "deactivated" : (this.areAllSelectedCoursesInactive ? "activated" : "activated / deactivated")} selected course${this.checkedItems.length > 1 ? 's' : ''}.`
            });
            this.search();
          }
        }, this.errorHandlerService.handleError);
      }
    })
  }

  onCheckboxChange(items: any[]){
    // Update list of checkedItems
    this.checkedItems = items;
  }

  get canToggleSelectedCoursesStatus():boolean {
    return this.checkedItems
      && this.checkedItems.length > 0
      && (this.areAllSelectedCoursesActive
        || this.areAllSelectedCoursesInactive);
	}

  get areAllSelectedCoursesActive():boolean {
    return this.checkedItems.every((item: any) => {return item.status === ACTIVE});
  }

  get areAllSelectedCoursesInactive():boolean {
    return this.checkedItems.every((item: any) => {return item.status === INACTIVE})
  }
}
