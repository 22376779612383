import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { studentDataProperties } from 'src/app/core/constants/configuration/order-constants.config';

@Component({
  selector: 'app-order-student-selection-list',
  templateUrl: './order-student-selection-list.component.html',
  styleUrls: ['./order-student-selection-list.component.scss']
})
export class OrderStudentSelectionListComponent implements OnInit {

  @Input() items: any[] = [];
  @Output() getSelectedStudent = new EventEmitter<any>();

  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private loaderMessagingService: LoaderMessagingService,
  ) {
    this.subscribeToLoaderMessagingService();
  }

  ngOnInit() {
    this.dataProperties = studentDataProperties;
    this.headersLength = this.dataProperties.length;
  }

  formatAmount(value: number): string {
    return value < 0 ? `$ (${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
      : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  onCheckboxCheck($event: any, id: number) {
    // Unchecks all other checkboxes other than the currently selected
    for (let index = 0; index < this.items.length; index++) {
      if (this.items[index].check && this.items[index].id !== id) {
        this.items[index].check = false
      }
    }

    this.getSelectedStudent.emit({id: id, checked: $event.checked});
  }

}
