import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Credential } from '../../../core/models/credential.model';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Input()
  message: String;

  @Input()
  isProcessing: boolean;

  @Output()
  login: EventEmitter<Credential> = new EventEmitter();

  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  password: FormControl = new FormControl('', [
    Validators.required,
  ]);

  constructor() { }

  ngOnInit() {

  }

  onSubmit(): void {

    if (this.email.valid && this.password.valid)
      this.login.emit(this.buildCredentials());

  }

  buildCredentials(): Credential {
    return {
      username: this.email.value.trim(),
      password: this.password.value.trim(),
      otp: null
    };
  }

}
