import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'add-new-video-dialog',
  templateUrl: './add-new-video-dialog.component.html',
  styleUrls: ['./add-new-video-dialog.component.scss']
})
export class AddNewVideoDialogComponent implements OnInit {

  constructor(
    public dlDialogRef: MatDialogRef<AddNewVideoDialogComponent>
  ) { }

  ngOnInit() {
  }

  private static urlValidator({value}: AbstractControl): null | ValidationErrors {
    try {
       new URL(value);
       return null;
    } catch {
       return {pattern: true};
    }
  }

  public confirmTitle: string;
  public cancelLabel: string;
  public confirmLabel: string;
  public reactiveForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required, AddNewVideoDialogComponent.urlValidator])
  })
  
  onClick(){
    if(this.reactiveForm.valid){
      this.dlDialogRef.close(this.reactiveForm.value);
    }
  }
}
