import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { videoListDataProperties } from 'src/app/core/constants/configuration/video-constants.config';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { STUDENTS_PATH } from 'src/app/core/constants/routes.constant';
import { Router } from '@angular/router';
import { UserService } from '../../../../../core/services/util/user.service';
import { ADMIN, PROGRAM_DIRECTOR } from 'src/app/core/constants/configuration/role-constants.config';
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
	selector: 'videos-list',
	templateUrl: './videos-list.component.html',
	styleUrls: ['./videos-list.component.scss']
})
export class VideosListComponent implements OnInit, OnDestroy {

    @Input() items: any[];
    @Output() onEdit = new EventEmitter<any>();
    @Output() onToggle = new EventEmitter<any>();

    unsubscribe: Subject<any> = new Subject();
    isLoading: boolean = false;
    dataProperties: DataProperties[] = videoListDataProperties;
    faPencilAlt = faPencilAlt;

    constructor(
        private userService: UserService,
        private loaderMessagingService: LoaderMessagingService,
        private router: Router,
    ) {
        this.subscribeToLoaderMessagingService();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    formatAmount(value: number): string {
      return value < 0 ? `$ (${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
        : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    onLinkClick(item: any): void {
        window.open(item.url);
    }

    private subscribeToLoaderMessagingService(): void {
        this.loaderMessagingService.listLoader$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(show => setTimeout(() => (this.isLoading = show)));
    }

    get canEditVideo(){
		return this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]);
	}

    onEditClick(item: any){
        this.onEdit.emit(item);
    }

    onToggleClick(item: any){
        this.onToggle.emit(item);
    }

	isVideoActive(item: any){
		return item.active;
	}

    get canViewActionCol(){
        return this.userService.hasRole([ADMIN]);
    }

    get canViewStatusCol(){
        return this.userService.hasRole([ADMIN]);
    }

}
