import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { allReportsEndpoint, getReportCOSProcessor } from 'src/app/core/constants/endpoints.constant';
import { EosEmployeeDetails } from 'src/app/core/models/eos/eos-employee-details.model';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { FileService } from 'src/app/core/services/data/file.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { QueryService } from 'src/app/core/services/util/query.service';
import { UserService } from 'src/app/core/services/util/user.service';

@Component({
  selector: 'app-cos-processor-list',
  templateUrl: './cos-processor-list.component.html',
  styleUrls: ['./cos-processor-list.component.scss']
})
export class CosProcessorListComponent implements OnInit {

  unsubscribe: Subject<any> = new Subject();
  isProcessing: boolean;
  keyword: string = "";
  cosProcessorList: EosEmployeeDetails[] = [];
  selectedCOSProcessorList: any[] = [];
  @Output() onSelectedCOSProcessor = new EventEmitter<any>();
  @Input() isEnabled: boolean = true;
  pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
  itemsCount: number = 0;

  constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private formsUtil: FormUtil,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
    private queryService: QueryService,
    private userService: UserService,
    private downloadFileService: FileService,
  ) { }

  ngOnInit() {
    this.getCOSProcessors();
  }

  onProcessorClick(cosProcessor){
    if(cosProcessor.isClicked){
      cosProcessor.isClicked = false;
      this.selectedCOSProcessorList = this.selectedCOSProcessorList.filter(item => item.email != cosProcessor.email);
      this.onSelectedCOSProcessor.emit(this.selectedCOSProcessorList);
    } else {
      cosProcessor.isClicked = true;
      this.selectedCOSProcessorList.push(cosProcessor);
      this.onSelectedCOSProcessor.emit(this.selectedCOSProcessorList);
    }
  }

  onSelectedProcessorClick(selectedCOSProcessor){
    selectedCOSProcessor.isClicked = false;
    this.selectedCOSProcessorList = this.selectedCOSProcessorList.filter(item => item.email != selectedCOSProcessor.email);
    this.onSelectedCOSProcessor.emit(this.selectedCOSProcessorList);
  }

  onPageChange($event: any): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.getCOSProcessors();
  }

  getCOSProcessors(){
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
    .getAll<EosEmployeeDetails>(getReportCOSProcessor.concat(this.queryService.buildCOSProcessorQuery(this.pageIndex, this.pageSize, "" , "" , this.keyword)))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(response => {

      if (response) {
        this.itemsCount = response.totalElements;
        this.cosProcessorList = response.content;
        if(this.selectedCOSProcessorList){
          for(let processor of this.cosProcessorList){
            for(let selectedProcessor of this.selectedCOSProcessorList){
              if(processor.email == selectedProcessor.email){
                processor.isClicked = true;
              }
            }
          }
        }
      }

    }, (error) => {
      this.isProcessing = false;
      this.errorHandlerService.handleError(error)
    }, this.handleCompletion);

  }

  onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.getCOSProcessors();
	}
  
	search() : void {
		this.loaderMessagingService.showListLoader(true);	
	}

  //Disable Loading
  handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  }

}
