import { DataProperties } from "../../models/data-properties.model";

export const TASK: string = "TASK";
export const TASK_GROUP_1: string = "TASK_GROUP_1";
export const TASK_GROUP_2: string = "TASK_GROUP_2";
export const TASK_GROUP_3: string = "TASK_GROUP_3";
export const TASK_GROUP_4: string = "TASK_GROUP_4";
export const TASK_GROUP_5: string = "TASK_GROUP_5";

export const UNASSIGN: string = "UNASSIGN";
export const ASSIGN_OTHERS: string = "ASSIGN_OTHERS";
export const ASSIGN_OTHERS_WD: string = "ASSIGN_OTHERS_WD";
export const ASSIGN_OTHERS_VWD: string = "ASSIGN_OTHERS_VWD";
export const ASSIGN_SELF: string = "ASSIGN_SELF";
export const ASSIGN_SELF_WD: string = "ASSIGN_SELF_WD";
export const ASSIGN_SELF_VWD: string = "ASSIGN_SELF_VWD";
export const UNASSIGN_WD: string = "UNASSIGN_WD";
export const UNASSIGN_VWD: string = "UNASSIGN_VWD";
export const PRE_COMPLETE: string = "PRE_COMPLETE";
export const COMPLETE: string = "COMPLETE";
export const CANCEL: string = "CANCEL";
export const WITHDRAW: string = "WITHDRAW";
export const WORKBOOKTEXTBOOK: string = "Workbook/Text Book";
export const APPROVE_WAITLIST: string = "APPROVE_WAITLIST";
export const CHANGE_COURSE: string = "CHANGE_COURSE";

export const taskDataProperties: DataProperties[] = [
    {
        label: "Task ID",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum",
        property: "curriculumName",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Course Name",
        property: "course",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "COS Order ID",
        property: "cosOrderCode",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "HUB Order ID",
        property: "eosOrderCode",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Student Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "districtId",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Task Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Order Request Date",
        property: "createdDate",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
      },
      {
        label: "Assigned To",
        property: "assignedTo",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
      },
      {
          label: "Course Name",
          property: "courseName",
          sortable: false,
          clickable: false,
          filterable: true,
          autocomplete: false,
          endpoint: ""
      },
      {
          label: "Course Section",
          property: "courseSection",
          sortable: false,
          clickable: false,
          filterable: true,
          autocomplete: false,
          endpoint: ""
      },
      {
          label: "Course Subject",
          property: "courseSubject",
          sortable: false,
          clickable: false,
          filterable: true,
          autocomplete: false,
          endpoint: ""
      },
      {
          label: "Notes",
          property: "notes",
          sortable: false,
          clickable: false,
          filterable: false,
          autocomplete: false,
          endpoint: ""
      }
];
