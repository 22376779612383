import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Class } from 'src/app/core/models/class.model';
import { allClassesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { CLASSES_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, createSuccessClass } from 'src/app/core/constants/message.constant';
import { sideNavLabelClass, CLASS } from 'src/app/core/constants/configuration/class-constants.config';
import { CREATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-class-create',
  templateUrl: './class-create.component.html',
  styleUrls: ['./class-create.component.scss']
})
export class ClassCreateComponent implements OnInit, OnDestroy {

	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	unsubscribe: Subject<any> = new Subject();
	disableSubsequentCourseParam: string = "";

	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event) {
		// Reenable Add Anouther Course button in the other Add Course page using listener and localStorage
		localStorage.setItem('enableAddAnotherCourse', 'true');
	}

	constructor(
		private headerMessagingService: HeaderMessagingService,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
		private router: Router,
    	private userService: UserService,
		private route: ActivatedRoute,
	) {
		this.userService.checkRolePermission(CLASSES_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(CLASS, CREATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelClass;
	}

	ngOnInit() {
		this.createSubsequentCourseInit();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onSaveClass($event: Class) {

		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.add<Class>(allClassesEndpoint, $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					// Presence of disableSubsequentCourseParam signifies that we need to close the popup Add Course window
					if (this.disableSubsequentCourseParam) {
						// Remove saved Subsequent Course details in localstorage, if any
						localStorage.removeItem("subsequentCourseCode");
						localStorage.removeItem("subsequentCourseName");
						
						// Save the other course's details in localstorage
						localStorage.setItem("subsequentCourseCode", response.code);
						localStorage.setItem("subsequentCourseName", response.name);

						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
						dialogRef.componentInstance.contentMessage = createSuccessClass;
						dialogRef.afterClosed().subscribe((response: any) => {
							window.close();	
						});
						
					} else {
						this.router.navigate([CLASSES_PATH, response.id]);
						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
						dialogRef.componentInstance.contentMessage = createSuccessClass;						
					}

				}

			}, this.errorHandlerService.handleError, this.handleCompletion);
		
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	createSubsequentCourseInit():void {
		// Gets param if subsequent courses option should be disabled (in the case of Add Course popup window from Add Course page)
		this.disableSubsequentCourseParam = this.route.snapshot.queryParamMap.get('disableSubsequentCourse') || "";
		// Remove saved object in localstorage
		// This is to prevent Add Another Course button from being enabled by refreshing the popup Add Course window
		localStorage.removeItem('enableAddAnotherCourse');
		// Manually fire storage event to trigger listener
		window.dispatchEvent(new Event('storage'));
	}

}
