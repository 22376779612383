import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { Order } from "src/app/core/models/order/old/old-order.model";
import { Task } from "src/app/core/models/order/old/old-task.model";
import { MatDialog } from "@angular/material";
import { Subject, Observable } from "rxjs";
import { takeUntil, map } from 'rxjs/operators';
import { CrudService } from "src/app/core/services/data/crud.service";
import { OldOrderItemRefundDialogComponent } from '../order-item-refund-dialog/order-item-refund-dialog.component';

import { getOrderItemTypeEndpoint,
  getResourceTypesEndpoint,
  getResourceGroupsEndpoint,
  getOrderTotalEndpoint,
  getItemLatestStatusHistoryEndpoint,
  getItemRefundHistoryEndpoint,
  getOrderDepositItemsEndpoint,
  getByCodeClassEndpoint,
  getByCodeCurriculumEndpoint,
  getByCodeResourcesEndpoint, 
  getByCodeBundlesEndpoint,
  oldEndpoint,
  getOldItemLatestStatusHistoryEndpoint,
  getOldOrderItemTypeEndpoint
} from "src/app/core/constants/endpoints.constant";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { dialogBoxSuccessTitle } from "src/app/core/constants/message.constant";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle, faUndo, faPen, faDollarSign, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import {
  ORDER_ITEM_TYPE_1,
  ORDER_ITEM_TYPE_2,
  ORDER_ITEM_TYPE_3,
  ORDER_ITEM_TYPE_4,
  ORDER_ITEM_TYPE_5,
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  ORDER_ITEM_TYPE_7,
  ORDER_ITEM_TYPE_8,
  PROCESSING_STATUS_7,
} from "src/app/core/constants/configuration/old-order-constants.config";
import { TASK_GROUP_3, TASK_GROUP_2 } from 'src/app/core/constants/configuration/task-constants.config';
import { RESOURCE_TYPE_CODE_1 } from 'src/app/core/constants/configuration/resource-constants.config';
import { UserService } from "src/app/core/services/util/user.service";
import { ADMIN, SUPERVISOR, SPECIALIST, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR } from 'src/app/core/constants/configuration/role-constants.config';
import { OrderItemStatusHistory } from 'src/app/core/models/order/old/old-order-item-status-history.model';
import { OrderItemRefundHistory } from 'src/app/core/models/order/old/old-order-item-refund-history.model';

import { withdrawnMsg } from 'src/app/core/constants/message.constant';
import { PROCESSING_STATUS_8 } from 'src/app/core/constants/configuration/order-constants.config';

@Component({
  selector: 'app-old-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OldOrderItemsComponent implements OnInit {

  @Input() canRemoveItem: boolean = false;
  @Input() forTaskPage: boolean = false;
  @Input() forPrintModal: boolean = false;
  @Input() orderItemAssoc: any[] = [];
  @Input() processingStatus: any[];
  @Input() moduleName: any;
  @Input() taskDetails: Task[] = [];
  @Output() onGetNewOrderDetails = new EventEmitter<any>();
  @Output() onGetTotalAmount = new EventEmitter<any>();
  @Output() onRefund = new EventEmitter<any>();
  
  orderDetailsValue: Order;
  orderItemsList: any[] = [];
  unsubscribe: Subject<any> = new Subject();
  referenceTypes: any[];
  resourceTypes: any[];
  resourceGroups: any[];
  selectedItems: any[] = [];
  totalAmount: number = 0;
  canRequestToCancel: boolean = false;
  canUndoRequestToCancel: boolean = false;
  selectedItemStatusHistory: OrderItemStatusHistory;
  selectedItemRefundHistory: OrderItemRefundHistory;
  taskFeeDepositCanRefund: any;

  completeIcon: IconDefinition = faCheckCircle;
  removeIcon: IconDefinition = faTimesCircle;
  undoIcon: IconDefinition = faUndo;
  editIcon: IconDefinition = faPen;
  refundIcon: IconDefinition = faDollarSign;
  withdraw: IconDefinition = faSignOutAlt;

  withdrawTooltipText : string = withdrawnMsg;

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.getReferenceTypes();
    this.taskFeeDepositCanRefund = false;
  }

  get isTaskFeeOrDeposit() {
    return this.taskDetails[0].code.includes("DEPOSIT") || this.taskDetails[0].code.includes("FEE");
  }

  @Input()
  set orderDetails(value) {
    this.orderDetailsValue = value;
    this.selectedItems = [];
    this.canUndoRequestToCancel = false;
    this.groupItemsByCurriculum();
    if (this.orderDetailsValue.code) this.getOrderTotal();
  }

  getResourceTypeName(code: string): string {
    let resourceType = this.resourceTypes ? this.resourceTypes.find(type => type.code === code) : "";
    return resourceType ? resourceType.name : "";
  }

  isAssociatedToTask(item: any): any {
    let orderItemAssocIds = [];
    this.orderItemAssoc.forEach(assoc => {
      orderItemAssocIds.push(assoc.txnOrderItemId);
    })

    if (this.forTaskPage && this.isWorkbookTextBookTask) {
      return (this.isClass(item) && this.isCurriculum(item.parentItem) && item.parentItem.curriculumOrderDetails.bookRequired) ||
        (this.isCurriculum(item) && item.curriculumOrderDetails.bookRequired) ? item : null;
    } else if (this.orderItemAssoc && this.orderItemAssoc.length > 0 && !this.isWorkbookTextBookTask) {
      if (orderItemAssocIds && orderItemAssocIds.includes(item.id)) {
        return item;
      } else {
        return null;
      }
    } else {
      return item;
    }
  }

  groupItemsByCurriculum() {
    this.orderItemsList = [];
    let tempOrderList = [];
    let tempResourceList = [];
    let curriculumOrderItems = [];

    if (this.orderDetailsValue) {
      this.orderDetailsValue.orderItems.forEach(item => {
        if (item.resourceOrderDetails) {
          if (this.isAssociatedToTask(item)) tempResourceList.push(this.isAssociatedToTask(item));
        } else {
          let itemExists = tempOrderList.slice().filter(listItem => listItem.referenceCode === item.referenceCode).length > 0;
          if (!(this.isCurriculum(item) && itemExists)) {
            if (this.isAssociatedToTask(item)) tempOrderList.push(this.isAssociatedToTask(item));
            if (this.isCurriculum(item)) curriculumOrderItems.push(item);
          }
          item.subItems.slice().filter(orderSubItem => orderSubItem.referenceCode).sort((a,b) => b.referenceType.localeCompare(a.referenceType)).forEach(subItem => {
            if (this.isCurriculum(subItem)) {
              curriculumOrderItems.push(subItem);
              let subItemExists = tempOrderList.slice().filter(listItem => listItem.referenceCode === subItem.referenceCode).length > 0;
              let isAssociatedToTask = this.isAssociatedToTask({ ...subItem, parentItem: { ...item, subItems: [] } });
              if (!subItemExists && isAssociatedToTask) tempOrderList.push(isAssociatedToTask);
            } else {
              let isAssociatedToTask = this.isAssociatedToTask({ ...subItem, parentItem: { ...item, subItems: [] } });
              if (isAssociatedToTask) tempOrderList.push(isAssociatedToTask);
            }
            if (subItem.subItems && subItem.subItems.length > 0) {
              subItem.subItems.slice().filter(orderSubItem => orderSubItem.referenceCode).sort((a,b) => b.referenceType.localeCompare(a.referenceType)).forEach(subSubItem => {
                let isAssociatedToTask = this.isAssociatedToTask({ ...subSubItem, parentItem: { ...subItem, subItems: [], parentItem: { ...item, subItems: [] } } });
                if (isAssociatedToTask) tempOrderList.push(isAssociatedToTask);
                if (subSubItem.subItems && subSubItem.subItems.length > 0) {
                  subSubItem.subItems.forEach(subSubSubItem => {
                    let isAssociatedToTaskSub = this.isAssociatedToTask({ ...subSubSubItem, parentItem: { ...subSubItem, subItems: [] } });
                    if (isAssociatedToTaskSub) tempOrderList.push(isAssociatedToTaskSub);
                  });
                }
              });
            }
          });
        }
      });

      if (this.orderItemAssoc && this.orderItemAssoc.length > 0 && tempOrderList.length > 0) {
        if (this.isClass(tempOrderList[0])) {
          let classCurriculum = curriculumOrderItems.find(item => item.referenceCode === tempOrderList[0].parentItem.referenceCode);
          if (classCurriculum) tempOrderList = [classCurriculum, ...tempOrderList];
        } else if (this.isResource(tempOrderList[0]) && this.isWorkbookTextBook(tempOrderList[0]) && tempOrderList[0].parentItem) {
          let resourceCurriculum = null;
          if (this.isCurriculum(tempOrderList[0].parentItem)) {
            resourceCurriculum = curriculumOrderItems.find(item => item.referenceCode === tempOrderList[0].parentItem.referenceCode);
          } else {
            resourceCurriculum = curriculumOrderItems.find(item => item.referenceCode === tempOrderList[0].parentItem.parentItem.referenceCode);
          }
          if (resourceCurriculum) tempOrderList = [resourceCurriculum, ...tempOrderList];
        }
      }
      
      tempOrderList = tempOrderList.slice().filter(item => !(this.isCurriculum(item) && item.id === null && item.price === 0 && item.deposit === 0 && item.subItems.length === 0));

      tempOrderList = tempOrderList.slice().filter(item => item.referenceCode);
      
      if (this.forPrintModal) tempOrderList = tempOrderList.slice().filter(item => item.assetCode && item.trackingId);
      if (this.forPrintModal) tempResourceList = tempResourceList.slice().filter(item => item.assetCode && item.trackingId);

      this.orderItemsList.push(...tempOrderList);
      this.orderItemsList.push(...tempResourceList);
    }

    if (!this.orderDetailsValue.code) {
      this.totalAmount = 0;
      this.orderItemsList.forEach(item => {
        if (item.status !== PROCESSING_STATUS_5) {
          if (!this.isClassReference(item) && !this.isCurriculum(item))
            this.totalAmount += item.price + item.deposit;
          if (this.isClass(item) && item.parentItem) {
            this.totalAmount += item.parentItem.perClassCost;
          }
        }
      });
  
      this.onGetTotalAmount.emit(this.totalAmount);
    }

    this.orderItemsList.forEach(item => {
      if(this.isShowModifiedDate(item) && !this.isDepositReference(item) && !this.isFeeReference(item)) {
        this.setLatestStatusDetails(item);
      }
      if(this.isShowModifiedDate(item)) {
        if(this.isRefunded(item)){
          this.setRefundHistoryDetails(item);
        }
      }
    });

    // For each items in orderItems List: Set activeStatus:
    //this.retrieveActiveStatus();
  }

  getActiveStatusEndpoint(
    referenceType: string,
    referenceCode: string
  ): string {
    let path: string;
    switch(referenceType) {
      case ORDER_ITEM_TYPE_1:
        path = getByCodeCurriculumEndpoint
      break;
      case ORDER_ITEM_TYPE_2:
        path = getByCodeClassEndpoint
      break;
      case ORDER_ITEM_TYPE_3:
        path = getByCodeResourcesEndpoint
      break;
      case ORDER_ITEM_TYPE_4:
          path = getByCodeBundlesEndpoint
      break;
    }
    return path
      .concat("?")
      .concat("code=")
      .concat(referenceCode);
  }
  
  getActiveStatus(
    value: string
  ): string {
      return value === 'STATUS_1' || value === 'ACTIVE' 
        ? 'Active' 
        : 'Inactive';
  }

  setRefundHistoryDetails(item) {
    this.crudService
    .getById<OrderItemRefundHistory>(oldEndpoint.concat(getItemRefundHistoryEndpoint.concat(`?orderItemId=${item.id}`)))
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(response => {
      if(response) {
        item.refundedModifiedDate = response.modifiedDate ? response.modifiedDate : "";
        item.refundedModifiedBy = response.modifiedBy ? response.modifiedBy : "";
      }
    }, this.errorHandlerService.handleError, this.handleCompletion);
    
  }

  setLatestStatusDetails(item) {
    this.crudService
      .getById<OrderItemStatusHistory>(getOldItemLatestStatusHistoryEndpoint.concat(`?orderItemId=${item.id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.statusHistoryModifiedDate = response.modifiedDate ? response.modifiedDate : "";
          item.statusHistoryModifiedBy = response.modifiedBy ? response.modifiedBy : "";
          item.statusHistoryStatus = response.status ? response.status : "";
        }
      }, this.errorHandlerService.handleError);
  }
  
  isBundle(item) {
    return item.referenceType === ORDER_ITEM_TYPE_4
  }
  
  isCurriculum(item) {
    return item.referenceType === ORDER_ITEM_TYPE_1
  }
  
  isClass(item) {
    return item.referenceType === ORDER_ITEM_TYPE_2
  }

  isRefClass(item) {
    return item.referenceType === ORDER_ITEM_TYPE_5
  }
  
  isResource(item) {
    return item.referenceType === ORDER_ITEM_TYPE_3
  }
  
  isDepositReference(item) {
    return item.referenceType === ORDER_ITEM_TYPE_7
  }
  
  isFeeReference(item) {
    return item.referenceType === ORDER_ITEM_TYPE_8
  }
  
  isWorkbookTextBook(item) {
    return item.resourceOrderDetails && item.resourceOrderDetails.type === RESOURCE_TYPE_CODE_1
  }

  isAlaCarteWorkbookTextBook(item) {
    let hasMatch = false;
    this.taskDetails.forEach(task => {
      if (task.groupType === TASK_GROUP_3) {
        task.orderItemAssoc.forEach(taskItem => {
          if (item.id === taskItem.txnOrderItemId) hasMatch = true;
        });
      }
    });
    return hasMatch;
  }
  
  isClassReference(item) {
    return item.referenceType === ORDER_ITEM_TYPE_5
  }
  
  isCancelled(item) {
    return item.status === PROCESSING_STATUS_5
  }

  isPreCompleted(item) {
    return item.status === PROCESSING_STATUS_3
  }

  isCompleted(item) {
    return item.status === PROCESSING_STATUS_4
  }

  isRefunded(item) {
    return item.status === PROCESSING_STATUS_6
  }

  isAdjusted(item) {
    return item.status === PROCESSING_STATUS_7
  }
  
  isRequired(item) {
    return item.resourceOrderDetails && item.resourceOrderDetails.required
  }

  formatAmount(value: number): string {
    return value >= 0 ? `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      : `$(${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
  }

  formatDate(value: Date): string {
    return moment(value).format('MMM DD, YYYY')
  }

  isItemSelected(item: any): boolean {
    return this.selectedItems.filter(selectedItem => selectedItem.referenceCode === item.referenceCode && selectedItem.id === item.id).length > 0
  }

  isAllItemSelected() {
    return this.selectedItems.length > 0 && this.selectedItems.length === this.orderItemsList.filter(item => !this.isCurriculum(item) && !this.isBundle(item) && !this.isCancelled(item)).length
  }

  get isWorkbookTextBookTask(): boolean {
    return this.taskDetails.filter(task => task.groupType === TASK_GROUP_2).length > 0;
  }

  get isOtherBooksTask(): boolean {
    return this.taskDetails.filter(task => task.groupType === TASK_GROUP_3).length > 0;
  }

  get canPreComplete(): boolean {
    return ((this.selectedItems.length > 0 && !this.isResource(this.selectedItems[0])) ||
      (this.selectedItems.length === 1 && this.isResource(this.selectedItems[0]))) &&
      this.selectedItems.filter(item => item.status !== PROCESSING_STATUS_2 || item.forCancel).length === 0
  }

  get isUserTeacher(): boolean {
    return !this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]);
  }

  onSelectAll($event: any) {
    if ($event.checked) {
      this.selectedItems = this.orderItemsList.filter(item => !this.orderItemAssoc || this.orderItemAssoc.length === 0 ? (this.isClass(item) || (item.resourceOrderDetails && 
        (!item.parentItem || (item.parentItem && !item.resourceOrderDetails.required)))) && !this.isCancelled(item) : !this.isCurriculum(item) && !this.isCancelled(item));
    } else {
      this.selectedItems = [];
    }
    this.getOrderDepositDetails();
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
    this.canRequestToCancel = this.selectedItems.length > 0 &&
      this.selectedItems.filter(selectedItem => selectedItem.forCancel ||
      (this.orderDetailsValue.status === PROCESSING_STATUS_1 && selectedItem.status === PROCESSING_STATUS_1)).length === 0;
    this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  onSelectItem($event: any, item: any) {
    if ($event.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    }
    this.getOrderDepositDetails();
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel && 
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status))
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
    this.canRequestToCancel = this.selectedItems.length > 0 &&
      this.selectedItems.filter(selectedItem => selectedItem.forCancel ||
      (this.orderDetailsValue.status === PROCESSING_STATUS_1 && selectedItem.status === PROCESSING_STATUS_1)).length === 0;
    this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  getReferenceTypeValue(value: string): string {
    let referenceType = this.referenceTypes ? this.referenceTypes.find(type => type.code === value) : null;
    return referenceType && referenceType.name;
  }

  getReferenceTypes() {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService.getById<any>(getOldOrderItemTypeEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.referenceTypes = response;
        this.getResourceTypes();
      }, this.errorHandlerService.handleError);
  }

  getResourceTypes(): void {
    this.crudService
      .getById<any>(getResourceTypesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceTypes = response;
        this.resourceTypes.sort((a, b) => a.code.localeCompare(b.code));
        this.getResourceGroups();
      }, this.errorHandlerService.handleError);
  }

  getResourceGroups(): void {
    this.crudService
      .getById<any>(getResourceGroupsEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.resourceGroups = response;
        this.resourceGroups.sort((a, b) => a.code.localeCompare(b.code));
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getOrderTotal(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<any>(oldEndpoint.concat(getOrderTotalEndpoint.concat(`?code=${this.orderDetailsValue.code}`)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.totalAmount = response;
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemLatestStatusHistory(orderItemId) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestStatusHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemStatusHistory = response;
        this.getItemRefundHistory(orderItemId);
      }, this.errorHandlerService.handleError);
  }

  getItemRefundHistory(orderItemId) {
    this.crudService
      .getById<OrderItemRefundHistory>(oldEndpoint.concat(getItemRefundHistoryEndpoint.concat(`?orderItemId=${orderItemId}`)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemRefundHistory = response;
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus.find(status => status.code === statusCode);
    return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      // case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      case PROCESSING_STATUS_8: return "waitlisted status"
      default: return "status"
    }
  }

  isShowModifiedDate(item) {
    return (!this.isRefClass(item) && (item.id != 0 && item.id != null)) && (this.isCompleted(item) || this.isPreCompleted(item) || this.isCancelled(item) || this.isRefunded(item) || this.isAdjusted(item))
  }

  hasAdditionalItemDetails(item) {
    return (item.forCancel || this.isShowModifiedDate(item))
  }

  hasReason(item) {
    return item.reason != null && item.reason != '';
  }

  isRefundable(): boolean {
    return this.getOrderDepositDetails();
  }

  getOrderDepositDetails(): any {
    if(this.selectedItems.length === 1 && (this.selectedItems[0].referenceType === ORDER_ITEM_TYPE_7 
      || this.selectedItems[0].referenceType === ORDER_ITEM_TYPE_8) 
      && (this.selectedItems[0].newPrice === null && this.selectedItems[0].newDeposit === null) 
      && (this.selectedItems[0].adjustedPrice === null && this.selectedItems[0].adjustedDeposit === null)){
      this.crudService.getById<any>(getOrderDepositItemsEndpoint.concat(`/${this.orderDetailsValue.id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response) {
          if(response.orderDepositList.length > 0){
            let cancelledItemCount = 0;
            let totalItemCount = 0;
          response.orderDepositList.forEach(item => {
            if(item.orderItemDepositList && item.orderItemDepositList.length > 0){
              item.orderItemDepositList.forEach(subItem => {
                if(subItem.type === ORDER_ITEM_TYPE_2 || subItem.type === ORDER_ITEM_TYPE_3) {
                  if(this.isCancelled(subItem) || this.isRefunded(subItem) || this.isAdjusted(subItem)) {
                    cancelledItemCount++;
                  }
                  totalItemCount++;
                }
              });
            } 
            if(item.type && (item.type === ORDER_ITEM_TYPE_2 || item.type === ORDER_ITEM_TYPE_3)) {
              if(this.isCancelled(item) || this.isRefunded(item) || this.isAdjusted(item)) {
                cancelledItemCount++;
              }
              totalItemCount++;
            }

          });
          if(totalItemCount == cancelledItemCount && !this.isRefunded(this.selectedItems[0]) 
          && !this.isAdjusted(this.selectedItems[0])){
            this.taskFeeDepositCanRefund = true;
          } else {
            this.taskFeeDepositCanRefund = false;
          }
        }
      }
      }, this.errorHandlerService.handleError);
    }else {
      this.taskFeeDepositCanRefund = false;
    }
  }

  refundConfirmDialog(forRefund: boolean) {
    const dialogRef = this.dialog.open(OldOrderItemRefundDialogComponent, { width: "50%" });
    dialogRef.componentInstance.item = this.selectedItems[0];
    dialogRef.componentInstance.forRefund = forRefund;
    dialogRef.componentInstance.close.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.componentInstance.refund.subscribe((result) => {
      if (result) {
        this.onRefund.emit({
          orderCode: this.orderDetailsValue.code,
          itemId: this.selectedItems[0].id,
          amount: result.amount,
          type: result.type,
          forRefund: forRefund,
          remarks: result.remarks,
          itemCodeName: `${this.selectedItems[0].referenceCode} ${this.selectedItems[0].referenceName}`,
        });
        this.taskFeeDepositCanRefund = false;
        this.canRequestToCancel = false;
        dialogRef.close();
      }
    });
  }

  get canAdjust(): boolean {
    return this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status);
  }
}
