import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faCheckCircle, faUndo, faPen, faDollarSign, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from "@angular/material";
import { Subject } from "rxjs";
import { Order } from "src/app/core/models/order/order.model";
import { Router } from '@angular/router';
import { CrudService } from "src/app/core/services/data/crud.service";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { dialogBoxSuccessTitle, updateChangeCourse, withdrawnMsg } from "src/app/core/constants/message.constant";
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  ORDER_ITEM_TYPE_1,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  ORDER_ITEM_TYPE_2,
  ORDER_ITEM_TYPE_3,
  ORDER_ITEM_TYPE_4,
  PROCESSING_STATUS_8,
  PROCESSING_STATUS_9,
  PROCESSING_STATUS_10,
  PROCESSING_STATUS_11,
  PROCESSING_STATUS_12,
  PROCESSING_STATUS_13,
} from "src/app/core/constants/configuration/order-constants.config";
import { UserService } from "src/app/core/services/util/user.service";
import { ADMIN, SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, TEACHER, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR } from 'src/app/core/constants/configuration/role-constants.config';
import { OrderItemAdjustmentDialogComponent } from '../order-item-adjustment-dialog/order-item-adjustment-dialog.component';
import { getOrderBundleByItem, getItemLatestStatusHistoryEndpoint, getItemAdjustmentHistoryEndpoint, getItemCreatedStatusHistoryEndpoint, getItemLatestHistoryByStatusEndpoint, changeCourseEndpoint, studentForSvApprove, getClassOrderedCount, getWdRequestAssigneesEndpoint } from '../../../../core/constants/endpoints.constant';
import { OrderItemStatusHistory } from '../../../../core/models/order/order-item-status-history.model';
import { takeUntil } from 'rxjs/operators';
import { OrderItemAdjustmentHistory } from '../../../../core/models/order/order-item-adjustment-history.model';
import { Class } from 'src/app/core/models/class.model';
import { OrderItemWithdrawDialogComponent } from '../order-item-withdraw-dialog/order-item-withdraw-dialog.component';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { OrderItemRefundConfirmationDialogComponent } from '../order-item-refund-confirmation-dialog/order-item-refund-confirmation-dialog.component';
import { Task } from 'src/app/core/models/order/task.model';
import { TASKS_PATH } from 'src/app/core/constants/routes.constant';
import { OrderBundle } from 'src/app/core/models/order/order-bundle.model';
import { CANCEL, COMPLETE, TASK_GROUP_2 } from 'src/app/core/constants/configuration/task-constants.config';
import { ConfirmSvApprovalDialogComponent } from 'src/app/shared/components/confirm-sv-approval-dialog/confirm-sv-approval-dialog.component';
import { ClassStartDateDialogComponent } from 'src/app/shared/components/class-start-date-dialog/class-start-date-dialog.component';
import { ClassListComponent } from 'src/app/domains/home/class/class-list/class-list.component';
import { OrderClassResourceComponent } from '../../order-create/order-class-resource/order-class-resource.component';
import { StudentForSvApprovalPopupComponent } from '../student-for-sv-approval-popup/student-for-sv-approval-popup.component';

@Component({
  selector: 'app-order-items-classes',
  templateUrl: './order-items-classes.component.html',
  styleUrls: ['./order-items-classes.component.scss'],
})
export class OrderItemsClassesComponent implements OnInit, OnChanges {

  @Input() canRemoveItem: boolean = true;
  @Input() orderItemAssoc: any[] = [];
  @Input() processingStatus: any[];
  @Input() forCreate: boolean;
  @Input() forTaskPage: boolean = false;
  @Input() waitlisted: boolean = false;
  @Input() svApproved: boolean = false;
  @Input() studentDetails: any;
  @Input() forSummary: boolean = false;
  @Input() canProgramDirectorDoAction: boolean = false;
  @Output() onGetNewOrderDetails = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onPreComplete = new EventEmitter<any>();
  @Output() onComplete = new EventEmitter<any>();
  @Output() onPreCompleteWTB = new EventEmitter<any>();
  @Output() onCompleteWTB = new EventEmitter<any>();
  @Output() onAdjust = new EventEmitter<any>();
  @Output() requestToCancel = new EventEmitter<any>();
  @Output() undoRequestToCancel = new EventEmitter<any>();
  @Output() onWithdraw = new EventEmitter<any>();
  @Output() onApprove = new EventEmitter<any>();
  @Output() onSvApprove = new EventEmitter<any>();
  @Output() onRequestToWithdraw = new EventEmitter<any>();
  @Output() onUndoRequestToWithdraw = new EventEmitter<any>();
  @Output() onVerifyWdRequest = new EventEmitter<any>();
  @Output() onRefund = new EventEmitter<any>();
  @Output() onNoRefund = new EventEmitter<any>();
  @Output() onAssignWD = new EventEmitter<any>();
  @Output() onAssignVWD = new EventEmitter<any>();
  @Output() onUnassignWD = new EventEmitter<any>();
  @Output() onUnassignVWD = new EventEmitter<any>();
  @Output() onAssignOthersWD = new EventEmitter<any>();
  @Output() onAssignOthersVWD = new EventEmitter<any>();
  orderDetailsValue: Order;
  taskDetailsValue: any;
  orderItemsList: any[] = [];
  orderGroupCodes: string[] = [];
  unsubscribe: Subject<any> = new Subject();
  referenceTypes: any[];
  selectedItems: any[] = [];
  selectedItemStatusHistory: OrderItemStatusHistory;
  selectedItemAdjustmentHistory: OrderItemAdjustmentHistory;
  selectedOrderBundleByItem: OrderBundle;
  canRequestToCancel: boolean = false;
  canUndoRequestToCancel: boolean = false;
  hasSvApproval: boolean = false;
  selectedUser: string = "";
  usernames: string[] = [];

  completeIcon: IconDefinition = faCheckCircle;
  removeIcon: IconDefinition = faTimesCircle;
  undoIcon: IconDefinition = faUndo;
  editIcon: IconDefinition = faPen;
  refundIcon: IconDefinition = faDollarSign;
  withdraw: IconDefinition = faSignOutAlt;

  collapse: boolean = false;
  //ICONS
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  collapsed() {
    this.collapse = !this.collapse;
  }

  REQUEST_CANCEL: any = { action: "REQUEST_CANCEL", label: ""  };
  UNDO_REQUEST_CANCEL: any = { action: "UNDO_REQUEST_CANCEL", label: ""  };
  // PRE_COMPLETE: any = { action: "PRE_COMPLETE", label: "pre-completed"  };
  COMPLETE: any = { action: "COMPLETE", label: "completed"  };
  CANCEL: any = { action: "CANCEL", label: "cancelled"  };

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
    private router: Router
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.collapse = !!this.forCreate;
    this.getWdRequestAssignees();
  }

  ngAfterContentInit(){
    this.selectedItems = this.orderItemsList;
  }

  ngOnChanges(){
    this.selectedItems = this.orderItemsList;
  }

  withdrawTooltipText : string = withdrawnMsg;

  @Input()
  set orderDetails(value) {
    this.orderDetailsValue = null;
    this.orderDetailsValue = value;
    this.selectedItems = [];
    this.groupItemsByCurriculum();
  }

  @Input()
  set taskDetails(value) {
    this.taskDetailsValue = value;
  }
  
  get canPreCompleteWTB(): boolean {
    return this.taskDetailsValue.status !== PROCESSING_STATUS_2;
  }

  get canCompleteWTB(): boolean {
    return this.taskDetailsValue.status !== PROCESSING_STATUS_2 && this.taskDetailsValue.status !== PROCESSING_STATUS_3 && this.canProgramDirectorDoAction;
  }

  get isWorkbookTextBook(): boolean {
    let isWorkbookTextBook = false;
    if(this.taskDetailsValue && this.taskDetailsValue.groupType === TASK_GROUP_2){
      isWorkbookTextBook = true;
    }
    return isWorkbookTextBook;
  }

  isClass(item){
    return item.referenceType === ORDER_ITEM_TYPE_1;
  }

  getReferenceTypeValue(value: string): string {
    let referenceType = this.referenceTypes ? this.referenceTypes.find(type => type.code === value) : null;
    return referenceType && referenceType.name;
  }

  groupItemsByCurriculum() {
    if(this.orderDetailsValue){
      this.orderItemsList = this.orderDetailsValue.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_1);
      this.orderItemsList.forEach(item => {
        this.getItemCreatedStatusDetails(item)
      });
      if (this.orderItemAssoc.length > 0) {
        let tempList = [];
        this.orderItemAssoc.forEach(itemAssoc => {
          this.orderItemsList.forEach(item => {
            if (item.id === itemAssoc.txnOrderItemId) {
              tempList.push(item);
            }
          });
        });
        this.orderItemsList = tempList;
      }
  
      this.orderDetailsValue.orderItems.forEach(item => {
        if (!this.orderGroupCodes.includes(item.txnOrderGroup)) this.orderGroupCodes.push(item.txnOrderGroup);
      });
      this.orderGroupCodes.sort();
      this.bindItemBundle(this.orderItemsList);
      // For each items in orderItems List: Set activeStatus:
      //this.retrieveActiveStatus();
    }
  }

  
  private bindItemBundle(itemList) {
    if(itemList != null && this.orderDetailsValue.orderBundles != null) {
      itemList.forEach(item => {
        this.orderDetailsValue.orderBundles.forEach(bundle => {
          bundle.orderBundleXCurriculums.forEach(curr => {
            curr.orderBundleCurriculumXClasses.forEach(currItem => {
              if(currItem.orderItem.id === item.id) {
                item.orderBundle = bundle;
              }
            })
          })
        })
      });
    }
  }


  getActiveStatusEndpoint(
    referenceType: string,
    referenceCode: string
  ): string {
    let path: string;
    switch(referenceType) {
      case ORDER_ITEM_TYPE_1:
        path = "/classes/get-by-code";
      break;
      // soon if status for other types.      
    }
    return path
    .concat("?")
    .concat("code=")
    .concat(referenceCode);
  }

  retrieveActiveStatus(): void {
    this.orderItemsList.forEach(
      orderItem => {
        let path: String;
        if (orderItem.referenceType == ORDER_ITEM_TYPE_1) {
          this.crudService.getById<Class>(
            this.getActiveStatusEndpoint(
              orderItem.referenceType,
              orderItem.referenceCode
            )
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            response => {
              orderItem.activeStatus = response.status === 'STATUS_1' || response.status === 'ACTIVE'
              ? 'Active'
              : 'Inactive';
            },
            this.errorHandlerService.handleError, this.handleCompletion
          );
        }
      }
    );
  }

  formatAmount(value: number): string {
    value = value != null ? value : 0;
    return value >= 0 ? `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      : `$(${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
  }
  
  formatDate(value: Date): string {
    return moment(value).format('MMM d, y hh:mm:ss a')
  }
  
  onRemoveItem(item) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to remove the class? \n" + 
      "This will automatically remove attached resources that were selected together with the class.";
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onGetNewOrderDetails.emit(item);
        const dialogRef = this.dialog.open(DialogBoxComponent);
        dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
        dialogRef.componentInstance.contentMessage = 'Class was successfully removed.';
      }
    });
  }

  get isUserTeacher(): boolean {
    return !this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]);
  }

  hasClass(code: string): boolean {
    return this.orderItemsList.filter(item => item.txnOrderGroup === code &&
      item.referenceType === ORDER_ITEM_TYPE_1).length > 0;
  }

  isItemSelected(item: any): boolean {
    return this.selectedItems.filter(selectedItem => selectedItem.referenceCode === item.referenceCode && selectedItem.id === item.id).length > 0
  }

  isAllItemSelected() {
    return this.selectedItems.length > 0 && this.selectedItems.length === this.orderItemsList.length
  }

  isRefunded(item) {
    return item.status === PROCESSING_STATUS_6
  }

  isAdjusted(item) {
    return item.status === PROCESSING_STATUS_7
  }

  onSelectAll($event: any) {
    if ($event.checked) {
      this.selectedItems = this.orderItemsList;
    } else {
      this.selectedItems = [];
    }
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
      // Also checks user roles
      this.canRequestToCancel = this.selectedItems.length > 0 && this.userService.hasRole([TEACHER, ASSISTANT_DIRECTOR]) &&
        this.selectedItems.filter(selectedItem => selectedItem.forCancel || !(selectedItem.status === PROCESSING_STATUS_2 || selectedItem.status ===PROCESSING_STATUS_9) || selectedItem.withdrawn).length === 0;
      this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.userService.hasRole([TEACHER, ASSISTANT_DIRECTOR]) && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  onSelectItem($event: any, item: any) {
    if ($event.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    }
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
      // Also checks user roles
      this.canRequestToCancel = this.selectedItems.length > 0 && this.userService.hasRole([TEACHER, ASSISTANT_DIRECTOR]) &&
        this.selectedItems.filter(selectedItem => selectedItem.forCancel || !(selectedItem.status === PROCESSING_STATUS_2 || selectedItem.status ===PROCESSING_STATUS_9) || selectedItem.withdrawn).length === 0;
      this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.userService.hasRole([TEACHER, ASSISTANT_DIRECTOR]) && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus.find(status => status.code === statusCode);
    if(statusName){
      if (statusName.name === "Completed") {
        return "Processed";
      }
      if (statusName.name === "HST Approved") {
        return "Pending Orders";
      }
      if (statusName.name === "Verified WD Request"){
        if(this.orderDetailsValue && this.orderDetailsValue.withdrawType){
          return `${statusName.name} - ${this.orderDetailsValue.withdrawType}`;
        } else {
          return statusName.name;
        }
      }
      return statusName.name;
    }
    else return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      // case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      case PROCESSING_STATUS_8: return "waitlisted status"
      case PROCESSING_STATUS_9: return "sv-approval status"
      case PROCESSING_STATUS_10: return "wd-request status"
      case PROCESSING_STATUS_11: return "verified-wd-request status"
      case PROCESSING_STATUS_12: return "finalized-wd-request status"
      case PROCESSING_STATUS_13: return "invoiced status"
      default: return "status"
    }
  }

  hasAdditionalItemDetails(item) {
    return (item.forCancel || this.isShowModifiedDate(item))
  }

  hasReason(item) {
    return item.reason != null && item.reason != '';
  }

  isShowModifiedDate(item) {
    return (item.id != 0 && item.id != null) && [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(item.status)
  }

  get canPreComplete(): boolean {
    return this.selectedItems && this.selectedItems.length > 0 && 
      this.selectedItems.filter(item => item.status !== PROCESSING_STATUS_2 || item.forCancel).length === 0 && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canComplete(): boolean {
    return this.selectedItems && this.selectedItems.length > 0 && this.canProgramDirectorDoAction && 
      this.selectedItems.filter(item => ![PROCESSING_STATUS_2, PROCESSING_STATUS_3].includes(item.status) || item.forCancel).length === 0 
      && this.orderDetailsValue.syCode != "2019-2020" 
      && (this.userService.hasRole([ADMIN]) 
      || (this.userService.hasRole([SUPERVISOR, SPECIALIST]) && 
      (this.selectedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0)) ||
      this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username);
  }

  get canCancel(): boolean {
    return this.isUserTeacher ? this.selectedItems && this.selectedItems.length > 0 &&
      this.selectedItems.filter(item => !(this.orderDetailsValue.status === PROCESSING_STATUS_1 && 
        item.status === PROCESSING_STATUS_1)).length === 0 && this.selectedItems.filter(item => item.inBundle).length <= 1
      : this.selectedItems.length > 0 && this.selectedItems.filter(item => item.inBundle).length <=1 && !this.isPastThirtyDays && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canNewCancel(): boolean {
    // if(this.userService.hasRole([ADMIN, SUPERVISOR, STUDENT_ACCOUNT_SPECIALIST])) {
    //   return this.selectedItems.length === 1 &&
    //     this.selectedItems.filter(item => !(item.status === PROCESSING_STATUS_2 || item.status === PROCESSING_STATUS_3 ||
    //     item.status === PROCESSING_STATUS_4 || item.status === PROCESSING_STATUS_5 ||
    //     item.status === PROCESSING_STATUS_6 || item.status === PROCESSING_STATUS_7)).length === 0 &&
    //     this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020";
    // } else {
    //   return this.selectedItems.length === 1 &&
    //     this.selectedItems.filter(item => !(item.status === PROCESSING_STATUS_3 ||
    //     item.status === PROCESSING_STATUS_4 || item.status === PROCESSING_STATUS_5 ||
    //     item.status === PROCESSING_STATUS_6 || item.status === PROCESSING_STATUS_7)).length === 0 &&
    //     this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020";
    // }
    if(this.selectedItems && this.selectedItems.length > 0){
      if(this.userService.hasRole([ADMIN])){
        return this.selectedItems.length === 1 && [PROCESSING_STATUS_1, PROCESSING_STATUS_2, PROCESSING_STATUS_8, PROCESSING_STATUS_9].includes(this.selectedItems[0].status) && this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020" && this.canProgramDirectorDoAction;
      } else if(this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST])){
        if([PROCESSING_STATUS_1, PROCESSING_STATUS_8].includes(this.selectedItems[0].status)){
          return this.selectedItems.length === 1 && this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020" && this.canProgramDirectorDoAction;
        } else if([PROCESSING_STATUS_2, PROCESSING_STATUS_9].includes(this.selectedItems[0].status)){
          return this.selectedItems.length === 1 && this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020" 
          && (this.selectedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0 ||
          this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username) && this.canProgramDirectorDoAction;
        } else {
          return false;
        }
      } else if(this.userService.hasRole([ASSISTANT_DIRECTOR, TEACHER, PROGRAM_DIRECTOR])){
        return this.selectedItems.length === 1 && [PROCESSING_STATUS_1, PROCESSING_STATUS_8].includes(this.selectedItems[0].status) && this.selectedItems.filter(item => item.inBundle).length <= 1 && this.orderDetailsValue.syCode != "2019-2020" && this.canProgramDirectorDoAction;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get canApprove(): boolean {
    let canApprove = false;

    // if(!this.isUserTeacher && this.orderDetailsValue.status == PROCESSING_STATUS_8) {
    //   canApprove = false;
    // }

    if(this.selectedItems && this.selectedItems.length === 1){
      if(this.userService.hasRole([ADMIN])) {
        if([PROCESSING_STATUS_8].includes(this.selectedItems[0].status)){
          canApprove = true;
        }
      } else if(this.userService.hasRole([PROGRAM_DIRECTOR])) {
        if(
          [PROCESSING_STATUS_8].includes(this.selectedItems[0].status)
          && this.userService.getLoginUser().username === this.selectedItems[0].curriculum.assignedTo
        ) {
          canApprove = true;
        }
      }
    }
    
    return canApprove && this.canProgramDirectorDoAction;
  }

  get canSvApprove(): boolean {
     let canApprove = false;

     if(this.selectedItems && this.selectedItems.length === 1){
      if(this.userService.hasRole([SUPERVISOR, ADMIN])){
        if([PROCESSING_STATUS_9].includes(this.selectedItems[0].status)){
          canApprove = true;
        }
      }
    }
    
    return canApprove && this.canProgramDirectorDoAction;
  }

  get canRefund(): boolean {
    return (this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4].includes(this.selectedItems[0].status) &&
      this.isPastThirtyDays && (this.selectedItems[0].newPrice === null || this.selectedItems[0].newDeposit === null || 
      this.selectedItems[0].newPerClassCost === null) && this.selectedItems[0].adjustedPrice === null && 
      this.selectedItems[0].adjustedDeposit === null && this.selectedItems[0].adjustedPerClassCost === null) && this.orderDetailsValue.syCode != "2019-2020";
  }

  get canNewRefund(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      // Also checks user roles
      return (this.selectedItems && this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_11].includes(this.selectedItems[0].status) &&
        (this.selectedItems[0].newPrice === null || this.selectedItems[0].newDeposit === null || 
        this.selectedItems[0].newPerClassCost === null) && this.selectedItems[0].adjustedPrice === null && 
        this.selectedItems[0].adjustedDeposit === null && this.selectedItems[0].adjustedPerClassCost === null) && this.orderDetailsValue.syCode != "2019-2020"
        && this.userService.hasRole([ADMIN, STUDENT_ACCOUNT_SPECIALIST, SPECIALIST, SUPERVISOR, PROGRAM_DIRECTOR])
        && this.orderDetailsValue && !this.orderDetailsValue.withdrawType &&
        this.taskDetailsValue && this.taskDetailsValue.assignedTo && this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username && this.canProgramDirectorDoAction;
    }
  }


  get canAdjust(): boolean {
    return (this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_12].includes(this.selectedItems[0].status)
      && this.userService.hasRole([ADMIN, STUDENT_ACCOUNT_SPECIALIST, SPECIALIST, PROGRAM_DIRECTOR])) ||
      this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_2, PROCESSING_STATUS_4].includes(this.selectedItems[0].status)
      && this.userService.hasRole([ADMIN, STUDENT_ACCOUNT_SPECIALIST])
  }
  
  get canWithdraw(): boolean {
    return (this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status) && 
      this.selectedItems[0].referenceType === ORDER_ITEM_TYPE_1 && !this.selectedItems[0].withdrawn)
      && this.userService.hasRole([ADMIN, SUPERVISOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]);
  }

  withdrawConfirmDialog() {
    const dialogRef = this.dialog.open(OrderItemWithdrawDialogComponent, { width: "50%" });
    dialogRef.componentInstance.item = this.selectedItems[0];
    dialogRef.componentInstance.close.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.componentInstance.withdraw.subscribe((result) => {
      const data = {};
      data["selectedItemId"] = this.selectedItems[0].id;
      data["itemName"] = this.selectedItems[0].referenceCode;
      data["itemCode"] = this.selectedItems[0].referenceName;
      data["reason"] = result; 
      this.onWithdraw.emit(data);
      dialogRef.close();
    });
  }

  get isPastThirtyDays(): boolean {
    if (this.selectedItems.length === 1 && this.selectedItemStatusHistory) {
      let dateLimit = moment(this.selectedItemStatusHistory.dateModified).add(30, 'days');
      return moment(this.selectedItems[0].rtcModifiedDate).isAfter(dateLimit);
    } else return false;
  }

  showConfirmDialogWTB(code: any){
    let message = `Are you sure you want to tag the task as ${code.label}?`;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
      dialogRef.componentInstance.confirmMessage = message;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          switch (code.action) {
            // case this.PRE_COMPLETE.action: {
            //   this.onPreCompleteWTB.emit(this.taskDetailsValue);
            //   this.selectedItems = [];
            //   this.canUndoRequestToCancel = false;
            //   break;
            // }
            case this.COMPLETE.action: {
              this.onCompleteWTB.emit(this.taskDetailsValue);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
          }
        }
      });
  }

  getStudentDetails(code: any, message: string){
    let studentForSvApprovalList = [];
    this.hasSvApproval = false;
    let studentForSvApprovalPopupClosed = false;
    let cosCodeList = [];
    cosCodeList.push(this.selectedItems[0].tempOrderCode);
    
		this.crudService
		.edit<any>(studentForSvApprove, cosCodeList)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe((response: any) => {
			if (response) {
				for(const student of response.content){
					// Check if already SV approved (e.g. has approveNotes)
					if(student.availableBalance < 0 && (this.orderDetailsValue.approveNotes === null || this.orderDetailsValue.approveNotes === "")){
						this.hasSvApproval = true;
						student.name = student.firstName + " " + student.lastName;
						studentForSvApprovalList.push(student);
					}
				}
        if(this.hasSvApproval){
				const dialogRef = this.dialog.open(StudentForSvApprovalPopupComponent, {
					data: {
						studentList: studentForSvApprovalList
					}
				  });
				  dialogRef.afterClosed().subscribe( response => {
					
					if (response) {
						studentForSvApprovalPopupClosed = true;

            if(this.hasSvApproval){
              message = message.replace("processed?", "sv approval?");
              this.selectedItems[0].hasSvApproval = this.hasSvApproval;
            }
            const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
            dialogRef.componentInstance.confirmMessage = message;
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                switch (code.action) {
                  case this.COMPLETE.action: {
                    this.onComplete.emit(this.selectedItems);
                    this.selectedItems = [];
                    this.canUndoRequestToCancel = false;
                    break;
                  }
                }
              }
            });
          }
				});
      } else if(!this.hasSvApproval && code.action === this.COMPLETE.action) {
        const classStartRef = this.dialog.open(ClassStartDateDialogComponent, { autoFocus: false });
        classStartRef.componentInstance.confirmTitle = 'Course Start date';
        classStartRef.componentInstance.confirmMessage = `Set course start date`;
        classStartRef.componentInstance.cancelLabel = "Cancel";
        classStartRef.componentInstance.confirmLabel = "Confirm";
        classStartRef.afterClosed().subscribe(dateResult => {
          if(dateResult) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
            dialogRef.componentInstance.confirmMessage = message;
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.onComplete.emit(this.selectedItems.map(items=> {
                  items.classStartDate = dateResult;
                  return items
                }));
                this.selectedItems = [];
                this.canUndoRequestToCancel = false;
              }
            });
          }
        })
      }
    };
	}, this.errorHandlerService.handleError, this.handleCompletion);
	}

  showConfirmDialog(code: any, event=null) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let selectedItemsCodes: string[] = [];
    this.selectedItems.slice().forEach(item => {
      selectedItemsCodes.push(item.referenceCode)
    });
    
    let message = `Are you sure you want to tag the selected ${this.selectedItems.length > 1 ? 'courses' : 'course'} as ${code.label == 'completed' ? 'processed' : code.label}?`;
    
    if (code.action === this.REQUEST_CANCEL.action) {
      message = `Are you sure you want to submit a Request to Cancel for the selected ${this.selectedItems.length > 1 ? 'courses' : 'course'}?`
    } else if (code.action === this.UNDO_REQUEST_CANCEL.action) {
      message = `Are you sure you want to undo the Request to Cancel for the selected ${this.selectedItems.length > 1 ? 'courses' : 'course'}?`
    }

    if(code.action == COMPLETE){
      this.getStudentDetails(code, message);
    }

    if (this.selectedItems && this.selectedItems.length > 0 && code.action !== COMPLETE && (!this.svApproved || code.action === CANCEL)) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
      dialogRef.componentInstance.confirmMessage = message;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          switch (code.action) {
            case this.REQUEST_CANCEL.action: {
              this.requestToCancel.emit(selectedItemsCodes);
              this.selectedItems = [];
              this.canRequestToCancel = false;
              break;
            }
            case this.UNDO_REQUEST_CANCEL.action: {
              this.undoRequestToCancel.emit(selectedItemsCodes);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
            // case this.PRE_COMPLETE.action: {
            //   this.onPreComplete.emit(this.selectedItems);
            //   this.selectedItems = [];
            //   this.canUndoRequestToCancel = false;
            //   break;
            // }
            case this.COMPLETE.action: {
              this.onComplete.emit(this.selectedItems);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
            case this.CANCEL.action: {
              this.onCancel.emit(this.selectedItems);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
          }
        }
      });
    }
  }

  refundConfirmationDialog() {
    if(this.selectedItems[0].status == PROCESSING_STATUS_6) {
      this.adjustmentConfirmDialog(true);
    } else {
      const dialogRef = this.dialog.open(OrderItemRefundConfirmationDialogComponent, { width: "50%" });
      dialogRef.componentInstance.item = this.selectedItems[0];
      dialogRef.componentInstance.close.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.componentInstance.cancel.subscribe(() => {
        dialogRef.close();
        this.showConfirmDialog(this.CANCEL);
      });
      dialogRef.componentInstance.refund.subscribe((result) => {
        dialogRef.close();
        this.adjustmentConfirmDialog(true);
      });
    }
  }

  adjustmentConfirmDialog(forRefund: boolean) {
    if(this.selectedItems[0].inBundle) {
      this.getBundleInfo(this.selectedItems[0].id, forRefund)
    } else {
      this.openAdjustmentDialog(forRefund, null)
    }
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  openAdjustmentDialog(forRefund: boolean, orderBundle: OrderBundle) {
    const dialogRef = this.dialog.open(OrderItemAdjustmentDialogComponent, { width: "50%", height: "100%" });
      dialogRef.componentInstance.item = this.selectedItems[0];
      dialogRef.componentInstance.forRefund = forRefund;
      dialogRef.componentInstance.withdrawType = this.orderDetailsValue.withdrawType;
      if(orderBundle != null) {
        dialogRef.componentInstance.orderBundle = orderBundle;
      }
      dialogRef.componentInstance.close.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.componentInstance.refund.subscribe((result) => {
        if (result) {
          this.onAdjust.emit({
            orderCode: this.orderDetailsValue.code,
            itemId: this.selectedItems[0].id,
            amount: result.amount,
            type: result.type,
            forRefund: forRefund,
            remarks: result.remarks,
            itemCodeName: `${this.selectedItems[0].referenceCode} ${this.selectedItems[0].referenceName}`,
          });
          this.canRequestToCancel = false;
          dialogRef.close();
        }
      });
  }

  changeCourseDialog() {
    const dialogRef = this.dialog.open(OrderClassResourceComponent, { width: "90%", height: "90%" });
      dialogRef.componentInstance.selectedCurriculum = this.selectedItems[0].curriculum;
      dialogRef.componentInstance.existingCourse = this.selectedItems[0].classDetails;
      dialogRef.componentInstance.selectedItems = this.selectedItems[0];
      dialogRef.componentInstance.isModal = true;
      dialogRef.componentInstance.close.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.componentInstance.changeCourse.subscribe((result) => {
        if (result) {
          this.orderDetailsValue.orderItems[0].classDetails.code = result.course.code;
          this.orderDetailsValue.orderItems[0].classDetails.name = result.course.name;
          this.loaderMessagingService.showPageLoader(true);
          this.crudService.add<any>(changeCourseEndpoint, this.orderDetailsValue)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((response) => {
            if(response){
              const dialogRef = this.dialog.open(DialogBoxComponent);
              dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
              dialogRef.componentInstance.contentMessage = updateChangeCourse;
              // Redirect after closing the dialog box
              dialogRef.afterClosed().subscribe(response => {
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([TASKS_PATH, this.orderDetailsValue.syCode, this.taskDetailsValue.id])).then(() => window.location.reload());
              });
            }
          }, this.errorHandlerService.handleError, this.handleCompletion);
          dialogRef.close();
        }
      });
  }

  getItemLatestStatusHistory(orderItemId) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestStatusHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemStatusHistory = response;
        this.getItemAdjustmentHistory(orderItemId);
      }, this.errorHandlerService.handleError);
  }

  getBundleInfo(orderItemId, forRefund) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderBundle>(getOrderBundleByItem.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedOrderBundleByItem = response;
        this.openAdjustmentDialog(forRefund, this.selectedOrderBundleByItem);
        this.handleCompletion();
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemCreatedStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemCreatedStatusHistoryEndpoint.concat(`?orderItemId=${item.id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.createdStatusHistoryModifiedDate = response.dateModified ? response.dateModified : "";
          item.createdStatusHistoryModifiedBy = response.modifiedBy ? response.modifiedBy : "";
          item.createdStatusHistoryStatus = response.status ? response.status : "";
        }
        this.getItemCancelledStatusDetails(item);
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemCancelledStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_5`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.cancelledStatusHistoryDate = response.dateModified ? response.dateModified : "";
          item.cancelledStatusHistoryModifiedBy = response.modifiedBy ? response.modifiedBy : "";
        }
        this.getItemPreCompletedStatusDetails(item);
    }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemPreCompletedStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_3`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.precompletedStatusHistoryDate = response.dateModified ? response.dateModified : "";
          item.precompletedStatusHistoryBy = response.modifiedBy ? response.modifiedBy : "";
        }
        this.getItemCompletedStatusDetails(item);
    }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemCompletedStatusDetails(item) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_4`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.completedStatusHistoryDate = response.dateModified ? response.dateModified : "";
          item.completedStatusHistoryBy = response.modifiedBy ? response.modifiedBy : "";
        }
    }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getItemAdjustmentHistory(orderItemId) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemAdjustmentHistory>(getItemAdjustmentHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemAdjustmentHistory = response;
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  getClassLink(item: any) {
    var task = this.taskDetailsValue.filter(task => {
      let orderItemFiltered = task.orderItemAssoc.filter(orderItem => {
        return orderItem.txnOrderItemId === item.id
      });
      if (orderItemFiltered.length > 0 && task.groupType =="TASK_GROUP_1") {
        return true;
      } else {
        return false;
      }
    })[0];
    this.router.navigate([TASKS_PATH, this.orderDetailsValue.syCode, task.id]);
  }


  openApproveDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Approve Waitlisted Order';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to approve this order?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Approve";
    dialogRef.afterClosed().subscribe(result => {

      if(result){
        this.onApprove.emit(this.selectedItems);
        this.selectedItems = [];
        this.canUndoRequestToCancel = false;
      }
    });
  }

  openSvApproveDialog() {
    const dialogRef = this.dialog.open(ConfirmSvApprovalDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'SV Approve Order';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to approve this order?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Approve";
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onSvApprove.emit({orderCode: this.orderDetailsValue.code, approveNotes: result, classStartDate: null});
        this.selectedItems = [];
        this.canUndoRequestToCancel = false;
      }
    });
  }

  isOutsideGradeLevels(item: any){
    if(item && this.studentDetails){
      if(this.studentDetails.gradeLevel < item.classDetails.fromGradeLevel || this.studentDetails.gradeLevel > item.classDetails.toGradeLevel){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get canChangeCourse(): boolean {
    let canChangeCourse = false;

    //  if(!this.isUserTeacher && this.isProcessed() && this.selectedItems.length == 0) {
    //   canChangeCourse = true;
    //  }

    if(this.selectedItems && this.selectedItems.length === 1){
      if(this.userService.hasRole([SUPERVISOR, ADMIN])) {
        if([PROCESSING_STATUS_1, PROCESSING_STATUS_2, PROCESSING_STATUS_4, PROCESSING_STATUS_8].includes(this.selectedItems[0].status)){
          canChangeCourse = true;
        }
      } else if(this.userService.hasRole([PROGRAM_DIRECTOR])){
        if([PROCESSING_STATUS_1, PROCESSING_STATUS_8].includes(this.selectedItems[0].status)){
          canChangeCourse = true;
        }
      } else if(this.userService.hasRole([SPECIALIST])) {
        if([PROCESSING_STATUS_1, PROCESSING_STATUS_2, PROCESSING_STATUS_8].includes(this.selectedItems[0].status)){
          canChangeCourse = true;
        }
      }
    }
    
    return canChangeCourse && this.canProgramDirectorDoAction;
 }

 isProcessed(){
  return this.selectedItems.filter(selectedItem => selectedItem.status === PROCESSING_STATUS_1 && selectedItem.status === PROCESSING_STATUS_2 && selectedItem.status === PROCESSING_STATUS_8 ).length === 0;
}

  get canRequestToWithdraw(): boolean {
    return this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_4].includes(this.selectedItems[0].status) && this.canProgramDirectorDoAction;
  }

  get canUndoRequestToWithdraw(): boolean {
    return this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_10].includes(this.selectedItems[0].status) &&
      this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST]) && this.canProgramDirectorDoAction;
  }

  requestToWithdrawConfirmDialog(event: any){
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Request to Withdraw Order';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to request to withdraw this order?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onRequestToWithdraw.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  undoRequestToWithdrawConfirmDialog(event: any){
    event.stopPropagation();

    this.crudService.getById<any>(getClassOrderedCount + "?classCode=" + this.selectedItems[0].referenceCode)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response) => {
      if(response != null){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
        dialogRef.componentInstance.confirmTitle = 'Undo Request to Withdraw Order';
    
        if(this.selectedItems[0].classDetails.maxSlot != null && this.selectedItems[0].classDetails.maxSlot <= response){
          dialogRef.componentInstance.confirmMessage = `There is NO AVAILABLE Seat. Are you sure you want to continue?`;
        } else {
          dialogRef.componentInstance.confirmMessage = `Are you sure you want to undo the request to withdraw this order?`;
        }
        dialogRef.componentInstance.cancelLabel = "Cancel";
        dialogRef.componentInstance.confirmLabel = "Confirm";
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.onUndoRequestToWithdraw.emit(this.selectedItems);
            this.selectedItems = [];
          }
        });
      }
    }, this.errorHandlerService.handleError, this.handleCompletion);




  }

  get canVerifyWdRequest(): boolean {
    return this.selectedItems && this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_10].includes(this.selectedItems[0].status) &&
      this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR, SUPERVISOR, STUDENT_ACCOUNT_SPECIALIST, SPECIALIST]) &&
      this.taskDetailsValue && this.taskDetailsValue.assignedTo && this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username && this.canProgramDirectorDoAction;
  }

  verifyWdRequestConfirmDialog(event: any){
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Verify WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to verify the request to withdraw this order?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onVerifyWdRequest.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  newRefundConfirmDialog(event: any){
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Refund Order';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to refund this order?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onRefund.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  get canNoRefund(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      return (this.selectedItems && this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_11].includes(this.selectedItems[0].status) &&
        this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, SUPERVISOR]))
        && this.orderDetailsValue && !this.orderDetailsValue.withdrawType &&
        this.taskDetailsValue && this.taskDetailsValue.assignedTo && this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username && this.canProgramDirectorDoAction;
    }
  }

  noRefundConfirmDialog(event: any){
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'No Refund Order';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to opt for no refund on this order?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onNoRefund.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  get canAssignWD(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      return this.selectedItems &&
        this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_10].includes(this.selectedItems[0].status) &&
        ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
          (this.taskDetailsValue.assignedTo === null ||
            this.taskDetailsValue.assignedTo === '')) ||
        ((this.userService.hasRole([ADMIN]) &&
          this.taskDetailsValue.assignedTo !== this.userService.getLoginUser().username))) && this.canProgramDirectorDoAction;
    }
  }

  get canAssignVWD(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      return this.selectedItems &&
        this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_11].includes(this.selectedItems[0].status) &&
        ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
          (this.taskDetailsValue.assignedTo === null ||
            this.taskDetailsValue.assignedTo === '')) ||
        ((this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
          this.taskDetailsValue.assignedTo !== this.userService.getLoginUser().username))) && this.canProgramDirectorDoAction;
    }
  }

  assignWDConfirmDialog(event: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Assign WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to assign the selected task to yourself?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onAssignWD.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  assignVWDConfirmDialog(event: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Assign Verified WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to assign the selected task to yourself?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onAssignVWD.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  get canUnassignWD(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      return this.selectedItems &&
        this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_10].includes(this.selectedItems[0].status) &&
        ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
          this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username) ||
        (this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
          (this.taskDetailsValue.assignedTo !== null &&
            this.taskDetailsValue.assignedTo !== ''))) && this.canProgramDirectorDoAction;
    }
  }

  get canUnassignVWD(): boolean {
    if(this.selectedItems.length <= 0) {
      return false;
    } else {
      return this.selectedItems &&
        this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_11].includes(this.selectedItems[0].status) &&
        ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
          this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username) ||
        (this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
          (this.taskDetailsValue.assignedTo !== null &&
            this.taskDetailsValue.assignedTo !== ''))) && this.canProgramDirectorDoAction;
    }
  }

  unassignWDConfirmDialog(event: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Unassign WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to unassign the selected task from ${this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username ? 'yourself' : this.taskDetailsValue.assignedTo}?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onUnassignWD.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  unassignVWDConfirmDialog(event: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Unassign Verified WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to unassign the selected task from ${this.taskDetailsValue.assignedTo === this.userService.getLoginUser().username ? 'yourself' : this.taskDetailsValue.assignedTo}?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onUnassignVWD.emit(this.selectedItems);
        this.selectedItems = [];
      }
    });
  }

  get showAssignToOthersWD(): boolean {
    return (
        this.userService.hasRole([SUPERVISOR, ADMIN]) &&
        this.selectedItems &&
        this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_10].includes(this.selectedItems[0].status) &&
        (this.taskDetailsValue.assignedTo === null ||
          this.taskDetailsValue.assignedTo === '')
        && this.canProgramDirectorDoAction
    )
  }

  get showAssignToOthersVWD(): boolean {
    return (
        this.userService.hasRole([SUPERVISOR, ADMIN]) &&
        this.selectedItems &&
        this.selectedItems.length === 1 &&
        [PROCESSING_STATUS_11].includes(this.selectedItems[0].status) &&
        (this.taskDetailsValue.assignedTo === null ||
          this.taskDetailsValue.assignedTo === '')
        && this.canProgramDirectorDoAction
    )
  }

  onSelectUser(user: string) {
    this.selectedUser = user;
  }

  assignOthersWDConfirmDialog(event: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Assign WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to assign the selected task to ${this.selectedUser}?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onAssignOthersWD.emit({
          selectedItems: this.selectedItems,
          selectedUser: this.selectedUser
        });
        this.selectedUser = "";
        this.selectedItems = [];
      }
    });
  }

  assignOthersVWDConfirmDialog(event: any) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Assign Verified WD Request';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to assign the selected task to ${this.selectedUser}?`;
    dialogRef.componentInstance.cancelLabel = "Cancel";
    dialogRef.componentInstance.confirmLabel = "Confirm";
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.onAssignOthersVWD.emit({
          selectedItems: this.selectedItems,
          selectedUser: this.selectedUser
        });
        this.selectedItems = [];
        this.selectedUser = "";
      }
    });
  }

  get canAssignToOthersWD(): boolean {
    return (
        this.selectedUser.length > 0 && this.canProgramDirectorDoAction
    )
  }

  get canAssignToOthersVWD(): boolean {
    return (
        this.selectedUser.length > 0 && this.canProgramDirectorDoAction
    )
  }

  getWdRequestAssignees(): void {
    if(
      this.taskDetailsValue &&
      this.taskDetailsValue.status &&
      (this.taskDetailsValue.status === PROCESSING_STATUS_10 ||
      this.taskDetailsValue.status === PROCESSING_STATUS_11)
    ) {
      this.crudService
      .getAllBy<string>(getWdRequestAssigneesEndpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
          if (response) {
            this.usernames = response.sort();
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
    }
  }
}
