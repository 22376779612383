import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Scope } from "src/app/core/models/scope.model";
import * as moment from 'moment';
import { NEXT, INACTIVE, ACTIVE } from 'src/app/core/constants/configuration/common.constant';
import { FormUtil } from 'src/app/core/services/util/form.util';
import { CurriculumMainForm } from 'src/app/core/models/form/curriculum-main-form.model';
import { allCurriculumsEndpoint, allScopes, existsByName } from 'src/app/core/constants/endpoints.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { invoke } from 'q';
import { ADMIN } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from 'src/app/core/services/util/user.service';

@Component({
	selector: 'curriculum-main-form',
	templateUrl: './curriculum-main-form.component.html',
	styleUrls: ['./curriculum-main-form.component.scss']
})
export class CurriculumMainFormComponent implements OnInit, OnDestroy {

	@Output() changePage = new EventEmitter<any>();
	@Output() getFormValue = new EventEmitter<any>();
	@Input() isFirst: boolean;
	@Input() isLast: boolean;
	@Input() forUpdate: boolean;
	@Input() reference: any;
	@Input() curriculumFormValues: CurriculumMainForm;
	@Input() code: string;
	@Input() programDirectors: string[] = [""];

	curriculumForm: FormGroup;
	minDate: string;
	isProcessing: boolean;
	delayTimer: NodeJS.Timer;
	timeout: number = 300;
	existsByNameEndpoint: string = allCurriculumsEndpoint.concat(existsByName);
	unsubscribe: Subject<any> = new Subject();
	exists: boolean = true;
	hasDeposit: boolean = false;
	bookRequired: boolean = false;
	scopeList: Scope[];
	scopeSelected: Scope[] = [];
	allScopesToggle: boolean = false;

	constructor(
		private formsUtil: FormUtil,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private userService: UserService,
	) { 
		this.curriculumForm = this.formsUtil.createCurriculumMainForm();
	}
	
	ngOnInit() {
		this.initParams();
		this.curriculumFormValues.scopes.forEach(scope => {
			this.scopeSelected.push(scope)
		});
		if(this.curriculumFormValues.assignedTo && !this.programDirectors.includes(this.curriculumFormValues.assignedTo)) {
			this.programDirectors.push(this.curriculumFormValues.assignedTo);
		}
		// this.getAllScopes();
	}
	
	private initParams = (): void => {
		this.minDate = new Date().toJSON().split('T')[0];
		if (this.forUpdate || this.curriculumFormValues) {
			this.initFormValues();
		} else {
			this.curriculumForm.controls['status'].setValue('');
		}
	}

	private initFormValues = (): void => {
		this.curriculumForm.setValue(this.formsUtil.initCurriculumMainForm(this.curriculumFormValues));
		this.updateStatus();
	}

	// private checkScope(scopeId: number): boolean {
	// 	var scope = this.scopeSelected.filter(scope => scopeId == (scope ? scope.id : -1));
	// 	return scope.length > 0;
	// }

	// private getScopeFromList(scopeId: number): Scope[] {
	// 	return this.scopeList.filter(scope => scopeId == scope.id);
	// }

	// private indexOfScope(scopeId: number): number {
	// 	return this.scopeSelected.findIndex(scope => scope.id === scopeId);
	// }

	// private isChecked(scopeId : number): boolean {
	// 	return this.checkScope(scopeId);
	// }

	// private onCheckScope(scopeId : number) {
	// 	if(scopeId != null) {
	// 		if(this.checkScope(scopeId)) {
	// 			let index = this.indexOfScope(scopeId);
	// 			this.scopeSelected.splice(index, 1);
	// 		} else {
	// 			var scopes: Scope[] = this.getScopeFromList(scopeId);
	// 			if(scopes.length > 0) {
	// 				this.scopeSelected.push(scopes[0])
	// 			}
	// 		}
	// 		this.allScopesToggle = this.scopeSelected.length == this.scopeList.length;
	// 	} else {
	// 		this.allScopesToggle = !this.allScopesToggle;
	// 		this.scopeSelected = this.scopeSelected.filter(scope => scope.status! == "STATUS_2");
	// 		if(this.allScopesToggle) {
	// 			this.scopeList.forEach(scope => {
	// 				if(scope.status == "STATUS_1") {
	// 					this.scopeSelected.push(scope);
	// 				}
	// 			});
	// 		}
	// 	}
	// 	if(this.curriculumFormValues.scopes) {
	// 		var maxLength = this.curriculumFormValues.scopes.length;
	// 		this.curriculumFormValues.scopes.splice(0, maxLength)
	// 	}
		
	// 	this.scopeSelected.forEach(scope => {
	// 		this.curriculumFormValues.scopes.push(scope);
	// 	});
	// }
	
	onToggleDeposit(): void {
		this.hasDeposit = this.curriculumForm.get("hasDeposit").value;
	}

	onToggleBookRequired(): void {
		this.bookRequired = this.curriculumForm.get("bookRequired").value;
	}

	onClick = (label: string): void => {
		if(label === NEXT) {
			this.validateUntouchedForms();
			this.validateName().then(()=>{
				setTimeout(() => 
				{
					if (!this.curriculumForm.valid) {
						return;
					} else {
						this.changePage.emit(NEXT);
						this.getFormValue.emit({
							formValues: this.curriculumForm.value,
						});
						this.changePage.emit(label);
						this.curriculumForm.reset();
					}
				}, this.timeout);
			});
		}
	}

	onDateChange($event: any): void {
		this.updateStatus();
	}
	
	updateStatus(): void {
		const startDate = moment(this.curriculumForm.controls['startDate'].value).hours(0).minutes(0).seconds(0);
		const endDate = moment(this.curriculumForm.controls['endDate'].value).hours(23).minutes(59).seconds(59);
		const status = moment().isBetween(startDate, endDate) ? ACTIVE : INACTIVE;
		this.curriculumForm.controls['status'].setValue(status);
	}

	private validateUntouchedForms(): void {
		Object.keys(this.curriculumForm.controls).forEach(control => {
			this.curriculumForm.get(control).markAsTouched();
			this.curriculumForm.get(control).markAsDirty();
		});
	}

	onChangeNumber(type: string): void {
		const value: number = this.curriculumForm.controls[type].value;
    	const max = 999999999;
		if (value === null){
				this.curriculumForm.controls[type].setValue("");
		} else if (value === -0) {
				this.curriculumForm.controls[type].setValue(0);
		} else if (value > max) {
		this.curriculumForm.controls[type].setValue(String(value).substring(0,9));
		} else if (value % 1 != 0 && String(value).split('.').slice(-1)[0].length > 2) {
		this.curriculumForm.controls[type].setValue(value.toFixed(2));
		}
	}

	// getAllScopes(): void {
	// 	allScopes
	// 	this.crudService
	// 		.getById<Scope>(allScopes)
	// 		.pipe(takeUntil(this.unsubscribe))
	// 		.subscribe((response: any) => {
	// 			if(response) {
	// 				this.scopeList = response;
	// 			}
	// 			this.allScopesToggle = this.scopeSelected.length === this.scopeList.length;
	// 		}, this.errorHandlerService.handleError);
	// }

	get scopeListSize() : number {
		return this.scopeList.length ? this.scopeList.length : 0;
	}

	async validateName() {
		this.exists = true;
		this.curriculumForm.controls['name'].updateValueAndValidity();
		
		const name = this.curriculumForm.get("name").value;
		const syCode = this.curriculumForm.get("syCode").value;
		if (name && syCode) {
			this.crudService
			.getById<JSON>(this.existsByNameEndpoint.concat(`
				${encodeURIComponent(this.curriculumForm.get("name").value)}
				&syCode=
				${encodeURIComponent(this.curriculumForm.get("syCode").value)}
				&code=
				${encodeURIComponent(this.code ? this.code : "")}
			`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				response.value = this.curriculumForm.get("name").value;
				this.checkExistResult(response);
				this.exists = response.exists;
			}, this.errorHandlerService.handleError);
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	checkExistResult(result: any): void {
		if (result.exists) {
			this.curriculumForm.controls['name'].setErrors({'nameExists': true});
		} else {
			this.curriculumForm.controls['name'].updateValueAndValidity();
		}
	}

	get canRoleAssignCurriculum(): Boolean{
		return this.userService.hasRole([ADMIN]);
	}
}
