import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnDestroy
} from "@angular/core";
import {
  DESC,
  ASC,
} from "src/app/core/constants/configuration/common.constant";
import {
  faSort,
  faSortUp,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataProperties } from "src/app/core/models/data-properties.model";
import { curriculumTab, curriculumDataProperties } from "src/app/core/constants/configuration/curriculum-constants.config";
import { classTab, classDataProperties } from "src/app/core/constants/configuration/class-constants.config";
import { bundleTab, bundleDataProperties } from "src/app/core/constants/configuration/bundle-constants.config";
import { resourceTab, resourceDataProperties } from "src/app/core/constants/configuration/resource-constants.config";
import { hqtTab, hqtDataProperties } from "src/app/core/constants/configuration/hqt-constants.config";
import { modelTab, modelDataProperties } from "src/app/core/constants/configuration/model-constants.config";
import { forEach } from "@angular/router/src/utils/collection";
import { assetDataProperties, assetTab, orderTxnHistoryTab, orderItemTxnHistoryTab, courseHistoryTab } from "src/app/core/constants/configuration/asset-constants.config";
import { orderTxnHistoryDataProperties, PROCESSING_STATUS_1, PROCESSING_STATUS_2, PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_5, PROCESSING_STATUS_6, PROCESSING_STATUS_7, orderItemTxnHistoryDataProperties, ORDER_CURRICULUM, createOrderClassDataProperties, WAITLISTED_STUDENTS, waitlistedStudentDataProperties, PROCESSING_STATUS_8, STUDENT_FOR_SV_APPROVAL, studentForSVApprovalDataProperties, courseHistoryDataProperties } from "src/app/core/constants/configuration/order-constants.config";
import { UserService } from "src/app/core/services/util/user.service";
import { ADMIN, PROGRAM_DIRECTOR, SUPERVISOR, TEACHER } from "src/app/core/constants/configuration/role-constants.config";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent implements OnInit, OnDestroy {

  @Input()
  type: string;

  @Input()
  includeAction: boolean;

  @Input()
  items: any[] = [];

  @Input()
  includeCheckbox: boolean;

  @Output()
  linkClick: EventEmitter<string> = new EventEmitter();

  @Output()
  classLinkClick: EventEmitter<string> = new EventEmitter();

  @Output()
  editClick: EventEmitter<string> = new EventEmitter();

  @Output()
  editClassClick: EventEmitter<string> = new EventEmitter();

  @Output()
  deleteItem: EventEmitter<string> = new EventEmitter();

  @Output()
  toggleSorting: EventEmitter<any> = new EventEmitter();

  @Output()
  selectedItem: EventEmitter<any> = new EventEmitter();

  @Output()
  checkboxChange: EventEmitter<any> = new EventEmitter();

  unsubscribe: Subject<any> = new Subject();
  headersLength: number;
  sortedBy: string;
  sortOrder: string;
  faSortIcon = faSort;
  faSort = faSort;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  faTimes = faTimes;
  isLoading: boolean = false;
  dataProperties: DataProperties[];
  checkedItems: any[] = [];

  constructor(private loaderMessagingService: LoaderMessagingService,
              private userService: UserService
  ) {
    this.subscribeToLoaderMessagingService();
  }

  ngOnInit(): void {
    switch (this.type) {

      case curriculumTab: {
        this.dataProperties = curriculumDataProperties;
        break;
      }

      case ORDER_CURRICULUM: {
        this.dataProperties = createOrderClassDataProperties;
        break;
      }

      case WAITLISTED_STUDENTS: {
        this.dataProperties = waitlistedStudentDataProperties;
        break;
      }

      case STUDENT_FOR_SV_APPROVAL: {
        this.dataProperties = studentForSVApprovalDataProperties;
        break;
      }

      case classTab: {
        this.dataProperties = classDataProperties;
        break;
      }

      case bundleTab: {
        this.dataProperties = bundleDataProperties;
        break;
      }

      case resourceTab: {
        this.dataProperties = resourceDataProperties;
        break;
      }

      case hqtTab: {
        this.dataProperties = hqtDataProperties;
        break;
      }

      case modelTab: {
        this.dataProperties = modelDataProperties;
        break;
      }

      case assetTab: {
        this.dataProperties = assetDataProperties;
        break;
      }

      case orderTxnHistoryTab: {
        this.dataProperties = orderItemTxnHistoryDataProperties;
        break;
      }
      
      case orderItemTxnHistoryTab: {
        this.dataProperties = orderItemTxnHistoryDataProperties;
        break;
      }

      case courseHistoryTab: {
        this.dataProperties = courseHistoryDataProperties;
        break;
      }
    }
    
    this.headersLength = this.includeAction ? 1 : 0;
    
    this.dataProperties.forEach(prop => {
      if (prop.property === 'name' && this.type !== hqtTab) {
        this.headersLength += 3;
      } else if (prop.property === 'status') {
        this.headersLength += 1;
      } else {
        this.headersLength += 2;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onLinkClick($event: any, id: string): void {
    this.linkClick.emit(id);
  }

  getLink(id: string): string {
    return id;
  }

  onClassLinkClick($event: any, className: string): void {
    this.classLinkClick.emit(className);
  }

  onDeleteItem(id: string): void {
    this.deleteItem.emit(id);
  }

  toggleSort(property: string) {
    if (property === this.sortedBy) {
      if (this.sortOrder === DESC) {
        this.faSort = faSortUp;
        this.sortOrder = ASC;
      } else if (this.sortOrder === ASC) {
        this.faSort = faSortDown;
        this.sortOrder = DESC;
      } else if (this.faSort === faSortDown) {
        this.faSort = faSort;
        this.sortedBy = "";
        this.sortOrder = ASC;
      }
    } else {
      this.sortedBy = property;
      if (!this.sortOrder && property === 'name') {
        this.faSort =  faSortDown;
        this.sortOrder = DESC;
      } else {
        this.faSort =  faSortUp;
        this.sortOrder = ASC;
      }
    }

    this.toggleSorting.emit({ sortedBy: this.sortedBy, sortOrder: this.sortOrder });
  }

  onEditClick($event: any, id: string) : void {
    this.editClick.emit(id);
  }

  onEditClassClick($event: any, id: string) : void {
    this.editClassClick.emit(id);
  }

  isDeleteEnable(type: any) : boolean {
    let isDeleteEnable = false;

    if(type === 'HQT' || type === 'MODEL' || type === 'ASSET') {
      isDeleteEnable = true;
    }

    return isDeleteEnable;
  }

  getColSpan(property: any) : number {
    let colSpan = 0;

    switch(property) {
      case 'availableCount':
      case 'checkOutCount':
      case 'forDisposalCount':
      case 'total':
      case 'lostCount':
        colSpan = 1;
        break;
      case 'name':
        colSpan = 3;
        break;
      default:
        colSpan = 2;
        break;
    }

    return colSpan;
  }

  hasTooltipAndEllipses(property: string): boolean {
    let list = ['name', 'curriculumName', 'hqtUsername', 'curriculumList', 'modelName', 'description', 'scopeName', 'modifiedBy', 'sectionName'];
    if (this.type === modelTab) list.push('code');
    return list.includes(property);
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  // Get scss class of the item-col: ex. processing, approved, etc.
  getStatusClass(statusName: string): string {
    if (statusName) {
      switch (statusName) {
        case PROCESSING_STATUS_1: return "approved"
        case PROCESSING_STATUS_2: return "processing"
        // case PROCESSING_STATUS_3: return "pre-completed"
        case PROCESSING_STATUS_4: return "processed"
        case PROCESSING_STATUS_5: return "canceled"
        case PROCESSING_STATUS_6: return "refunded"
        case PROCESSING_STATUS_7: return "adjusted"
        case PROCESSING_STATUS_8: return "waitlisted"
        default: return "status"
      }
    }
  }

  getTxnHistoryStatusValue(statusName: string): string {
    if (statusName) {
      switch (statusName) {
        case PROCESSING_STATUS_1: return "Pending Orders"
        case PROCESSING_STATUS_2: return "Processing"
        // case PROCESSING_STATUS_3: return "Pre-completed"
        case PROCESSING_STATUS_4: return "Processed"
        case PROCESSING_STATUS_5: return "Canceled"
        case PROCESSING_STATUS_6: return "Refunded"
        case PROCESSING_STATUS_7: return "Adjusted"
        case PROCESSING_STATUS_8: return "Waitlisted"
        default: return statusName
      }
    }
  }

  isTxnHistoryField(type: string) : boolean {
    return type == orderItemTxnHistoryTab || type == orderTxnHistoryTab || type == courseHistoryTab;
  }

  selectionChange(item) {
    this.selectedItem.emit(item);
  }

  get canDoAction(): Boolean {
    return this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR]);
  }

  get canIncludeCheckbox(){
    if(this.includeCheckbox){
      return true;
    } else {
      return false;
    }
  }

  onCheckboxChange(currentItem: any, $event: any){
    if($event.checked){
      // Check if currentItem is already in checkedItems
      if (this.checkedItems.find(item => item.code === currentItem.code)) {
        // Do nothing
      } else {
        // Add currentItem to checkedItems
        this.checkedItems.push(currentItem);
      }
    } else {
      // Check if currentItem is in checkedItems
      if (this.checkedItems.find(item => item.code === currentItem.code)) {
        // Remove currentItem from checkedItems
        this.checkedItems = this.checkedItems.filter(item => item.code !== currentItem.code);
      } else {
        // Do nothing
      }
    }

    // Emit current checkedItems
    this.checkboxChange.emit(this.checkedItems);
  }

  formatAmount(value: number): string {
    return value < 0 ? `$ (${(value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`
      : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  isCurriculumEditable(item: any): boolean {
		return !(
      this.type === curriculumTab
			&& this.userService.hasRole([PROGRAM_DIRECTOR])
      && this.userService.getLoginUser().username !== item.assignedTo
		)
  }

  isCourseEditable(item: any): boolean {
		return !(
      this.type === classTab
			&& this.userService.hasRole([PROGRAM_DIRECTOR])
      && this.userService.getLoginUser().username !== item.curriculum.assignedTo
		)
  }

  clearCheckedItems():void {
    this.checkedItems = [];
  }
}
