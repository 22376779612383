export class CourseHistoryModel {
    private _id: number;
    private _code: string;
    private _historyType: string;
    private _description: string;
    private _dateModified: Date;
    private _modifiedBy: string;

    constructor(
        id: number,
        code: string,
        historyType: string,
        description: string,
        dateModified: Date,
        modifiedBy: string,
    ) {
        this._id = id;
        this._code = code;
        this._historyType = historyType;
        this._description = description;
        this._dateModified = dateModified;
        this._modifiedBy = modifiedBy;
    }

    get id() {
        return this._id;
    }


    public get code(): string {
        return this._code;
    }

    public get historyType(): string {
        return this._historyType;
    }

    public get description(): string {
        return this._description;
    }

    public get dateModified(): Date {
        return this._dateModified;
    }

    public get modifiedBy(): string {
        return this._modifiedBy;
    }
}