import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { User } from "../../models/user.model";
import { loginUser } from "../../constants/configuration/config.constant";
import { Authority } from "../../models/authority.model";
import { ADMIN, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, SUPERVISOR, TEACHER } from "../../constants/configuration/role-constants.config";
import { CURRICULUMS_PATH, ORDERS_PATH } from "../../constants/routes.constant";

@Injectable({ providedIn: 'root' })
export class UserService {

	constructor(
		private router: Router,
	) {
	}

    getLoginUser(): User {
        return JSON.parse(localStorage.getItem(loginUser)) as User;
    }

    getFirstRole(): Authority {
        const user = JSON.parse(localStorage.getItem(loginUser)) as User;

        const userRolesWeight = {
            [TEACHER]: 4,
            [PROGRAM_DIRECTOR]: 3,
            [STUDENT_ACCOUNT_SPECIALIST]: 3,
            [ASSISTANT_DIRECTOR]: 3,
            [SUPERVISOR]: 2,
            [SPECIALIST]: 2,
            [ADMIN]: 1
        };

        const highestRole = Object.entries(userRolesWeight)
        .filter(([key]) => user.authorities.some((r: Authority) => r.authority == key))
        .reduce((minRole, [role, value]) => value < userRolesWeight[minRole] ? role : minRole, TEACHER);

        const userRole = user.authorities.filter(role => role.authority == highestRole);

        return userRole[0] as Authority;
    }

    getUserRoles(): String[] {
        const user = JSON.parse(localStorage.getItem(loginUser)) as User;
        return user.authorities.map(authority => authority.authority);
    }

    hasRole(roles: String[]): boolean {
        return this.getUserRoles().some(role => roles.includes(role));
    }

    checkRolePermission(path: String, routingPermissions: any[]) {
        let routingPermission = routingPermissions.find(route => route.path === path);
        if (!this.hasRole(routingPermission ? routingPermission.roles : []))
            if (this.hasRole([ADMIN, PROGRAM_DIRECTOR])) this.router.navigateByUrl(CURRICULUMS_PATH)
            else this.router.navigateByUrl(ORDERS_PATH)
    }

}
