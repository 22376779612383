import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { ASSIGN_OTHERS_VWD, ASSIGN_OTHERS_WD, taskDataProperties, WORKBOOKTEXTBOOK } from 'src/app/core/constants/configuration/task-constants.config';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Task } from "src/app/core/models/order/task.model";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faBell, faCheckCircle, faTimesCircle, faPen, faSort, faSortUp, faSortDown, faThumbsUp, IconDefinition, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { TASKS_PATH } from 'src/app/core/constants/routes.constant';
import { Router } from '@angular/router';
import {
    COMPLETED,
    FINALIZED_WD_REQUEST,
    HST_APPROVED,
    PENDING_ORDERS,
    PROCESSED,
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_10,
  PROCESSING_STATUS_11,
  PROCESSING_STATUS_12,
  PROCESSING_STATUS_13,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  PROCESSING_STATUS_8,
  PROCESSING_STATUS_9,
  VERIFIED_WD_REQUEST,
  WD_REQUEST,
} from 'src/app/core/constants/configuration/order-constants.config';
import { TASK_GROUP_2, TASK_GROUP_3 } from 'src/app/core/constants/configuration/task-constants.config';
import { ADMIN, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, SUPERVISOR, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { UserService } from '../../../../../core/services/util/user.service';
import { DESC, ASC } from 'src/app/core/constants/configuration/common.constant';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { OrderNotes } from 'src/app/core/models/order/order-notes.model';
import { getOrderNotesByCosCodeEndpoint } from 'src/app/core/constants/endpoints.constant';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { OrderNotesPopupComponent } from '../../../shared/order-notes/order-notes-popup/order-notes-popup.component';

@Component({
	selector: 'task-list',
	templateUrl: './task-list.component.html',
	styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy {

    @Input() processingStatus: any[];
    @Input() taskGroupType: any[];
    @Input() showAssignToSpecialist: boolean;
    @Input() showAssignToSpecialistWD: boolean;
    @Input() showAssignToSpecialistVWD: boolean;
    @Input() selectedSpecialistValue: any;
    @Input() sortedBy: string;
    @Input() sortOrder: string;
    @Input() usernames: string[] = [];
    @Input() filters: Map<string, string> = new Map<string, string>();
    @Output() bulkAction: EventEmitter<any> = new EventEmitter();
    @Output() getSelectedSpecialist: EventEmitter<any> = new EventEmitter();
    @Output() toggleSortOnClick : EventEmitter<any> = new EventEmitter();

    items: any[];
    specialists: any[] = [];
    specialistUsernames: string[] = [];
    unsubscribe: Subject<any> = new Subject();
    isLoading: boolean = false;
    dataProperties: DataProperties[] = taskDataProperties;
    checkedItems: any[] = [];
    selectedSpecialist: any;
    selectedUser: string = "";
    isButtonClickable: boolean = true;
    assignIcon: IconDefinition = faBell;
	completeIcon: IconDefinition = faCheckCircle;
	cancelIcon: IconDefinition = faTimesCircle;
    editIcon: IconDefinition = faPen;
	faSortDefaultIcon = faSort;
	faSortIcon = faSort;
	faSort = faSort;
    faThumbsUp = faThumbsUp;
    faEnvelope = faEnvelope;

    constructor(
        private loaderMessagingService: LoaderMessagingService,
        private dataCustomizerService: DataCustomizerService,
        private router: Router,
        private userService: UserService,
        private dialog: MatDialog,
        private crudService: CrudService,
        private errorHandlerService: ErrorHandlerService,
    ) {
        this.subscribeToLoaderMessagingService();
    }

    ngOnInit(): void {
        this.setStatusDisplay(null);
        this.items = this.dataCustomizerService.addCheckboxProperty(this.items);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
    
    @Input()
    set specialistList(values: any []) {
        this.specialists = values;
        this.specialists.forEach(specialist => {
            if (!this.specialistUsernames.includes(specialist.username)) {
                this.specialistUsernames.push(specialist.username);
            }
        });
        if (this.selectedSpecialistValue && this.selectedSpecialistValue.username)
            this.selectedSpecialist = this.selectedSpecialistValue;
    }
    
    @Input()
    set tasks(values: any []) {
        this.items = values;
        this.checkedItems = [];
        if(!this.showAssignToSpecialist) this.selectedSpecialist = "";
    }

    onSelectSpecialist(value) {
        this.selectedSpecialist = value;
        this.specialists.forEach(specialist => {
            if (specialist.username === this.selectedSpecialist) {
                this.getSelectedSpecialist.emit(specialist);
            }
        });
    }

    onCheckedAll(): void {
        this.items.forEach(item => {
            if (!this.isCancelled(item) && !this.isCompleted(item) && !this.isSvApproval(item) && !(this.isProgramDirectorRole() && !this.isCurriculumAssignedToProgramDirector(item))) {
                item.checked = !this.isAllChecked;
            }
        });
        if (!this.isAllChecked) {
            this.checkedItems = Object.assign([], this.items.filter(item =>
                !this.isCancelled(item) && !this.isCompleted(item) && !this.isSvApproval(item) && !(this.isProgramDirectorRole() && !this.isCurriculumAssignedToProgramDirector(item)))
            );
        } else {
            this.checkedItems = [];
        }
    }

    onChecked(currentItem: any): void {
        if (this.checkedItems.find(item => item.code === currentItem.code)) {
            this.items.find(item => item.code === currentItem.code).checked = false;
            this.checkedItems = this.checkedItems.filter(item => item.code !== currentItem.code);
        } else {
            this.items.find(item => item.code === currentItem.code).checked = true;
            this.checkedItems.push(currentItem);
        }
    }

    hasTooltipAndEllipses(property: string): boolean {
        return ['code', 'curriculumName', 'type', 'name', 'requestedBy'].includes(property);
    }

    get showAssignToOthers(): boolean {
        return this.showAssignToSpecialist && this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR])
    }

    get canAssignToOthers(): boolean {
        return this.canAssign && this.selectedSpecialist.length > 0
    }

    get canAssign(): boolean {
        let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);
        return this.checkedItems.length > 0 && this.checkedItems.filter(item => item.status !== hstApprovedStatus.name).length === 0 &&
            this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST])
    }
 
    get canPreComplete(): boolean {
        let processingStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
        let isSameGroupType = this.checkGroupType();
        let isTemporaryDisableMultiClass = this.disableMultiClass();
        return this.checkedItems.length > 0 && isSameGroupType && this.checkedItems.filter(item =>
            item.status !== processingStatus.name || this.hasRequestToCancel(item)).length === 0 &&
            (this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR]) ||
                (this.userService.hasRole([SPECIALIST]) && this.checkedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0))
    }

    checkGroupType(): boolean {
        let isSameGroupType = true;

        for (let i = 1; i < this.checkedItems.length; i++) {
            if (this.checkedItems[i].type !== this.checkedItems[0].type) {
                return false;
            }
        }
        return isSameGroupType;
    }

    disableMultiClass(): boolean {
        let counter = 0;
        for (let i = 0; i < this.checkedItems.length; i++) {
            if (this.checkedItems[i].type !== WORKBOOKTEXTBOOK) {
                counter++;
            }
        }
        return counter <= 1;
    }

    get canComplete(): boolean {
        let processingStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
        let preCompletedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_3);
        let isSameGroupType = this.checkGroupType();
        let isTemporaryDisableMultiClass = this.disableMultiClass();
        return this.checkedItems.length > 0 && isSameGroupType && this.checkedItems.filter(item =>
            ![processingStatus.name, preCompletedStatus.name].includes(item.status) || this.hasRequestToCancel(item)).length === 0 &&
            (this.userService.hasRole([ADMIN]) ||
                (this.userService.hasRole([SUPERVISOR, SPECIALIST]) && this.checkedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0))
    }

    getTaskGroupCode(item: any): string {
        let taskGroup = this.taskGroupType.find(type => type.name === item.type);
        return taskGroup ? taskGroup.code : ""
    }

    isCancelled(item: any): boolean {
        let cancelledStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_5);
        return item.status === cancelledStatus.name;
    }

    isCompleted(item: any): boolean {
        let completedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_4);
        return item.status === completedStatus.name;
    }

    isSvApproval(item: any): boolean {
        let svApprovalStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_9);
        return item.status === svApprovalStatus.name;
    }

    isProcessing(item: any): boolean {
        let status = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
        return item.status === status.name;
    }

    isCurriculumAssignedToProgramDirector(item: any): boolean {
        return item.curriculumAssignedTo === this.userService.getLoginUser().username;
    }

    isProgramDirectorRole(): boolean {
        return this.userService.hasRole([PROGRAM_DIRECTOR]);
    }

    isWorkbookTextBook(item: any): boolean {
        return this.getTaskGroupCode(item) === TASK_GROUP_2;
    }

    get isAllChecked(): boolean {
        return this.items.length > 0 && this.checkedItems.length > 0 &&
            this.checkedItems.length === this.items.filter(item =>
                !this.isCancelled(item) && !this.isCompleted(item) && !this.isSvApproval(item)  && !(this.isProgramDirectorRole() && !this.isCurriculumAssignedToProgramDirector(item))).length;
    }

    hasRequestToCancel(selectedItem: any): boolean {
        return selectedItem.orderItemAssoc.filter(orderItem => orderItem.forCancel).length > 0;
    }

    onBulkAction(action: string): void {
        // Set isButtonClickable to false to prevent user from attempting to click buttons multiple times
        this.setIsButtonClickable(false);

        // Emits the selected action, items, and selectedSpecialist to TaskComponent
        this.bulkAction.emit({
            action,
            items: Object.assign([], this.checkedItems),
            selectedSpecialist: this.selectedSpecialist,
        });
    }

    onLinkClick($event: any, item: any): void {
        this.router.navigate([TASKS_PATH, item.syCode, item.id]);
    }

    getLink(item: any): string {
        return "/" + TASKS_PATH + "/" + item.syCode + "/" + item.id;
    }

    getStatusClass(statusName: string): string {
      let currentStatus = this.processingStatus.find(status => status.name === statusName);
      if (currentStatus) {
        switch (currentStatus.code) {
          case PROCESSING_STATUS_1: return "approved status"
          case PROCESSING_STATUS_2: return "processing status"
        //   case PROCESSING_STATUS_3: return "pre-completed status"
          case PROCESSING_STATUS_4: return "completed status"
          case PROCESSING_STATUS_5: return "canceled status"
          case PROCESSING_STATUS_6: return "refunded status"
          case PROCESSING_STATUS_7: return "adjusted status"
          case PROCESSING_STATUS_8: return "waitlisted status"
          case PROCESSING_STATUS_9: return "sv-approval status"
          case PROCESSING_STATUS_10: return "wd-request status"
          case PROCESSING_STATUS_11: return "verified-wd-request status"
          case PROCESSING_STATUS_12: return "finalized-wd-request status"
          case PROCESSING_STATUS_13: return "invoiced status"
          default: return "status"
        }
      }
    }

    private subscribeToLoaderMessagingService(): void {
        this.loaderMessagingService.listLoader$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(show => setTimeout(() => (this.isLoading = show)));
    }

    toggleSort(property: string) {
        if (property === this.sortedBy) {
          if (this.sortOrder === DESC) {
            this.faSort = faSortUp;
            this.sortOrder = ASC;
          } else if (this.sortOrder === ASC) {
            this.faSort = faSortDown;
            this.sortOrder = DESC;
          } else if (this.faSort === faSortDown) {
            this.faSort = faSort;
            this.sortedBy = "";
            this.sortOrder = ASC;
          }
        } else {
          this.sortedBy = property;
          if (!this.sortOrder && property === 'name') {
            this.faSort =  faSortDown;
            this.sortOrder = DESC;
          } else {
            this.faSort =  faSortUp;
            this.sortOrder = ASC;
          }
        }
    
        this.toggleSortOnClick.emit({ sortedBy: this.sortedBy, sortOrder: this.sortOrder });
    }

    approveOrder(item) {
	}
	
	openApproveDialog(item) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
		dialogRef.componentInstance.confirmTitle = 'Approve Order';
		dialogRef.componentInstance.confirmMessage = `Are you sure you want to approve this order?`;
		dialogRef.componentInstance.cancelLabel = "Cancel";
		dialogRef.componentInstance.confirmLabel = "Approve";
		dialogRef.afterClosed().subscribe(result => {
			if(result) this.approveOrder(item);
		});
	}

    setStatusDisplay(item){
        if(!!item) {
          // if(item.status == HST_APPROVED){
          //   item.status = PENDING_ORDERS;
          // } else if (item.status == COMPLETED){
          //   item.status = PROCESSED;
          // }
          if (item.status === "HST Approved") {
            return "Pending Orders";
          } else if (item.status === "Completed") {
            return "Processed";
          } else if(item.status === "Finalized WD Request") {
            if(item.withdrawType === "refund") {
              return "Finalized WD Request (Refund)";
            } else if(item.withdrawType === "no refund") {
              return "Finalized WD Request (No Refund)";
            } else return "Finalized WD Request (Adjusted)";
          } else return item.status;
        }
    }

    selectOrderNotes(item: any) {
        this.crudService.getAll<OrderNotes>(getOrderNotesByCosCodeEndpoint.concat(`?cosOrderCode=${item.cosOrderCode}`))
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
            this.openOrderNotes(item, response);
        }, this.errorHandlerService.handleError);
    }
    
    openOrderNotes(item: any, orderNotes: any){       
        const dialogRef = this.dialog.open(OrderNotesPopupComponent, {
            data: {
                orderDetails: {
                    name: item.name,
                    code: item.cosOrderCode,
                    eosOrderCode: item.eosOrderCode,
                },
                orderNotes: orderNotes,
                username: this.userService.getLoginUser().username
            },
            panelClass: 'order-notes-popup',
            autoFocus: false
        });
      }

    get canAssignWD(): boolean {
        let wdRequestStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_10);
        return this.checkedItems.length > 0 &&
            this.checkedItems.filter(item => item.status !== wdRequestStatus.name).length === 0 &&
            ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
                this.checkedItems.filter(item => item.assignedTo !== null && item.assignedTo !== "").length === 0) ||
            (this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
                this.checkedItems.filter(item => item.assignedTo === this.userService.getLoginUser().username).length === 0))
    }

    get canAssignVWD(): boolean {
        let vwdRequestStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_11);
        return this.checkedItems.length > 0 &&
            this.checkedItems.filter(item => item.status !== vwdRequestStatus.name).length === 0 &&
            ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
                this.checkedItems.filter(item => item.assignedTo !== null && item.assignedTo !== "").length === 0) ||
            (this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
                this.checkedItems.filter(item => item.assignedTo === this.userService.getLoginUser().username).length === 0))
    }

    get showAssignToOthersWD(): boolean {
        let wdRequestStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_10);

        return (
            this.userService.hasRole([SUPERVISOR, ADMIN]) &&
            this.checkedItems.length > 0 &&
            this.checkedItems.filter(item => item.status !== wdRequestStatus.name).length === 0 &&
            this.checkedItems.filter(item => item.assignedTo !== null && item.assignedTo !== "").length === 0
        )
    }

    get showAssignToOthersVWD(): boolean {
        let vwdRequestStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_11);

        return (
            this.userService.hasRole([SUPERVISOR, ADMIN]) &&
            this.checkedItems.length > 0 &&
            this.checkedItems.filter(item => item.status !== vwdRequestStatus.name).length === 0 &&
            this.checkedItems.filter(item => item.assignedTo !== null && item.assignedTo !== "").length === 0
        )
    }

    get canAssignToOthersWD(): boolean {
        return (
            this.selectedSpecialist.length > 0
        )
    }

    get canAssignToOthersVWD(): boolean {
        return (
            this.selectedSpecialist.length > 0
        )
    }

    get canCancel(): boolean {
        if(this.checkedItems && this.checkedItems.length > 0) {
            if(this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, ASSISTANT_DIRECTOR, TEACHER, PROGRAM_DIRECTOR])) {
                let waitlistedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_8);
                return this.checkedItems.filter(item => item.status !== waitlistedStatus.name || item.syCode === "2019-2020").length === 0;
            } else return false;
        } else return false;
    }

    get canApproveWaitlist(): boolean {
        if(this.checkedItems && this.checkedItems.length > 0) {
            if(this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR])) {
                let waitlistedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_8);
                return this.checkedItems.filter(item => item.status !== waitlistedStatus.name).length === 0;
            } else return false;
        } else return false;
    }

    get canChangeCourse(): boolean {
        if(this.checkedItems && this.checkedItems.length > 0) {
            if(this.checkedItems.filter(item => !item.cosOrderCode).length === 0) {
                if(this.userService.hasRole([SPECIALIST, SUPERVISOR, ADMIN])) {
                    let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);
                    let processingStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_2);
                    let waitlistedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_8);
                    let selectedCurriculumCode = this.checkedItems[0].curriculumCode;
                    return this.checkedItems.filter(item => 
                        (item.status !== hstApprovedStatus.name &&
                        item.status !== processingStatus.name &&
                        item.status !== waitlistedStatus.name) ||
                        item.curriculumCode !== selectedCurriculumCode
                    ).length === 0;
                } else if(this.userService.hasRole([PROGRAM_DIRECTOR])) {
                    let waitlistedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_8);
                    let selectedCurriculumCode = this.checkedItems[0].curriculumCode;
                    return this.checkedItems.filter(item => 
                        item.status !== waitlistedStatus.name ||
                        item.curriculumCode !== selectedCurriculumCode
                    ).length === 0;
                } else return false;
            } else return false;
        } else return false;
    }
    
    get canUnassignWD(): boolean {
        let wdRequestStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_10);
        return this.checkedItems.length > 0 &&
            this.checkedItems.filter(item => item.status !== wdRequestStatus.name).length === 0 &&
            ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
                this.checkedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0) ||
            (this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
                this.checkedItems.filter(item => item.assignedTo === null || item.assignedTo === "").length === 0))
    }

    get canUnassignVWD(): boolean {
        let vwdRequestStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_11);
        return this.checkedItems.length > 0 &&
            this.checkedItems.filter(item => item.status !== vwdRequestStatus.name).length === 0 &&
            ((this.userService.hasRole([SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) &&
                this.checkedItems.filter(item => item.assignedTo !== this.userService.getLoginUser().username).length === 0) ||
            (this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) &&
                this.checkedItems.filter(item => item.assignedTo === null || item.assignedTo === "").length === 0))
    }

    onSelectUser(user: string) {
        this.selectedSpecialist = user;
    }

    clearSelections():void {
        // Clear all current selections
        this.selectedSpecialist = "";
        this.selectedUser = "";
        this.checkedItems = [];
    }

    setIsButtonClickable(isButtonClickable: boolean = true):void {
        this.isButtonClickable = isButtonClickable;
    }

    get isAnyWDRequestFilterSelected():boolean {
		const selectedStatusFilter: string = String(this.filters.get("status"));
        return selectedStatusFilter.toLowerCase().includes(WD_REQUEST.toLowerCase());
    }

    get selectedWdRequestDateProperty():string {
        const selectedStatusFilter: string = String(this.filters.get("status")).toLowerCase();
        switch (selectedStatusFilter) {
            case WD_REQUEST.toLowerCase():
                return "withdrawRequestDate";
            case VERIFIED_WD_REQUEST.toLowerCase():
                return "verifiedWithdrawRequestDate";
            case FINALIZED_WD_REQUEST.toLowerCase():
                return "finalizedWithdrawRequestDate";
            default:
                return "createdDate";
        }
    }
}
