import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-waitlisted-orders-popup',
  templateUrl: './waitlisted-orders-popup.component.html',
  styleUrls: ['./waitlisted-orders-popup.component.scss']
})
export class WaitlistedOrdersPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dlDialogRef: MatDialogRef<WaitlistedOrdersPopupComponent>) { }

  ngOnInit() {
  }

  

}
