import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { combineLatest, concat, forkJoin, from, of, Subject, zip } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { saveOrderNoteEndpoint } from 'src/app/core/constants/endpoints.constant';
import { OrderNotes } from 'src/app/core/models/order/order-notes.model';

import { CrudService } from "src/app/core/services/data/crud.service";
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";

@Component({
  selector: 'app-order-notes-popup',
  templateUrl: './order-notes-popup.component.html',
  styleUrls: ['./order-notes-popup.component.scss']
})
export class OrderNotesPopupComponent implements OnInit {
  note: any;
  username: String;
  charLimit: number = 1000;
  editId = null;
  unsubscribe: Subject<any> = new Subject();

  @ViewChild("textarea") textarea : ElementRef;

  orderNotesList = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OrderNotesPopupComponent>,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private dataCustomizerService: DataCustomizerService) {

     }
  
  ngOnInit() {
    this.orderNotesList = this.data.orderNotes;
    this.username = this.data.username;
  }

  checkMaxChar(event) {
    if(event.which !== 8 && event.target.innerText.length + 1 > this.charLimit) return false;
  }

  editNote($event) {
    this.writeText($event.note);
    this.editId = $event.id;
  }

  writeText(string: any = false) {
    this.textarea.nativeElement.innerText = this.note = string ? string : ' ';
    if(!string) this.editId = null;
    
  }

  saveText() {
    if(this.editId) {
      const item = this.orderNotesList.find(x => x.id === this.editId);
      item.note = this.note;
      item.modifiedDate = moment().format()

      const updatedNotesModel = this.dataCustomizerService.formatOrderNotesModel(item);
      this.crudService
      .add<any>(saveOrderNoteEndpoint, updatedNotesModel)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {      
        this.writeText();
    	}, this.errorHandlerService.handleError);

    } else {
      const newNote = {
        id: -1, // placeholder value
        eosOrderCode: this.data.orderDetails.eosOrderCode,
        code: this.data.orderDetails.code,
        email: this.username, 
        createdDate: moment().format(), 
        modifiedDate: moment().format(), 
        note: this.note,
        level: 1, // per James, value should always be 1 since it is the lowest level accessible by all
        fromHub: false
      }
      
      const newNoteModel = this.dataCustomizerService.formatOrderNotesModel(newNote);
      this.crudService
      .add<any>(saveOrderNoteEndpoint, newNoteModel)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        this.orderNotesList.push({
          id: response.id, 
          email: response.email, 
          createdDate: response.createdDate, 
          modifiedDate: response.modifiedDate, 
          note: response.note,
          eosOrderCode: response.eosOrderCode,
          level: response.level,
          eosNoteId: response.eosNoteId,
          code: response.code,
          fromHub: false
        })

        this.writeText();
    	}, this.errorHandlerService.handleError);
      
    }
    
  }

}
