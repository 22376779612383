import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirm-sv-approval-dialog',
  templateUrl: './confirm-sv-approval-dialog.component.html',
  styleUrls: ['./confirm-sv-approval-dialog.component.scss']
})
export class ConfirmSvApprovalDialogComponent implements OnInit {

  constructor(public dlDialogRef: MatDialogRef<ConfirmSvApprovalDialogComponent>) { }

  ngOnInit() {
  }

  public confirmTitle: string;
  public confirmMessage: string;
  public cancelLabel: string;
  public confirmLabel: string;

  public svApprovalFormControl = new FormControl('', [Validators.required]);
  public value: string;
  
  onClick(){
    this.svApprovalFormControl.updateValueAndValidity();
    if(this.svApprovalFormControl.hasError('required')){
      this.svApprovalFormControl.markAsTouched();
    } else {
      this.dlDialogRef.close(this.value);
    }
  }
}
