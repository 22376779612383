export const environmentDev: boolean = false;
export const environmentUat: boolean = false;
export const environmentProd: boolean = true;
export const baseServerUriDev: string = "http://localhost:9000";
//export const baseServerUriDev: string = "http://3.17.64.152:9001";
export const baseServerUriUat: string = "https://uat-curriculum-api.pcahomeschoolhub.com";
export const baseServerUriProd: string = "https://legacy-curriculum-api.pcahomeschoolhub.com";

export const baseServerUri: string = !environmentProd ?
  environmentUat ? baseServerUriUat : baseServerUriDev : baseServerUriProd;

export const clientIdentity: string = "client-id:client-secret";
export const accessToken: string = "ACCESS_TOKEN";
export const authorized: string = "AUTHORIZED";
export const unauthorized: string = "UNAUTHORIZED";
export const bearerToken: string = "Bearer ";
export const loginUser: string = "loginUser";
export const listFilters: string = "listFilters";

export const xlsxFileMediaType: string =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  export const csvMediaType: string =
  "text/csv";
export const pdfFileMediaType: string = "application/pdf";
export const fileNameCurriculum: string = "curriculum";
export const fileNameModel: string = "model";
export const fileNameAssets: string = "assets";
export const fileNameClass: string = "course";
export const fileNameBundle: string = "bundle";
export const fileNameResource: string = "resources";
export const fileNameShippingDtls: string = "shippingDtls";
export const fileNameTemplate: string = "_template";
export const fileNameError: string = "_error";
export const downloadFileExtension: string = ".xlsx";
export const csvFileExtension: string = ".csv";
export const zipFileExtension: string = ".zip";
export const pdfFileExtension: string = ".pdf";
export const fileNameOrder: string = "orders";
