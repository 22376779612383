import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, Output } from '@angular/core';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Subject } from 'rxjs';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import {
  COMPLETED,
  HST_APPROVED,
  orderDataProperties,
  PENDING_ORDERS,
  PROCESSED,
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_10,
  PROCESSING_STATUS_11,
  PROCESSING_STATUS_12,
  PROCESSING_STATUS_13,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  PROCESSING_STATUS_8,
  PROCESSING_STATUS_9,
} from 'src/app/core/constants/configuration/order-constants.config';
import { takeUntil } from 'rxjs/operators';
import { faPencilAlt, faSort, faSortUp, faSortDown, faUserCheck, faThumbsUp, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Router } from '@angular/router';
import { ORDERS_PATH, ORDER_DEPOSIT_PATH, ORDER_CURRICULUM_FEE_PATH, OLD_ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { EventEmitter } from '@angular/core';
import { DESC, ASC } from 'src/app/core/constants/configuration/common.constant';
import SortUtil from 'src/app/shared/components/utils/sort-utils/sort-util';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { CrudService } from "src/app/core/services/data/crud.service";
import { OrderNotesPopupComponent } from '../../shared/order-notes/order-notes-popup/order-notes-popup.component';
import { OrderNotes } from 'src/app/core/models/order/order-notes.model';
import { getOrderNotesByCosCodeEndpoint } from 'src/app/core/constants/endpoints.constant';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { UserService } from 'src/app/core/services/util/user.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() sortedBy: string;
  @Input() sortOrder: string;
  @Input() orderStatus: any[] = [];
  @Output() toggleSortOnClick : EventEmitter<any> = new EventEmitter();
  dataProperties: DataProperties[];
  headersLength: number;
  isLoading: boolean = false;
  unsubscribe: Subject<any> = new Subject();
  faPencilAlt = faPencilAlt;
  faSortDefaultIcon = faSort;
  faSortIcon = faSort;
  faSort = faSort;
  faUserCheck = faUserCheck;
  faThumbsUp = faThumbsUp;
  faEnvelope = faEnvelope;


  updatedSortOrder: string;
  orderNotes: any;

  constructor(
    private crudService: CrudService,
    private userService: UserService,
    private loaderMessagingService: LoaderMessagingService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.subscribeToLoaderMessagingService();
  }

  // How can I bind the updated value of parent's field to child without this loc?
  ngOnChanges() {
    this.updatedSortOrder = this.sortOrder;
    this.faSortIcon = SortUtil.toggleSortIcon(this.updatedSortOrder);
  }

  ngOnInit() {
    this.setStatusDisplay(null);
    this.dataProperties = orderDataProperties;
    this.headersLength = this.dataProperties.length;
  }

  onLinkClick(item: any, $event: any, id: string): void {
    if(item.code.includes("-DEPOSIT")) {
      this.router.navigate([ORDER_DEPOSIT_PATH, id]);
    } else if(item.code.includes("-FEE")) {
      this.router.navigate([ORDER_CURRICULUM_FEE_PATH, id]);
    } else if(item.syCode == "2019-2020"){
      this.router.navigate([OLD_ORDERS_PATH, id]);
    } else {
      this.router.navigate([ORDERS_PATH, id]);
    }
  }

  getLink(item: any, id: string): string {
    let path = "";
    if (item.code.includes("-DEPOSIT")) {
      path = ORDER_DEPOSIT_PATH;
    } else if (item.code.includes("-FEE")) {
      path = ORDER_CURRICULUM_FEE_PATH;
    } else if (item.syCode == "2019-2020") {
      path = OLD_ORDERS_PATH;
    } else {
      path = ORDERS_PATH;
    }
    return "/" + path + "/" + id;
  }

  private subscribeToLoaderMessagingService(): void {
    this.loaderMessagingService.listLoader$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(show => setTimeout(() => (this.isLoading = show)));
  }

  getStatusClass(statusName: string): string {
    let currentStatus = this.orderStatus.find(status => status.name === statusName);
    if (currentStatus) {
      switch (currentStatus.code) {
        case PROCESSING_STATUS_1: return "approved status"
        case PROCESSING_STATUS_2: return "processing status"
        // case PROCESSING_STATUS_3: return "pre-completed status"
        case PROCESSING_STATUS_4: return "completed status"
        case PROCESSING_STATUS_5: return "canceled status"
        case PROCESSING_STATUS_6: return "refunded status"
        case PROCESSING_STATUS_7: return "adjusted status"
        case PROCESSING_STATUS_8: return "waitlisted status"
        case PROCESSING_STATUS_9: return "sv-approval status"
        case PROCESSING_STATUS_10: return "wd-request status"
        case PROCESSING_STATUS_11: return "verified-wd-request status"
        case PROCESSING_STATUS_12: return "finalized-wd-request status"
        case PROCESSING_STATUS_13: return "invoiced status"
        default: return "status"
      }
    }
  }

  toggleSort(property: string) {
    if (property === this.sortedBy) {
      if (this.sortOrder === DESC) {
        this.faSort = faSortUp;
        this.sortOrder = ASC;
      } else if (this.sortOrder === ASC) {
        this.faSort = faSortDown;
        this.sortOrder = DESC;
      } else if (this.faSort === faSortDown) {
        this.faSort = faSort;
        this.sortedBy = "";
        this.sortOrder = ASC;
      }
    } else {
      this.sortedBy = property;
      if (!this.sortOrder && property === 'name') {
        this.faSort =  faSortDown;
        this.sortOrder = DESC;
      } else {
        this.faSort =  faSortUp;
        this.sortOrder = ASC;
      }
    }

    this.toggleSortOnClick.emit({ sortedBy: this.sortedBy, sortOrder: this.sortOrder });
  }

  getOrderNotesByCosCode(item): void {
    this.crudService.getAll<OrderNotes>(getOrderNotesByCosCodeEndpoint.concat(`?cosOrderCode=${item.code}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.openOrderNotes(item, response);
      }, this.errorHandlerService.handleError);
  }

  selectOrderNotes(item) {
    this.getOrderNotesByCosCode(item);
  }

  openOrderNotes(item, orderNotes){       
    const dialogRef = this.dialog.open(OrderNotesPopupComponent, {
      data: {
        orderDetails: item,
        orderNotes: orderNotes,
        username: this.userService.getLoginUser().username
      },
      panelClass: 'order-notes-popup',
      autoFocus: false
    });
  }

  setStatusDisplay(item){
    if(!!item) {
      // if(item.status == HST_APPROVED){
      //   item.status = PENDING_ORDERS;
      // } else if (item.status == COMPLETED){
      //   item.status = PROCESSED;
      // }
      if (item.status === "HST Approved") {
        return "Pending Orders";
      } else if (item.status === "Completed") {
        return "Processed";
      } else if(item.status === "Finalized WD Request") {
        if(item.withdrawType === "adjusted") {
          return "Finalized WD Request (Adjusted)";
        } else if(item.withdrawType === "refund") {
          return "Finalized WD Request (Refund)";
        } else if(item.withdrawType === "no refund") {
          return "Finalized WD Request (No Refund)";
        } else return item.status;
      } else return item.status;
    }
  }


}
