import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudentDetails } from 'src/app/core/models/eos/student-details.model';
import { TeacherDetails } from 'src/app/core/models/eos/teacher-details.model';

@Component({
  selector: 'app-order-students-details',
  templateUrl: './order-students-details.component.html',
  styleUrls: ['./order-students-details.component.scss']
})
export class OrderStudentsDetailsComponent implements OnInit {
  @Input() studentDetails: any;
  @Input() teacherDetails: any;
  @Input() shippingDetails: any = {};
  @Input() selectedShippingDetail: any = {};
  @Input() orderDetails: any;
  @Input() processingStatus: any[] = [];
  @Output() getNewOrderDetails = new EventEmitter<any>();
  @Output() onGetNewShippingDetails = new EventEmitter<any>();

  constructor() { }


  ngOnInit() {
  }

  getNewShippingDetails(shippingDetails) {
    this.onGetNewShippingDetails.emit(shippingDetails);
  }

  formatAmount(value: number): string {
    if(!value) return "$" + 0.00;
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }



}
