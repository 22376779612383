import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Class } from 'src/app/core/models/class.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { CLASS } from 'src/app/core/constants/configuration/class-constants.config';
import { VIEW_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { allClassesEndpoint, getClassSubjectsEndpoint, allGradeLevelsEndpoint, getActivePeriodStatusEndpoint, getStatusEndpoint, allHqtEndpoint, getClassOrderedCount, getActiveSchoolYearEndpoint, getProcessedOrderedCount, getWaitlistedOrderedCount, getOrderList, getProcessingStatusEndpoint, getProcessedNoWDOrderedCount, getCountByCodeAndStatus, getMainCourseBySubsequentCourseCodeCourseEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { Hqt } from 'src/app/core/models/hqt.model';
import { CURRICULUMS_PATH, CLASSES_PATH, CLASS_UPDATE_PATH, ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { FabButtonMessagingService } from 'src/app/core/services/messaging/fab-button-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, ASSISTANT_DIRECTOR, homeRoutingPermissions, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, SUPERVISOR } from 'src/app/core/constants/configuration/role-constants.config';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { truncateSync } from 'fs';

@Component({
	selector: 'app-class-view',
	templateUrl: './class-view.component.html',
	styleUrls: ['./class-view.component.scss']
})
export class ClassViewComponent implements OnInit, OnDestroy {

	unsubscribe: Subject<any> = new Subject();
	clazz: Class;
	reference: any = {};
	referenceValues: any = {};
	hasInitialized: boolean = false;
	curriculum: any = {};
	hqt: any = null;
	classOrderedCount : number = null;
	classProcessedOrderedCount : number = null;
	classWaitlistedOrderedCount : number = null;
	orderList: any[];
	addIcon: IconDefinition = faPlus;
	canOrderCourseParam: string;
	savedResponse: any;
	courseOrderDetails: any;
	curriculumOrderDetails: any;
	processingStatus: any[] = [];
	statusListCount = new Map<string, number>();
	mainCourse: Class = null;

  constructor(
		private route: ActivatedRoute,
		private router: Router,
		private crudService: CrudService,
		private loaderMessagingService: LoaderMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private dataCustomizerService: DataCustomizerService,
		private fabButtonMessagingService: FabButtonMessagingService,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(CLASSES_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(CLASS, VIEW_MODULE_NAME, false, null);
	}

  	ngOnInit() {
		this.loaderMessagingService.showPageLoader(true);
		this.findClass(this.route.snapshot.paramMap.get('id'));
		this.canOrderCourseParam = this.route.snapshot.queryParamMap.get('canOrderCourse');
	}

	ngOnDestroy(): void {
		this.fabButtonMessagingService.sendMessage({ canEdit: false, path: null, id: null });
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	};

	get canView(): boolean {
		return this.clazz && this.hasInitialized;
	}

	onLinkClick(id: number): void {
		this.router.navigate([CURRICULUMS_PATH, id]);
	}

	onOrderLinkClick(id: number): void {
		this.router.navigate([ORDERS_PATH, id]);
	}

	onCourseLinkClick(id: number): void {
		this.router.navigate([CLASSES_PATH, id]).then(()=>{
			window.location.reload();
		});
	}

	private setReferenceValues(): void {

		const subject: string = this.reference.subjects.find(subj => this.clazz.subject === subj.code).name;
		const fromGradeLevel: string = this.reference.gradeLevels.find(level => this.clazz.fromGradeLevel === level.code).name;
		const toGradeLevel: string = this.reference.gradeLevels.find(level => this.clazz.toGradeLevel === level.code).name;
		const activePeriodType: string = this.reference.periodTypes.find(type => this.clazz.activePeriodType === type.code).name;
		const status: string = this.reference.status.find(status => this.clazz.status === status.code).name;

		this.referenceValues = { subject, fromGradeLevel, toGradeLevel, activePeriodType, curriculum: this.curriculum, status, hqt: this.hqt };
	}

	// PUT ALL API CALL BELOW
	private findClass(id: string): void {

		this.crudService
			.getById<Class>(allClassesEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.savedResponse = response;
					this.clazz = this.dataCustomizerService.formatClassDetailsDisplay(response);
					this.curriculum = {
						id: response.classCurriculum.id,
						code: response.classCurriculum.code,
						name: response.classCurriculum.name,
						syCode: response.classCurriculum.syCode,
						startDate: response.classCurriculum.startDate,
						endDate: response.classCurriculum.endDate,
						assignedTo: response.classCurriculum.assignedTo
					};
					this.findMainCourse(this.clazz.code);
					this.getClassOrderedCount();
				}
			}, this.errorHandlerService.handleError);
	}

	private getClassOrderedCount(): void {
		this.crudService
            .getById<any>(getClassOrderedCount + "?classCode=" + this.clazz.code)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
				if(response) {
					this.classOrderedCount = response;
				}
				this.getClassProcessedOrderedCount();
				this.getClassWaitlistedOrderedCount();
				this.getOrderList();
        }, this.errorHandlerService.handleError);
	}

	private getOrderList(): void {
		this.crudService
            .getById<any>(getOrderList + "?courseCode=" + this.clazz.code)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
				if(response) {
					this.orderList = response;
					this.getProcessingStatus();
				}
				this.setReferenceSubjects();
        }, this.errorHandlerService.handleError);
	}

	private getClassProcessedOrderedCount(): void {
		this.crudService
            .getById<any>(getProcessedNoWDOrderedCount + "?classCode=" + this.clazz.code)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
				if(response) {
					this.classProcessedOrderedCount = response;
				}
				this.setReferenceSubjects();
        }, this.errorHandlerService.handleError);
	}

	private getClassWaitlistedOrderedCount(): void {
		this.crudService
            .getById<any>(getWaitlistedOrderedCount + "?classCode=" + this.clazz.code)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
				if(response) {
					this.classWaitlistedOrderedCount = response;
				}
				this.setReferenceSubjects();
        }, this.errorHandlerService.handleError);
	}

	private setReferenceSubjects(): void {

		this.crudService
			.getById<any>(getClassSubjectsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference = {};
					this.reference.subjects = response;
					this.reference.subjects.sort((a, b) => a.code.localeCompare(b.code));
					this.setReferenceGradeLevels();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferenceGradeLevels(): void {

		this.crudService
			.getById<any>(allGradeLevelsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.gradeLevels = response;
					this.reference.gradeLevels.sort((a, b) => a.code.localeCompare(b.code));
					this.setReferencePeriodTypes();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferencePeriodTypes(): void {

		this.crudService
			.getById<any>(getActivePeriodStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.periodTypes = response;
					this.reference.periodTypes.sort((a, b) => a.code.localeCompare(b.code));
					this.setReferenceStatus();
				}

			}, this.errorHandlerService.handleError);

	}

	private setReferenceStatus(): void {

		this.crudService
			.getById<any>(getStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.status = response;
					this.reference.status.sort((a, b) => a.code.localeCompare(b.code));
					if (this.clazz.hqtUsername && this.clazz.hqtUsername.trim() !== "") {
						this.findHqt();
					} else {
						this.setReferenceValues();
						this.setCourseAndOrderDetails();
						this.handleCompletion();
					}
					this.checkActiveSchoolYear();
				}
				
			}, this.errorHandlerService.handleError);

	}

	private findHqt(): void {

		this.crudService
			.getAllBy<Hqt>(`${allHqtEndpoint}/get-by-name-or-email?keyword=${this.clazz.hqtUsername}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.hqt = response.map(hqt => ({ email: hqt.email, name: hqt.name }))[0];
					this.setReferenceValues();
					this.setCourseAndOrderDetails();
				}

			}, this.errorHandlerService.handleError, this.handleCompletion);

	}

	private getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if(response){
					this.processingStatus = response;
					this.orderList = this.orderList.map(order => {
						order.status = this.getProcessingStatusName(order.status, order.withdrawType);
						return order;
					});
					if(this.canViewSummary) {
						this.getStatusCount();
					}
				}
			}, this.errorHandlerService.handleError);
  }

	private initFabMessage(): void {
		let roles = homeRoutingPermissions.find(route => route.path === CLASSES_PATH);
		const message: any = { canEdit: this.userService.hasRole(roles ? roles.roles : []) && this.isEditable, path: CLASS_UPDATE_PATH, id: this.clazz.id };
		this.fabButtonMessagingService.sendMessage(message);
		this.hasInitialized = true;
	}

	private checkActiveSchoolYear() {
		this.crudService
		.getById<any>(getActiveSchoolYearEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			if (response) {
				if(this.curriculum.syCode >= response.schoolYear) {
					this.initFabMessage();
				} else {
					this.hasInitialized = true;
				}
			}
		}, this.errorHandlerService.handleError);
	}

	onOrderCourse(){
		// Remove saved objects in localstorage
		localStorage.removeItem('courseOrderDetails');
		localStorage.removeItem('curriculumOrderDetails');
		localStorage.removeItem('createOrder');

		// Put the object into localStorage
		localStorage.setItem('courseOrderDetails', JSON.stringify(this.courseOrderDetails));
		localStorage.setItem('curriculumOrderDetails', JSON.stringify(this.curriculumOrderDetails));
		localStorage.setItem('createOrder', 'true');

		window.close();
	}

	get canOrderCourse(){
		if(this.canOrderCourseParam){
			if(this.canOrderCourseParam === 'true'){
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	setCourseAndOrderDetails(){
		this.courseOrderDetails = this.dataCustomizerService.createCustomizedOrderClass(this.savedResponse, this.reference);
		this.curriculumOrderDetails = this.dataCustomizerService.createCustomizedCurriculum(this.savedResponse.classCurriculum);
	}

	getProcessingStatusName(statusCode: string, withdrawType: string): string {
		let statusName: any = this.processingStatus.find(status => status.code === statusCode);
		if (statusName.name === "HST Approved") {
            return "Pending Orders";
        } else if (statusName.name === "Completed") {
            return "Processed";
        } else if(statusName.name === "Finalized WD Request") {
            if(withdrawType === "refund") {
            	return "Finalized WD Request (Refund)";
            } else if(withdrawType === "no refund") {
            	return "Finalized WD Request (No Refund)";
            } else return "Finalized WD Request (Adjusted)";
        } else return statusName.name || "";
	}

	getStatusCount() {
		this.statusListCount = new Map<string, number>();
		this.crudService.getById<Map<string, number>>(getCountByCodeAndStatus + "?courseCode=" + this.clazz.code)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			if(response) {
				this.statusListCount = response;
			}
		}, this.errorHandlerService.handleError);
	}

	get canViewSummary() {
		return this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR]);
	}

	findMainCourse(code: string) {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.getById<Class>(getMainCourseBySubsequentCourseCodeCourseEndpoint.concat(`?code=${code}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.mainCourse = response;
				}
			},
		this.errorHandlerService.handleError);	
	}

	get isEditable(): boolean {
		return !(
			this.userService.hasRole([PROGRAM_DIRECTOR]) &&
			this.userService.getLoginUser().username !== this.curriculum.assignedTo
		)
	}
}
