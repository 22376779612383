import { Injectable } from '@angular/core';
import * as moment from "moment";
import { Order } from '../../models/order/order.model';
import { OrderItem } from '../../models/order/order-item.model';
import { ShippingDetail } from '../../models/order/shipping-detail.model';
import { StudentInfo } from '../../models/eos/student-info.model';
import { ORDER_ITEM_TYPE_1, ORDER_ITEM_TYPE_3, PROCESSING_STATUS_1, ORDER_ITEM_TYPE_6 } from '../../constants/configuration/order-constants.config';

@Injectable({ providedIn: 'root' })
export class OrderHelper {

  constructor() { }

  setOrderModel(
    curriculumInfo: any,
    studentInfo: StudentInfo,
    selectedShippingDetail: any,
    selectedClasses: any,
    selectedBooks: any,
    syCode: string,
  ): Order {
    let shippingDetails = this.setShippingDetails(selectedShippingDetail);
    let orderItems = [];
    selectedClasses.forEach(clazz => orderItems.push(this.setOrderItem(clazz.selectedClass, ORDER_ITEM_TYPE_1)));
    selectedBooks.forEach(book => orderItems.push(this.setOrderItem(book, ORDER_ITEM_TYPE_3)));
    
    if(syCode != "2019-2020") {
      let onlineAccount: any[] = [];
        selectedClasses.forEach(clazz =>
          clazz.selectedCurriculumResources.forEach(oa=>
            this.addUnique(oa, onlineAccount)
            ));

        if(onlineAccount != null && onlineAccount.length > 0){
        onlineAccount.forEach(oa => orderItems.push(this.setOrderItem(oa, ORDER_ITEM_TYPE_6)));
        }
    }
    return {
      id: 0,
      code: '',
      name: studentInfo.studentDetails.name,
      description: studentInfo.studentDetails.scope,
      status: PROCESSING_STATUS_1,
      studentId: String(studentInfo.studentDetails.id),
      createdBy: null,
      modifiedBy: null,
      createdDate: null,
      modifiedDate: null,
      eosOrderCode: '',
      studentDistrictId: studentInfo.studentDetails.districtId,
      syCode: syCode,
      orderItems: orderItems,
      total: 0,
      shippingDetails: shippingDetails,
      orderBundles: null,
      processedTotal: 0,
      unprocessedTotal: 0,
      curriculumId: String(curriculumInfo.id),
      approveNotes: '',
      withdrawType: null,
      orderNotes: null
    };
  }

  private addUnique(item, onlineAccount) {

    if(onlineAccount.findIndex(x => x.id == item.id) == -1){
      onlineAccount.push(item);
    }
  }

  setShippingDetails(selectedShippingDetail: any): ShippingDetail {
		return {
      id: 0,
      guardianId: selectedShippingDetail.guardianId,
      name: selectedShippingDetail.addressName,
      email: selectedShippingDetail.email,
      line: selectedShippingDetail.addressLine,
      city: selectedShippingDetail.city,
      state: selectedShippingDetail.state,
      zipCode: selectedShippingDetail.zipCode,
      phone: selectedShippingDetail.phone,
		};
  }
  
  setOrderItem(item: any, itemType: string): OrderItem {
    return {
      id: 0,
      referenceCode: item.code,
      referenceName: item.name,
      referenceType: itemType,
      txnOrderGroup: null,
      price: item.fee || item.price || 0,
      originalPrice: item.fee || item.price || 0,
      newPrice: null,
      adjustedPrice: null,
      deposit: item.deposit ? null : 0,
      originalDeposit: item.deposit ? null : 0,
      newDeposit: null,
      adjustedDeposit: null,
      perClassCost: item.perClassCost || 0,
      originalPerClassCost: item.perClassCost || 0,
      newPerClassCost: null,
      adjustedPerClassCost: null,
      status: PROCESSING_STATUS_1,
      assetCode: null,
      trackingId: null,
      forCancel: false,
      checkIn: false,
      order: null,
      //activeStatus: item.activeStatus,
      curriculum: item.curriculum ? {
        ...item.curriculum,
        createdDate: moment(item.curriculum.createdDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
        modifiedDate: moment(item.curriculum.modifiedDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
      } : null,
      orderBundle: null,
      inBundle: false,
      classDetails: null,
      resourceDetails: null,
      rtcModifiedBy: null,
      rtcModifiedDate: null,
      priceRefundHistory: null,
      depositRefundHistory: null,
      perClassCostRefundHistory: null,
      priceAdjustmentHistory: null,
      depositAdjustmentHistory: null,
      perClassCostAdjustmentHistory: null,
      assetDetails: null,
    };
  }

}
