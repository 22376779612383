import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Credential } from "../../models/credential.model";
import { HttpClient } from "@angular/common/http";
import { baseServerUri, accessToken } from "../../constants/configuration/config.constant";
import { TokenDetails } from "../../models/token-details.model";
import { User } from "../../models/user.model";
import { currentUserEndpoint, revokeTokenEndpoint, accessTokenEndpoint, preLoginEndpoint } from "../../constants/endpoints.constant";

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private http: HttpClient) {}

  authenticate(credential: Credential): Observable<TokenDetails> {
    const body: string = `grant_type=password&username=${encodeURIComponent(credential.username)}&password=${encodeURIComponent(credential.password)}&otp=${encodeURIComponent(credential.otp)}`;
    return this.http.post<TokenDetails>(baseServerUri.concat(accessTokenEndpoint), body);
  }

  logout(): Observable<any> {
    let options: Object = { responseType: 'text' };
    return this.http.get(baseServerUri.concat(revokeTokenEndpoint), options);
  }

  checkAuthentication(callback: Function): void {
    callback(localStorage.getItem(accessToken) && localStorage.getItem(accessToken) !== '');
  }

  getLoginUser(): Observable<User> {
    return this.http.get<User>(baseServerUri.concat(currentUserEndpoint));
  }

}
