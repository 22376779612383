import { DataProperties } from "../../models/data-properties.model";
import { allCurriculumsEndpoint, allClassesEndpoint } from "../endpoints.constant";

export const ORDER: string = "ORDER";
export const NEW_ORDER_KEY: string = "newOrder";
export const PAST_ORDERS_KEY: string = "pastOrders";

export const ORDER_ITEM_TYPE_1: string = "ORDER_ITEM_TYPE_1";
export const ORDER_ITEM_TYPE_2: string = "ORDER_ITEM_TYPE_2";
export const ORDER_ITEM_TYPE_3: string = "ORDER_ITEM_TYPE_3";
export const ORDER_ITEM_TYPE_4: string = "ORDER_ITEM_TYPE_4";
export const ORDER_ITEM_TYPE_5: string = "ORDER_ITEM_TYPE_5";
export const ORDER_ITEM_TYPE_6: string = "ORDER_ITEM_TYPE_6";
export const ORDER_ITEM_TYPE_7: string = "ORDER_ITEM_TYPE_7";
export const ORDER_ITEM_TYPE_8: string = "ORDER_ITEM_TYPE_8";

export const PROCESSING_STATUS_1: string = "PROCESSING_STATUS_1";
export const PROCESSING_STATUS_2: string = "PROCESSING_STATUS_2";
export const PROCESSING_STATUS_3: string = "PROCESSING_STATUS_3";
export const PROCESSING_STATUS_4: string = "PROCESSING_STATUS_4";
export const PROCESSING_STATUS_5: string = "PROCESSING_STATUS_5";
export const PROCESSING_STATUS_6: string = "PROCESSING_STATUS_6";
export const PROCESSING_STATUS_7: string = "PROCESSING_STATUS_7";

export const REFUND_TYPE_1: string = "REFUND_TYPE_1";
export const REFUND_TYPE_2: string = "REFUND_TYPE_2";
export const REFUND_TYPE_3: string = "REFUND_TYPE_3";
export const REFUND_TYPE_4: string = "REFUND_TYPE_4";

export const orderDataProperties: DataProperties[] = [
    {
        label: "COS Order ID",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Student Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "studentDistrictId",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "School",
        property: "scope",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Date Submitted",
        property: "dateSubmitted",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Total Amount",
        property: "totalAmount",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "HUB Order ID",
        property: "eosOrderCode",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];

export const studentDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "districtId",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "MA",
        property: "masterAgreement",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "School",
        property: "scope",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Grade Level",
        property: "gradeLevel",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Learning Center",
        property: "learningCenter",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Current Balance",
        property: "currentBalance",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const shippingDetailDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Address Name",
        property: "addressName",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Address Line",
        property: "addressLine",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "City",
        property: "city",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "State",
        property: "state",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Zip Code",
        property: "zipCode",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Phone",
        property: "phone",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Email",
        property: "email",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];

export const orderResourcesDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Type",
        property: "type",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "ISBN/Serial Number",
        property: "code",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Description",
        property: "description",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    // {
    //     label: "Association",
    //     property: "group",
    //     sortable: false,
    //     clickable: false,
    //     filterable: true,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    {
        label: "Deposit",
        property: "deposit",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Price",
        property: "price",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const orderClassDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum Name",
        property: "curriculumName",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: true,
        endpoint: allCurriculumsEndpoint.concat("/get-by-code-or-name")
    },
    {
        label: "Class Code",
        property: "code",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Class Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: true,
        endpoint: allClassesEndpoint.concat("/get-by-code-or-name")
    },
    {
        label: "Course Code",
        property: "courseCode",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Per Class Cost",
        property: "perClassCost",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Availability Start Date",
        property: "startDate",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Availability End Date",
        property: "endDate",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];
