// HOME
export const CURRICULUMS_PATH: string = "home/curriculums";
export const CURRICULUM_CREATE_PATH: string = `${CURRICULUMS_PATH}/create`;
export const CURRICULUM_UPDATE_PATH: string = `${CURRICULUMS_PATH}/update`;
export const CLASSES_PATH: string = "home/classes";
export const CLASS_CREATE_PATH: string = `${CLASSES_PATH}/create`;
export const CLASS_UPDATE_PATH: string = `${CLASSES_PATH}/update`;
export const BUNDLES_PATH: string = "home/bundles";
export const BUNDLE_CREATE_PATH: string = `${BUNDLES_PATH}/create`;
export const BUNDLE_UPDATE_PATH: string = `${BUNDLES_PATH}/update`;
export const RESOURCES_PATH: string = "home/resources";
export const RESOURCE_CREATE_PATH: string = `${RESOURCES_PATH}/create`;
export const RESOURCE_UPDATE_PATH: string = `${RESOURCES_PATH}/update`;
export const VIDEOS_PATH: string = "home/videos";

// SETTINGS
export const HQTS_PATH: string = "settings/hqts";
export const HQT_CREATE_PATH: string = `${HQTS_PATH}/create`;
export const HQT_UPDATE_PATH: string = `${HQTS_PATH}/update`;
export const MODEL_PATH: string = "settings/model";
export const MODEL_UPDATE_PATH: string = `${MODEL_PATH}/update`;
export const MODEL_CREATE_PATH: string = `${MODEL_PATH}/create`;
export const ASSET_PATH: string = "settings/asset";
export const ASSET_UPDATE_PATH: string = `${ASSET_PATH}/update`;
export const ASSET_CREATE_PATH: string = `${ASSET_PATH}/create`;
export const UPLOADS_PATH: string = "settings/uploads";
export const DOWNLOAD_REPORTS_PATH: string = `settings/reports`;

// ORDERS
export const ORDERS_PATH: string = "home/orders";
export const OLD_ORDERS_PATH: string = "home/orders/old";
export const ORDER_DEPOSIT_PATH: string = "home/order-deposit/old";
export const ORDER_CURRICULUM_FEE_PATH: string = "home/order-curriculum-fee/old";
export const ORDER_CREATE_PATH: string = `${ORDERS_PATH}/create`;
export const ORDER_UPDATE_PATH: string = `${ORDERS_PATH}/update`;
export const TASKS_PATH: string = "home/tasks";
export const DOWNLOAD_ONLINE_ACCOUNTS_PATH: string = `${DOWNLOAD_REPORTS_PATH}/download-online-accounts`;
export const DOWNLOAD_CURRICULUM_REPORTS_PATH: string = `${DOWNLOAD_REPORTS_PATH}/download-curriculum-reports`;
export const STUDENTS_PATH: string = "home/students";

export const AUTHORIZE_REDIRECT_PATH: string = CURRICULUMS_PATH;
export const AUTHORIZE_SUPERVISOR_REDIRECT_PATH: string = ORDERS_PATH;
export const LOGIN_PATH: string = "/login";
