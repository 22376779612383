import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { UploadMessageService } from "src/app/core/services/messaging/upload-message.service";
import { FileService } from "../../../../core/services/data/file.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {
  downloadModelErrorEndpoint,
  modelErrorExist,
  assetErrorExist,
  downloadAssetErrorEndpoint,
  downloadAssetTemplateEndpoint,
  downloadBundleTemplateEndpoint,
  downloadClassTemplateEndpoint,
  downloadCurriculumTemplateEndpoint,
  downloadModelTemplateEndpoint,
  downloadResourceTemplateEndpoint,
  uploadCurriculumTemplateEndpoint,
  uploadClassTemplateEndpoint,
  uploadBundleTemplateEndpoint,
  uploadModelTemplateEndpoint,
  uploadAssetTemplateEndpoint,
  uploadResourceTemplateEndpoint,
  downloadCurriculumRolloverTemplateEndpoint,
  uploadCurriculumRolloverTemplateEndpoint,
} from "src/app/core/constants/endpoints.constant";
import { UploadStatusService } from "src/app/core/services/data/upload-status.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  baseServerUri,
  fileNameError,
  downloadFileExtension,
  xlsxFileMediaType,
  fileNameTemplate,
  zipFileExtension
} from "../../../../core/constants/configuration/config.constant";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import {
  downloadConfirmationModelError,
  downloadConfirmationTemplate
} from "src/app/core/constants/message.constant";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { saveAs } from "file-saver";
import { CrudService } from "src/app/core/services/data/crud.service";

@Component({
  selector: "upload-body",
  templateUrl: "./upload-body.component.html",
  styleUrls: ["./upload-body.component.scss"]
})
export class UploadBodyComponent implements OnInit, OnDestroy {
  @Input() navSide: string[];
  @ViewChild("fileSelect") inputFile: ElementRef;
  unsubscribe: Subject<any> = new Subject();
  public file: File;
  public messages: string[] = [];
  selectedNav: string;
  toggle: number = 0;
  okayToUpload: boolean = false;
  hasUploadProcessed: boolean = false;
  private eventBodies: {} = {};
  private hasInProgress: boolean = false;
  private targetUploadType;
  private intrvl: NodeJS.Timer;

  modelErrorExist: boolean = false;
  assetErrorExist: boolean = false;

  constructor(
    private uploadFileService: FileService,
    private crudService: CrudService,
    private uploadStatusService: UploadStatusService,
    private uploadMessageService: UploadMessageService,
    private errorHandlerService: ErrorHandlerService,
    private loaderMessagingService: LoaderMessagingService,
    private downloadFileService: FileService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedNav = this.navSide[0];

    this.intrvl = setInterval(() => {
      this.uploadPollingCheck();
    }, 3000);

    this.checkErrorModelDownload();
    this.checkErrorAssetDownload();

    this.uploadMessageService.uploadMessage$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(message => {
        this.selectedNav = this.navSide[message];
        this.file = null;
        this.toggle = message;
        this.inputFile.nativeElement.value = "";
        this.uploadPollingCheck();
      }, this.errorHandlerService.handleError);
  }

  private checkErrorAssetDownload(): void {
    this.crudService
      .getById<JSON>(assetErrorExist)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: any) => {
          this.assetErrorExist = response.exists;
        },
        error => {
          this.errorHandlerService.handleError(error);
        },
        this.handleCompletion
      );
  }

  private checkErrorModelDownload(): void {
    this.crudService
      .getById<JSON>(modelErrorExist)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: any) => {
          this.modelErrorExist = response.exists;
        },
        error => {
          this.errorHandlerService.handleError(error);
        },
        this.handleCompletion
      );
  }

  uploadPollingCheck(): void {
    this.uploadStatusService
      .checkUploadStatus()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            if (this.targetUploadType) {
              if (
                event.body[this.targetUploadType]["status"] === "SUCCESS" &&
                (this.hasUploadProcessed || this.hasInProgress)
              ) {
                this.messages.push(
                  `${new Date().toISOString()}: ${
                    event.body[this.targetUploadType]["description"]
                  }`
                );
                this.hasUploadProcessed = false;
              }
              if (
                event.body[this.targetUploadType]["status"] === "FAIL" &&
                (this.hasUploadProcessed || this.hasInProgress) &&
                typeof event.body[this.targetUploadType]["description"] === 'string' &&
                event.body[this.targetUploadType]["description"] !== '[object ProgressEvent]'
              ) {
                this.messages.push(
                  `${new Date().toISOString()}: ERROR: ${
                    event.body[this.targetUploadType]["description"]
                  }`
                );
                this.hasUploadProcessed = false;
              }
            }

            this.hasInProgress = Object.keys(event.body).some(uploadType => {
              this.targetUploadType = uploadType;
              return event.body[uploadType]["status"] === "IN_PROGRESS";
            });

            this.targetUploadType = this.hasInProgress
              ? this.targetUploadType
              : null;

            if (!this.hasInProgress) {
              Object.keys(event.body).forEach(uploadType => {
                if (
                  event.body[uploadType]["type"] ===
                  this.selectedNav.toLocaleUpperCase()
                ) {
                  this.targetUploadType = uploadType;
                }
              });
            }

            if (this.hasInProgress) {
              this.okayToUpload = false;
              this.messages.push(
                `${new Date().toISOString()}: ${
                  event.body[this.targetUploadType]["description"]
                }`
              );
            } else {
              this.okayToUpload = true;
            }
          }
        },
        (error: HttpErrorResponse) => {
          if (
            error instanceof HttpErrorResponse &&
            typeof error.error["error"] === 'string' &&
            error.error["error"] !== '[object ProgressEvent]'
          ) {
            this.messages.push(
              `${new Date().toISOString()}: ERROR: ${error.error["error"]}`
            );
          }
        }
      );
  }

  getFileDetails($event: any) {
    this.file = $event.target.files[0];
    this.uploadPollingCheck();
  }

  uploadFile(): void {
    this.okayToUpload = false;
    this.messages.push(
      `${new Date().toISOString()}: Uploading ${this.selectedNav} file...`
    );
    this.uploadFileService
      .pushFileToApi(
        this.file,
        baseServerUri.concat(this.getUploadEndpoint(this.navSide[this.toggle]))
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            if (event.ok) {
              this.messages.push(
                `${new Date().toISOString()}: ${event.body.toString()}`
              );
            }
            this.hasUploadProcessed = true;
          }
        },
        (error: HttpErrorResponse) => {
          if (
            error instanceof HttpErrorResponse &&
            String(error.error) !== '[object ProgressEvent]'
          ) {
            this.messages.push(
              `${new Date().toISOString()}: ERROR: ${String(error.error)}`
            );
          }
        }
      );
  }

  openDownloadTemplate(): void {
    var confirmMessage = downloadConfirmationTemplate.replace(
      "%",
      `${this.navSide[this.toggle]}`
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = confirmMessage;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        result => {
          if (result) {
            this.download(this.getTemplateEndpoint(this.navSide[this.toggle]), false);
            
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  getTemplateEndpoint(name){
    switch(name){
      case "Curriculum":
        return downloadCurriculumTemplateEndpoint;
      case "Course":
        return downloadClassTemplateEndpoint;
      case "Bundle":
        return downloadBundleTemplateEndpoint;
      case "Model":
        return downloadModelTemplateEndpoint;
      case "Asset":
        return downloadAssetTemplateEndpoint;
      case "Resource":
        return downloadResourceTemplateEndpoint;
      case "Curriculum Rollover":
        return downloadCurriculumRolloverTemplateEndpoint;
    }

  }

  getUploadEndpoint(name){
    switch(name){
      case "Curriculum":
        return uploadCurriculumTemplateEndpoint;
      case "Course":
        return uploadClassTemplateEndpoint;
      case "Bundle":
        return uploadBundleTemplateEndpoint;
      case "Model":
        return uploadModelTemplateEndpoint;
      case "Asset":
        return uploadAssetTemplateEndpoint;
      case "Resource":
        return uploadResourceTemplateEndpoint;
      case "Curriculum Rollover":
        return uploadCurriculumRolloverTemplateEndpoint;
    }

  }

  openConfirmationErrorDownloadDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = downloadConfirmationModelError;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        result => {
          if (result) {
            this.download(this.toggle == 3 ? downloadModelErrorEndpoint : downloadAssetErrorEndpoint, true);
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  handleCompletion = (): void => {
    this.loaderMessagingService.showPageLoader(false);
  };

  download(urlPath: string, isErrorFile: boolean): void {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri.concat(urlPath);
    this.downloadFileService
      .getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        const blob = new Blob([response], { type: xlsxFileMediaType });
        saveAs(
          blob,
          isErrorFile
            ? this.navSide[this.toggle].toLowerCase().replace(/ /g, '_').concat(fileNameError).concat(downloadFileExtension)
            : this.navSide[this.toggle]
                .toLowerCase()
                .replace(/ /g, '_')
                .concat(fileNameTemplate)
                .concat(downloadFileExtension)
        );
      }, this.errorHandlerService.handleError);
  }

  ngOnDestroy(): void {
    clearInterval(this.intrvl);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
