import { DataProperties } from "../../models/data-properties.model";

export const STUDENT: string = "STUDENT";

export const studentListDataProperties: DataProperties[] = [
    {
        label: "District ID",
        property: "districtId",
        sortable: false,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Subject",
        property: "subject",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Course Name",
        property: "courseName",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Section",
        property: "sectionName",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Section School Code",
        property: "sectionSchoolCode",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "MA",
        property: "masterAgreement",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    // {
    //     label: "School",
    //     property: "scope",
    //     sortable: false,
    //     clickable: false,
    //     filterable: false,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    {
        label: "Grade Level",
        property: "gradeLevel",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Learning Center",
        property: "learningCenter",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Current Balance",
        property: "currentBalance",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const studentClassesListDataProperties: DataProperties[] = [
    {
        label: "Name",
        property: "codeName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum",
        property: "curriculumName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Request to Cancel",
        property: "requestToCancel",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Order ID",
        property: "orderCode",
        sortable: false,
        clickable: true,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];
export const studentWaitlistedOrdersDataProperties: DataProperties[] = [
    {
        label: "Name",
        property: "codeName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum",
        property: "curriculumName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Order ID",
        property: "orderCode",
        sortable: false,
        clickable: true,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];



export const studentBooksListDataProperties: DataProperties[] = [
    {
        label: "Name",
        property: "codeName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Consumable",
        property: "consumable",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Asset Tag",
        property: "assetCode",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Modified By",
        property: "modifiedBy",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Modified Date",
        property: "modifiedDate",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
    // {
    //     label: "Order ID",
    //     property: "orderCode",
    //     sortable: false,
    //     clickable: true,
    //     filterable: false,
    //     autocomplete: false,
    //     endpoint: ""
    // }
];

export const studentOnlineAccountsListDataProperties: DataProperties[] = [
    {
        label: "Name",
        property: "codeName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    // {
    //     label: "Curriculum",
    //     property: "curriculumName",
    //     sortable: false,
    //     clickable: false,
    //     filterable: false,
    //     autocomplete: false,
    //     endpoint: ""
    // },
];

export const studentBundlesListDataProperties: DataProperties[] = [
    {
        label: "Name",
        property: "codeName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Classes",
        property: "classes",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Order ID",
        property: "order",
        sortable: false,
        clickable: true,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
];

export const refundListDataProperties: DataProperties[] = [
    {
        label: "Order ID",
        property: "orderCode",
        sortable: false,
        clickable: true,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Refund Amount",
        property: "amount",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    
    {
        label: "Reference Name",
        property: "orderItemCodeName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Refund Type",
        property: "type",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Item Status",
        property: "orderItemStatus",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
];
