import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseHistoryModel } from 'src/app/core/models/course-history.model';
import { pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { PaginationDto } from 'src/app/core/models/dto/pagination-model';
import { SortDto } from 'src/app/core/models/dto/sort-dto';

@Component({
  selector: 'course-history',
  templateUrl: './course-history.component.html',
  styleUrls: ['./course-history.component.scss']
})
export class CourseHistoryComponent implements OnInit {
  @Input() courseHistoryItems: CourseHistoryModel = null
  @Input() courseHistoryItemsCount: number = 0;
  @Input() itemType: string;
  pageSize: number = pageSizeOptions[0];
  @Input() pageIndex: number;
  keyword: string;
  @Output() pageOnChangeEventEmitter = new EventEmitter<PaginationDto>();
  @Output() sortOnChangeEventEmitter = new EventEmitter<SortDto>();
  @Output() searchOnChangeEventEmitter = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
    this.keyword = "";
    this.pageIndex = 0;
  }

  onPageChange(page: PaginationDto) {
    this.pageOnChangeEventEmitter.emit(page);
  }

  onToggleSort(value: SortDto) {
    this.sortOnChangeEventEmitter.emit(value);
  }

  onSearch(keyword: string) {
    this.searchOnChangeEventEmitter.emit(keyword);
  }

}
