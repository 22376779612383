import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FormUtil } from '../../../../core/services/util/form.util';
import { Page } from '../../../../core/models/page.model';
import { Resource } from '../../../../core/models/resource.model';
import { TASKS_PATH } from '../../../../core/constants/routes.constant';
import { ASC, FIRST_SEM, SECOND_SEM } from '../../../../core/constants/configuration/common.constant';
import { TASK } from '../../../../core/constants/configuration/task-constants.config';
import { homeRoutingPermissions, SPECIALIST } from "../../../../core/constants/configuration/role-constants.config";
import { CrudService } from '../../../../core/services/data/crud.service';
import { UserService } from '../../../../core/services/util/user.service';
import { QueryService } from '../../../../core/services/util/query.service';
import { ErrorHandlerService } from '../../../../core/services/util/error-handler.service';
import { DataCustomizerService } from '../../../../core/services/util/data-customizer.service';
import { HeaderMessagingService } from '../../../../core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from '../../../../core/services/messaging/loader-messaging.service';
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { saveAs } from "file-saver";
import {
  allResourcesEndpoint,
  getProcessingStatusEndpoint,
  downloadBundlesEndpoint,
  allCurriculumsEndpoint,
  allClassesEndpoint,
  allReportsEndpoint,
} from '../../../../core/constants/endpoints.constant';
import { baseServerUri, xlsxFileMediaType, fileNameBundle, downloadFileExtension, csvMediaType, csvFileExtension } from 'src/app/core/constants/configuration/config.constant';
import { FileService } from 'src/app/core/services/data/file.service';
import { HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { downloadCurriculumReportHeader, downloadCurriculumReportMessage, downloadCustomReportHeader, downloadCustomReportMessage, downloadOnlineAccountReportHeader, downloadOnlineAccountReportMessage } from 'src/app/core/constants/message.constant';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { Class } from 'src/app/core/models/class.model';

@Component({
  selector: 'app-download-online-accounts',
  templateUrl: './download-online-accounts.component.html',
  styleUrls: ['./download-online-accounts.component.scss']
})
export class DownloadOnlineAccountsComponent implements OnInit {
  
	unsubscribe: Subject<any> = new Subject();
  downloadOnlineAccountForm: FormGroup;
  // onlineAccounts: any[] = [];
  // processingStatus: any[] = [];
  // selectedOnlineAccount: any;
  // selectedStatus: any;
  fromDate: any;
  toDate: any;
  // testcheck = true;

	downloadIcon: IconDefinition = faDownload;

  field: any;
  curriculum: any;
  curriculums: any;
  course: any;
  courses: any;
  isProcessing: boolean;
  delayTimer: NodeJS.Timer;
  timeout: number = 300;
  selectedCurriculum: any;
  selectedCourse = "";
  selectedReport: any;
  selectedReportType: any;
  reports: any[] = [];
  isFromDateFilled: any;
  isToDateFilled: any;
  reportTypeList: any[] = [];
  reportSelectionType: any;
  reportSelectionTypeList: any[] = [];
  selectedCOSProcessor: any[] = [];
  selectedActivePeriodType: string = "";
  activePeriodTypes: any[] = [
		{display: "All Semester", value: ""},
		{display: "1st Semester", value: FIRST_SEM},
		{display: "2nd Semester", value: SECOND_SEM}
	];

  constructor(
		private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private formsUtil: FormUtil,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
    private queryService: QueryService,
    private userService: UserService,
    private downloadFileService: FileService,
  ) {
  }

  ngOnInit() {
    this.downloadOnlineAccountForm = this.formsUtil.createDownloadOnlineAccountForm();
    this.downloadOnlineAccountForm.controls["course"].disable();
    this.setDefaultReport();
    this.setDefaultReportSelectionType();

    // this.initParams();
  }

  getSelectedCOSProcessor(selectedCOSProcessor){
    this.selectedCOSProcessor = selectedCOSProcessor;
  }
  
  onReportTypeChange(reportType){
    if(reportType == "PRODUCTIVITY_REPORT"){
      this.reports = [];
      this.reports.push({name: 'Summary of Processed Order Report', code: 'SUMMARY_OF_PROCESSED_ORDER_REPORT'});
      this.reports.push({name: 'Summary of Verified WD Request Report', code: 'SUMMARY_OF_VERIFIED_WD_REQUEST_REPORT'});
    } else {
      this.reports = [];
    }
  }

  setDefaultReport(){
    this.reportTypeList = [];
    if(!(this.userService.getFirstRole().authority == SPECIALIST && this.userService.getUserRoles().length == 1)){
      this.reportTypeList.push({name: 'Per Curriculum Report', code: 'PER_CURRICULUM_REPORT'});
    }
    this.reportTypeList.push({name: 'Productivity Report', code: 'PRODUCTIVITY_REPORT'});

    this.reports = [];
  }

  setDefaultReportSelectionType(){
    this.reportSelectionTypeList = [];
    this.reportSelectionTypeList.push('All Employee');
    this.reportSelectionTypeList.push('Selected Employee');

  }

  isDownloadButtonDisabled(){
    if(
      (!this.selectedReportType) ||
      (this.reportSelectionType === 'Selected Employee' && this.selectedCOSProcessor.length === 0) ||
      (this.selectedReportType == 'PRODUCTIVITY_REPORT' && (
        !this.downloadOnlineAccountForm.controls['dateFrom'].value || !this.downloadOnlineAccountForm.controls['dateTo'].value
      ))
    ){
      return true;
    } else {
      return false;
    }
  }

  // initParams() {
	// 	// this.loaderMessagingService.showPageLoader(true);
  //   // this.getProcessingStatus();
  //   // this.fromDate = new Date;
  //   // this.downloadOnlineAccountForm.get("dateFrom").setValue(this.fromDate);
  //   // this.toDate = new Date;
  //   // this.downloadOnlineAccountForm.get("dateTo").setValue(this.toDate);
  //   // this.clearValidator('curriculumCode');
  // }

  // onSelectOnlineAccount(value: any) {
  //   this.selectedOnlineAccount = value;
  // }

  // onSelectStatus(value: any) {
  //   this.selectedStatus = value;
  // }
  
  // getProcessingStatus(): void {
	// 	this.crudService
	// 		.getById<any>(getProcessingStatusEndpoint)
	// 		.pipe(takeUntil(this.unsubscribe))
	// 		.subscribe(response => {
  //       this.processingStatus = response;
  //       this.processingStatus.sort((a, b) => a.code.localeCompare(b.code));
  //       this.processingStatus.forEach(status => {
  //         if(status.code != "PROCESSING_STATUS_5") {
  //           status.checked = true;
  //         } else {
  //           status.checked = false;
  //         }
  //       });
  //       // this.getOnlineAccounts();
  //     }, this.errorHandlerService.handleError,
  //     this.handleCompletion);
  // }

  // getOnlineAccounts(): void {
  //   let filters: Map<String, String> = new Map<string, string>();
  //   filters.set("type", "Online Account");
    
  //   this.crudService
  //     .getAll<Resource>(allResourcesEndpoint.concat(
  //       this.queryService.buildResourceSearchQuery(0, 1000, name, ASC, "", filters, this.getReference())))
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe(
  //       this.handleSearchResponse,
  //       this.errorHandlerService.handleError,
  //       this.handleCompletion
  //     );
  //   // this.onlineAccounts.push({name: 'ConnectEd', code: '1'});
  //   // this.onlineAccounts.push({name: 'ConnectEd2', code: '4'});
  //   // this.onlineAccounts.push({name: 'Edgenuity', code: '7'});
  //   // this.onlineAccounts.push({name: 'Schoology', code: '10'});
  // }

  // handleSearchResponse = (response: Page<Resource>): void => {
  //   if (response) {
  //     response.content.forEach((resource: Resource) => {
  //       if(resource.code == "12"){
  //       this.onlineAccounts.push(this.dataCustomizerService.formatResourceDetailsDisplay(resource, true, this.getReference()))
  //       }
  //     }
  //     );
  //   }
  // };

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  }

  // getReference(): any {
  //   return {
  //     resourceTypes: [],
  //     resourceGroups: [],
  //     onlineAccounts: [],
  //   };
  // }

  onDownloadOnlineAccounts() {
    this.fromDate = "";
    this.toDate = "";
    this.isFromDateFilled = Date.parse(this.downloadOnlineAccountForm.controls['dateFrom'].value) || 0;
    this.isToDateFilled = Date.parse(this.downloadOnlineAccountForm.controls['dateTo'].value) || 0;

    if(this.isFromDateFilled && this.isToDateFilled){
      this.formatDates();
    } else if(this.isFromDateFilled || this.isToDateFilled){
      this.downloadOnlineAccountForm.controls['dateFrom'].setValidators([Validators.required]);
      this.downloadOnlineAccountForm.controls['dateFrom'].updateValueAndValidity();
      this.downloadOnlineAccountForm.controls['dateTo'].setValidators([Validators.required]);
      this.downloadOnlineAccountForm.controls['dateTo'].updateValueAndValidity();
    } else {
      this.downloadOnlineAccountForm.controls['dateFrom'].clearValidators();
      this.downloadOnlineAccountForm.controls['dateFrom'].updateValueAndValidity();
      this.downloadOnlineAccountForm.controls['dateTo'].clearValidators();
      this.downloadOnlineAccountForm.controls['dateTo'].updateValueAndValidity();
    }

    this.validateUntouchedForms();

    if (
      this.downloadOnlineAccountForm.valid
      || (
        this.selectedReportType === "PER_CURRICULUM_REPORT"
        && this.downloadOnlineAccountForm.controls["curriculumCode"].valid
        && this.downloadOnlineAccountForm.controls["reportType"].valid
        && this.downloadOnlineAccountForm.controls["report"].valid
      )
    ) {
      const params = new Map<String, String>();
      params.set("curriculumCode-Report", this.selectedCurriculum + "-" + this.selectedReport);
      params.set("courseCode", this.selectedCourse);
      params.set("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);
      params.set("selectedActivePeriodType", this.selectedActivePeriodType);
      
      if(this.isFromDateFilled && this.isToDateFilled){
        params.set("all", "false");
        params.set("fromDate", this.fromDate + " 00:00:00");
        params.set("toDate", this.toDate + " 23:59:59");
      } else {
        params.set("all","true");
        params.set("fromDate", this.fromDate);
        params.set("toDate", this.toDate);
      }

      if(this.userService.getFirstRole().authority !== SPECIALIST && this.selectedReportType == "PRODUCTIVITY_REPORT"){
        this.reportSelectionType === 'All Employee';
      }

      if(this.reportSelectionType === 'Selected Employee' && this.selectedCOSProcessor.length > 0){
        let selectedCOSProcessorList: any[] = [];
        this.selectedCOSProcessor.filter(processor => selectedCOSProcessorList.push(processor.email));
        params.set("selectedCOSProcessor", selectedCOSProcessorList.toString());
      }

      if (
        this.downloadOnlineAccountForm.valid
        || (
          this.selectedReportType === "PER_CURRICULUM_REPORT"
          && this.downloadOnlineAccountForm.controls["curriculumCode"].valid
          && this.downloadOnlineAccountForm.controls["reportType"].valid
          && this.downloadOnlineAccountForm.controls["report"].valid
        )
      ) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent);
        dialogRef.componentInstance.confirmTitle = downloadCustomReportHeader;
        dialogRef.componentInstance.confirmMessage = downloadCustomReportMessage;
        dialogRef.afterClosed().subscribe(result => {
          if(result) this.download(params);
        });
      }
    }
  }

  formatDates() {
    this.fromDate = this.downloadOnlineAccountForm.controls['dateFrom'].value;
    this.toDate = this.downloadOnlineAccountForm.controls['dateTo'].value;

    const format = "yyyy-MM-dd";
    this.fromDate = formatDate(this.fromDate, format, 'en-US');
    this.toDate = formatDate(this.toDate, format, 'en-US');
  }

  refineURI(query: String): String {
		return encodeURIComponent(String(query));
	}

  download(params: any): void {
    let fileExtension = "";
    this.loaderMessagingService.showPageLoader(true);
    let endpoint = "";
    if(this.selectedReportType != "PRODUCTIVITY_REPORT"){
      endpoint = baseServerUri.concat("/report/downloadCurriculum");
      fileExtension = csvFileExtension;
    } else {
      endpoint = baseServerUri.concat("/report/download-productivity-report");
      fileExtension = downloadFileExtension;
    }
    let query = "";
    if (params.size > 0) params.forEach((value, key) => query = query.concat(`&${key}=${this.refineURI(value)}`));
    this.downloadFileService.getFile(endpoint.concat('?' + query))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
          let date = new Date();
          const format = "yyyyMMdd_HHmm";
          const formattedDate = formatDate(date, format, 'en-US');
          let selectedSemester = this.activePeriodTypes.find(activePeriodType => activePeriodType.value == this.selectedActivePeriodType).display || "";
          let fileName = "";
          if(this.curriculum && this.selectedReportType != "PRODUCTIVITY_REPORT"){
            fileName = this.curriculum.name + " (" + selectedSemester + ") " + formattedDate;
          } else {
            if(this.selectedReport == 'SUMMARY_OF_PROCESSED_ORDER_REPORT'){
              fileName = 'Summary of Processed Order Report ' + " (" + selectedSemester + ") " + formattedDate;
            } else {
              fileName = 'Summary of Verified WD Request Report ' + " (" + selectedSemester + ") " + formattedDate;
            }
          }
          const blob = new Blob([response], { type: csvMediaType });
          saveAs(blob, fileName.concat(fileExtension));
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  private validateUntouchedForms(): void {
		Object.keys(this.downloadOnlineAccountForm.controls).forEach(control => {
			this.downloadOnlineAccountForm.get(control).markAsTouched();
      this.downloadOnlineAccountForm.get(control).markAsDirty();
		});
  }


  onCurriculumChange(keyword: string): void {
		this.field = "CURRICULUM";
		this.curriculums = [];
    this.downloadOnlineAccountForm.controls["course"].disable();
		this.isProcessing = false;
		
		clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			this.isProcessing = true;
			setTimeout(() => this.findCurriculums(keyword.trim()), this.timeout);
		}, this.timeout);

    if(keyword == ""){
      this.setDefaultReport();
    }

  }

  onCourseChange(keyword: string): void {
    if(keyword == ""){
      this.courses = [];
      this.course = {};
      this.selectedCourse = "";
    }
		this.field = "COURSE";
		this.courses = [];
		this.isProcessing = false;
		
		clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			this.isProcessing = true;
			setTimeout(() => this.findCourses(keyword.trim(), this.curriculum.id), this.timeout);
		}, this.timeout);

  }


  findCurriculums(keyword: string): void {
		this.crudService
			.getAllBy<Curriculum>(`${allCurriculumsEndpoint}/get-by-code-or-name?keyword=${keyword}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
          this.isProcessing = false
					if (response.length > 0) {
						response.map(curriculum => this.curriculums.push({ code: curriculum.code, name: curriculum.name, id: curriculum.id }));
					} else {
						this.downloadOnlineAccountForm.controls['curriculumCode'].setErrors({ 'notExists': true });
					}

				}

			}, (error) => {
				this.isProcessing = false;
				this.errorHandlerService.handleError(error)
			}, this.handleCompletion);

  }

  onCurriculumSelect(code: string): void {
		this.curriculum = {};
		this.curriculum.code = code;
		this.curriculum.name = this.curriculums.find(curr => curr.code === code).name;
    this.curriculum.id = this.curriculums.find(curr => curr.code === code).id;
    this.downloadOnlineAccountForm.get("curriculumCode").setValue(`${this.curriculum.name} (${this.curriculum.code})`);
    this.selectedCurriculum = code;
    this.downloadOnlineAccountForm.controls['report'].reset();
    this.downloadOnlineAccountForm.controls['course'].reset();
    this.courses = [];
    this.downloadOnlineAccountForm.controls["course"].enable();
    this.setCurriculumReports(this.selectedCurriculum, this.curriculum.name);
  }

  
  onCourseSelect(code: string): void {
		this.course = {};
		this.course.code = code;
		this.course.name = this.courses.find(curr => curr.code === code).name;
    this.course.id = this.courses.find(curr => curr.code === code).id;
    this.downloadOnlineAccountForm.get("course").setValue(`${this.course.name} (${this.course.code})`);
    this.selectedCourse = code;
  }


  findCourses(keyword, currId){
		this.crudService
			.getAllBy<Class>(`${allClassesEndpoint}/get-by-curriculum-id-keyword?curriculumId=${currId}&keyword=${keyword}`)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
          this.isProcessing = false
					if (response.length > 0) {
						response.map(course => this.courses.push({ code: course.code, name: course.name }));
					} else {
						this.downloadOnlineAccountForm.controls['course'].setErrors({ 'notExists': true });
					}

				}

			}, (error) => {
				this.isProcessing = false;
				this.errorHandlerService.handleError(error)
			}, this.handleCompletion);

  }

  setCurriculumReports(curriculumCode, curriculumName: string){
    this.reports = [];
    if(curriculumName.toUpperCase() == "CALVERT LEARNING"){
      this.reports.push({name: 'Standard Report', code: 'STANDARD_REPORT'});
      this.reports.push({name: 'WD Request Report', code: 'WD_REQUEST_REPORT'});
      this.reports.push({name: 'Verified WD Request Report', code: 'VERIFIED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'Finalized WD Request Report', code: 'FINALIZED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'Students Report', code: 'STUDENTS_REPORT'});
      this.reports.push({name: 'Teachers Report', code: 'TEACHERS_REPORT'});
      this.reports.push({name: 'Sections Report', code: 'SECTIONS_REPORT'});
      this.reports.push({name: 'Enrollments Report', code: 'ENROLLMENTS_REPORT'});
    } else if(curriculumName.toUpperCase() == "CHOICE PLUS ACADEMY") {
      this.reports.push({name: 'Standard Report', code: 'STANDARD_REPORT'});
      this.reports.push({name: 'WD Request Report', code: 'WD_REQUEST_REPORT'});
      this.reports.push({name: 'Verified WD Request Report', code: 'VERIFIED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'Finalized WD Request Report', code: 'FINALIZED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'MHE Courses Report', code: 'MHE_COURSES_REPORT'});
      this.reports.push({name: 'MHE Classes Report', code: 'MHE_CLASSES_REPORT'});
      this.reports.push({name: 'MHE Users Report', code: 'MHE_USERS_REPORT'});
      this.reports.push({name: 'MHE Enrollments Report', code: 'MHE_ENROLLMENTS_REPORT'});
      this.reports.push({name: 'Schoology Course Report', code: 'SCHOOLOGY_COURSE_REPORT'});
      this.reports.push({name: 'Schoology Users Report', code: 'SCHOOLOGY_USERS_REPORT'});
      this.reports.push({name: 'Schoology Enrollment Report', code: 'SCHOOLOGY_ENROLLMENT_REPORT'});
    } else if(curriculumName.toUpperCase() == "EDGENUITY HQT PROGRAM"){
      this.reports.push({name: 'Standard Report', code: 'STANDARD_REPORT'});
      this.reports.push({name: 'WD Request Report', code: 'WD_REQUEST_REPORT'});
      this.reports.push({name: 'Verified WD Request Report', code: 'VERIFIED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'Finalized WD Request Report', code: 'FINALIZED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'Edgenuity Student Report', code: 'EDGENUITY_STUDENT_REPORT'});
      this.reports.push({name: 'Edgenuity Teacher Report', code: 'EDGENUITY_TEACHER_REPORT'});
    } else {
      this.reports.push({name: 'Standard Report', code: 'STANDARD_REPORT'});
      this.reports.push({name: 'WD Request Report', code: 'WD_REQUEST_REPORT'});
      this.reports.push({name: 'Verified WD Request Report', code: 'VERIFIED_WD_REQUEST_REPORT'});
      this.reports.push({name: 'Finalized WD Request Report', code: 'FINALIZED_WD_REQUEST_REPORT'});
    }
  }
}
