import { DataProperties } from "../../models/data-properties.model";

export const CLASS: string = "COURSE";
export const classTab: string = "CLASS";

export const sideNavLabelClass: string[] = ["Main Form", "Summary"];
export const classDataProperties: DataProperties[] = [
    {
        label: "Code",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum",
        property: "curriculumName",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Subject",
        property: "subject",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Section Name",
        property: "sectionName",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Section",
        property: "section",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Keywords",
        property: "keywords",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];
