import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudentDetails } from "src/app/core/models/eos/student-details.model";
import { TeacherDetails } from "src/app/core/models/eos/teacher-details.model";
import { Order } from "src/app/core/models/order/order.model";
import { InputUtilitiesModule } from 'angular-bootstrap-md';
import { StudentInfoWithShipping } from 'src/app/core/models/eos/student-info-with-shipping.model';
import { ORDER_ITEM_TYPE_6, ORDER_ITEM_TYPE_3, ORDER_ITEM_TYPE_5 } from 'src/app/core/constants/configuration/order-constants.config';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  @Input() studentDetails: StudentInfoWithShipping[];
  @Input() teacherDetails: TeacherDetails;
  @Input() shippingDetails: any = {};
  @Input() selectedShippingDetail: any = {};
  @Input() orderDetails: any = [];
  @Input() processingStatus: any[] = [];
  @Output() getNewOrderDetails = new EventEmitter<any>();
  @Output() onGetNewShippingDetails = new EventEmitter<any>();
  @Output() getTotalAmount = new EventEmitter<any>();

  studentList: StudentDetails[];
  showStudentPanel: boolean = false;

  type1 : string = "ORDER_ITEM_TYPE_2";
  type2 : string = "ORDER_ITEM_TYPE_3";

  constructor() { }

  ngOnInit() {
  }

  onGetNewOrderDetails($event) {
    this.getNewOrderDetails.emit($event);
  }

  onGetTotalAmount($event) {
    this.getTotalAmount.emit($event);
  }

  getNewShippingDetails($event) {
    this.onGetNewShippingDetails.emit($event);
  }

  formatAmount(value: number): string {
    if(!value) return "$" + 0.00;
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  findSelectedShipping(items) {
    return items.find( shippingDetails => shippingDetails.check );
  }

  hasOnlineAccount(indexStudent){
    if(this.orderDetails[indexStudent].orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_6).length > 0){
      return true;
    } else{
      return false;
    }
  }

  hasWorkBook(indexStudent){
    if(this.orderDetails[indexStudent].orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_3).length > 0){
      return true;
    } else{
      return false;
    }
  }

  hasFeeDepositReference(indexStudent){
    if(this.orderDetails[indexStudent].orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_5).length > 0){
      return true;
    } else{
      return false;
    }
  }

  isOutsideGradeLevels(orderDetails: any[], studentDetails: any){
    return !!orderDetails && !!studentDetails && orderDetails.filter(item => studentDetails.gradeLevel < item.classDetails.fromGradeLevel || studentDetails.gradeLevel > item.classDetails.toGradeLevel).length > 0;
  }

  coursesOutsideGradeLevels(orderDetails: any[], studentDetails: any){
    let courseNames = "";
    if (!!orderDetails && !!studentDetails) {
      orderDetails.map(item => {
        if(studentDetails.gradeLevel < item.classDetails.fromGradeLevel || studentDetails.gradeLevel > item.classDetails.toGradeLevel) {
          if(courseNames.length == 0) {
            courseNames = courseNames + item.referenceCode + " " + item.referenceName;
          } else {
            courseNames = courseNames + ", " + item.referenceCode + " " + item.referenceName;
          }
        }
      });
    }
    return courseNames;
  }
}
