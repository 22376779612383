import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { homeModuleName, pageSizeOptions, CLOSE, SY_CODE, ASC, fullYearFormat, OPEN, SUCCESS, PAGE_SIZE, PAGE_INDEX, FIRST_SEM, SECOND_SEM, ACTIVE_PERIOD_TYPE } from 'src/app/core/constants/configuration/common.constant';
import { homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faDownload, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { taskDataProperties, ASSIGN_OTHERS, ASSIGN_SELF, PRE_COMPLETE, COMPLETE, WORKBOOKTEXTBOOK, TASK_GROUP_2, CANCEL, APPROVE_WAITLIST, CHANGE_COURSE, ASSIGN_OTHERS_WD, ASSIGN_SELF_WD, ASSIGN_SELF_VWD, ASSIGN_OTHERS_VWD, UNASSIGN_WD, UNASSIGN_VWD } from 'src/app/core/constants/configuration/task-constants.config';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { MatDialog } from '@angular/material';
import {
	updateTaskStatusEndpoint,
	getAllSchoolYearsEndpoint,
	getProcessingStatusEndpoint,
	allTasksEndpoint,
	getTaskGroupTypeEndpoint,
	getCountByStatus,
	getUserAssignedCurriculumEndpoint,
	getActiveSchoolYearEndpoint,
	oldEndpoint,
	updateWTBTaskStatusEndpoint, allCurriculumsEndpoint, getClassSubjectsEndpoint, studentForSvApprove, approveWaitlistedTasksEndpoint, getOrdersByTasksEndpoint, changeCoursesEndpoint,getWdRequestAssigneesEndpoint
} from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FilterModalComponent } from 'src/app/shared/components/filter-modal/filter-modal.component';
import { changeSize } from 'src/app/core/constants/animations.constant';
import { DialogMessagingService } from 'src/app/core/services/messaging/dialog-messaging.service';
import {
	taskActionConfirmMessage,
	assignTaskSuccessMessage,
	preCompleteTaskSuccessMessage,
	completeTaskSuccessMessage,
	cancelTaskSuccessMessage,
	dialogBoxSuccessTitle,
	updateChangeCourse,
	unassignTaskActionConfirmMessage,
	unassignTaskSuccessMessage,
} from 'src/app/core/constants/message.constant';
import { Task } from 'src/app/core/models/order/task.model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { TASKS_PATH, DOWNLOAD_ONLINE_ACCOUNTS_PATH, DOWNLOAD_REPORTS_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { ClassStartDateDialogComponent } from 'src/app/shared/components/class-start-date-dialog/class-start-date-dialog.component';
import {
	PROCESSING_STATUS_2,
	PROCESSING_STATUS_3,
	PROCESSING_STATUS_4,
	PROCESSING_STATUS_5,
	PROCESSING_STATUS_1,
	COMPLETED,
	HST_APPROVED,
	PENDING_ORDERS,
	PROCESSED,
	PROCESSING_STATUS_6,
	PROCESSING_STATUS_7,
	PROCESSING_STATUS_8,
	PROCESSING_STATUS_9,
	PROCESSING_STATUS_10,
	PROCESSING_STATUS_11,
	PROCESSING_STATUS_12,
	PROCESSING_STATUS_13,
	WD_REQUEST,
	VERIFIED_WD_REQUEST,
	FINALIZED_WD_REQUEST,
} from 'src/app/core/constants/configuration/order-constants.config';
import { FilterService } from "src/app/core/services/util/filter.service";
import { StudentForSvApprovalPopupComponent } from '../../shared/student-for-sv-approval-popup/student-for-sv-approval-popup.component';
import { OrderClassResourceComponent } from '../../order-create/order-class-resource/order-class-resource.component';
import { Order } from 'src/app/core/models/order/order.model';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { TaskListComponent } from './task-list/task-list.component';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss'],
	animations: [changeSize]
})
export class TasksComponent implements OnInit, OnDestroy {

	@ViewChild(FilterComponent) filterComponent: FilterComponent;
	@ViewChild(TaskListComponent) taskListComponent: TaskListComponent;
	tasks: any[] = [];
	items: any[] = [];
	studentForSvApprovalList: any[] = [];
	curriculums: any[] = [];
	studentForSvApprovalPopupClosed = false;
	itemsCount: number = 0;
	pageIndex: number = 0;
	pageSize: number = pageSizeOptions[0];
	keyword: string = "";
	filters: Map<string, string> = new Map<string, string>();
	unsubscribe: Subject<any> = new Subject();
	reference: any = {};
	selectedSyId: number;
	selectedAssignedTo: string;
	schoolYear: string;
	filterLabels: string[] = [];
	taskDataProperties: DataProperties[] = taskDataProperties;
	schoolYears: any[] = [];
	processingStatus: any[] = [];
	taskGroupType: any[] = [];
	specialists: any[] = [];
	clearFilters: boolean = true;
	sortedBy: string;
	sortOrder: string;
	isType1: boolean = false;
	withRequestToCancel: boolean = false;
	showAssignToSpecialist: boolean = false;
	showAssignToSpecialistWD: boolean = false;
	showAssignToSpecialistVWD: boolean = false;
	assignedToSelf: boolean = false;
	trigger: string = CLOSE;
	statusFilter = new Map<string, string>();
	statusListCount = new Array<any>();
	hasSvApproval: boolean = false;
	usernames: string[] = [];

	filterAssignedToKey: string = "assignedTo";
	filterAssignedToSelfKey: string = "assignedToSelf";
	filterWithRequestToCancelKey: string = "withRequestToCancel";
	filterSelectedSpecialistKey: string = "selectedSpecialist";
	selectedSpecialist: any;
	filtersKey: string = "taskFilters";

	filterIcon: IconDefinition = faFilter;
	downloadIcon: IconDefinition = faDownload;
	isRunning: boolean = false;

	selectedActivePeriodType: string = "";
	activePeriodTypes: any[] = [
		{display: "All", value: ""},
		{display: "1st Semester", value: FIRST_SEM},
		{display: "2nd Semester", value: SECOND_SEM}
	];
	showBanner: boolean = false;
	bannerMessage: string = "K12 curriculum is not currently available to order for the 2024/25 school year. It should be available soon. More information will be available in next week's Weekly Update.";

	constructor(
		private crudService: CrudService,
		private dataCustomizerService: DataCustomizerService,
		private dialog: MatDialog,
		private dialogMessagingService: DialogMessagingService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private queryService: QueryService,
		private router: Router,
		private userService: UserService,
		private filterService: FilterService
	) {
		this.userService.checkRolePermission(TASKS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
		// Uncomment to retain search keyword and filters for Task tab upon switching to another tab
		let taskFilters = this.filterService.getFilter(this.filtersKey);
		if (taskFilters) {
			this.keyword = taskFilters.keyword || "";
			this.filters = taskFilters.filters ? this.filterService.objectToMap(taskFilters.filters) : new Map<string, string>();
			if (this.filters.has(this.filterWithRequestToCancelKey)) {
				this.filters.delete(this.filterWithRequestToCancelKey);
				this.onToggleWithRequestToCancel(false);
			}
			if (this.filters.has(this.filterAssignedToSelfKey)) {
				this.filters.delete(this.filterAssignedToSelfKey);
				this.onToggleAssignedToSelf(false);
			}
			if (this.filters.has(this.filterSelectedSpecialistKey)) {
				this.onSelectSpecialist({ username: this.filters.get(this.filterSelectedSpecialistKey) }, false);
			}
			if (this.filters.has(this.filterAssignedToKey)) {
				this.selectedAssignedTo = this.filters.get(this.filterAssignedToKey);
			}
		}
	}

	initializeTaskFilters(
		taskFilters: any
	) {
		if (taskFilters != null) {
			if (taskFilters.filters.Size) {
				this.pageSize = taskFilters.filters.Size;
			}
			if (taskFilters.filters.Page) {
				this.pageIndex = taskFilters.filters.Page;
			}
		}
		// else let be on default values:
	}

	ngOnInit() {
		this.setStatusDisplay(null);
		this.loaderMessagingService.showPageLoader(true);
		this.initializeTaskFilters(
			this.filterService
				.getFilter(this.filtersKey)
		);
		this.setReferenceSubjects();
		this.getWdRequestAssignees();
		this.getSchoolYears();
	}
	
	setPageIndexFilter(pageIndex: number): void {
		this.filters.set(PAGE_INDEX, String(pageIndex));
	  	this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}
  
	setPageSizeFilter(pageSize: number): void {
		this.filters.set(PAGE_SIZE, String(pageSize));
	  	this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);
	}
  

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
	};
	
	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}

	search(): void {
		// Clear all current selection from TaskListComponent
		this.taskListComponent.clearSelections();
		
		let statusFilter: string = String(this.filters.get("status"));
		let syCode: string = String(this.filters.get("syCode"));
		let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);
		this.showAssignToSpecialist = statusFilter && hstApprovedStatus && statusFilter.toLowerCase() === hstApprovedStatus.name.toLowerCase();

		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		this.filterService.setFilter(this.filtersKey, this.keyword, this.filters);

		let urlString = allTasksEndpoint.concat(this.queryService.buildTaskSearchQuery(
			this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters));
		if(syCode == "2019-2020") {
			urlString = oldEndpoint.concat(allTasksEndpoint.concat(this.queryService.buildTaskSearchQuery(
				this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, this.filters)));
		}

		// Uses request dates instead of createdDate for sorting when using any WD Request as status filter
		if(this.isAnyWDRequestFilterSelected) {
			const selectedStatusFilter: string = String(this.filters.get("status")).toLowerCase();
			switch (selectedStatusFilter) {
				case WD_REQUEST.toLowerCase():
					urlString = urlString.replace("sort=createdDate", "sort=withdrawRequestDate");
					break;
				case VERIFIED_WD_REQUEST.toLowerCase():
					urlString = urlString.replace("sort=createdDate", "sort=verifiedWithdrawRequestDate");
					break;
				case FINALIZED_WD_REQUEST.toLowerCase():
					urlString = urlString.replace("sort=createdDate", "sort=finalizedWithdrawRequestDate");
					break;
				default:
					break;
			}
		}
		
		this.crudService
			.getAll<Task>(urlString)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.itemsCount = response.totalElements;
					this.tasks = [];
					response.content.forEach(task =>
						this.customFilter(task)
					);
				}
				// this.getCurriculums();
			},
			this.errorHandlerService.handleError,
			this.handleCompletion
		);
	}

	customFilter(task: any): void {

		let matchCount = 0;

		// For Course Name filter
		if (this.filters.has('courseName')){
			for(const orderItem of task.orderItemAssoc){
				if(orderItem.referenceName.toLowerCase().includes(this.filters.get('courseName').toLowerCase())){
					matchCount += 1;
				}
			}
			if(matchCount == 0){
				return;
			} else {
				matchCount = 0;
			}
		}

		// For Course Section filter
		if (this.filters.has('courseSection')){
			for(const orderItem of task.orderItemAssoc){
				if(orderItem.section.toLowerCase().includes(this.filters.get('courseSection').toLowerCase())){
					matchCount += 1;
				}
			}
			if(matchCount == 0){
				return;
			} else {
				matchCount = 0;
			}
		}

		// For Course Subject filter
		if (this.filters.has('courseSubject')){
			for(const orderItem of task.orderItemAssoc){
				if(this.dataCustomizerService.getSubjectName(orderItem.subject, this.reference.subjects).toLowerCase().includes(this.filters.get('courseSubject').toLowerCase())){
					matchCount += 1;
				}
			}
			if(matchCount == 0){
				return;
			} else {
				matchCount = 0;
			}
		}

		this.tasks.push(task);
		this.items.push(this.dataCustomizerService.createCustomizedTaskForList(task, this.getReferences()));

	}

	setReferenceSubjects() {
		this.crudService
		.getById<any>(getClassSubjectsEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
  
		  if (response) {
			this.reference.subjects = response;
		  }
  
		}, this.errorHandlerService.handleError);
	}
	
	getReferences() {
		return {
			processingStatus: this.processingStatus,
			taskGroupType: this.taskGroupType,
		}
	}
	
	goToDownloadReportsPage() {
		this.router.navigateByUrl(DOWNLOAD_REPORTS_PATH);
	}

	onSetActivePeriodType() {
		if (this.filters.has(ACTIVE_PERIOD_TYPE)) {
			this.filters.delete(ACTIVE_PERIOD_TYPE);
		}

		this.setFilterLabels();
		this.getFilterCount();
		this.search();
	}

	setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
		  this.schoolYear = schoolYear.schoolYear;
		  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
		  this.setFilterLabels();
		  this.getFilterCount();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
				  		this.schoolYear = response.schoolYear;
						this.selectedSyId = this.schoolYears.find(sy =>
							String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
							
						if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
						this.setFilterLabels();
						this.getFilterCount();
						this.search();
						this.getCurriculums();
					}
			}, this.errorHandlerService.handleError);
		}
	}

	setFilterLabels(): void {
		this.filterLabels = [];
		this.filters.forEach((value, key) => {
			if (value === "Completed") { value = "Processed" };	
			let prop = this.taskDataProperties.find(property => property.property === key);
			if (prop && prop.label) this.filterLabels.push(`${prop.label}: ${value}`)
		});

		this.filters.set(SY_CODE, this.schoolYear);
		this.filters.set(ACTIVE_PERIOD_TYPE, this.selectedActivePeriodType);

		const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
		if (index !== -1) this.filterLabels.splice(index, 1);
		this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

		const activePeriodTypeIndex = this.filterLabels.findIndex(label => label.includes('Semester:'));
		if(activePeriodTypeIndex !== -1) {
			this.filterLabels.splice(activePeriodTypeIndex, 1);
		}
		const selectedActivePeriodTypeObj = this.activePeriodTypes.filter(activePeriodType => activePeriodType.value == this.selectedActivePeriodType);
		this.filterLabels.splice(1, 0, `Semester: ${selectedActivePeriodTypeObj[0].display}`);

		let statusFilter: string = String(this.filters.get("status"));
		let hstApprovedStatus = this.processingStatus.find(status => status.code === PROCESSING_STATUS_1);

		const selectedSpecialistIndex: number = this.filterLabels.findIndex(label => label.includes('Selected Specialist'));
		if (statusFilter && statusFilter.toLowerCase() === hstApprovedStatus.name.toLowerCase() && this.selectedSpecialist) {
			this.filters.set(this.filterSelectedSpecialistKey, this.selectedSpecialist.username);
			if (selectedSpecialistIndex === -1) this.filterLabels.splice(1, 0, `Selected Specialist: ${this.selectedSpecialist.username}`);
		} else {
			this.selectedSpecialist = null;
			this.filters.delete(this.selectedSpecialist);
			if (selectedSpecialistIndex !== -1) this.filterLabels.splice(selectedSpecialistIndex, 1);
		}

		const withRequestToCancelIndex: number = this.filterLabels.findIndex(label => label === 'With Request to Cancel: Yes');
		if (this.withRequestToCancel) {
			this.filters.set(this.filterWithRequestToCancelKey, "Yes");
			if (withRequestToCancelIndex === -1) this.filterLabels.splice(1, 0, 'With Request to Cancel: Yes');
		} else {
			this.filters.delete(this.filterWithRequestToCancelKey);
			if (withRequestToCancelIndex !== -1) this.filterLabels.splice(withRequestToCancelIndex, 1);
		}

		const withAssignedToSelfIndex: number = this.filterLabels.findIndex(label => label === 'Assigned to Self: Yes');
		if (this.assignedToSelf) {
			this.filters.set(this.filterAssignedToSelfKey, "Yes");
			if (withAssignedToSelfIndex === -1) this.filterLabels.splice(1, 0, 'Assigned to Self: Yes');
		} else {
			this.filters.delete(this.filterAssignedToSelfKey);
			if (withAssignedToSelfIndex !== -1) this.filterLabels.splice(withAssignedToSelfIndex, 1);
		}
		
		const selectedAssignedToIndex: number = this.filterLabels.findIndex(label => label.includes('Assigned To'));
		if (this.selectedAssignedTo) {
			this.filters.set(this.filterAssignedToKey, this.selectedAssignedTo);
			if (selectedAssignedToIndex === -1) {
				this.filterLabels.splice(1, 0, `Assigned To: ${this.selectedAssignedTo}`);
			} else {
				this.filterLabels.splice(selectedAssignedToIndex, 1);
				this.filterLabels.splice(1, 0, `Assigned To: ${this.selectedAssignedTo}`);
			}
		} else {
			this.selectedAssignedTo = null;
			this.filters.delete(this.filterAssignedToKey);
			if (selectedAssignedToIndex !== -1) this.filterLabels.splice(selectedAssignedToIndex, 1);
		}

		this.clearFilters = this.filters.size === 1;
	}
  
	getSchoolYears(): void {
		this.crudService
			.getById<any>(getAllSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.schoolYears = response;
					this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
					this.getProcessingStatus();
				}
			}, this.errorHandlerService.handleError);
	}
	
	getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
				this.getTaskGroupType();
			}, this.errorHandlerService.handleError);
	}
	
	getTaskGroupType(): void {
		this.crudService
			.getById<any>(getTaskGroupTypeEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.taskGroupType = response;
				this.taskGroupType.sort((a, b) => b.code.localeCompare(a.code));
				this.getSpecialists();
			}, this.errorHandlerService.handleError);
	}
	
	getSpecialists(): void {
		this.crudService
			.getById<any>(getUserAssignedCurriculumEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.specialists = response;
				this.specialists.sort((a, b) => b.username.localeCompare(a.username));
				this.initParams();
			}, this.errorHandlerService.handleError);
	}

	getCurriculums(): void {
		this.crudService
			.getById<any>(allCurriculumsEndpoint+"/all?syCode=" + this.schoolYear)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.curriculums = response;
			}, this.errorHandlerService.handleError);
	}
  
	initParams(): void {
		this.sortedBy = this.taskDataProperties[0].property;
		this.sortOrder = ASC;
		let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
		this.setSchoolYear(null);
	}

	onSelectAssignedTo(specialist: any): void {
		this.selectedAssignedTo = specialist.username;
		this.setFilterLabels();
		this.search();
  }

	onSelectSpecialist(specialist: any, willSearch: boolean) {
		this.selectedSpecialist = specialist;
		if (willSearch) {
			this.setFilterLabels();
			this.search();
		}
	}
  
	onToggleWithRequestToCancel(willSearch: boolean): void {
		this.withRequestToCancel = !this.withRequestToCancel;
		if (willSearch) {
			this.setFilterLabels();
			this.search();
		}
	}

	onToggleSort($event: any) {
		this.sortedBy = this.getSortedByAsQuery($event.sortedBy);
		this.sortOrder = $event.sortOrder;
		this.search();
	}

	// Transforms header as accepted parameter in the backend for search.
	getSortedByAsQuery(
		sortedBy: string
	): string {
		let response: string;
		switch(sortedBy) {
			case 'cosOrderCode':
				response = 'txnOrderCode';
			break;
			case 'requestedBy':
				response = 'createdBy';
			break;
			case 'districtId':
				response = 'studentDistrictId';
			break;
			case 'type':
				response = 'groupType';
			break;
			default:
				response = sortedBy;
			break;
		}
		return response;
	}
  
	onToggleAssignedToSelf(willSearch: boolean): void {
		this.assignedToSelf = !this.assignedToSelf;
		if (willSearch) {
			this.setFilterLabels();
			this.search();
		}
	}
  
	onChangeFilterType(): void {
		this.isType1 = !this.isType1;
	}
  
	filterOnClick(): void {
		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];
			this.taskDataProperties.forEach(property => {
				if (property.filterable)
					data.push({
						label: property.label,
						prop: property.property,
						value: this.filters.get(property.property) || "",
					});
			});
			const modalStyle = { width: "30%", autoFocus: false, maxHeight: '90vh' };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
			dialogRef.componentInstance.curriculums = this.curriculums;
			dialogRef.componentInstance.taskGroupType = this.taskGroupType;
			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			}); 
		}
	}
  
	filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
		this.search();
	}
  
  onClearFilters(): void {
		this.clearFilters = true;
		this.assignedToSelf = false;
		this.withRequestToCancel = false;
		this.selectedSpecialist = null;
		this.selectedAssignedTo = null;
		this.keyword = '';
		this.selectedActivePeriodType = '';
		this.filters.clear();
		this.filterOnChange(new Map<string, string>());
	}
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.setPageSizeFilter(this.pageSize);
		this.setPageIndexFilter(this.pageIndex);
		this.search();
	}
  
	onBulkAction($event: any): void {
		let successMessage: string;
		let confirmMessage: string;
		let newStatus: string;
		let specialist: string;
		switch ($event.action) {
			case ASSIGN_OTHERS: {
				specialist = $event.selectedSpecialist;
				successMessage = assignTaskSuccessMessage.replace("??", specialist);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", `to ${specialist}?`);
				newStatus = PROCESSING_STATUS_2;
				break;
			}
			case ASSIGN_SELF: {
				successMessage = assignTaskSuccessMessage.replace("??", this.userService.getLoginUser().username);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", "to yourself?");
				newStatus = PROCESSING_STATUS_2;
				specialist = null;
				break;
			}
			// case PRE_COMPLETE: {
			// 	successMessage = preCompleteTaskSuccessMessage;
			// 	confirmMessage = taskActionConfirmMessage.replace("?", "tag").replace("??", "as pre-completed?").replace("*", "");
			// 	newStatus = PROCESSING_STATUS_3;
			// 	break;
			// }
			case COMPLETE: {
				successMessage = completeTaskSuccessMessage;
				confirmMessage = taskActionConfirmMessage.replace("?", "tag").replace("??", "as processed?").replace("*", "");
				newStatus = PROCESSING_STATUS_4;

				this.getStudentDetails(confirmMessage, successMessage, $event, newStatus, specialist);
				break;
			}
			case CANCEL: {
				successMessage = cancelTaskSuccessMessage;
				confirmMessage = taskActionConfirmMessage.replace("?", "tag").replace("??", "as cancelled?").replace("*", "");
				newStatus = PROCESSING_STATUS_5;
				break;
			}
			case APPROVE_WAITLIST: {
				successMessage = `Successfully approved the waitlisted item${ $event.items.length > 1 ? 's' : '' }`;
				confirmMessage = `Are you sure you want to approve ${ $event.items.length > 1 ? 'these' : 'this' } waitlisted item${ $event.items.length > 1 ? 's' : '' }?`;
				this.approveWaitlist(confirmMessage, successMessage, $event);
				break;
			}
			case CHANGE_COURSE: {
				this.getOrdersByTasks($event);
				break;
			}
			case ASSIGN_OTHERS_WD: {
				// "specialist" = "others"
				specialist = $event.selectedSpecialist;
				successMessage = assignTaskSuccessMessage.replace("??", specialist);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", `to ${specialist}?`);
				newStatus = PROCESSING_STATUS_10;
				break;
			}
			case ASSIGN_SELF_WD: {
				successMessage = assignTaskSuccessMessage.replace("??", this.userService.getLoginUser().username);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", "to yourself?");
				newStatus = PROCESSING_STATUS_10;
				specialist = this.userService.getLoginUser().username;
				break;
			}
			case ASSIGN_SELF_VWD: {
				successMessage = assignTaskSuccessMessage.replace("??", this.userService.getLoginUser().username);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", "to yourself?");
				newStatus = PROCESSING_STATUS_11;
				specialist = this.userService.getLoginUser().username;
				break;
			}
			case ASSIGN_OTHERS_VWD: {
				// "specialist" = "others"
				specialist = $event.selectedSpecialist;
				successMessage = assignTaskSuccessMessage.replace("??", specialist);
				confirmMessage = taskActionConfirmMessage.replace("?", "assign").replace("??", `to ${specialist}?`);
				newStatus = PROCESSING_STATUS_11;
				break;
			}
			case UNASSIGN_WD: {
				successMessage = unassignTaskSuccessMessage;
				confirmMessage = unassignTaskActionConfirmMessage.replace("??", "unassign");
				newStatus = PROCESSING_STATUS_10;
				specialist = "";
				break;
			}
			case UNASSIGN_VWD: {
				successMessage = unassignTaskSuccessMessage;
				confirmMessage = unassignTaskActionConfirmMessage.replace("??", "unassign");
				newStatus = PROCESSING_STATUS_11;
				specialist = "";
				break;
			}
			default: break;
		}
		if(!this.hasSvApproval && $event.action !== COMPLETE && $event.action !== APPROVE_WAITLIST && $event.action !== CHANGE_COURSE) {
			this.openConfirmDialog(confirmMessage, successMessage, $event, newStatus, specialist);
		}

	}

	getStudentDetails(confirmMessage: string, successMessage: string, event: any, newStatus: string, specialist: string){
		let cosCodeList: any[] = [];
		this.studentForSvApprovalPopupClosed = false;
		for(let item of event.items){
			cosCodeList.push(item.cosOrderCode);
		}
		this.crudService
		.edit<any>(studentForSvApprove, cosCodeList)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe((response: any) => {
			if (response) {
				this.studentForSvApprovalList = [];
				this.hasSvApproval = false;
				for(const student of response.content){
					// Check if ALL selected items of the student are already SV approved (e.g. has approveNotes)
					const studentItems = event.items.filter((item: any) => item.districtId === student.districtId);
					const studentItemsSvApproved = studentItems.filter((item: any) => !(item.approveNotes === null || item.approveNotes === ""));
					const areAllSvApproved = studentItems.length === studentItemsSvApproved.length;
					if(student.availableBalance < 0 && !areAllSvApproved){
						this.hasSvApproval = true;
						student.name = student.firstName + " " + student.lastName;
						this.studentForSvApprovalList.push(student);
					}
				}
				if(this.hasSvApproval){
					confirmMessage = confirmMessage.replace("processed?", "sv approval/processed?");
					successMessage = successMessage.replace("processed", "sv approval/processed.");

					const dialogRef = this.dialog.open(StudentForSvApprovalPopupComponent, {
						data: {
							studentList: this.studentForSvApprovalList
						}
					});
					dialogRef.afterClosed().subscribe( response => {
						if (response) {
							this.studentForSvApprovalPopupClosed = true;
							this.openConfirmDialog(confirmMessage, successMessage, event, newStatus, specialist);
						}

						// Re-enable buttons in TaskListComponent
						this.taskListComponent.setIsButtonClickable(true);
					});
				} else {
					this.openConfirmDialog(confirmMessage, successMessage, event, newStatus, specialist);
				}

			};
		}, this.errorHandlerService.handleError, this.handleCompletion);
	}

    openConfirmDialog(confirmMessage: string, successMessage: string, event: any, newStatus: string, specialist: string) {
		let selectedItems: any[] = event.items;
		let svApprovalDistrictIds = this.studentForSvApprovalList.map(student=>student.districtId)
		let hasNonSvApproval: boolean = false;
		hasNonSvApproval = selectedItems.filter(item=>!svApprovalDistrictIds.includes(item.studentDistrictId)).length >  0
		if (event.action === COMPLETE && hasNonSvApproval) {
			const classStartRef = this.dialog.open(ClassStartDateDialogComponent, { autoFocus: false });
			classStartRef.componentInstance.confirmTitle = 'Course Start date';
			classStartRef.componentInstance.confirmMessage = `Set course start date`;
			classStartRef.componentInstance.cancelLabel = "Cancel";
			classStartRef.componentInstance.confirmLabel = "Confirm";
			classStartRef.afterClosed().subscribe(dateResult => {
			if(dateResult) {
				const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
				dialogRef.componentInstance.confirmMessage = confirmMessage;
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						let items = selectedItems.map(item => {
						item.classStartDate = dateResult;
							let orderItems = [];
							item.orderItemAssoc.map(itemAssoc => {
								if (itemAssoc.status !== PROCESSING_STATUS_5) {
									orderItems.push({ ...itemAssoc, status: newStatus })
								}
							});
							let task = this.tasks.find(currentTask => currentTask.code === item.code);
							if(task.groupType === TASK_GROUP_2){
								task.status = newStatus;
							}
			
							let emptyListTempItem = {
								id: null,
								task: null,
								txnOrderItemId: null,
								status: newStatus,
								forCancel: false,
							};
							orderItems = orderItems.length > 0 ? orderItems : [emptyListTempItem];
							if (newStatus === PROCESSING_STATUS_2) return { ...task, orderItemAssoc: orderItems, assignedTo: specialist }
							else return { ...task, orderItemAssoc: orderItems, classStartDate: dateResult }
						});
						this.updateTaskStatus(successMessage, items, event.action);
					}
				});
			}

			// Re-enable buttons in TaskListComponent
			this.taskListComponent.setIsButtonClickable(true);
			})
		} else {
			const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
			dialogRef.componentInstance.confirmMessage = confirmMessage;
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					let items = selectedItems.map(item => {
						let orderItems = [];
						item.orderItemAssoc.map(itemAssoc => {
							if (itemAssoc.status !== PROCESSING_STATUS_5) {
								orderItems.push({ ...itemAssoc, status: newStatus })
							}
						});
						let task = this.tasks.find(currentTask => currentTask.code === item.code);
						if(task.groupType === TASK_GROUP_2 && (event.action === PRE_COMPLETE || event.action === COMPLETE)){
							task.status = newStatus;
						}
		
						let emptyListTempItem = {
							id: null,
							task: null,
							txnOrderItemId: null,
							status: newStatus,
							forCancel: false,
						};
						orderItems = orderItems.length > 0 ? orderItems : [emptyListTempItem];
						if (newStatus === PROCESSING_STATUS_2 || newStatus === PROCESSING_STATUS_10 || newStatus === PROCESSING_STATUS_11) return { ...task, orderItemAssoc: orderItems, assignedTo: specialist }
						else return { ...task, orderItemAssoc: orderItems }
					});
					
					this.updateTaskStatus(successMessage, items, event.action);
				}

				// Re-enable buttons in TaskListComponent
				this.taskListComponent.setIsButtonClickable(true);
			});
		}
  }

	onFilterChange(event: any) {
		if(!this.isRunning){
			this.isRunning = true;
			this.statusFilter = event;
			this.trigger = OPEN;
			setTimeout(() =>{
				this.filterComponent.filterByStatus();
				this.isRunning = false;
			},500);
		}
	}

	getFilterCount() : void {
		let statusFilter : Map<string, string> = new Map<string, string>();
		statusFilter.set(SY_CODE, this.schoolYear);
		let urlApi = allTasksEndpoint.concat(getCountByStatus);
		if(this.schoolYear == "2019-2020") {
			urlApi = oldEndpoint.concat(allTasksEndpoint.concat(getCountByStatus));
		} else {
			urlApi = allTasksEndpoint.concat(getCountByStatus).concat("?syCode=" + this.schoolYear);
		}
		
		this.statusListCount = [];
		this.crudService
		.getById<Task>(urlApi)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			this.processingStatus.reverse().forEach((status:any) => {
				let statusCount;
				statusCount = {
					name: status.name,
					code: status.code,
					count: response[status.code]
				}
				this.statusListCount.push(statusCount);
				this.statusListCount.sort((a, b) => a.code !== b.code ? a.code < b.code ? -1 : 1 : 0);
	
			});
			this.sortStatusListCount();
		}, this.errorHandlerService.handleError);
	}

	sortStatusListCount(){
		let sortedStatus = [{},{},{},{},{},{},{},{},{},{},{},{},{}];
		for(var status of this.statusListCount){
			switch(status.code){
				case PROCESSING_STATUS_1:
					sortedStatus.splice(0,1,status);
					break;
				case PROCESSING_STATUS_8:
					sortedStatus.splice(1,1,status);
					break;
				case PROCESSING_STATUS_2:
					sortedStatus.splice(2,1,status);
					break;
				case PROCESSING_STATUS_9:
					sortedStatus.splice(3,1,status);
					break;
				case PROCESSING_STATUS_4:
					sortedStatus.splice(4,1,status);
					break;
				case PROCESSING_STATUS_10:
					sortedStatus.splice(5,1,status);
					break;
				case PROCESSING_STATUS_11:
					sortedStatus.splice(6,1,status);
					break;
				case PROCESSING_STATUS_12:
					sortedStatus.splice(7,1,status);
					break;
				case PROCESSING_STATUS_13:
					sortedStatus.splice(8,1,status);
					break;
				case PROCESSING_STATUS_3:
					sortedStatus.splice(9,1,status);	
					break;	
				case PROCESSING_STATUS_6:
					sortedStatus.splice(10,1,status);	
					break;
				case PROCESSING_STATUS_7:
					sortedStatus.splice(11,1,status);	
					break;
				case PROCESSING_STATUS_5:
					sortedStatus.splice(12,1,status);	
					break;
			}
		}
		this.statusListCount = sortedStatus;

	}

	updateTaskStatus(successMessage: string, selectedItems: any[], action: string) {
		this.loaderMessagingService.showPageLoader(true);
		let taskEndpoint = "";
		if(selectedItems[0].groupType === TASK_GROUP_2 && (action === PRE_COMPLETE || action === COMPLETE)){
			taskEndpoint = updateWTBTaskStatusEndpoint;
		} else {
			taskEndpoint = updateTaskStatusEndpoint;
		}

		this.crudService
			.edit<any>(taskEndpoint, { tasks: selectedItems })
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
					this.dialogMessagingService.sendMessage({
						status: SUCCESS,
						value: successMessage.replace("?",
							`${selectedItems.length} ${selectedItems.length > 1 ? 'tasks were ' : 'task was'}`),
					});
					this.getFilterCount();
					this.onSearch("");
				}
		}, this.errorHandlerService.handleError, this.handleCompletion);
	}

	setStatusDisplay(status){
		if(status == "Task Status: " + HST_APPROVED){
		  status = "Task Status: " + PENDING_ORDERS;
		} else if (status == "Task Status: " + COMPLETED){
		  status = "Task Status: " + PROCESSED;
		}
		return status;
	}

	approveWaitlist(confirmMessage: string, successMessage: string, event: any): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
		dialogRef.componentInstance.confirmMessage = confirmMessage;
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loaderMessagingService.showPageLoader(true);

				let taskDTOList = event.items.map((item: any) => {
					let task = this.tasks.find(currentTask => currentTask.code === item.code);
					return { ...task };
				});
		
				this.crudService
				.edit<any>(approveWaitlistedTasksEndpoint, taskDTOList)
				.pipe(takeUntil(this.unsubscribe))
				.subscribe((response: any) => {
						if (response) {
							this.dialogMessagingService.sendMessage({
								status: SUCCESS,
								value: successMessage
							});
							this.getFilterCount();
							this.onSearch("");
						}
				}, this.errorHandlerService.handleError, this.handleCompletion);
			}

			// Re-enable buttons in TaskListComponent
			this.taskListComponent.setIsButtonClickable(true);
		});
	}

	getOrdersByTasks(event: any): void {
		let taskDTOList: Task = event.items.map((item: any) => {
			let task = this.tasks.find(currentTask => currentTask.code === item.code);
			return { ...task };
		});

		this.loaderMessagingService.showPageLoader(true);

		this.crudService
		.edit<any>(getOrdersByTasksEndpoint, taskDTOList)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe((response: any) => {
			if(response) {
				this.changeCourse(response);
			}
		}, this.errorHandlerService.handleError, this.handleCompletion);
	}

	changeCourse(orderList: Order[]): void {
		const dialogRef = this.dialog.open(OrderClassResourceComponent, { width: "90%", height: "90%" });
		dialogRef.componentInstance.selectedCurriculum = orderList[0].orderItems[0].curriculum;
		if(orderList.length > 1) {
			let existingCourses: any[] = orderList.map(order => {
				return order.orderItems[0].classDetails;
			});

			dialogRef.componentInstance.existingCourses = existingCourses;
		} else if(orderList.length === 1) {
			dialogRef.componentInstance.existingCourse = orderList[0].orderItems[0].classDetails;
		}
		dialogRef.componentInstance.isModal = true;
		dialogRef.componentInstance.close.subscribe(() => {
			dialogRef.close();
		});
		dialogRef.componentInstance.changeCourse.subscribe((result: any) => {
			if (result) {
				orderList.forEach(order => {
					order.orderItems[0].classDetails.code = result.course.code;
					order.orderItems[0].classDetails.name = result.course.name;
				});

				this.loaderMessagingService.showPageLoader(true);
				this.crudService
				.add<any>(changeCoursesEndpoint, orderList)
				.pipe(takeUntil(this.unsubscribe))
				.subscribe((response) => {
					if(response){
						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
						dialogRef.componentInstance.contentMessage = `${orderList.length > 1 ? 'Courses were' : 'Course was'} successfully changed.`;

						this.getFilterCount();
						this.onSearch("");
					}
				}, this.errorHandlerService.handleError, this.handleCompletion);

				dialogRef.close();
			}
		});

		// Re-enable buttons in TaskListComponent
		this.taskListComponent.setIsButtonClickable(true);
	}

	getWdRequestAssignees():void {
		this.crudService
		.getAllBy<string>(getWdRequestAssigneesEndpoint)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			if (response) {
			  this.usernames = response.sort();
			}
		  },
		  this.errorHandlerService.handleError
		);
	}

    get isAnyWDRequestFilterSelected():boolean {
		const selectedStatusFilter: string = String(this.filters.get("status"));
        return selectedStatusFilter.toLowerCase().includes(WD_REQUEST.toLowerCase());
    }
}
