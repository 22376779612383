import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NEXT, pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { allStudentsEndpoint, allGradeLevelsEndpoint } from 'src/app/core/constants/endpoints.constant';
import { QueryService } from 'src/app/core/services/util/query.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { DataProperties } from 'src/app/core/models/data-properties.model';
import { Student } from 'src/app/core/models/eos/student.model';
import { studentDataProperties } from 'src/app/core/constants/configuration/order-constants.config';
import { UserService } from 'src/app/core/services/util/user.service';
import { TEACHER } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-order-student-selection',
  templateUrl: './order-student-selection.component.html',
  styleUrls: ['./order-student-selection.component.scss']
})
export class OrderStudentSelectionComponent implements OnInit {

  @Output() getFormValue = new EventEmitter<any>();
  items: any[] = [];
  itemsCount = this.items.length;
  pageIndex: number = 0;
  pageSize: number = pageSizeOptions[0];
  keyword: string = "";
  filters: DataProperties[];
  selectedFilter: string;
  unsubscribe: Subject<any> = new Subject();
  reference: any = {};

  selectedStudentIds: number[];

  selectedStudentId: number;
  students: any;

  clearFilters: boolean = true;
  studentDataProperties: DataProperties[] = studentDataProperties;
  filterLabels: string[] = [];
  includeInactive: boolean = false;

  constructor(
    private loaderMessagingService: LoaderMessagingService,
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
    private queryService: QueryService,
    private dataCustomizerService: DataCustomizerService,
    private userService: UserService
  ) {
    this.loaderMessagingService.showPageLoader(true);
    this.includeInactive = !this.userService.hasRole([TEACHER]);
  }

  ngOnInit() {
    this.selectedStudentId = null;
    this.selectedStudentIds = [];
    this.filters = Object.assign([], this.studentDataProperties.filter(prop => prop.filterable));
    this.selectedFilter = this.filters[1].property;
    this.setReferenceGradeLevels();
  }

  search() {
    this.loaderMessagingService.showListLoader(true);
    this.items = [];

    this.crudService
      .getAll<Student>(allStudentsEndpoint.concat(this.queryService.buildStudentSearchQuery(
        this.pageIndex, this.pageSize, this.includeInactive, this.keyword, this.selectedFilter)))
      .pipe(takeUntil
      (this.unsubscribe))
      .subscribe(response => {
        if (response) {
          this.itemsCount = response.totalElements;
          response.content.forEach((student: Student) =>
            this.items.push(this.dataCustomizerService.formatStudentDetailDisplay(student, this.reference, this.selectedStudentIds)));
        }

      },
        this.errorHandlerService.handleError,
        this.handleCompletion
    );

  }

  private setReferenceGradeLevels(): void {

		this.crudService
			.getById<any>(allGradeLevelsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {

				if (response) {
					this.reference.gradeLevels = response;
					this.reference.gradeLevels.sort((a, b) => a.code.localeCompare(b.code));
					this.search();
				}

			}, this.errorHandlerService.handleError);

  }
  
  handleCompletion = (): void => {
    this.loaderMessagingService.showListLoader(false);
    this.loaderMessagingService.showPageLoader(false);
  };

  onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
  }
  
  filterOnChange(filters: string) {
		this.selectedFilter = filters;
		this.pageIndex = 0;
		this.search();
  }
  
  onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
  }
  
  onSelectStudent(selected: any): void {
    if(selected.checked){
      // Clears the current selectedStudentIds
      this.selectedStudentIds.length = 0;

      this.selectedStudentIds.push(selected.id);
    }
    else{
      // remove unchecked student from list
      const index = this.selectedStudentIds.indexOf(selected.id, 0);
      if (index > -1) {
        this.selectedStudentIds.splice(index, 1);
      }
    }

    this.getFormValue.emit(this.selectedStudentIds);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
