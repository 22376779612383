import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from "src/app/core/models/order/order.model";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faWrench, faCopy, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_10,
  PROCESSING_STATUS_11,
  PROCESSING_STATUS_12,
  PROCESSING_STATUS_13,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  PROCESSING_STATUS_8,
  PROCESSING_STATUS_9
} from 'src/app/core/constants/configuration/order-constants.config';
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, PROGRAM_DIRECTOR, SUPERVISOR } from 'src/app/core/constants/configuration/role-constants.config';
import { ActivatedRoute, Router } from '@angular/router';
import { ORDER_DEPOSIT_PATH, ORDER_CURRICULUM_FEE_PATH, ORDERS_PATH, OLD_ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { MatDialog } from '@angular/material';
import { OrderNotesPopupComponent } from '../order-notes/order-notes-popup/order-notes-popup.component';
import {MatBadgeModule} from '@angular/material/badge';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  @Input() orderDetails: Order;
  @Input() processingStatus: any[];
  @Input() showReform: boolean = false;
  @Output() reformEosOrder = new EventEmitter<any>();
  @Input() isTask: boolean = false;

  @Input() orderNotes: any;

  reformIcon: IconDefinition = faWrench;
  faCopy: IconDefinition = faCopy;
  faEnvelope: IconDefinition = faEnvelope;
  ids: string[];
  orderIds: string;
  codes: string[];
  orderCodes: string;
  selected: string;
  codesAndIds: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    
    this.selected = this.orderDetails.code;

    this.orderCodes = this.route.snapshot.queryParamMap.get('codes');
    if(this.orderCodes){
      this.codes = this.orderCodes.split(',');
    }

    this.orderIds = this.route.snapshot.queryParamMap.get('ids');
    if(this.orderIds){
      this.ids = this.orderIds.split(',');
    }

    if(this.orderCodes && this.orderIds){
      this.codesAndIds = this.codes.reduce((acc, value, i) => (acc[value] = this.ids[i], acc), {});
    }

  }

  get canReformEosOrder(): boolean {
    return this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR])
  }

  onReformEosOrder() {
    this.reformEosOrder.emit();
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus ? this.processingStatus.find(status => status.code === statusCode) : "";
    if(statusName){
      if (statusName.name === "Completed") {
        return "Processed";
      }
      if (statusName.name === "HST Approved") {
        return "Pending Orders";
      }
      if (statusName.name === "Finalized WD Request"){
        if(!!this.orderDetails && !!this.orderDetails.withdrawType) {
          return `${statusName.name} (${this.orderDetails.withdrawType})`;
        } else {
          return statusName.name;
        }
      }
      return statusName.name;
    }
    else return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      // case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      case PROCESSING_STATUS_8: return "waitlisted status"
      case PROCESSING_STATUS_9: return "sv-approval status"
      case PROCESSING_STATUS_10: return "wd-request status"
      case PROCESSING_STATUS_11: return "verified-wd-request status"
      case PROCESSING_STATUS_12: return "finalized-wd-request status"
      case PROCESSING_STATUS_13: return "invoiced status"
      default: return "status"
    }
  }

  copyToClipboard(item): void {
    let listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  goToOrder(order: any) {
    if(order.syCode == "2019-2020"){
      if(order.code.includes("DEPOSIT")){
        this.router.navigate([ORDER_DEPOSIT_PATH, order.id]);
      } else if(order.code.includes("FEE")){
        this.router.navigate([ORDER_CURRICULUM_FEE_PATH, order.id]);
      } else {
        this.router.navigate([OLD_ORDERS_PATH, order.id])
      }
    } else {
      this.router.navigate([ORDERS_PATH, order.id]);
    }
  }

  openOrderNotes() {
    const dialogRef = this.dialog.open(OrderNotesPopupComponent, {
      data: {
        orderDetails: this.orderDetails,
        orderNotes: this.orderNotes,
        username: this.userService.getLoginUser().username
      },
      panelClass: 'order-notes-popup',
      autoFocus: false
    });
  }

  navigateTo(code:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([ORDERS_PATH, this.codesAndIds[code]], {queryParams: {codes: this.orderCodes, ids: this.orderIds}})).then(() => window.location.reload());
  }

}
