import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  ORDER_ITEM_TYPE_4,
  ORDER_ITEM_TYPE_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  PROCESSING_STATUS_8,
  PROCESSING_STATUS_9,
  PROCESSING_STATUS_10,
  PROCESSING_STATUS_11,
  PROCESSING_STATUS_12,
  PROCESSING_STATUS_13,
} from 'src/app/core/constants/configuration/order-constants.config';
import { ORDERS_PATH, ORDER_DEPOSIT_PATH, ORDER_CURRICULUM_FEE_PATH, OLD_ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faRegistered } from '@fortawesome/free-solid-svg-icons';
import { ASSET_STATUS_1, ASSET_STATUS_2 } from 'src/app/core/constants/configuration/asset-constants.config';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  @Input() title: string = '';
  @Input() items: any = [];
  @Input() processingStatus: any = [];
  @Input() dataProperties: any = [];
  @Input() itemsCount: number = 0;
  @Input() pageSize: number = 0;
  @Input() pageIndex: number = 0;
  @Input() keyword: string = '';
  @Output() pageChange = new EventEmitter<any>();
  @Input() cardType: string = '';
  @Output() onSearchEvent = new EventEmitter<string>();
  @Output() onClearFiltersEvent = new EventEmitter<boolean>();
  rtcIcon: IconDefinition = faRegistered;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  hasTooltipAndEllipses(property: string): boolean {
    return ['codeName', 'classes', 'orderItemCodeName', 'modifiedDate', 'modifiedBy', 'rtcModifiedBy', 'rtcModifiedDate'].includes(property);
  }

  getStatusName(code: string): string {
    let status = this.processingStatus.find(status => status.code === code);
    if (status.name === "Completed") {
      return "Processed";
    }
    if (status.name === "HST Approved") {
      return "Pending Orders";
    }
    else return status ? status.name : null;
    // return status ? status.name : null;
  }

  getAssetStatusName(code: string): string {
    switch(code) {
      case ASSET_STATUS_1: return "Checked In"
      case ASSET_STATUS_2: return "Checked Out"
    }
  }
  getAssetStatusClass(code: string): string {
    switch(code) {
      case ASSET_STATUS_1: return "completed"
      case ASSET_STATUS_2: return "canceled"
    }
  }

  getStatusClass(code: string): string {
    switch (code) {
      case PROCESSING_STATUS_1: return "status approved"
      case PROCESSING_STATUS_2: return "status processing"
      // case PROCESSING_STATUS_3: return "status pre-completed"
      case PROCESSING_STATUS_4: return "status completed"
      case PROCESSING_STATUS_5: return "status canceled"
      case PROCESSING_STATUS_6: return "status refunded"
      case PROCESSING_STATUS_7: return "status adjusted"
      case PROCESSING_STATUS_8: return "waitlisted status"
      case PROCESSING_STATUS_9: return "sv-approval status"
      case PROCESSING_STATUS_10: return "wd-request status"
      case PROCESSING_STATUS_11: return "verified-wd-request status"
      case PROCESSING_STATUS_12: return "finalized-wd-request status"
      case PROCESSING_STATUS_13: return "invoiced status"
      default: return "status"
    }
  }

  getType(type: string): string {
    switch(type){
      case "REFUND_TYPE_1": return "Class Fee"
      case "REFUND_TYPE_2": return "Class Deposit"
      case "REFUND_TYPE_3": return "Class Cost"
      case"REFUND_TYPE_4": return "Bundle Price"
    }
  }
  
  onPageChange($event: any): void {
    this.pageChange.emit({...$event, title: this.title});
  }
  
  goToBundleOrder(order){
    let syCode;
    if(order.bundle){
      syCode = order.bundle.syCode;
    } else if(order.order.syCode){
      syCode = order.order.syCode;
    } else {
      syCode = "2019-2020"
    }
    if(syCode == "2019-2020"){
      if(order.orderCode.includes("DEPOSIT")){
        this.router.navigate([ORDER_DEPOSIT_PATH, order.order.id]);
      } else if(order.orderCode.includes("FEE")){
        this.router.navigate([ORDER_CURRICULUM_FEE_PATH, order.order.id]);
      } else {
        this.router.navigate([OLD_ORDERS_PATH, order.order.id])
      }
    } else {
      this.router.navigate([ORDERS_PATH, order.order[0].id]);
    }
  }
  
  goToOrder(order: any) {
    let syCode;
    if(order.curriculum){
      syCode = order.curriculum.syCode;
    } else {
      syCode = "2019-2020";
    }
    if(syCode == "2019-2020"){
      if(order.orderCode.includes("DEPOSIT")){
        this.router.navigate([ORDER_DEPOSIT_PATH, order.orderId]);
      } else if(order.orderCode.includes("FEE")){
        this.router.navigate([ORDER_CURRICULUM_FEE_PATH, order.orderId]);
      } else {
        this.router.navigate([OLD_ORDERS_PATH, order.orderId])
      }
    } else {
      this.router.navigate([ORDERS_PATH, order.orderId]);
    }
  }

  isBundle(item: any): boolean {
    return (item.bundle != null && item.bundle.code.length > 0) || this.isOldBundle(item);
  }

  isOldBundle(item: any): boolean {
    let isOldBundle = false;
    if(item.order && item.order.syCode == '2019-2020' && item.referenceType == ORDER_ITEM_TYPE_4){
      isOldBundle = true;
    }
    return isOldBundle;
  }

  isClassReference(itemType: string): boolean {
    return itemType === ORDER_ITEM_TYPE_5;
  }

  get getFlexBasis(): string {
    let flexBasis = 100;
    let statusAndOrOrderCodeLength = this.dataProperties.filter(prop => ['status', 'orderCode'].includes(prop.property)).length;
    flexBasis = flexBasis - (15 * statusAndOrOrderCodeLength);
    flexBasis = flexBasis / (this.dataProperties.length - statusAndOrOrderCodeLength);
    return flexBasis + '%';
  }

  onSearch(assetTag: string) {
    this.keyword = assetTag;
    this.onSearchEvent.emit(assetTag);
  }

  onClearFilters() {
    this.keyword = '';
    this.onClearFiltersEvent.emit(true);
  }

}
