import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, from, Subject } from "rxjs";
import { concatMap, map, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { faAngleLeft, faAngleRight, faEye } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatStepper } from "@angular/material";
import { CrudService } from "src/app/core/services/data/crud.service";
import { Resource } from 'src/app/core/models/resource.model';
import { StudentInfo } from "src/app/core/models/eos/student-info.model";
import { ORDER } from 'src/app/core/constants/configuration/order-constants.config';
import { CREATE_MODULE_NAME, tableDateFormatWithTime } from 'src/app/core/constants/configuration/common.constant';
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { OrderResourceSelectionComponent } from './order-resource-selection/order-resource-selection.component';
import { OrderResourceModalFormComponent } from "./order-resource-modal-form/order-resource-modal-form.component";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { GuardianShippingDetails } from 'src/app/core/models/eos/guardian-shipping-details.model';
import { Order } from 'src/app/core/models/order/order.model';
import {
  allOrdersEndpoint,
  getShippingDetailsEndpoint,
  getOrderTransformEndpoint,
  getActiveSchoolYearEndpoint,
  getProcessingStatusEndpoint,
  getClassOrderedCount,
  getProcessedOrderedCount,
  getCartSession,
} from 'src/app/core/constants/endpoints.constant';
import { DataCustomizerService } from 'src/app/core/services/util/data-customizer.service';
import { OrderHelper } from 'src/app/core/services/util/order.helper';
import { Router } from '@angular/router';
import { ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { StudentInfoWithShipping } from 'src/app/core/models/eos/student-info-with-shipping.model';
import { WaitlistedOrdersPopupComponent } from './waitlisted-orders-popup/waitlisted-orders-popup.component';
import { Class } from 'src/app/core/models/class.model';
import { OrderClassResourceComponent } from './order-class-resource/order-class-resource.component';
import { CartSession } from 'src/app/core/models/cart-session.model';
import { UserService } from 'src/app/core/services/util/user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit {

  navLabels: string[] = ['Curriculum Selection','Student Selection', 'Shipping Details', 'Class and Resource', 'Summary'];
  activeIndex: number = 0;
  isFirst: boolean = true;
  isLast: boolean = false;
  studentId: number;
  isStudentCompleted: boolean;
  isShippingCompleted: boolean;
  isClassCompleted: boolean;
	unsubscribe: Subject<any> = new Subject();
  studentInfo: StudentInfo;
  resourceListDialogRef: any;
  resourceFormDialogRef: any;
  selectedClasses: any[] = [];
  selectedResources: Resource[] = [];
  newResources: Resource[] = [];
  shippingDetails: any[] = [];
  processingStatus: any[] = [];
  selectedShippingDetail: any;
  orderDetails: any[] = [];
  activeSchoolYear: any;
  totalAmount: number = 0;
  selectedCurriculum: any;
  selectedStepIndex: number = 0;
  curriculumMaxStudents = 0;
  processedClassOrders = 0;
  selectedClassTooltip = "";
  selectedCourses: any = null;

  multipleStudentsId: number[];
  multipleStudentsInfo: StudentInfoWithShipping[];

  multipleClasses: Class[];
  orderIds: string;
  orderCodes: string;

  cartSession: CartSession;
  disableChangePage: boolean = false;

  leftIcon: IconDefinition = faAngleLeft;
  rightIcon: IconDefinition = faAngleRight;
  eyeIcon: IconDefinition = faEye;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(OrderClassResourceComponent) orderClassResourceComponent: OrderClassResourceComponent;

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
    private headerMessagingService: HeaderMessagingService,
    private loaderMessagingService: LoaderMessagingService,
    private dataCustomizerService: DataCustomizerService,
    private orderHelper: OrderHelper,
    private router: Router,
    private userService: UserService,
  ) {
    this.headerMessagingService.setHeader(ORDER, CREATE_MODULE_NAME, false, null);
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(true);
    this.multipleStudentsInfo = [];
    this.isStudentCompleted = false;
    this.isShippingCompleted = false;
    this.isClassCompleted = false;
    this.getActiveSchoolYear();
    this.orderIds = '';
    this.orderCodes = '';
    this.checkForCourseOrder();
    this.createCartSession();
    this.loaderMessagingService.showPageLoader(false);
  }

  async onChangePage(prop: any, label: string, toValidateCartSession: boolean = true): Promise<void> {
    if(toValidateCartSession) this.validateCartSession();

    // For Curriculum Selection Next button
    // If user has not selected a curriculum, open prompt
      // Else, get the curriculum orders
    if (label === 'CURRICULUM_SELECTION') {
      if (!this.selectedCurriculum) {
        this.openOrderCreationSuccessDialog(null, '', "Please select a curriculum.");
      } else {
        this.getCurriculumOrders();
      }
    }

    // For Course and Resources buttons
    // If user has selected at least one CLASS, proceed to stepper index 2
    if (label === 'CLASS_RESOURCE') {
      if(this.isClassCompleted) {

        // Create order object
        // Moved to at after clicking next on student selection / shipping
        // this.createOrderObject();

        if(prop === 'NEXT'){
          this.stepper.selectedIndex = 2;
        } else {
          this.stepper.selectedIndex = 0;
        }
        
      } else if(prop === 'NEXT') {
        
        // On next: If user has not selected a CLASS, open prompt
        this.openOrderCreationSuccessDialog(null, '', "Please select a course.");

      } else if(prop === 'PREV') {
        
        // On prev: set isClassCompleted to false
        this.isClassCompleted = false;

      }
    }

    // For Student Selection buttons
    if (label === 'STUDENT_SELECTION') {
      // If user has selected at least one student, get the student/s, then proceed to stepper index 3 (next step)
      if (this.isStudentCompleted) {
        await this.getMultipleStudentsById().then(
          (multipleStudentsInfo: any) => {
            // assign default selected shipping detail per student
            for(let i=0; i < multipleStudentsInfo.length; i++){
              if(multipleStudentsInfo[i].shippingDetails.length > 0){
                // Use code below instead if you want to have the first shipping detail selected by default
                // multipleStudentsInfo[i].selectedShippingDetail = multipleStudentsInfo[i].shippingDetails[0];
                multipleStudentsInfo[i].selectedShippingDetail = null;
              }
            }
            this.multipleStudentsInfo = multipleStudentsInfo;
          }
        );

        await this.getMultipleStudentIds().then((studentIds: number[])=>{
          // Sorts multipleStudentsInfo based on order of multipleStudentsId
          this.multipleStudentsInfo.sort(function(a, b){
            return studentIds.indexOf(a.studentInfo.studentDetails.id) - studentIds.indexOf(b.studentInfo.studentDetails.id);
          });
        });

        // Handle waitlisting for each class selected
        for(let selectedClass of this.multipleClasses){
          this.hasMaxClass(selectedClass);
        }

        if(prop === 'NEXT'){
          for(let selectedStudent of this.multipleStudentsInfo){
            const hasOutsideGradeLevel = this.isOutsideGradeLevels(this.multipleClasses, selectedStudent.studentInfo.studentDetails)
            if (hasOutsideGradeLevel){
              this.showOutsideGradeLevelPopup(this.multipleClasses, selectedStudent.studentInfo.studentDetails)
            }
            else {
              this.stepper.selectedIndex = 3
            }
          }
          // this.stepper.selectedIndex = 3;
        } else {
          this.stepper.selectedIndex = 1;
        }

      } else if(prop === 'NEXT') {

        // On next: If user has not selected a student, open prompt
        this.openOrderCreationSuccessDialog(null, '', "Please select a student.");

      } else if(prop === 'PREV') {

        // On prev: set isStudentCompleted to false
        this.isStudentCompleted = false;

      }
    }

    // For Shipping Details buttons
    // If the student/s all has selected address...
      // Else, open prompt
    if (label === 'SHIPPING') {
      if (this.checkIfallHasSelectedAddress()) {

        // Moved creating order object here (from course selection)
        this.createOrderObject();

      } else if(prop === 'NEXT') {

        this.openOrderCreationSuccessDialog(null, '', "Please select a shipping address.");
        
      } 
    }

    if (label === 'SAVE_ORDER') {
      this.openSaveOrderConfirm();
    }

  }

  isOutsideGradeLevels(classes: any[], studentDetails: any){
    return !!classes && !!studentDetails && classes.filter(item => studentDetails.gradeLevel < item.fromGradeLevel || studentDetails.gradeLevel > item.toGradeLevel).length > 0;
  }

  showOutsideGradeLevelPopup(classes: any[], studentDetails: any){
    let courseNames = "";
    if (!!classes && !!studentDetails) {
      classes.map(item => {
        if(studentDetails.gradeLevel < item.fromGradeLevel || studentDetails.gradeLevel > item.toGradeLevel) {
          if(courseNames.length == 0) {
            courseNames = courseNames + item.code + " " + item.name;
          } else {
            courseNames = courseNames + ", " + item.code + " " + item.name;
          }
        }
      });
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmTitle = 'Warning';
    dialogRef.componentInstance.confirmMessage = "The following courses are outside the student's gradelevel: " + courseNames + ". Do you wish to continue?"
    dialogRef.afterClosed().subscribe(result => {
      if(result) this.stepper.selectedIndex = 3;
    });
    
  }
  
  // Handles waitlisting
  hasMaxClass(selectedClass: Class): void {
    if(selectedClass && selectedClass.maxSlot && this.multipleStudentsInfo.length + selectedClass.orderedCount > selectedClass.maxSlot){
        const dialogRef = this.dialog.open(WaitlistedOrdersPopupComponent, {
          data: {
            className: selectedClass.name,
            maxStudents: selectedClass.maxSlot,
            excessStudents:  this.multipleStudentsInfo.slice(selectedClass.maxSlot - selectedClass.orderedCount).map(
              (data: any) => data.studentInfo.studentDetails 
            )
          }
        });
        dialogRef.afterClosed().subscribe( response => {
          
          if (response) {

            // Passes empty array of selectedCurriculumResources and selectedClassResources as default
            this.getSelectedClassAndResources({
              selectedClass: selectedClass,
              selectedCurriculumResources: [],
              selectedClassResources: [],
            });

          };

        });
    } else {

      // Passes empty array of selectedCurriculumResources and selectedClassResources as default
      this.getSelectedClassAndResources({
        selectedClass: selectedClass,
        selectedCurriculumResources: [],
        selectedClassResources: []
      });

    }
  }

  getCurriculumOrders () {
    const maximumClassOrder$ = this.crudService
    .getById<any>(getClassOrderedCount + "?classCode=" + this.selectedCurriculum.code);

    const processedOrderCount$ = this.crudService
    .getById<any>(getProcessedOrderedCount + "?classCode=" + this.selectedCurriculum.code);

    combineLatest(maximumClassOrder$, processedOrderCount$)
    .subscribe(
     ([max, processed]) => {
        this.curriculumMaxStudents = max;
        this.processedClassOrders = processed;
      }
    )
  }

  onGetNewShippingDetails($event) {
    for(var student of this.multipleStudentsInfo){
      if(student.studentInfo.studentDetails.id == $event.student.studentInfo.studentDetails.id){
        student.selectedShippingDetail = $event.selectedItem;
      }
    }
  }

  getNewShippingDetails($event) {
    this.selectedShippingDetail = $event;
  }

  onStudentSelect(studentId: number): void {
    this.studentId = studentId;

    if (studentId) {
      this.isStudentCompleted = true;
    } else {
      this.isStudentCompleted = false;
    }
  }

  onMultipleStudentSelect(multipleStudentsId: number[]): void {
    this.multipleStudentsId = multipleStudentsId;
    if (multipleStudentsId.length > 0) {
      this.isStudentCompleted = true;
    } else {
      this.isStudentCompleted = false;
    }
  }

  onMultipleClassSelect(multipleClasses: Class[]): void {
    this.selectedCourses = multipleClasses;
    this.setSelectedClassTooltip(multipleClasses);
    this.multipleClasses = multipleClasses;
    if (multipleClasses.length > 0) {
      this.isClassCompleted = true;
    } else {
      this.isClassCompleted = false;
    }
  }

  onShippingSelect($event: any): void {
    this.shippingDetails = $event.items;
    this.selectedShippingDetail = $event.selectedItem;

    if ($event.selectedItem) {
      this.isShippingCompleted = true;
    } else {
      this.isShippingCompleted = false;
    }
  }

  onClick($event: any): void {
  }

  showResourceList() {
    this.resourceListDialogRef = this.dialog.open(OrderResourceSelectionComponent, { width: "80%", height: "82%" });
    this.resourceListDialogRef.componentInstance.openResourceFormModal.subscribe(() => {
      this.resourceListDialogRef.close();
      this.showResourceFormModal();
    });
    this.resourceListDialogRef.componentInstance.closeResourceListModal.subscribe(() => {
      this.resourceListDialogRef.close();
    });
    this.resourceListDialogRef.componentInstance.getSelectedResource.subscribe((resource: Resource) => {
      let message = null;
      let exists = this.selectedResources.find(value => value.code === resource.code);
      if (!exists)
        this.selectedResources.push(resource);
      else
        message = 'Resource is already added to cart';
      this.resourceListDialogRef.close();
      this.isClassCompleted = true;
      this.openSuccessDialog(message);
    });
  }

  showResourceFormModal() {
    this.resourceFormDialogRef = this.dialog.open(OrderResourceModalFormComponent, { width: "50%" });
    this.resourceFormDialogRef.componentInstance.closeResourceFormModal.subscribe(() => {
      this.resourceFormDialogRef.close();
    });
    this.resourceFormDialogRef.componentInstance.getResource.subscribe((resource: Resource) => {
      let message = null;
      let exists = this.newResources.find(value => value.code === resource.code && value.name === resource.name);
      if (!exists)
        this.newResources.push(resource);
      else
        message = 'Resource is already added to cart';
      this.resourceFormDialogRef.close();
      this.isClassCompleted = true;
      this.openSuccessDialog(message);
    });
  }

  getSelectedClassAndResources($event: any) {
    let selectedClass: any = this.selectedClasses.find( item => item.selectedClass.code === $event.selectedClass.code );
    
    if (selectedClass) {
      selectedClass.selectedClassResources = $event.selectedClassResources;
      selectedClass.selectedCurriculumResources = $event.selectedCurriculumResources;
    } else this.selectedClasses.push($event);
    
    this.updateCurriculumResourcesPerSelectedClass();

    // this.openSuccessDialog(null);
    
  }

  updateCurriculumResourcesPerSelectedClass() {
    let curriculums = [];
    this.selectedClasses.forEach(item => {
      let curriculumExists = curriculums.find(curr => curr.code === item.selectedClass.curriculum.code);
      if (curriculumExists) {
        let curriculumResources = curriculumExists.resources ? curriculumExists.resources : [];
        item.selectedCurriculumResources.forEach(currResource => {
          let resourceExists = curriculumResources.find(resource => resource.code === currResource.code);
          if (!resourceExists) curriculumResources.push(currResource);
        });
      } else
        curriculums.push({
          code: item.selectedClass.curriculum.code,
          resources: item.selectedCurriculumResources,
        });
    });
    curriculums.forEach(curr => {
      this.selectedClasses.forEach(item => {
        if (item.selectedClass.curriculum.code === curr.code)
          item.selectedCurriculumResources = curr.resources;
      });
    });
  }

  openSuccessDialog(message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmTitle = 'Success';
    dialogRef.componentInstance.confirmMessage = message ? message : "Item/s successfully added to cart.";
    dialogRef.componentInstance.cancelLabel = "Continue Browsing";
    dialogRef.componentInstance.confirmLabel = "Go to Cart";
    dialogRef.afterClosed().subscribe(result => {
      if(result) this.goToCart();
    });
  }

  openSaveOrderConfirm() {
    if (this.orderDetails.length && this.orderDetails[0].orderItems && this.orderDetails[0].orderItems.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent);
      dialogRef.componentInstance.confirmTitle = 'Save Order';
      dialogRef.componentInstance.confirmMessage = "Are you sure you want to save this order?";
      dialogRef.afterClosed().subscribe(result => {
        if(result && !this.disableChangePage) this.saveOrder();
      });
    }
  }

  goToCart() {
    this.createOrderObject();
    this.stepper.selectedIndex = 4;
  }

  onGetTotalAmount(totalAmount) {
    this.totalAmount = totalAmount;
    this.orderDetails[0].total = totalAmount;
  }

  getNewOrderDetails($event) {
    let selectedItem = $event;
    this.selectedClasses = this.selectedClasses.map(item => {
      if (item.selectedClass.code === selectedItem.referenceCode)
        return null
      else {
        let classResources = item.selectedClassResources.filter(resource =>
          selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
        );
        let curriculumResources = item.selectedCurriculumResources.filter(resource =>
          selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
        );
        return {
          selectedClass: item.selectedClass,
          selectedClassResources: classResources,
          selectedCurriculumResources: curriculumResources,
        }
      }
    });
    this.selectedResources = this.selectedResources.filter(resource =>
      selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
    );
    this.newResources = this.newResources.filter(resource =>
      selectedItem.referenceCode !== resource.code && selectedItem.referenceName !== resource.name
    );
    this.selectedClasses = this.selectedClasses.filter(item => item !== null);

    // Need to first update selected courses in general
    let courses = [];
    this.selectedClasses.forEach(item => {
      courses.push(item.selectedClass);
    });
    this.onMultipleClassSelect(courses);

    // Need to update checked items in course selection
    this.orderClassResourceComponent.onRemoveItem(selectedItem);
    
    this.updateCurriculumResourcesPerSelectedClass();
    this.createOrderObject();
  }

  createOrderObject() {
    let syCode = this.selectedClasses.length > 0 ?
      this.selectedClasses[0].selectedClass.curriculum.syCode
      : this.activeSchoolYear.schoolYear;
      this.loaderMessagingService.showPageLoader(true);

      from(this.multipleStudentsInfo)
      .pipe(
        map(
          (student: StudentInfoWithShipping) => {
            return this.orderHelper.setOrderModel(
              this.selectedCurriculum,
              student.studentInfo,
              student.selectedShippingDetail,
              this.selectedClasses,
              this.selectedResources,
              syCode,
            );
          }
        ),
        concatMap( order => {
          return this.transformOrder(order)
        }),
        toArray()
      )
      .subscribe( response => {
        this.orderDetails = response;
      }
      , this.errorHandlerService.handleError
      , this.handleCompletion)
  }
  
  private getStudentById(id: number): void {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService.getById<StudentInfo>(`/students/${id}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
      }, this.errorHandlerService.handleError);
  }

  getMultipleStudentsById() {
    return new Promise(resolve => {
      this.loaderMessagingService.showPageLoader(true);
      this.multipleStudentsInfo = [];
      
      let studentIds$ = from(this.multipleStudentsId);
  
      return studentIds$
      .pipe(
        mergeMap((studentId: number) => {
          const studentInfo = this.getStudentInfo(studentId);
          const shippingDetails = this.getGuardianShippingDetails(studentId)
          .pipe(
            map( 
              (shippingDetail: GuardianShippingDetails[]) => 
              shippingDetail.map( 
                (s: GuardianShippingDetails, index:number) => {
                  let formattedShippingDetails = this.dataCustomizerService.formatShippingDetailDisplay(s);
                  // Enable this if you want to have the first shipping details selected by default
                  // if(index === 0) formattedShippingDetails.check = true;
                  return formattedShippingDetails;
                }
              )
            )
          );
          return combineLatest([studentInfo,shippingDetails]);
        }),
        map(([studentInfo,shippingDetails] : [StudentInfo, GuardianShippingDetails]) => {
          return ({
            studentInfo: studentInfo,
            shippingDetails: shippingDetails
          })
        }),
        toArray()
      )
      .subscribe(
        async (response: StudentInfoWithShipping[]) => {
          resolve(response);
        },this.errorHandlerService.handleError
        ,this.handleCompletion
      );
    });
  }

  getStudentInfo(studentID) {
    return this.crudService.getById<StudentInfo>(`/students/${studentID}`);
  }

  transformOrder(orderDetails) {
    return this.crudService
      .add<Map<String, Order[]>>(getOrderTransformEndpoint, orderDetails);
  }

  saveOrder(): void {
		this.loaderMessagingService.showPageLoader(true);

    // for unique identityId
    if(this.orderDetails.length != 1){
      for(let i = 0; i < this.orderDetails.length; i++){
        this.orderDetails[i].identityId = i;
        this.orderDetails[i].id = i;
        this.orderDetails[i].shippingDetails.identityId = i;
        this.orderDetails[i].shippingDetails.id = i;
      }
    }

    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<Order[]>(allOrdersEndpoint, this.orderDetails)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
        response.forEach(order => {
          if(this.orderCodes === ''){
            this.orderCodes = this.orderCodes.concat(order.code);
          } else {
            this.orderCodes = this.orderCodes.concat(',').concat(order.code);
          }
          if(this.orderIds === ''){
            this.orderIds = this.orderIds.concat(order.id.toString());
          } else {
            this.orderIds = this.orderIds.concat(',').concat(order.id.toString());
          }
        });
        this.openOrderCreationSuccessDialog(response[0].id, 'Success', "Order successfully saved.");
    	}, this.errorHandlerService.handleError, this.handleCompletion);
    
  }

  openOrderCreationSuccessDialog(id: number, title: string, message: string): void {
    const dialogRef = this.dialog.open(DialogBoxComponent);
    dialogRef.componentInstance.dialogTitle = title;
    dialogRef.componentInstance.contentMessage = message;
    if (id) {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate([ORDERS_PATH, id], {queryParams: {codes: this.orderCodes, ids: this.orderIds}});
      });
    }
  }

  getGuardianShippingDetails(studentId) {
    return this.crudService
      .getAllBy<GuardianShippingDetails>(getShippingDetailsEndpoint.concat(`/${studentId}`));
  }

  getActiveSchoolYear(): void {
    this.crudService
      .getById<any>(getActiveSchoolYearEndpoint)
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.activeSchoolYear = response;
        }
        this.getProcessingStatus();
    	}, this.errorHandlerService.handleError);
  }
  
  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
			}, this.errorHandlerService.handleError);
  }

  checkIfallHasSelectedAddress() : boolean {
    return this.multipleStudentsInfo.length > 0 && this.multipleStudentsInfo.every(
      (student: StudentInfoWithShipping) => {
        return student.selectedShippingDetail;
      }
    )
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };

  selectionChange(curriculum) {
    this.selectedCurriculum = curriculum;
  }

  stepperChanged(event) {
    this.selectedStepIndex = event.selectedIndex;
  }
  
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setSelectedClassTooltip(multipleClasses){
    this.selectedClassTooltip = "";
    if(multipleClasses){
      for(var selectedClass of multipleClasses){
        this.selectedClassTooltip += selectedClass.code + " - " + selectedClass.name + "\n";
      }
    }
  }

  checkForCourseOrder(){
		// Retrieve the objects from storage
		let retrievedCourse = localStorage.getItem('courseOrderDetails');
    let retrievedCurriculum = localStorage.getItem('curriculumOrderDetails');
		let retrievedCreateOrder = localStorage.getItem('createOrder');

    // Parse the retrieved objects
    if(retrievedCourse && retrievedCurriculum && retrievedCreateOrder){
      let parsedCourse = JSON.parse(retrievedCourse);
      let parsedCurriculum = JSON.parse(retrievedCurriculum);
      
      // Set the selected curriculum
      this.selectionChange(parsedCurriculum);
      
      // Trigger onChangePage
      this.onChangePage('NEXT', 'CURRICULUM_SELECTION', false);
      this.stepper.selectedIndex = 1;

      // Set the selected course
      let courseList = [];
      if(Array.isArray(parsedCourse)){
        courseList = parsedCourse;
      } else {
        courseList.push(parsedCourse);
      }
      courseList.forEach(item => {
        item.checked = true;
      });
      this.onMultipleClassSelect(courseList);

      // Trigger onChangePage
      this.onChangePage('NEXT', 'CLASS_RESOURCE', false);
    }

    // Remove the retrieved objects from storage
		localStorage.removeItem('courseOrderDetails');
    localStorage.removeItem('curriculumOrderDetails');
		localStorage.removeItem('createOrder');
  }

  getMultipleStudentIds() {
    return new Promise(resolve => {
      this.loaderMessagingService.showPageLoader(true);
      let studentIds$ = from(this.multipleStudentsId);
  
      return studentIds$
      .pipe(toArray())
      .subscribe(async response => {
        resolve(response);
      }, this.errorHandlerService.handleError, this.handleCompletion);
    });
  }

  createCartSession() {
    this.cartSession = {
      id: null,
      email: this.userService.getLoginUser().username,
      sessionUuid: "",
      modifiedDate: null,
      isSubstitute: false
    };
    this.crudService.add<CartSession>(getCartSession, this.cartSession)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response) => {
      if(response) {
        if(!!response.sessionUuid) {
          this.cartSession = {
            ...response
          }
        } else {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false, disableClose: true });
          dialogRef.componentInstance.confirmTitle = `Multiple Active Cart Sessions`
          dialogRef.componentInstance.confirmMessage = `The system has detected that you currently have another active cart session (${moment(response.modifiedDate).format(tableDateFormatWithTime)}). Creating a new cart session will terminate the existing cart session. Would you like to continue creating a new cart?`;
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              // Terminate existing cart session and create new one
              this.cartSession = {
                ...this.cartSession,
                isSubstitute: true
              }
              this.crudService.add<CartSession>(getCartSession, this.cartSession)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((response) => {
                if(response) {
                  if(!!response.sessionUuid) {
                    this.cartSession = {
                      ...response
                    }
                  } else {
                    // Unable to generate UUID for some reason; redirect to Order Tab
                    const dialogRef = this.dialog.open(DialogBoxComponent);
                    dialogRef.componentInstance.dialogTitle = 'Error';
                    dialogRef.componentInstance.contentMessage = 'Unable to generate cart session UUID. Please try again later.';
                    dialogRef.afterClosed().subscribe(() => {
                      this.redirectToOrderTab();
                    });
                  }
                }
              }, this.errorHandlerService.handleError);
            } else {
              this.redirectToOrderTab();
            }
          });
        }
      }
    }, this.errorHandlerService.handleError);
  }

  redirectToOrderTab() {
    // Disable Prev and Next buttons
    this.disableChangePage = true;
    // Redirect to Order Tab
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([ORDERS_PATH])).then(() => window.location.reload());
  }

  validateCartSession() {
    this.crudService.add<CartSession>(getCartSession, this.cartSession)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((response) => {
      if(!!response && !response.sessionUuid) {
          // Current cart session has been terminated
          const dialogRef = this.dialog.open(DialogBoxComponent);
          dialogRef.componentInstance.dialogTitle = 'Current Cart Session Terminated';
          dialogRef.componentInstance.contentMessage = 'This cart session has been terminated by another existing cart session.';
          dialogRef.afterClosed().subscribe(() => {
            this.dialog.closeAll();
            this.redirectToOrderTab();
          });
      }
    }, this.errorHandlerService.handleError);
  }
}
