import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faQuestionCircle, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Class } from 'src/app/core/models/class.model';
import { Curriculum } from 'src/app/core/models/curriculum.model';
import { Scope } from 'src/app/core/models/scope.model';

@Component({
	selector: 'curriculum-details',
	templateUrl: './curriculum-details.component.html',
	styleUrls: ['./curriculum-details.component.scss']
})
export class CurriculumDetailsComponent implements OnInit {

	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Input() curriculum: Curriculum;
	@Input() classes: Class[];
	@Input() referenceValues: any;
	
	faQuestionCircle: IconDefinition = faQuestionCircle;
	mainDetails: any;
	scopesActive: Scope[];

	constructor() { }
		
	ngOnInit() {
		const { code, name , createdBy, createdDate, modifiedBy, modifiedDate, status, assignedTo } = this.curriculum;
		this.mainDetails = { code, name, createdBy, createdDate, modifiedBy, modifiedDate, status, assignedTo };
		this.scopesActive = this.curriculum.scopes.filter(scope => scope.status == "STATUS_1");
	}

	onViewClass(id: number): void {
		this.linkClink.emit(id);
	}

}
