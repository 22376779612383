import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NEXT, pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { StudentDetails } from "src/app/core/models/eos/student-details.model";
import { TeacherDetails } from "src/app/core/models/eos/teacher-details.model";
import { getShippingStatesEndpoint } from 'src/app/core/constants/endpoints.constant';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { StudentInfoWithShipping } from 'src/app/core/models/eos/student-info-with-shipping.model';

@Component({
  selector: 'app-order-shipping-details',
  templateUrl: './order-shipping-details.component.html',
  styleUrls: ['./order-shipping-details.component.scss']
})
export class OrderShippingDetailsComponent implements OnInit {

  @Input() studentDetails: StudentDetails;
  @Input() teacherDetails: TeacherDetails;
  @Input() multipleStudentsInfo: StudentInfoWithShipping[];
  @Input() shippingDetails: any[] = [];
  @Output() changePage = new EventEmitter<any>();
  @Output() changeShippingDetails = new EventEmitter<any>();

  selectedShippingDetail: any;
  reference: any = {};
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private crudService: CrudService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.setReferenceShippingStates();
  }

  private setReferenceShippingStates(): void {
		this.crudService
			.getById<any>(getShippingStatesEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.reference.shippingStates = response;
          this.reference.shippingStates.sort((a, b) => a.name.localeCompare(b.name));
				}
			}, this.errorHandlerService.handleError);
  }

  getNewShippingDetails($event: any, studentDetails): void {
    this.changeShippingDetails.emit({selectedItem: $event, student: studentDetails});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }



}
