import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TASK, UNASSIGN, ASSIGN_OTHERS, TASK_GROUP_3, TASK_GROUP_1, TASK_GROUP_4, TASK_GROUP_5 } from 'src/app/core/constants/configuration/task-constants.config';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from "@angular/material";
import { CrudService } from "src/app/core/services/data/crud.service";
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { VIEW_MODULE_NAME, pageSizeOptions, ASC } from 'src/app/core/constants/configuration/common.constant';
import { Task } from "src/app/core/models/order/task.model";
import { Order } from "src/app/core/models/order/order.model";
import { StudentInfo } from "src/app/core/models/eos/student-info.model";
import { homeRoutingPermissions, PROGRAM_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { TASKS_PATH } from 'src/app/core/constants/routes.constant';
import { UserService } from "src/app/core/services/util/user.service";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import {
  getByCodeOrderEndpoint,
  allTasksEndpoint,
  refundEndpoint,
  getProcessingStatusEndpoint,
  getTaskGroupTypeEndpoint,
  requestToCancelOrderItemEndpoint,
  updateTaskStatusEndpoint,
  allAssetsEndpoint,
  getUserAssignedCurriculumEndpoint,
  updateAssetRelatedTaskEndpoint,
  checkInStatusEndpoint,
  downloadShippingDtls,
  orderUpdateShippingAddressEndpoint,
  getShippingDetailsEndpoint,
  adjustItemAmountEndpoint,
  allTxnHistoryEndpoint,
  oldEndpoint,
  withdrawItemEndpoint,
  updateWTBTaskStatusEndpoint,
  approveWaitlistEndpoint,
  svApproveEndpoint,
  getOrderNotesByCosCodeEndpoint,
  requestToWithdrawItemEndpoint,
  undoRequestToWithdrawItemEndpoint,
  verifyWdRequestEndpoint,
  processWdRequestEndpoint,
} from 'src/app/core/constants/endpoints.constant';
import { TASK_GROUP_2 } from "src/app/core/constants/configuration/task-constants.config";
import { ASSET_STATUS_1, ASSET_STATUS_2, orderItemTxnHistoryTab } from "src/app/core/constants/configuration/asset-constants.config";
import {
  ORDER_ITEM_TYPE_3,
  ORDER_ITEM_TYPE_5,
  ORDER_ITEM_TYPE_6,
  PROCESSING_STATUS_1,
  PROCESSING_STATUS_10,
  PROCESSING_STATUS_11,
  PROCESSING_STATUS_12,
  PROCESSING_STATUS_13,
  PROCESSING_STATUS_2,
  PROCESSING_STATUS_3,
  PROCESSING_STATUS_4,
  PROCESSING_STATUS_5,
  PROCESSING_STATUS_6,
  PROCESSING_STATUS_7,
  PROCESSING_STATUS_8,
  PROCESSING_STATUS_9,
} from 'src/app/core/constants/configuration/order-constants.config';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBell, faPrint, faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { ADMIN, SPECIALIST, SUPERVISOR } from 'src/app/core/constants/configuration/role-constants.config';
import { ResourceTaskModalFormComponent } from '../../../../shared/components/form/resource-task-modal-form/resource-task-modal-form.component';
import { Asset } from '../../../../core/models/asset.model';
import { baseServerUri, pdfFileMediaType, fileNameShippingDtls, pdfFileExtension } from 'src/app/core/constants/configuration/config.constant';
import { FileService } from "../../../../core/services/data/file.service";
import { saveAs } from "file-saver";
import { downloadConfirmationShippingDtls } from 'src/app/core/constants/message.constant';
import { GuardianShippingDetails } from '../../../../core/models/eos/guardian-shipping-details.model';
import { DataCustomizerService } from '../../../../core/services/util/data-customizer.service';
import { AdjustRefundDetails } from '../../../../core/models/order/adjust-refund-details.model';
import { TxnHistoryModel } from 'src/app/core/models/order/txn-history-model';
import { Page } from 'src/app/core/models/page.model';
import { TxnHistoryItemDto } from '../../shared/txn-history/txn-history-item-dto';
import { QueryDto } from 'src/app/core/models/dto/query-dto';
import { PaginationDto } from 'src/app/core/models/dto/pagination-model';
import { QueryService } from 'src/app/core/services/util/query.service';
import { OrderItemTxnHistoryDto } from 'src/app/core/models/dto/order-item-txn-history-dto';
import { SortDto } from 'src/app/core/models/dto/sort-dto';
import { WorkTextBookSelectedService } from 'src/app/core/services/messaging/worktextbook-selected';
import { OrderNotes } from 'src/app/core/models/order/order-notes.model';

@Component({
	selector: 'app-task-view',
	templateUrl: './task-view.component.html',
	styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit {

  workTextBooksSelected: String[];
  shippingIcon: IconDefinition = faShippingFast;
	unsubscribe: Subject<any> = new Subject();
  taskDetails: Task;
  orderDetails: Order;
  studentInfo: StudentInfo;
  shippingDetails: any[] = [];
  processingStatus: any[] = [];
  taskGroupType: any[] = [];
  specialists: any[] = [];
  specialistUsernames: string[] = [];
  selectedSpecialist: string = "";
  selectedAsset: any;
  selectedTrackingId: string = "";
  assignIcon: IconDefinition = faBell;
  printIcon: IconDefinition = faPrint;
  itemType: string = orderItemTxnHistoryTab;
  txnHistoryItemsCount: number;
  filter: PaginationDto;
  sortedBy: string;
  sortOrder: string;
  queryParameters: QueryDto;
  keyword: string;
  pageIndex: number;
  displayTxnHistoryItems: TxnHistoryModel[] = [];
  taskId: any;
  syCode: any;
  isWorkTextBooksAllSelected: Boolean;
  type2 : string = "ORDER_ITEM_TYPE_3";
  orderNotes: any;

	constructor(
    private crudService: CrudService,
    private dataCustomizerService: DataCustomizerService,
    private dialog: MatDialog,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private loaderMessagingService: LoaderMessagingService,
		private route: ActivatedRoute,
    private userService: UserService,
    private downloadFileService: FileService,
    private queryService: QueryService,
    private workTextBookSelectedService : WorkTextBookSelectedService,
	) {
		this.userService.checkRolePermission(TASKS_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(TASK, VIEW_MODULE_NAME, false, null);
	}

	ngOnInit(): void {
    this.loaderMessagingService.showPageLoader(true);
    this.initializeData();
    this.reloadTaskDetails();

    // Set initial values for filters:
    this.filter = new PaginationDto(
      0,
      0,
      pageSizeOptions[0],
      '1'
    );
    this.sortedBy = "dateModified";
    this.sortOrder = ASC;
    this.keyword = "";
    this.pageIndex = this.filter.pageIndex;
    this.workTextBookSelectedService
    .getIsCheckedAll()
    .subscribe(
      isSelectedAll => {
        this.isWorkTextBooksAllSelected = isSelectedAll;
      }
    );
  }

  initializeData() {
    this.taskId = this.route.snapshot.paramMap.get('id');
    this.syCode = this.route.snapshot.paramMap.get('syCode');
  }

  reloadTaskDetails() {
    this.getTaskDetails(this.taskId);
  }

  updateCheckedWorkTextBooks(checkedItems: any[]) {
    this.workTextBooksSelected = [];
    checkedItems.forEach(
      checkedItem => {
        this.workTextBooksSelected.push(checkedItem.assetCode);
      }
    )
  }


  openDownloadShippingDtls(event: any): void {
    this.stopPropagation(event);
    var confirmMessage = downloadConfirmationShippingDtls;
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = confirmMessage;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        result => {
          if (result) {
            this.download(downloadShippingDtls, false);
          }
        },
        this.errorHandlerService.handleError,
        this.handleCompletion
      );
  }

  download(urlPath: string, isErrorFile: boolean): void {
    this.loaderMessagingService.showPageLoader(true);
    const endpoint = baseServerUri
      .concat(urlPath)
      .concat("?code=")
      .concat(this.orderDetails.code)
      .concat("&taskCode=")
      .concat(this.taskDetails.code)
      .concat('&assets=')
      .concat(this.workTextBooksSelected.toString());
    this.downloadFileService
      .getFile(endpoint)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        const blob = new Blob([response], { type: pdfFileMediaType });
        saveAs(
          blob,
          fileNameShippingDtls
            .concat(pdfFileExtension)
        );
      }, this.errorHandlerService.handleError);
  }


  getEndpoint(endpoint: string): string {
    if(this.syCode == '2019-2020'){
      endpoint = oldEndpoint.concat(endpoint);
    }
    return endpoint;
  }

  getTaskDetails(id: string) {
    this.crudService.getById<Task>(this.getEndpoint(allTasksEndpoint).concat(`/${id}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.taskDetails = response;
        this.getOrderDetails(this.taskDetails.txnOrderCode);
        // Get Txn History of Order Txn Code by Class:
        this.getOrderItemHistory(
          new TxnHistoryItemDto(
            new QueryDto(
              this.filter,
              this.sortedBy,
              this.sortOrder,
              this.keyword
            ),
            response.code,
            "CLASS"
          )
        );
      }, this.errorHandlerService.handleError);
  }

  getOrderItemHistory(
    dto: TxnHistoryItemDto
  ) {
    this.crudService.getById<Page<TxnHistoryModel>>(
      // url: /txn-history/orders/{id}/order-items/search?...
      allTxnHistoryEndpoint
        .concat(allTasksEndpoint)
        .concat(`/${dto.itemCode}`)
        .concat(this.queryService
          .buildBaseQuery(
            dto.query.pagination.pageIndex,
            dto.query.pagination.pageSize,
            dto.query.sortedBy,
            dto.query.sortOrder,
            dto.query.keyword
          )
        )
        .concat(`&itemType=${dto.itemType}`)
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          // TODO: PUSH TO UI.
          // re-initialize txnHistoryItems upon search:
          this.displayTxnHistoryItems = [];
          response.content.forEach(
            element => {
              this.displayTxnHistoryItems.push(
                element
              );
            }
          );
          this.txnHistoryItemsCount = response.totalElements;
          this.pageIndex = dto.query.pagination.pageIndex;
        }
      );
  }

  getOrderDetails(code: string) {
    this.crudService.getById<Order>(this.getEndpoint(getByCodeOrderEndpoint).concat(`?code=${code}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.orderDetails = response;
        this.getStudentById(this.orderDetails.studentId);
        this.getOrderNotesByCosCode(code);
      }, this.errorHandlerService.handleError);
  }

  getStudentById(id: string): void {
    this.crudService.getById<StudentInfo>(`/students/${id}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.studentInfo = response;
        this.getProcessingStatus();
      }, this.errorHandlerService.handleError);
  }

  getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.processingStatus = response;
				this.processingStatus.sort((a, b) => b.code.localeCompare(a.code));
				this.getTaskGroupType();
			}, this.errorHandlerService.handleError);
  }

	getTaskGroupType(): void {
		this.crudService
			.getById<any>(getTaskGroupTypeEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.taskGroupType = response;
        this.taskGroupType.sort((a, b) => b.code.localeCompare(a.code));
        this.getGuardianShippingDetails();
			}, this.errorHandlerService.handleError);
	}

  getGuardianShippingDetails(): void {
    this.shippingDetails = [];
    this.crudService
      .getAllBy<GuardianShippingDetails>(getShippingDetailsEndpoint.concat(`/${this.studentInfo.studentDetails.id}`))
      .pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          response.forEach(shippingDetail => {
            this.shippingDetails.push(
              this.dataCustomizerService.formatShippingDetailDisplay(shippingDetail))
          });
          const shippingDetail = {
            ...this.orderDetails.shippingDetails,
            check: true,
            addressLine: this.orderDetails.shippingDetails && this.orderDetails.shippingDetails.line ? this.orderDetails.shippingDetails.line : "",
            addressName: this.orderDetails.shippingDetails && this.orderDetails.shippingDetails.name ? this.orderDetails.shippingDetails.name : "",
          };
          let match = this.shippingDetails.find(detail => this.doesShippingAddressExists(detail, shippingDetail));
          if(match) this.shippingDetails.forEach(detail => { if(match.id === detail.id) detail.check = true; });
          else this.shippingDetails.push(shippingDetail);
          this.getSpecialists();
				}
    	}, this.errorHandlerService.handleError);
  }

  doesShippingAddressExists(shippingDetail: any, currentShippingDetail: any) {
    return shippingDetail.addressLine === currentShippingDetail.addressLine &&
      shippingDetail.addressName.trim() === currentShippingDetail.addressName.trim() &&
      shippingDetail.city === currentShippingDetail.city &&
      shippingDetail.email === currentShippingDetail.email &&
      shippingDetail.phone === currentShippingDetail.phone &&
      shippingDetail.state === currentShippingDetail.state &&
      shippingDetail.zipCode === currentShippingDetail.zipCode
  }

	getSpecialists(): void {
    this.specialistUsernames = [];
		this.crudService
			.getById<any>(getUserAssignedCurriculumEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.specialists = response;
        this.specialists.sort((a, b) => b.username.localeCompare(a.username));
        this.specialists.filter(specialist => {
          if (!this.specialistUsernames.includes(specialist.username))
              this.specialistUsernames.push(specialist.username);
        });
			}, this.errorHandlerService.handleError, this.handleCompletion);
	}

  getTaskGroupName(groupCode: string): string {
    const groupType: any = this.taskGroupType.find(type => type.code === groupCode);
    return groupType ? groupType.name : "";
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus.find(status => status.code === statusCode);
    if(statusName){
      if (statusName.name === "Completed") {
        return "Processed";
      } else if (statusName.name === "HST Approved") {
        return "Pending Orders";
      } else if (statusName.name === "Verified WD Request"){
        if(this.orderDetails && this.orderDetails.withdrawType){
          return `${statusName.name} - ${this.orderDetails.withdrawType}`;
        } else {
          return statusName.name;
        }
      } else return statusName.name;
    }
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };

  onSelectSpecialist(value) {
    this.selectedSpecialist = value;
  }

  get isClassTask(): boolean {
    return this.taskDetails.groupType === TASK_GROUP_1;
  }

  get isWorkbookTextBook(): boolean {
    return this.taskDetails.groupType === TASK_GROUP_2
  }

  get isOtherBooksTask(): boolean {
    return this.taskDetails.groupType === TASK_GROUP_3
  }

  get canPrint(): boolean {
    return (this.taskDetails.groupType === TASK_GROUP_2 || this.taskDetails.groupType === TASK_GROUP_3) && this.canProgramDirectorDoAction;
  }


  get isFeeTask(): boolean {
    return [TASK_GROUP_5].includes(this.taskDetails.groupType)
  }

  get isDepositTask(): boolean {
    return [TASK_GROUP_4].includes(this.taskDetails.groupType)
  }

  get canProgramDirectorDoAction(): boolean {
    if (this.userService.hasRole([PROGRAM_DIRECTOR])) {
      return this.userService.getLoginUser().username === this.taskDetails.curriculumAssignedTo;
    } else return true;
  }
  
  get canAssignToOthers(): boolean {
    return this.syCode != "2019-2020" && (!this.taskDetails.assignedTo && this.taskDetails.status === PROCESSING_STATUS_1 &&
      this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR])) && this.canProgramDirectorDoAction;
  }

  get isWaitlisted(): boolean {
    return this.orderDetails.status === PROCESSING_STATUS_8;
  }

  get isSvApprove(): boolean {
    return this.orderDetails.status === PROCESSING_STATUS_9;
  }

  get canAssign(): boolean {
    return this.syCode != "2019-2020" && (!this.taskDetails.assignedTo && this.taskDetails.status === PROCESSING_STATUS_1 &&
      this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST])) && this.canProgramDirectorDoAction;
  }

  get canUnassign(): boolean {
    if(this.taskDetails && this.taskDetails.groupType === TASK_GROUP_2){
      return this.syCode != "2019-2020" && this.taskDetails.assignedTo &&
      this.taskDetails.orderItemAssoc.filter(item => [PROCESSING_STATUS_2, PROCESSING_STATUS_4, PROCESSING_STATUS_5].includes(item.status)).length === this.taskDetails.orderItemAssoc.length &&
      (this.taskDetails.orderItemAssoc.length > 0 || this.taskDetails.status == PROCESSING_STATUS_2) &&
      (this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR]) || (this.userService.hasRole([SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) && this.taskDetails.assignedTo === this.userService.getLoginUser().username)) && this.canProgramDirectorDoAction;
    } else {
      return this.syCode != "2019-2020" && this.taskDetails.assignedTo && this.taskDetails.status != PROCESSING_STATUS_5  &&
      this.taskDetails.orderItemAssoc.filter(item => [PROCESSING_STATUS_2, PROCESSING_STATUS_5].includes(item.status)).length === this.taskDetails.orderItemAssoc.length &&
      (this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR]) || (this.userService.hasRole([SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]) && this.taskDetails.assignedTo === this.userService.getLoginUser().username)) && this.canProgramDirectorDoAction;
    }
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_1: return "approved status"
      case PROCESSING_STATUS_2: return "processing status"
      // case PROCESSING_STATUS_3: return "pre-completed status"
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_5: return "canceled status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      case PROCESSING_STATUS_8: return "waitlisted status"
      case PROCESSING_STATUS_9: return "sv-approval status"
      case PROCESSING_STATUS_10: return "wd-request status"
      case PROCESSING_STATUS_11: return "verified-wd-request status"
      case PROCESSING_STATUS_12: return "finalized-wd-request status"
      case PROCESSING_STATUS_13: return "invoiced status"
      default: return "status"
    }
  }

  onCancelSelected(selectedItems: any) {
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_5, "Successfully cancelled the selected items.", false, null, null);
  }

  onPreCompleteSelected(selectedItems: any) {
    if (this.taskDetails.groupType === TASK_GROUP_3 && !selectedItems[0].assetCode && !selectedItems[0].trackingId)
      this.showResourceFormModal(selectedItems, PROCESSING_STATUS_3, 'pre-complete');
    else
      this.updateTaskStatus(selectedItems, PROCESSING_STATUS_3, `Successfully tagged the selected items as pre-completed.`, false, null, null);
  }

  onCompleteSelected(selectedItems: any) {
    if (this.taskDetails.groupType === TASK_GROUP_3 && !selectedItems[0].assetCode && !selectedItems[0].trackingId)
      this.showResourceFormModal(selectedItems, PROCESSING_STATUS_4, 'complete');
    else
      this.updateTaskStatus(selectedItems, PROCESSING_STATUS_4, `Successfully tagged the selected items as processed.`, false, null, null);
  }

  // onPreCompleteWTB(task: any) {
  //     this.updateWTBTaskStatus(task, PROCESSING_STATUS_3, `Task was successfully pre-completed.`);
  // }

  onCompleteWTB(task: any) {
      this.updateWTBTaskStatus(task, PROCESSING_STATUS_4, `Task was successfully completed.`);
  }

  onCompletePreCompleteTask(action: string) {
    let status = action === "COMPLETE" ? PROCESSING_STATUS_4 : PROCESSING_STATUS_3;
    let emptyList = [{
      id: null,
      task: null,
      txnOrderItemId: null,
      status: status,
      forCancel: false,
    }];
    this.updateTaskStatus(emptyList, status, `Task was successfully ${action.toLowerCase()}d.`, true, null, null);
  }

  onAssignUnassign(action: string, selectedItems: any[], event: any) {
    this.stopPropagation(event);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
    let assignTo = action === ASSIGN_OTHERS ? this.selectedSpecialist : 'yourself';
    dialogRef.componentInstance.confirmMessage = `Are you sure you want to ${action.replace('_OTHERS','').toLowerCase()} ${assignTo} ${action === UNASSIGN ? 'from' : 'to'} this task?`;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let status = action === UNASSIGN ? PROCESSING_STATUS_1 : PROCESSING_STATUS_2;
        let filteredSelectedItems = selectedItems.filter(item => item.status !== PROCESSING_STATUS_5);
        let emptyListTempItem = {
          id: null,
          task: null,
          txnOrderItemId: null,
          status: status,
          forCancel: false,
        };
        filteredSelectedItems = filteredSelectedItems.length > 0 ? filteredSelectedItems : [emptyListTempItem];
        this.updateTaskStatus(filteredSelectedItems, status, `Successfully ${action.replace('_OTHERS','').toLowerCase()}ed ${assignTo} ${action === UNASSIGN ? 'from' : 'to'} this task.`, true, null, null);
      }
    });
  }

  updateWTBTaskStatus(task: any, newStatus: string, message: string){
    this.loaderMessagingService.showPageLoader(true);
    task.status = newStatus;
		this.crudService
			.edit<any>(updateWTBTaskStatusEndpoint, { tasks: [task] })
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
          this.openSuccessDialog(null, message);
          this.selectedSpecialist = "";
        }
			}, this.errorHandlerService.handleError, this.handleCompletion);
    }

  updateTaskStatus(selectedItems: any, newStatus: string, message: string, forAssign: boolean,
      assetCode: string, trackingId: string) {
    this.loaderMessagingService.showPageLoader(true);

    const taskToUpdate = this.setTaskToUpdate(selectedItems, newStatus, forAssign, assetCode, trackingId);

		this.crudService
			.edit<any>(updateTaskStatusEndpoint, { tasks: [taskToUpdate] })
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response: any) => {
				if (response) {
          if(selectedItems[0].hasSvApproval){
            message = message.replace("processed", "sv approval");
          }
          this.reloadTaskDetails();
          this.openSuccessDialog(null, message);
          this.selectedSpecialist = "";
        }
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  setTaskToUpdate(selectedItems: any, newStatus: string, forAssign: boolean,
    assetCode: string, trackingId: string) {
    let taskToUpdate = this.taskDetails;
    let classStartDate = null;
    if (forAssign) {
      let selectedTaskItems = [];
      selectedItems.forEach(item => {
        selectedTaskItems.push({...item, status: newStatus});
      });
      taskToUpdate.orderItemAssoc = selectedTaskItems;
    } else {
      let selectedTaskItems = [];
      selectedItems.forEach(item => {
        this.taskDetails.orderItemAssoc.slice().forEach(itemAssoc => {
          if (item.id === itemAssoc.txnOrderItemId) {
            selectedTaskItems.push({...itemAssoc, status: newStatus});
          }
        });
        if(item.classStartDate) {
          classStartDate = item.classStartDate
        }
      });
      taskToUpdate.orderItemAssoc = selectedTaskItems;
    }
    if (this.selectedSpecialist.length > 0) {
      taskToUpdate = {...taskToUpdate, assignedTo: this.selectedSpecialist};
    }
    if (assetCode && trackingId) {
      // used task's curriculumCode and curriculumName to send the values of assetCode and trackingId to updateTaskStatus API
      taskToUpdate = {...taskToUpdate, curriculumCode: assetCode, curriculumName: trackingId};
    }
    if(classStartDate) {
      taskToUpdate.classStartDate = classStartDate
    }
    return taskToUpdate;
  }

  undoRequestToCancel(selectedItemsCodes: string[]) {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.getById<any>(requestToCancelOrderItemEndpoint.concat(`?orderId=${this.orderDetails.id}&selectedItems=${selectedItemsCodes}&toCancel=FALSE`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
          this.openSuccessDialog(null, 'Successfully cancelled the Request to Cancel.');
        }
			}, this.errorHandlerService.handleError, this.handleCompletion);
  }

  openSuccessDialog(title: string, message: string): void {
    const dialogRef = this.dialog.open(DialogBoxComponent, { autoFocus: false });
    dialogRef.componentInstance.dialogTitle = title ? title : 'Success';
    dialogRef.componentInstance.contentMessage = message;
    dialogRef.afterClosed().subscribe(() => {
      this.reloadTaskDetails();
    });
  }

  onEditAssetDetails(item: any) {
    this.showResourceFormModal([item], item.status, "update");
  }

  showResourceFormModal(selectedItems: any, status: string, action: string) {
    let bookFormDialogRef = this.dialog.open(ResourceTaskModalFormComponent, { width: "50%" });
    bookFormDialogRef.componentInstance.book = selectedItems[0];
    bookFormDialogRef.componentInstance.action = action;
    bookFormDialogRef.componentInstance.requiredTrackingId = true;
    bookFormDialogRef.componentInstance.closeResourceTaskFormModal.subscribe(() => {
      bookFormDialogRef.close();
    });
    bookFormDialogRef.componentInstance.processBooks.subscribe((result: any) => {
      if (result) {
        bookFormDialogRef.close();
        this.selectedAsset = result.asset;
        this.selectedTrackingId = result.trackingId;
        if (action === "update") {
          this.revertPreviousAsset(selectedItems[0]);
        } else {
          let updatedAsset = {...this.selectedAsset, status: ASSET_STATUS_2, loanedTo: this.studentInfo.studentDetails.districtId, cosOrderId: this.orderDetails.code}
          this.updateAsset(updatedAsset, selectedItems, status, action, this.selectedAsset.code, this.selectedTrackingId);
        }
      }
    });
  }

	updateAsset(asset: any, selectedItems: any, status: string, action: string, assetCode: string, trackingId: string) {
    this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.editWithResponse<Asset>(allAssetsEndpoint.concat(`/${asset.id}`), asset)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((response) => {
        if (response) {
          let errorMessage = response.errorMessage;
          if (errorMessage && errorMessage.length > 0) {
            this.openSuccessDialog('Failed', errorMessage);
            this.loaderMessagingService.showPageLoader(false);
          } else {
            if (!action) {
              let updatedAsset = {...this.selectedAsset, status: ASSET_STATUS_2, loanedTo: this.studentInfo.studentDetails.districtId, cosOrderId: this.orderDetails.code}
              this.updateAsset(updatedAsset, selectedItems, selectedItems[0].status, "update", this.selectedAsset.code, this.selectedTrackingId);
            } else if (action === "update") {
              this.updateAssetRelatedTask(selectedItems, status, 'Successfully updated the asset details of the selected item.', false, assetCode, trackingId, asset);
            } else {
              this.updateAssetRelatedTask(selectedItems, status, `Successfully tagged the selected items as ${action}d.`, false, assetCode, trackingId, asset);
            }
          }
        }
			}, this.errorHandlerService.handleError);
  }
  updateAssetRelatedTask(selectedItems: any, newStatus: string, message: string, forAssign: boolean,
    assetCode: string, trackingId: string, asset: any) {
    const taskToUpdate = this.setTaskToUpdate(selectedItems, newStatus, forAssign, assetCode, trackingId);
    this.crudService
      .edit<any>(updateAssetRelatedTaskEndpoint, { tasks: [taskToUpdate] })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.selectedSpecialist = "";
          this.crudService
          .edit<any>(checkInStatusEndpoint, asset)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((response: any) => {
            if (response) {
              this.openSuccessDialog(null, message);
              this.selectedSpecialist = "";
            }
          }, this.errorHandlerService.handleError, this.handleCompletion);
        }
      });

  }

  revertPreviousAsset(item: any) : void {
    this.crudService
      .getAllBy<any>(`${allAssetsEndpoint}/get-by-code?code=${item.assetCode}&itemModelId=${item.referenceCode}`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {
        if (response) {
          let updatedAsset = {...response, itemModel: response.item, status: ASSET_STATUS_1, loanedTo: null, cosOrderId: null}
          this.updateAsset(updatedAsset, [item], status, null, '', '');
        }
      },
      this.errorHandlerService.handleError,
    );
  }

  getNewShippingDetails(details) {
    if (details) {
      this.loaderMessagingService.showPageLoader(true);
      this.crudService
        .add<any>(orderUpdateShippingAddressEndpoint, {
          ...details,
          name: details.addressName,
          line: details.addressLine,
          order: this.orderDetails,
        })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (response) {
            this.openSuccessDialog(null, "Successfully updated the shipping details.")
          }
        }, this.errorHandlerService.handleError, this.handleCompletion);
    }
  }

  onAdjust(adjustmentDetails: AdjustRefundDetails) {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<any>(adjustItemAmountEndpoint, adjustmentDetails)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          if(adjustmentDetails.orderCode.includes("DEPOSIT")){
            let itemName = adjustmentDetails.itemCodeName.split(' ');
            this.openSuccessDialog(null,
              adjustmentDetails.forRefund ?
              `Successfully refunded $${adjustmentDetails.amount.toFixed(2)} from ${itemName[0]}`
              : `Successfully ${adjustmentDetails.amount < 0 ? 'deducted' : 'added'} $${Math.abs(adjustmentDetails.amount).toFixed(2)} to ${itemName[0]}`);
          } else {
            this.openSuccessDialog(null,
              adjustmentDetails.forRefund ?
              `Successfully refunded $${adjustmentDetails.amount.toFixed(2)} from ${adjustmentDetails.itemCodeName}`
              : `Successfully ${adjustmentDetails.amount < 0 ? 'deducted' : 'added'} $${Math.abs(adjustmentDetails.amount).toFixed(2)} to ${adjustmentDetails.itemCodeName}`);
          }

        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  receivePageOnChange(value: PaginationDto) {
    // change the value of pageFilter:
    this.filter = value;
    this.getOrderItemHistory(
      new TxnHistoryItemDto(
        new QueryDto(
          this.filter,
          this.sortedBy,
          this.sortOrder,
          this.keyword
        ),
        this.taskDetails.code,
        "CLASS"
      )
    );
  }

  receiveSortOnChange(value: SortDto) {
    this.sortOrder = value.sortOrder;
    this.sortedBy = value.sortedBy;
    this.getOrderItemHistory(
      new TxnHistoryItemDto(
        new QueryDto(
          this.filter,
          this.sortedBy,
          this.sortOrder,
          this.keyword
        ),
        this.taskDetails.code,
        "CLASS"
      )
    );
  }

  receiveSearchSortOnChange(keyword: string) {
    this.keyword = keyword;
    // Upon search: Reset page index and pagesize to default:
    this.filter.pageIndex = 0;
    this.filter.pageSize = pageSizeOptions[0];
    this.getOrderItemHistory(
      new TxnHistoryItemDto(
        new QueryDto(
          this.filter,
          this.sortedBy,
          this.sortOrder,
          this.keyword
        ),
        this.taskDetails.code,
        "CLASS"
      )
    );
  }

  onWithdrawSelected(selectedItemsMap: any) {
    const { itemName, itemCode } = selectedItemsMap;
    this.withdrawSelected(selectedItemsMap, "Item "+itemCode +" "+itemName+" was successfully withdrawn. ");
  }

  withdrawSelected(selectedItemsMap: any, message: string) {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .edit<any>(withdrawItemEndpoint, { selectedItemsMap })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.openSuccessDialog(null, message);
          this.selectedSpecialist = "";
        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }


  onRefund(refundDetails: AdjustRefundDetails) {
    this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .add<any>(refundEndpoint, refundDetails)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          // if(refundDetails.item.referenceType !== ORDER_ITEM_TYPE_7){
          //   this.openSuccessDialog(null, `Successfully refunded $${refundDetails.amount.toFixed(2)} from
          //   ${refundDetails.item.referenceCode} ${refundDetails.item.referenceName}`);
          // } else {
          //   this.openSuccessDialog(null, `Successfully refunded $${refundDetails.amount.toFixed(2)} from
          //   ${refundDetails.item.referenceName}`);
          // }
          if(refundDetails.orderCode.includes("DEPOSIT")){
            let itemName = refundDetails.itemCodeName.split(' ');
            this.openSuccessDialog(null,
              refundDetails.forRefund ?
              `Successfully refunded $${refundDetails.amount.toFixed(2)} from ${itemName[0]}`
              : `Successfully ${refundDetails.amount < 0 ? 'deducted' : 'added'} $${Math.abs(refundDetails.amount).toFixed(2)} to ${itemName[0]}`);
          } else {
            this.openSuccessDialog(null,
              refundDetails.forRefund ?
              `Successfully refunded $${refundDetails.amount.toFixed(2)} from ${refundDetails.itemCodeName}`
              : `Successfully ${refundDetails.amount < 0 ? 'deducted' : 'added'} $${Math.abs(refundDetails.amount).toFixed(2)} to ${refundDetails.itemCodeName}`);
          }

        }
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

  get hasCheckOut(): boolean {
    let hasCheckedOut = false;
    if(this.taskDetails.orderItemAssoc.length > 0 && this.taskDetails.groupType == TASK_GROUP_2){
      hasCheckedOut = true;
    }
    return hasCheckedOut;
  }

  approveClass(selectedItems: any) {
    this.loaderMessagingService.showPageLoader(true);
      this.crudService
        .edit<any>(approveWaitlistEndpoint, selectedItems)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: any) => {
          if (response) {
              this.openSuccessDialog(null, "Successfully Approved the Waitlisted Item/s");
          }
        }, this.errorHandlerService.handleError, this.handleCompletion);
    }

    svApproveClass(details: any) {
      this.loaderMessagingService.showPageLoader(true);
        this.crudService
          .edit<any>(svApproveEndpoint, {orderCode: details.orderCode, approveNotes: details.approveNotes, classStartDate: details.classStartDate})
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((response: any) => {
            if (response) {
                this.openSuccessDialog(null, "Successfully SV Approved Order");
            }
          }, this.errorHandlerService.handleError, this.handleCompletion);
      }

  getOrderNotesByCosCode(code: string): void {
    this.crudService.getAll<OrderNotes>(getOrderNotesByCosCodeEndpoint.concat(`?cosOrderCode=${code}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.orderNotes = response;
      }, this.errorHandlerService.handleError);
  }

  stopPropagation(event: any){
    event.stopPropagation();
  }

  hasOnlineAccount(){
    if(this.orderDetails.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_6).length > 0){
      return true;
    } else{
      return false;
    }
  }

  hasWorkBook(){
    if(this.orderDetails.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_3).length > 0){
      return true;
    } else{
      return false;
    }
  }

  hasFeeDepositReference(){
    if(this.orderDetails.orderItems.filter(item => item.referenceType === ORDER_ITEM_TYPE_5).length > 0){
      return true;
    } else{
      return false;
    }
  }

  onRequestToWithdraw(selectedItems: any[]){
    this.taskDetails.assignedTo = null;
    this.taskDetails.status = PROCESSING_STATUS_10;
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_10, "Successfully requested to withdraw the item.", false, null, null);
  }

  onUndoRequestToWithdraw(selectedItems: any[]){
    this.taskDetails.status = PROCESSING_STATUS_4;
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_4, "Successfully undone the request to withdraw the item.", false, null, null);
  }

  onVerifyWdRequest(selectedItems: any[]){
    this.taskDetails.assignedTo = null;
    this.taskDetails.status = PROCESSING_STATUS_11;
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_11, "Successfully verified the request to withdraw the item.", false, null, null);
  }

  onNewRefund(selectedItems: any[]){
    this.taskDetails.status = PROCESSING_STATUS_12;
    this.taskDetails.withdrawType = 'refund';
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_12, "Successfully refunded the item.", false, null, null);
  }

  onNoRefund(selectedItems: any[]){
    this.taskDetails.status = PROCESSING_STATUS_12;
    this.taskDetails.withdrawType = 'no refund';
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_12, "Successfully opted for no refund on the item.", false, null, null);
  }

  onAssignWD(selectedItems: any[]) {
    this.taskDetails.assignedTo = this.userService.getLoginUser().username;
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_10, "Successfully assigned the task to self.", false, null, null);
  }

  onAssignVWD(selectedItems: any[]) {
    this.taskDetails.assignedTo = this.userService.getLoginUser().username;
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_11, "Successfully assigned the task to self.", false, null, null);
  }

  onUnassignWD(selectedItems: any[]) {
    let unassignFromString: string = this.taskDetails.assignedTo === this.userService.getLoginUser().username ? 'yourself' : this.taskDetails.assignedTo;
    this.taskDetails.assignedTo = "";
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_10, `Successfully unassigned the task from ${unassignFromString}.`, false, null, null);
  }

  onUnassignVWD(selectedItems: any[]) {
    let unassignFromString: string = this.taskDetails.assignedTo === this.userService.getLoginUser().username ? 'yourself' : this.taskDetails.assignedTo;
    this.taskDetails.assignedTo = "";
    this.selectedSpecialist = "";
    this.updateTaskStatus(selectedItems, PROCESSING_STATUS_11, `Successfully unassigned the task from ${unassignFromString}.`, false, null, null);
  }

  onAssignOthersWD(event: any) {
    this.taskDetails.assignedTo = event.selectedUser;
    this.selectedSpecialist = "";
    this.updateTaskStatus(event.selectedItems, PROCESSING_STATUS_10, `Successfully assigned the task to ${event.selectedUser}.`, false, null, null);
  }

  onAssignOthersVWD(event: any) {
    this.taskDetails.assignedTo = event.selectedUser;
    this.selectedSpecialist = "";
    this.updateTaskStatus(event.selectedItems, PROCESSING_STATUS_11, `Successfully assigned the task to ${event.selectedUser}.`, false, null, null);
  }
}
