import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Class } from 'src/app/core/models/class.model';
import { faQuestionCircle, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, SUPERVISOR, TEACHER } from 'src/app/core/constants/configuration/role-constants.config';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { allTxnHistoryEndpoint, getByCodeClassEndpoint } from 'src/app/core/constants/endpoints.constant';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { courseHistoryTab } from 'src/app/core/constants/configuration/asset-constants.config';
import { CourseHistoryModel } from 'src/app/core/models/course-history.model';
import { PaginationDto } from 'src/app/core/models/dto/pagination-model';
import { SortDto } from 'src/app/core/models/dto/sort-dto';
import { ASC, pageSizeOptions } from 'src/app/core/constants/configuration/common.constant';
import { CourseHistoryItemDto } from '../../course-history/course-history-item-dto';
import { QueryDto } from 'src/app/core/models/dto/query-dto';
import { Page } from 'src/app/core/models/page.model';
import { QueryService } from 'src/app/core/services/util/query.service';


@Component({
  selector: 'class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})
export class ClassDetailsComponent implements OnInit {
  
	@Output() linkClink: EventEmitter<number> = new EventEmitter();
	@Output() orderLinkClick: EventEmitter<number> = new EventEmitter();
	@Output() courseLinkClick: EventEmitter<number> = new EventEmitter();
	@Input() clazz: Class;
	@Input() referenceValues: any;
	@Input() classOrderedCount : number;
	@Input() classProcessedOrderedCount : number;
	@Input() classWaitlistedOrderedCount : number;
	@Input() orderList : any;
	@Input() statusListCount : any;
	@Input() canViewCharLimit: boolean = true;
	@Input() forUpdate: boolean = false;
	@Input() forCreate: boolean = false;
	@Input() mainCourse: Class = null;
	unsubscribe: Subject<any> = new Subject();

	mainDetails: any;

	faQuestionCircle: IconDefinition = faQuestionCircle;
	dataCustomizerService: any;
	curriculum: { id: any; code: any; name: any; syCode: any; };
	subsequentCourseDetails: any;

	// Course History Variables
	itemType: string = courseHistoryTab;
	courseHistoryItems: CourseHistoryModel[] = [];
	courseHistoryItemsCount: number = 0;
	filter: PaginationDto = new PaginationDto(
		0,
		0,
		pageSizeOptions[0],
		'1'
	);
	sortedBy: string = "dateModified";
	sortOrder: string = ASC;
	keyword: string = "";
	pageIndex: number = this.filter.pageIndex;

	constructor(
		private userService: UserService,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private queryService: QueryService,
	) { }

	ngOnInit() {
		// No need to get subsequent course for HST
		if (!this.isHST) this.findSubsequentCourse(this.clazz.subsequentCourse);
		
		// Don't get course history if in course update and create, or if HST
		if(!this.forUpdate && !this.forCreate && !this.isHST) {
			this.getCourseHistoryItems(new CourseHistoryItemDto(
				new QueryDto(
					this.filter,
					this.sortedBy,
					this.sortOrder,
					this.keyword
				),
				this.clazz.code
			));
		}

		const { code, name , createdBy, createdDate, modifiedBy, modifiedDate, status, maxSlot } = this.clazz
		const classOrderedCountArray = [];
		classOrderedCountArray['classOrderedCount'] = this.classOrderedCount;
		const classOrderedCount = classOrderedCountArray['classOrderedCount'];
		this.mainDetails = { code, name, createdBy, createdDate, modifiedBy, modifiedDate, status, maxSlot, classOrderedCount};
	}

	get hasHqt(): boolean {
		return this.referenceValues.hqt && String(this.referenceValues.hqt.email).trim().length > 0 && String(this.referenceValues.hqt.name).trim().length > 0;
	}

	private findSubsequentCourse(code: string): void {
		this.crudService
			.getById<Class>(getByCodeClassEndpoint.concat(`?code=${code}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.subsequentCourseDetails = response;
				}
			}, this.errorHandlerService.handleError);
	}
	
	getClassOrderedCount() {
		throw new Error('Method not implemented.');
	}


	onViewCurriculum(id: number): void {
		this.linkClink.emit(id);
	}

	onViewCourse(id: number): void {
		this.courseLinkClick.emit(id);
	}

	onViewOrder(id: number): void {
		this.orderLinkClick.emit(id);
	}

	get canViewSummary() {
		return this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR]);
	}

	receivePageOnChange(value: PaginationDto): void {
		// Update filter
		this.filter = value;

		this.getCourseHistoryItems(new CourseHistoryItemDto(
			new QueryDto(
				this.filter,
				this.sortedBy,
				this.sortOrder,
				this.keyword
			),
			this.clazz.code
		));
	}

	receiveSortOnChange(value: SortDto): void {
		// Update sorting
		this.sortOrder = value.sortOrder;
		this.sortedBy = value.sortedBy;

		this.getCourseHistoryItems(new CourseHistoryItemDto(
			new QueryDto(
				this.filter,
				this.sortedBy,
				this.sortOrder,
				this.keyword
			),
			this.clazz.code
		));
	}
	
	receiveSearchSortOnChange(keyword: string): void {
		// Update search keyword
		this.keyword = keyword;

		// Upon search, reset page index and pagesize to default:
		this.filter.pageIndex = 0;
		this.filter.pageSize = pageSizeOptions[0];

		this.getCourseHistoryItems(new CourseHistoryItemDto(
			new QueryDto(
				this.filter,
				this.sortedBy,
				this.sortOrder,
				this.keyword
			),
			this.clazz.code
		));
	}

	getCourseHistoryItems(dto: CourseHistoryItemDto) {
		// Retrieve as PageDto
		// Convert values of PageDto to course history items
		this.crudService.getById<Page<CourseHistoryModel>>(
			allTxnHistoryEndpoint
			.concat('/courses')
			.concat(`/${dto.code}`)
			.concat(this.queryService.buildBaseQuery(
				dto.query.pagination.pageIndex,
				dto.query.pagination.pageSize,
				dto.query.sortedBy,
				dto.query.sortOrder,
				dto.query.keyword
			))
		)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe((response: any) => {
			// Re-initialize courseHistoryItems
			this.courseHistoryItems = [];
			response.content.forEach((courseHistoryItem: CourseHistoryModel) => {
				this.courseHistoryItems.push(courseHistoryItem);
			});
			// Set total item count
			this.courseHistoryItemsCount = response.totalElements;
			// Set pageIndex
			this.pageIndex = dto.query.pagination.pageIndex;
		}, this.errorHandlerService.handleError);
	}

	// Checks if the user is an HST
	get isHST(): Boolean{
		return this.userService.hasRole([TEACHER]);
	}
}

