import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp, IconDefinition, faBell, faCogs, faCheckCircle, faTimesCircle, faClipboardList, faStamp, faUndo, faCoins, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-statistics',
  templateUrl: './order-statistics.component.html',
  styleUrls: ['./order-statistics.component.scss']
})
export class OrderStatisticsComponent implements OnInit {

  @Output() filterChange = new EventEmitter();
  @Input() statusListCount: any;
  filterStatus = new Map<string, string>();
	approvedIcon: IconDefinition = faThumbsUp;
	processingIcon: IconDefinition = faCogs;
	preCompletedIcon: IconDefinition = faCogs;
	clipboardList: IconDefinition = faClipboardList;
	clock: IconDefinition = faClock;
	completedIcon: IconDefinition = faCheckCircle;
  canceledIcon: IconDefinition = faTimesCircle;
  svApprovalIcon: IconDefinition = faStamp;
  wdRequestIcon: IconDefinition = faUndo;
  verifyIcon: IconDefinition = faCheckCircle;
  finalizedWdRequestIcon: IconDefinition = faUndo;
  finalizedIcon: IconDefinition = faCoins;
  invoicedIcon: IconDefinition = faFileInvoiceDollar;
  
  orderStats: any[] = [
    {name: 'waitlisted',     label: 'Waitlisted',       value: 0},
    {name: 'hstApproved',   label: 'Pending Orders',    value: 0},
    {name: 'processing',    label: 'Processing',      value: 0},
    // {name: 'preCompleted', label: 'Pre-Completed',  value: 0},
    {name: 'processed',     label: 'Processed',       value: 0},
    {name: 'cancelled',     label: 'Cancelled',       value: 0},
  ]

  constructor(
  ) { }

  ngOnInit() {
  }
  

  filter(value: string){
    this.filterStatus.set('status', value);
    this.filterChange.emit(this.filterStatus);
  }

}
