import { DataProperties } from "../../models/data-properties.model";

export const CURRICULUM: string = "CURRICULUM";
export const curriculumTab: string = "CURRICULUM";
export const sideNavLabelCurriculum: string[] = ["Main Form", "Summary"];
export const curriculumDataProperties: DataProperties[] = [
    {
        label: "Code",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Availability Start Date",
        property: "startDate",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Availability End Date",
        property: "endDate",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];
