import { CrudService } from "src/app/core/services/data/crud.service";
import { Component, OnInit, OnDestroy, Input, AfterContentInit, Output, EventEmitter } from "@angular/core";
import { HeaderMessagingService } from "src/app/core/services/messaging/header-messaging.service";
import { homeModuleName, pageSizeOptions, ASC, CLOSE, OPEN, SY_CODE, fullYearFormat } from "src/app/core/constants/configuration/common.constant";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { Curriculum } from "src/app/core/models/curriculum.model";
import { allCurriculumsEndpoint, downloadCurriculumsEndpoint, getActiveSchoolYearEndpoint, getAllSchoolYearsEndpoint, getCountByFilter as getCountByFilter, getSchoolYearsWithHistory } from "src/app/core/constants/endpoints.constant";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QueryService } from "src/app/core/services/util/query.service";
import { DataCustomizerService } from "src/app/core/services/util/data-customizer.service";
import { MatDialog } from "@angular/material";
import { Router } from '@angular/router';
import { FileService } from "../../../../core/services/data/file.service";
import { xlsxFileMediaType, baseServerUri } from "../../../../core/constants/configuration/config.constant";
import { saveAs } from 'file-saver';
import { fileNameCurriculum, downloadFileExtension } from "../../../../core/constants/configuration/config.constant";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import { downloadConfirmationCurriculum, downloadErrorNoDataCurriculum, dialogBoxErrorTitle } from "../../../../core/constants/message.constant";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { DialogBoxComponent } from "src/app/shared/components/dialog-box/dialog-box.component";
import { LoaderMessagingService } from "src/app/core/services/messaging/loader-messaging.service";
import { FilterModalComponent } from "src/app/shared/components/filter-modal/filter-modal.component";
import * as moment from 'moment';
import { DataProperties } from "src/app/core/models/data-properties.model";
import { changeSize } from "src/app/core/constants/animations.constant";
import { curriculumDataProperties } from "src/app/core/constants/configuration/curriculum-constants.config";
import { CURRICULUM_CREATE_PATH, CURRICULUM_UPDATE_PATH, CURRICULUMS_PATH } from "src/app/core/constants/routes.constant";
import { ADMIN, homeRoutingPermissions, PROGRAM_DIRECTOR } from "src/app/core/constants/configuration/role-constants.config";
import { UserService } from "src/app/core/services/util/user.service";
import { FilterService } from "src/app/core/services/util/filter.service";
import { DownloadFiltersDialogComponent } from "src/app/shared/components/download-filters-dialog/download-filters-dialog.component";
import { DownloadFiltersTypeEnum } from "src/app/shared/components/download-filters-dialog/enums/download-filters-type-enum";
import { DownloadFilters } from "src/app/shared/components/download-filters-dialog/models/download-filters";
import { ReferenceSchoolYearDTO } from "src/app/core/models/dto/referennce-school-year-dto";
import { createOrderClassDataProperties } from "src/app/core/constants/configuration/order-constants.config";

@Component({
	selector: "curriculum-list",
	templateUrl: "./curriculum-list.component.html",
	styleUrls: ["./curriculum-list.component.scss"],
	animations: [changeSize]
})

export class CurriculumListComponent implements OnInit, OnDestroy, AfterContentInit {
	@Input() fromCreateOrder: boolean = false;
	@Output()
  	selectedItem: EventEmitter<any> = new EventEmitter();
	unsubscribe: Subject<any> = new Subject();
	items: any[] = [];
	pageSize: number = pageSizeOptions[0];
	pageIndex: number = 0;
	faFilter = faFilter;
	filters: Map<string, string> = new Map<string, string>();
	itemsCount: number;
	keyword: string = "";
	sortOrder: string;
	sortedBy: string;
	trigger: string = CLOSE;
	isType1: boolean = false;
	filterLabels: string[] = [];
	clearFilters: boolean = true;
	schoolYears: any[] = [];
	selectedSyId: number;
	schoolYear: string;
	curriculumDataProperties: DataProperties[] = curriculumDataProperties;
	filtersKey: string = "curriculumFilters";
	orderCurriculumFiltersKey: string = "orderCurriculumFilters";
	schoolYearHistory: ReferenceSchoolYearDTO[] = [];
	activeSchoolYear: string;
	showBanner: boolean = false;
	bannerMessage: string = "K12 curriculum is not currently available to order for the 2024/25 school year. It should be available soon. More information will be available in next week's Weekly Update.";

	constructor(
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private headerMessagingService: HeaderMessagingService,
		private queryService: QueryService,
		private dataCustomizerService: DataCustomizerService,
		private downloadFileService: FileService,
		private loaderMessagingService: LoaderMessagingService,
		private router: Router,
		private dialog: MatDialog,
		private userService: UserService,
		private filterService: FilterService,
	) {
		this.headerMessagingService.setHeader(homeModuleName, "", true, homeRoutingPermissions);
	}

	
	ngOnInit() {
		// Make this accessible if from app-create-order; else check role permissions
		if (!this.fromCreateOrder){
			this.userService.checkRolePermission(CURRICULUMS_PATH, homeRoutingPermissions);
		}
		if(this.fromCreateOrder) this.curriculumDataProperties = createOrderClassDataProperties;
		this.loaderMessagingService.showPageLoader(true);
	}
	
	ngAfterContentInit(): void {
		// Uncomment to retain search keyword and filters for Curriculum tab upon switching to another tab
		let curriculumFilters = this.filterService.getFilter( this.fromCreateOrder ? this.orderCurriculumFiltersKey : this.filtersKey);
		if (curriculumFilters) {
			this.keyword = curriculumFilters.keyword || "";
			this.filters = curriculumFilters.filters ? this.filterService.objectToMap(curriculumFilters.filters) : new Map<string, string>();
		}
		this.getSchoolYears();
	}
	
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	initParams(): void {
		this.sortedBy = this.curriculumDataProperties[this.fromCreateOrder ? 2 : 1].property;
		this.sortOrder = ASC;
		let yearNow: string = moment((new Date()).valueOf()).format(fullYearFormat);
		this.setSchoolYear(null);
		this.getSchoolYersFromCurrentToEarliest();
	}

	onSearch(keyword: string): void {
		this.keyword = keyword;
		this.pageIndex = 0;
		this.search();
	}

	search() : void {

		this.loaderMessagingService.showListLoader(true);
		this.items = [];
		this.filterService.setFilter(this.fromCreateOrder ? this.orderCurriculumFiltersKey : this.filtersKey, this.keyword, this.filters);

		let updatedFilters: Map<string, string> = this.filters;
		if(this.fromCreateOrder && this.userService.hasRole([PROGRAM_DIRECTOR])) {
			updatedFilters.set("assignedTo", this.userService.getLoginUser().username);
		}

		this.crudService
			.getAll<Curriculum>(allCurriculumsEndpoint.concat(this.queryService.buildCurriculumSearchQuery(
					this.pageIndex, this.pageSize, this.sortedBy, this.sortOrder, this.keyword, updatedFilters)))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.itemsCount = response.totalElements;
					response.content.forEach(curriculum =>
						this.items.push(this.dataCustomizerService.createCustomizedCurriculum(curriculum)));
				}

				},
				this.errorHandlerService.handleError,
				this.handleCompletion
			);

	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showListLoader(false);
		this.loaderMessagingService.showPageLoader(false);
	};

	onPageChange($event: any): void {
		this.pageIndex = $event.pageIndex;
		this.pageSize = $event.pageSize;
		this.search();
	}

	onLinkClick(id: string): void {
		this.router.navigate([CURRICULUMS_PATH, id]);
	}

	onClickAdd() {
		this.router.navigateByUrl(CURRICULUM_CREATE_PATH);
	}
	
	download(
		filters: DownloadFilters
	): void {
		this.loaderMessagingService.showPageLoader(true);
		const endpoint = baseServerUri
			.concat(downloadCurriculumsEndpoint)
			.concat(this.queryService
				.buildDownloadQueryParams(filters)
			);
		this.downloadFileService.getFile(endpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					const blob = new Blob([response], {type: xlsxFileMediaType});
					saveAs(
						blob,
						fileNameCurriculum
							.concat(`_${filters.statusName}`)
							.concat(downloadFileExtension)
					);
				},
					this.errorHandlerService.handleError,
					this.handleCompletion
			);
	}

	getCurriculumsCountBySyCode(filters: DownloadFilters) {
		const endpoint = allCurriculumsEndpoint
			.concat(getCountByFilter)
			.concat(this.queryService.buildDownloadQueryParams(filters));
		this.crudService.getById<number>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					if (response > 0) {
						this.download(filters);
					} else {
						const dialogRef = this.dialog.open(DialogBoxComponent);
						dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
						dialogRef.componentInstance.contentMessage = downloadErrorNoDataCurriculum;
					}
				}
			)
	}
	
	openConfirmationDialog(): void {
		if (this.itemsCount > 0) {
			const dialogRef = this.dialog.open(
				DownloadFiltersDialogComponent,
				{
					width: '450px',
					data: {
						schoolYears: this.schoolYearHistory,
						selectedSchoolYear: this.schoolYear,
						statuses: [],
						type: DownloadFiltersTypeEnum.CURRICULUM
					}
				}
			);
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.unsubscribe))
				.subscribe(
					result => {
						if (result) {
							this.getCurriculumsCountBySyCode(result);
						}
					},
					this.errorHandlerService.handleError
				);
		} else {
			const dialogRef = this.dialog.open(DialogBoxComponent);
			dialogRef.componentInstance.dialogTitle = dialogBoxErrorTitle;
			dialogRef.componentInstance.contentMessage = downloadErrorNoDataCurriculum;
		}
	}

	filterOnClick(): void {

		if (!this.isType1) {
			this.trigger = this.trigger === CLOSE ? OPEN : CLOSE;
		} else {
			this.trigger = CLOSE;
			let data = [];

			this.curriculumDataProperties.forEach(property => {

				if (property.filterable)
				data.push({
					label: property.label,
					prop: property.property,
					value: this.filters.get(property.property) || "",
				});

			});

			const modalStyle = { width: "30%" };
			const dialogRef = this.dialog.open(FilterModalComponent, { ...modalStyle, data });
		
			dialogRef.afterClosed().subscribe(filters => {
				if (filters) this.filterOnChange(filters);
			});
		}

	}

	filterOnChange(filters: Map<string, string>) {
		this.filters = new Map<string, string>(filters);
		this.setFilterLabels();
		this.pageIndex = 0;
		this.search();
	}

	onToggleSort($event: any) {
		this.sortedBy = $event.sortedBy;
		this.sortOrder = $event.sortOrder;
		this.search();
	}

	onEditClick(id: string): void {
		this.router.navigate([CURRICULUM_UPDATE_PATH, id]);
	}

	onChangeFilterType(): void {
		this.isType1 = !this.isType1;
	}

	setFilterLabels(): void {

		this.filterLabels = [];

		this.filters.forEach((value, key) =>
		this.filterLabels.push(`${this.curriculumDataProperties.find(property => property.property === key).label}: ${value}`));

		this.filters.set(SY_CODE, this.schoolYear);
		
		this.clearFilters = this.filters.size === 1;

		const index: number = this.filterLabels.findIndex(label => label.includes('School Year:'));
		if (index !== -1) this.filterLabels.splice(index, 1);

		this.filterLabels.splice(0, 0, `School Year: ${this.schoolYear}`);

	}

	onClearFilters(): void {
		this.clearFilters = true;
		this.keyword = '';
		this.filters.clear();
		this.filterOnChange(new Map<string, string>());
	}

	getSchoolYears(): void {
		this.crudService
			.getById<any>(getAllSchoolYearsEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				this.schoolYears = response;
				this.schoolYears.sort((a, b) => b.schoolYear.localeCompare(a.schoolYear));
				this.initParams();
			}, this.errorHandlerService.handleError);
	}

	setSchoolYear(schoolYear: any): void {
		this.selectedSyId = 0;
		if(schoolYear != undefined || schoolYear != null) {
		  this.selectedSyId = schoolYear.id;
		  this.schoolYear = schoolYear.schoolYear;
		  if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
		  this.setFilterLabels();
		  this.search();
		} else {
		  this.crudService
		  .getById<any>(getActiveSchoolYearEndpoint)
		  .pipe(takeUntil(this.unsubscribe))
				.subscribe(response => {
					if (response) {
						this.activeSchoolYear = response.schoolYear;
						this.schoolYear = response.schoolYear;
						this.selectedSyId = this.schoolYears.find(sy =>
							String(sy.schoolYear).trim() === this.schoolYear.trim()).id;
							
						if (this.filters.has(SY_CODE)) this.filters.delete(SY_CODE);
						this.setFilterLabels();
						this.search();
				}
			}, this.errorHandlerService.handleError);
		}
	}

	getSchoolYersFromCurrentToEarliest() {
		const endpoint = getAllSchoolYearsEndpoint
			.concat(getSchoolYearsWithHistory);
		this.crudService.getAllBy<ReferenceSchoolYearDTO>(
			endpoint
		)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(
				response => {
					this.schoolYearHistory = response;
				}
			)
	}

	canEdit(): Boolean {
		if(this.activeSchoolYear) {
			return this.selectedSyId >= this.schoolYears.find(sy =>
				String(sy.schoolYear).trim() === this.activeSchoolYear.trim()).id;
		}	
		return false;
	}

	selectionChange(item){
		item.syCode = this.schoolYear;
		this.selectedItem.emit(item);
	}

	get canViewCurriculums(){
		return this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]);
	}

}
