import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, NgZone, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { faPen, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-order-note',
  templateUrl: './order-note.component.html',
  styleUrls: ['./order-note.component.scss']
})
export class OrderNoteComponent implements OnInit {
  @Input() note: any;
  @Input() self: boolean = false;
  @Output() editNote = new EventEmitter();
  faPencilAlt = faPencilAlt;


  constructor(
    private _ngZone: NgZone,
    private sanitize: DomSanitizer
    ) { }
  
  ngOnInit() {
  }
  

  onEditClick() {
    this.editNote.emit(this.note);
  }

  convertDate(timeStamp: string) :string {
    return ;
  }

  getNoteDate() {
    var localDate = new Date(this.note.createdDate);
    return null || this.sanitize.bypassSecurityTrustHtml(
      `${this.note.email} - ${moment(localDate, 'YYYY-MM-DD[T]HH:mm:ss').format("MMM DD YYYY h:mm")}${moment(localDate).format("a").toUpperCase()}</span>`
    )
  }

}
