import { DataProperties } from "../../models/data-properties.model";
import { allCurriculumsEndpoint, allClassesEndpoint } from "../endpoints.constant";

export const ORDER: string = "ORDER";
export const NEW_ORDER_KEY: string = "newOrder";
export const PAST_ORDERS_KEY: string = "pastOrders";
export const ORDER_CURRICULUM: string = "ORDER_CURRICULUM";
export const WAITLISTED_STUDENTS: string = "WAITLISTED_STUDENTS";
export const STUDENT_FOR_SV_APPROVAL: string = "STUDENT_FOR_SV_APPROVAL";

export const ORDER_ITEM_TYPE_1: string = "ORDER_ITEM_TYPE_1";
export const ORDER_ITEM_TYPE_2: string = "ORDER_ITEM_TYPE_2";
export const ORDER_ITEM_TYPE_3: string = "ORDER_ITEM_TYPE_3";
export const ORDER_ITEM_TYPE_4: string = "ORDER_ITEM_TYPE_4";
export const ORDER_ITEM_TYPE_5: string = "ORDER_ITEM_TYPE_5";
export const ORDER_ITEM_TYPE_6: string = "ORDER_ITEM_TYPE_6";

export const PROCESSING_STATUS_1: string = "PROCESSING_STATUS_1";
export const PROCESSING_STATUS_2: string = "PROCESSING_STATUS_2";
export const PROCESSING_STATUS_3: string = "PROCESSING_STATUS_3";
export const PROCESSING_STATUS_4: string = "PROCESSING_STATUS_4";
export const PROCESSING_STATUS_5: string = "PROCESSING_STATUS_5";
export const PROCESSING_STATUS_6: string = "PROCESSING_STATUS_6";
export const PROCESSING_STATUS_7: string = "PROCESSING_STATUS_7";
export const PROCESSING_STATUS_8: string = "PROCESSING_STATUS_8";
export const PROCESSING_STATUS_9: string = "PROCESSING_STATUS_9";
export const PROCESSING_STATUS_10: string = "PROCESSING_STATUS_10";
export const PROCESSING_STATUS_11: string = "PROCESSING_STATUS_11";
export const PROCESSING_STATUS_12: string = "PROCESSING_STATUS_12";
export const PROCESSING_STATUS_13: string = "PROCESSING_STATUS_13";

export const HST_APPROVED: string = "HST Approved";
export const COMPLETED: string = "Completed";
export const PENDING_ORDERS: string = "Pending Orders";
export const PROCESSED: string = "Processed";
export const WAITLISTED: string = "Waitlisted";
export const WD_REQUEST: string = "WD Request";
export const VERIFIED_WD_REQUEST: string = "Verified WD Request";
export const FINALIZED_WD_REQUEST: string = "Finalized WD Request";

export const ADJUSTMENT_TYPE_1: string = "ADJUSTMENT_TYPE_1";
export const ADJUSTMENT_TYPE_2: string = "ADJUSTMENT_TYPE_2";
export const ADJUSTMENT_TYPE_3: string = "ADJUSTMENT_TYPE_3";
export const ADJUSTMENT_TYPE_4: string = "ADJUSTMENT_TYPE_4";

export const REFUND_TYPE_1: string = "REFUND_TYPE_1";
export const REFUND_TYPE_2: string = "REFUND_TYPE_2";
export const REFUND_TYPE_3: string = "REFUND_TYPE_3";
export const REFUND_TYPE_4: string = "REFUND_TYPE_4";

export const orderTxnHistoryDataProperties: DataProperties[] = [
    {
        label: "History Type",
        property: "historyType",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Description",
        property: "description",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Date Modified",
        property: "dateModified",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Modified By",
        property: "modifiedBy",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const orderItemTxnHistoryDataProperties: DataProperties[] = [
    {
        label: "History Type",
        property: "historyType",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Description",
        property: "description",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Date Modified",
        property: "dateModified",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Modified By",
        property: "modifiedBy",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "From Value",
        property: "fromValue",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "To Value",
        property: "toValue",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "School",
        property: "scopeName",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const orderDataProperties: DataProperties[] = [
    {
        label: "COS Order ID",
        property: "code",
        sortable: true,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Student Name",
        property: "name",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "studentDistrictId",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Date Submitted",
        property: "dateSubmitted",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Course Name",
        property: "course",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Total Amount",
        property: "totalAmount",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "HUB Order ID",
        property: "eosOrderCode",
        sortable: true,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Course Name",
        property: "courseName",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Course Section",
        property: "courseSection",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Course Subject",
        property: "courseSubject",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Notes",
        property: "notes",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const waitlistedStudentDataProperties: DataProperties[] = [

    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    // CR-130: Temporary Hide Only
    {
        label: "Current Balance",
        property: "currentBalance",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const studentForSVApprovalDataProperties: DataProperties[] = [

    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    // CR-130: Temporary Hide Only
    {
        label: "Available Balance",
        property: "availableBalance",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const studentDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "District ID",
        property: "districtId",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "MA",
        property: "masterAgreement",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "School",
        property: "scope",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Grade Level",
        property: "gradeLevel",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Learning Center",
        property: "learningCenter",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Current Balance",
        property: "currentBalance",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];

export const shippingDetailDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Address Name",
        property: "addressName",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Address Line",
        property: "addressLine",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "City",
        property: "city",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "State",
        property: "state",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Zip Code",
        property: "zipCode",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Phone",
        property: "phone",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Email",
        property: "email",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];

export const orderResourcesDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Type",
        property: "type",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "ISBN/Serial Number",
        property: "code",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Description",
        property: "description",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    // {
    //     label: "Association",
    //     property: "group",
    //     sortable: false,
    //     clickable: false,
    //     filterable: true,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    // {
    //     label: "Deposit",
    //     property: "deposit",
    //     sortable: false,
    //     clickable: false,
    //     filterable: false,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    {
        label: "Price",
        property: "price",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Has Deposit",
        property: "hasDeposit",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
];

export const orderClassDataProperties: DataProperties[] = [
    {
        label: "",
        property: "check",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum Name",
        property: "curriculumName",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: true,
        endpoint: allCurriculumsEndpoint.concat("/get-by-code-or-name")
    },
    // {
    //     label: "Course ID",
    //     property: "code",
    //     sortable: false,
    //     clickable: false,
    //     filterable: true,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    {
        label: "Course Name",
        property: "name",
        sortable: false,
        clickable: true,
        //clickable: false,
        filterable: true,
        autocomplete: true,
        endpoint: ""
        //endpoint: allClassesEndpoint.concat("/get-by-code-or-name")
    },
    // {
    //     label: "Course Code",
    //     property: "courseCode",
    //     sortable: false,
    //     clickable: false,
    //     filterable: true,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    // {
    //     label: "Per Class Cost",
    //     property: "perClassCost",
    //     sortable: false,
    //     clickable: false,
    //     filterable: true,
    //     autocomplete: false,
    //     endpoint: ""
    // },
    {
        label: "Availability Start Date",
        property: "startDate",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Availability End Date",
        property: "endDate",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "status",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    }
];

export const createOrderClassDataProperties: DataProperties[] = [
    {
        label: "Curriculum Code",
        selectable: true,
        property: "code",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Curriculum Name",
        property: "name",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: true,
        endpoint: ""
    },
    {
        label: "Curriculum Description",
        property: "description",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
];

export const courseHistoryDataProperties: DataProperties[] = [
    {
        label: "History Type",
        property: "historyType",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Description",
        property: "description",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Date Modified",
        property: "dateModified",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Modified By",
        property: "modifiedBy",
        sortable: true,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
];
