import { DataProperties } from "../../models/data-properties.model";

export const VIDEO: string = "VIDEO";

export const videoListDataProperties: DataProperties[] = [
    {
        label: "Title",
        property: "title",
        sortable: false,
        clickable: true,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Description",
        property: "description",
        sortable: false,
        clickable: false,
        filterable: true,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "URL",
        property: "url",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    },
    {
        label: "Status",
        property: "active",
        sortable: false,
        clickable: false,
        filterable: false,
        autocomplete: false,
        endpoint: ""
    }
];