import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderMessagingService } from 'src/app/core/services/messaging/header-messaging.service';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/core/models/class.model';
import { baseServerUri } from 'src/app/core/constants/configuration/config.constant';
import { allClassesEndpoint, getCountByCodeAndStatus, getMainCourseBySubsequentCourseCodeCourseEndpoint, getOrderList, getProcessingStatusEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';
import { CLASSES_PATH, ORDERS_PATH } from 'src/app/core/constants/routes.constant';
import { DialogBoxComponent } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { dialogBoxSuccessTitle, updateSuccessClass } from 'src/app/core/constants/message.constant';
import { sideNavLabelClass, CLASS } from 'src/app/core/constants/configuration/class-constants.config';
import { UPDATE_MODULE_NAME } from 'src/app/core/constants/configuration/common.constant';
import { UserService } from 'src/app/core/services/util/user.service';
import { ADMIN, PROGRAM_DIRECTOR, SUPERVISOR, homeRoutingPermissions } from 'src/app/core/constants/configuration/role-constants.config';

@Component({
  selector: 'app-class-update',
  templateUrl: './class-update.component.html',
  styleUrls: ['./class-update.component.scss']
})
export class ClassUpdateComponent implements OnInit, OnDestroy {

  	activeIndex: number = 0;
	sideNavLabels: string[] = [];
	unsubscribe: Subject<any> = new Subject();
	clazz: Class;
	mainCourse: Class = null;
	orderList: any[] = [];
	processingStatus: any[] = [];
	statusListCount: Map<string, number> = new Map<string, number>();

	constructor(
		private headerMessagingService: HeaderMessagingService,
		private crudService: CrudService,
		private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
    private userService: UserService,
	) {
		this.userService.checkRolePermission(CLASSES_PATH, homeRoutingPermissions);
		this.headerMessagingService.setHeader(CLASS, UPDATE_MODULE_NAME, false, null);
		this.sideNavLabels = sideNavLabelClass;
	}

	ngOnInit(): void {
		this.loaderMessagingService.showPageLoader(true);
		this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => this.findClass(params["id"]));
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	private findClass(id: number): void {

		this.crudService
			.getById<Class>(allClassesEndpoint.concat(`/${id}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.clazz = response;
					this.findMainCourse(this.clazz.code);
					if (this.canViewOrderSummaryAndAssociation) {
						this.getOrderList();
						this.getStatusCount();
					}
				}
			},
			this.errorHandlerService.handleError, this.handleCompletion);

	}

	private getOrderList(): void {
		this.crudService
            .getById<any>(getOrderList + "?courseCode=" + this.clazz.code)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
				if(response) {
					this.orderList = response;
					this.getProcessingStatus();
				}
        }, this.errorHandlerService.handleError);
	}

	private getProcessingStatus(): void {
		this.crudService
			.getById<any>(getProcessingStatusEndpoint)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if(response){
					this.processingStatus = response;
					this.orderList = this.orderList.map(order => {
						order.status = this.getProcessingStatusName(order.status, order.withdrawType);
						return order;
					});
				}
			}, this.errorHandlerService.handleError);
	}

	getProcessingStatusName(statusCode: string, withdrawType: string): string {
		let statusName: any = this.processingStatus.find(status => status.code === statusCode);
		if (statusName.name === "HST Approved") {
			return "Pending Orders";
		} else if (statusName.name === "Completed") {
			return "Processed";
		} else if(statusName.name === "Finalized WD Request") {
			if(withdrawType === "refund") {
				return "Finalized WD Request (Refund)";
			} else if(withdrawType === "no refund") {
				return "Finalized WD Request (No Refund)";
			} else return "Finalized WD Request (Adjusted)";
		} else return statusName.name || "";
	}

	getStatusCount() {
		this.statusListCount = new Map<string, number>();
		this.crudService.getById<Map<string, number>>(getCountByCodeAndStatus + "?courseCode=" + this.clazz.code)
		.pipe(takeUntil(this.unsubscribe))
		.subscribe(response => {
			if(response) {
				this.statusListCount = response;
			}
		}, this.errorHandlerService.handleError);
	}

	onSaveClass($event: Class) {

		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.edit<Class>(allClassesEndpoint.concat(`/${$event.id}`), $event)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.router.navigate([CLASSES_PATH, $event.id]);
				const dialogRef = this.dialog.open(DialogBoxComponent);
				dialogRef.componentInstance.dialogTitle = dialogBoxSuccessTitle;
				dialogRef.componentInstance.contentMessage = updateSuccessClass;
			}, this.errorHandlerService.handleError, this.handleCompletion);
		
	}

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
	}

	findMainCourse(code: string) {
		this.loaderMessagingService.showPageLoader(true);
		this.crudService
			.getById<Class>(getMainCourseBySubsequentCourseCodeCourseEndpoint.concat(`?code=${code}`))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(response => {
				if (response) {
					this.mainCourse = response;
				}
			},
		this.errorHandlerService.handleError, this.handleCompletion);		
	}

	onOrderLinkClick(id: number): void {
		this.router.navigate([ORDERS_PATH, id]);
	}

	get canViewOrderSummaryAndAssociation() {
		return this.userService.hasRole([ADMIN, SUPERVISOR, PROGRAM_DIRECTOR]);
	}
}
