import {
  CURRICULUM_CREATE_PATH,
  CLASSES_PATH,
  BUNDLES_PATH,
  CURRICULUMS_PATH,
  RESOURCES_PATH,
  ORDERS_PATH,
  UPLOADS_PATH,
  HQTS_PATH,
  MODEL_PATH,
  TASKS_PATH,
  ASSET_PATH,
  STUDENTS_PATH,
  DOWNLOAD_REPORTS_PATH,
  VIDEOS_PATH
} from "../routes.constant";

export const ADMIN: string = "ROLE_COS_ADMIN";
export const SUPERVISOR: string = "ROLE_COS_SUPERVISOR";
export const SPECIALIST: string = "ROLE_COS_SPECIALIST";
export const TEACHER: string = "ROLE_TEACHER";
export const ASSISTANT_DIRECTOR: string = "ROLE_COS_ASSISTANT_DIRECTOR";
export const STUDENT_ACCOUNT_SPECIALIST: string = "ROLE_COS_STUDENT_ASSISTANT_SPECIALIST";
export const PROGRAM_DIRECTOR: string = "ROLE_COS_PROGRAM_DIRECTOR";

export const CURRICULUM_CREATE_ROUTE: any[] = [
  {
    path: CURRICULUM_CREATE_PATH,
    name: "Curriculum",
    roles: [ADMIN, PROGRAM_DIRECTOR]
  }
];

export const homeRoutingPermissions: any[] = [
  {
    path: CURRICULUMS_PATH,
    name: "Curriculum",
    roles: [ADMIN, PROGRAM_DIRECTOR]
  },
  {
    path: CLASSES_PATH,
    name: "Course",
    roles: [ADMIN, SUPERVISOR, SPECIALIST, TEACHER, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]
  },

  // {
  //   path: BUNDLES_PATH,
  //   name: "Bundle",
  //   roles: [ADMIN, PROGRAM_DIRECTOR]
  // },
  // {
  //   path: RESOURCES_PATH,
  //   name: "Resource",
  //   roles: [ADMIN, PROGRAM_DIRECTOR]
  // },

  {
    path: ORDERS_PATH,
    name: "Order",
    roles: [ADMIN, SUPERVISOR, SPECIALIST, TEACHER, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]
  },
  {
    path: TASKS_PATH,
    name: "Task",
    roles: [ADMIN, SUPERVISOR, SPECIALIST, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]
  },
  {
    path: STUDENTS_PATH,
    name: "Student",
    roles: [ADMIN, SUPERVISOR, TEACHER, ASSISTANT_DIRECTOR, PROGRAM_DIRECTOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST]
  },
  {
    path: VIDEOS_PATH,
    name: "Video",
    roles: [ADMIN, SUPERVISOR, SPECIALIST, STUDENT_ACCOUNT_SPECIALIST, ASSISTANT_DIRECTOR, TEACHER, PROGRAM_DIRECTOR]
  }

];

export const settingsRoutingPermissions: any[] = [
  {
    path: CURRICULUMS_PATH,
    name: "Home",
    roles: [ADMIN, PROGRAM_DIRECTOR]
  },
  {
    path: UPLOADS_PATH,
    name: "Upload",
    roles: [ADMIN, SUPERVISOR, PROGRAM_DIRECTOR]
  },
  {
    path: HQTS_PATH,
    name: "HQT",
    roles: [ADMIN, PROGRAM_DIRECTOR]
  },

  // {
  //   path: MODEL_PATH,
  //   name: "Model",
  //   roles: [ADMIN, PROGRAM_DIRECTOR]
  // },
  // {
  //   path: ASSET_PATH,
  //   name: "Asset",
  //   roles: [ADMIN, PROGRAM_DIRECTOR]
  // },

  {
    path: DOWNLOAD_REPORTS_PATH,
    name: "Report",
    roles: [ADMIN, SUPERVISOR, PROGRAM_DIRECTOR, SPECIALIST]
  }
];
