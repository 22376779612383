import { Component, OnInit, ChangeDetectorRef, Input } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { faHome, faChevronLeft, faChevronDown, faTools } from "@fortawesome/free-solid-svg-icons";
import {
  navTabsSettings,
  CREATE_MODULE_NAME,
  UPDATE_MODULE_NAME
} from "src/app/core/constants/configuration/common.constant";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { UserService } from "src/app/core/services/util/user.service";
import { User } from "src/app/core/models/user.model";
import { AuthService } from "src/app/core/services/data/auth.service";
import { LoginMessagingService } from "src/app/core/services/messaging/login-messaging.service";
import { rotatingIcon } from "src/app/core/constants/animations.constant";
import { ErrorHandlerService } from "src/app/core/services/util/error-handler.service";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../components/confirm-dialog/confirm-dialog.component";
import { backConfirmation } from "src/app/core/constants/message.constant";
import {
  UPLOADS_PATH,
  HQTS_PATH,
  MODEL_PATH,
  CURRICULUMS_PATH,
  LOGIN_PATH,
  ASSET_PATH,
  ORDERS_PATH,
  DOWNLOAD_REPORTS_PATH,
  STUDENTS_PATH,
  VIDEOS_PATH
} from "src/app/core/constants/routes.constant";
import { ADMIN, PROGRAM_DIRECTOR } from "src/app/core/constants/configuration/role-constants.config";
import { unauthorized } from "src/app/core/constants/configuration/config.constant";
import { CrudService } from "../../../core/services/data/crud.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [rotatingIcon]
})
export class HeaderComponent implements OnInit {
  @Input() isMain: boolean;
  @Input() title: string;
  @Input() moduleName: string;
  @Input() routes: any[];

  unsubscribe: Subject<any> = new Subject();
  homePath = null;
  fixStatusList: any[] = [];
  canFixStatus: boolean = false;

  //Icons
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;
  faHome = faHome;
  faTools = faTools;
  configurationChoices: string[] = [];
  loginUser: User = null;

  state: string = "default";

  rotate(status) {
    this.state = status ? "rotated" : "default";
  }

  constructor(
    private crudService: CrudService,
    private loginMessagingService: LoginMessagingService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private _location: Location,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.homePath = this.userService.hasRole([ADMIN, PROGRAM_DIRECTOR]) ? CURRICULUMS_PATH : ORDERS_PATH;
    this.loginUser = userService.getLoginUser();
    this.configurationChoices = Object.assign([], navTabsSettings.filter(route => this.userService.hasRole(route.roles)).map(route => route.name));
    this.configurationChoices.push("Logout");
    this.configurationChoices.unshift(userService.getFirstRole().authority.replace('ROLE_', '').replace('COS_', ''));
    this.configurationChoices.unshift(this.loginUser.firstName + " " + this.loginUser.lastName);
  }

  onClick(item: string) {
    if (item === "Logout") {
      this.goToPath("LOGOUT");
    } else if (item === "Home") {
      this.goToPath(this.homePath);
    } else if (item === "Upload") {
      this.goToPath(UPLOADS_PATH);
    } else if (item === "HQT") {
      this.goToPath(HQTS_PATH);
    } else if (item === "Model") {
      this.goToPath(MODEL_PATH);
    } else if (item === "Asset") {
      this.goToPath(ASSET_PATH);
    } else if (item === "Report"){
      this.goToPath(DOWNLOAD_REPORTS_PATH)
    } else if (item === "Video"){
      this.goToPath(VIDEOS_PATH);
    }
  }

  logout(): void {
    this.authService
      .logout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          localStorage.clear();
          this.loginMessagingService.sendMessage(unauthorized);
          this.router.navigateByUrl(LOGIN_PATH);
        }
      }, this.errorHandlerService.handleError);
  }

  ngOnInit() {
  }

  goToPath(location: string) {
    if (this.isValidFormModuleName()) {
      this.openConfirmDialog(location);
    } else {
      if (location)
        if (location === "LOGOUT")
          this.logout();
        else
          this.router.navigateByUrl(location);
      else
        this._location.back();
    }
  }

  openConfirmDialog(location: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = backConfirmation;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result) {
          if (location)
            if (location === "LOGOUT")
              this.logout();
            else
              this.router.navigateByUrl(location);
          else
            this._location.back();
        }
      }, this.errorHandlerService.handleError);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private isValidFormModuleName(): boolean {
    return (
      this.moduleName === CREATE_MODULE_NAME ||
      this.moduleName === UPDATE_MODULE_NAME
    );
  }

}
