import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { MfaForm } from 'src/app/core/models/form/mfa-form.model';
import { FormUtil } from 'src/app/core/services/util/form.util';

@Component({
  selector: 'app-mfa-popup-dialog',
  templateUrl: './mfa-popup-dialog.component.html',
  styleUrls: ['./mfa-popup-dialog.component.scss']
})
export class MfaPopupDialogComponent implements OnInit {

  
  @Input() seconds = 300;
  @Input() mfaFormValues: MfaForm;
  @Output() onValidate = new EventEmitter<any>();
  @Output() resendCode = new EventEmitter<any>();
  @Output() errorMessageEvent = new EventEmitter<any>();
  otp: string = "";
  email: string = "";
  timeLeft = 300;
  mfaForm: FormGroup;
  errorMessage: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dlDialogRef: MatDialogRef<MfaPopupDialogComponent>,
    private formsUtil: FormUtil,) {
      this.mfaForm = this.formsUtil.createMfaForm();
    }


  ngOnInit() {
    this.initParams();
    this.timeLeft = 300;
  }


	private initParams = (): void => {
			this.initFormValues();
	}

	private initFormValues = (): void => {
		this.mfaForm.setValue(this.formsUtil.initMfaForm(this.mfaFormValues));
	}


  timeRemaining$ = timer(0, 1000).pipe(
    map(n => (this.timeLeft = this.seconds - n)),
    takeWhile(n => n >= 0),
  );

  onChange(value) {
    this.otp = value;
  }

  get canValidate() {
    let canValidate = false;
    if(this.otp.length == 6){
      canValidate = true;
    }
    return canValidate;
  }

  onResendVerificationCode(){
    this.resendCode.emit(true);

    this.seconds = 300;
    this.timeLeft = 300;
  }

  onValidateClick(){
    this.onValidate.emit(this.otp);
  }

}
