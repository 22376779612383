import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-student-for-sv-approval-popup',
  templateUrl: './student-for-sv-approval-popup.component.html',
  styleUrls: ['./student-for-sv-approval-popup.component.scss']
})
export class StudentForSvApprovalPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dlDialogRef: MatDialogRef<StudentForSvApprovalPopupComponent>) { }

  ngOnInit() {
  }

}
