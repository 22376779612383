import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Order } from 'src/app/core/models/order/order.model';
import { Subject } from 'rxjs';
import { OrderItemStatusHistory } from 'src/app/core/models/order/order-item-status-history.model';
import { OrderItemAdjustmentHistory } from 'src/app/core/models/order/order-item-adjustment-history.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faUndo, faPen, faDollarSign, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CrudService } from 'src/app/core/services/data/crud.service';
import { MatDialog } from '@angular/material';
import { ErrorHandlerService } from 'src/app/core/services/util/error-handler.service';
import { LoaderMessagingService } from 'src/app/core/services/messaging/loader-messaging.service';
import { UserService } from 'src/app/core/services/util/user.service';
import { ORDER_ITEM_TYPE_4, ORDER_ITEM_TYPE_5, PROCESSING_STATUS_6, PROCESSING_STATUS_7, PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_1, ORDER_ITEM_TYPE_1, ORDER_ITEM_TYPE_3 } from 'src/app/core/constants/configuration/order-constants.config';
import * as moment from "moment";
import { ADMIN, SUPERVISOR, SPECIALIST, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR } from 'src/app/core/constants/configuration/role-constants.config';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrderItemAdjustmentDialogComponent } from '../order-item-adjustment-dialog/order-item-adjustment-dialog.component';
import { getItemLatestStatusHistoryEndpoint, getItemAdjustmentHistoryEndpoint, getItemLatestHistoryByStatusEndpoint } from 'src/app/core/constants/endpoints.constant';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-order-items-deposit',
  templateUrl: './order-items-deposit.component.html',
  styleUrls: ['./order-items-deposit.component.scss']
})
export class OrderItemsDepositComponent implements OnInit {


  @Input() canRemoveItem: boolean = true;
  @Input() orderItemAssoc: any[] = [];
  @Input() processingStatus: any[];
  @Output() onGetNewOrderDetails = new EventEmitter<any>();
  @Output() onAdjust = new EventEmitter<any>();
  @Output() requestToCancel = new EventEmitter<any>();
  @Output() undoRequestToCancel = new EventEmitter<any>();
  orderDetailsValue: Order;
  orderItemsList: any[] = [];
  orderGroupCodes: string[] = [];
  unsubscribe: Subject<any> = new Subject();
  referenceTypes: any[];
  selectedItems: any[] = [];
  selectedItemStatusHistory: OrderItemStatusHistory;
  selectedItemAdjustmentHistory: OrderItemAdjustmentHistory;
  isDeposit: boolean = false;
  
  canRequestToCancel: boolean = false;
  canUndoRequestToCancel: boolean = false;

  removeIcon: IconDefinition = faTimesCircle;
  undoIcon: IconDefinition = faUndo;
  editIcon: IconDefinition = faPen;
  refundIcon: IconDefinition = faDollarSign;

  REQUEST_CANCEL: any = { action: "REQUEST_CANCEL", label: ""  };
  UNDO_REQUEST_CANCEL: any = { action: "UNDO_REQUEST_CANCEL", label: ""  };

  collapse: boolean = false;
  //ICONS
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  collapsed() {
    this.collapse = !this.collapse;
  }

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private errorHandlerService: ErrorHandlerService,
		private loaderMessagingService: LoaderMessagingService,
		private userService: UserService,
  ) {
    this.loaderMessagingService.showPageLoader(true);
  }

  ngOnInit() {
    this.loaderMessagingService.showPageLoader(false);
  }

  @Input()
  set orderDetails(value) {
    this.orderDetailsValue = null;
    this.orderDetailsValue = value;
    this.selectedItems = [];
    this.groupItemsByCurriculum();
  }

  itemHasDeposit(item){
    let hasDeposit = false;
    if(item.referenceType == ORDER_ITEM_TYPE_1){
      if(item.curriculum.hasDeposit){
        hasDeposit = true;
      }
    } else if(item.referenceType == ORDER_ITEM_TYPE_3){
      if(item.resourceDetails.hasDeposit){
        hasDeposit = true;
      }
    }
    return hasDeposit;
  }

  groupItemsByCurriculum() {
    this.orderGroupCodes = [];
    this.orderItemsList = this.orderDetailsValue.orderItems.filter(item => [ORDER_ITEM_TYPE_4].includes(item.referenceType));
    this.orderItemsList.forEach(item => {
      this.getItemCreatedStatusDetails(item)
    });
    if (this.orderItemAssoc.length > 0) {
      if ( this.orderItemsList[0].referenceType === ORDER_ITEM_TYPE_4) this.isDeposit = true;
      let tempList = [];
      this.orderItemAssoc.forEach(itemAssoc => {
        this.orderItemsList.forEach(item => {
          if (item.id === itemAssoc.txnOrderItemId) tempList.push(item);
        });
      });
      this.orderItemsList = tempList;
    }

    this.orderItemsList.forEach(item => {
      if (!this.orderGroupCodes.includes(item.txnOrderGroup)) this.orderGroupCodes.push(item);
    });
    this.orderGroupCodes.sort();
  }

  get toDisplayItems() : any{
    let items = [];
    this.orderDetailsValue.orderItems.forEach(item => {
      if(this.itemHasDeposit(item)) {
        if(item.referenceType == 'ORDER_ITEM_TYPE_1') {
          items.push("Curriculum: " + item.curriculum.code + " " + item.curriculum.name);
        } else if (item.referenceType == 'ORDER_ITEM_TYPE_3') {
          items.push("Other Books: " + item.referenceCode + " " + item.referenceName);
        }
      }
    });
    return new Set(items);
  }

  isDepositOrFee(code: any): boolean {
    return code.includes("DEPOSIT") || code.includes("FEE")
  }

  formatAmount(value: number): string {
    return "$" + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  formatDate(value: Date): string {
    return moment(value).format('MMM DD, YYYY')
  }

  get isUserTeacher(): boolean {
    return !this.userService.hasRole([ADMIN, SUPERVISOR, SPECIALIST, ASSISTANT_DIRECTOR, STUDENT_ACCOUNT_SPECIALIST, PROGRAM_DIRECTOR]);
  }

  isItemSelected(item: any): boolean {
    return this.selectedItems.filter(selectedItem => selectedItem.referenceCode === item.referenceCode && selectedItem.id === item.id).length > 0
  }

  isAllItemSelected() {
    return this.selectedItems.length > 0 && this.selectedItems.length === this.orderItemsList.length
  }

  isRefunded(item) {
    return item.status === PROCESSING_STATUS_6
  }

  isAdjusted(item) {
    return item.status === PROCESSING_STATUS_7
  }

  onSelectAll($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    if ($event.checked) {
      this.selectedItems = this.orderItemsList;
    } else {
      this.selectedItems = [];
    }
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
    this.canRequestToCancel = this.selectedItems.length > 0 &&
      this.selectedItems.filter(selectedItem => selectedItem.forCancel ||
        (this.orderDetailsValue.status === PROCESSING_STATUS_1 && selectedItem.status === PROCESSING_STATUS_1)).length === 0;
    this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  onSelectItem($event: any, item: any) {
    if ($event.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    }
    if (this.selectedItems.length === 1 && this.selectedItems[0].forCancel &&
        [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status)) 
      this.getItemLatestStatusHistory(this.selectedItems[0].id);
    this.canRequestToCancel = this.selectedItems.length > 0 &&
      this.selectedItems.filter(selectedItem => selectedItem.forCancel ||
        (this.orderDetailsValue.status === PROCESSING_STATUS_1 && selectedItem.status === PROCESSING_STATUS_1)).length === 0;
    this.canUndoRequestToCancel = this.selectedItems.length > 0 && this.selectedItems.filter(selectedItem =>
      !selectedItem.forCancel || (selectedItem.resourceOrderDetails && selectedItem.resourceOrderDetails.required)).length === 0;
  }

  getStatusName(statusCode: string) {
    const statusName: any = this.processingStatus.find(status => status.code === statusCode);
    return statusName ? statusName.name : "";
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case PROCESSING_STATUS_4: return "completed status"
      case PROCESSING_STATUS_6: return "refunded status"
      case PROCESSING_STATUS_7: return "adjusted status"
      default: return "status"
    }
  }

  hasAdditionalItemDetails(item) {
    return (item.forCancel || this.isShowModifiedDate(item))
  }

  hasReason(item) {
    return item.reason != null && item.reason != '';
  }

  isShowModifiedDate(item) {
    return (item.id != 0 && item.id != null) && [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(item.status)
  }

  get canRefund(): boolean {
    return (this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4].includes(this.selectedItems[0].status) &&
      (this.selectedItems[0].newPrice === null || this.selectedItems[0].newDeposit === null || 
      this.selectedItems[0].newPerClassCost === null) && this.selectedItems[0].adjustedPrice === null && 
      this.selectedItems[0].adjustedDeposit === null && this.selectedItems[0].adjustedPerClassCost === null) && this.orderDetailsValue.syCode != "2019-2020";;
  }

  get canAdjust(): boolean {
    return this.selectedItems.length === 1 &&
      [PROCESSING_STATUS_3, PROCESSING_STATUS_4, PROCESSING_STATUS_6, PROCESSING_STATUS_7].includes(this.selectedItems[0].status);
  }

  get isPastThirtyDays(): boolean {
    if (this.selectedItems.length === 1 && this.selectedItemStatusHistory) {
      let dateLimit = moment(this.selectedItemStatusHistory.dateModified).add(30, 'days');
      return moment(this.selectedItems[0].rtcModifiedDate).isAfter(dateLimit);
    } else return false;
  }

  showConfirmDialog(code: any) {
    let selectedItemsCodes: string[] = [];
    this.selectedItems.slice().forEach(item => {
      selectedItemsCodes.push(item.referenceCode)
    });
    
    let message = null;
    
    if (code.action === this.REQUEST_CANCEL.action) {
      message = `Are you sure you want to submit a Request to Cancel for the selected ${this.selectedItems.length > 1 ? 'items' : 'item'}?`
    } else if (code.action === this.UNDO_REQUEST_CANCEL.action) {
      message = `Are you sure you want to undo the Request to Cancel for the selected ${this.selectedItems.length > 1 ? 'items' : 'item'}?`
    }

    if (this.selectedItems && this.selectedItems.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: false });
      dialogRef.componentInstance.confirmMessage = message;
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          switch (code.action) {
            case this.REQUEST_CANCEL.action: {
              this.requestToCancel.emit(selectedItemsCodes);
              this.selectedItems = [];
              this.canRequestToCancel = false;
              break;
            }
            case this.UNDO_REQUEST_CANCEL.action: {
              this.undoRequestToCancel.emit(selectedItemsCodes);
              this.selectedItems = [];
              this.canUndoRequestToCancel = false;
              break;
            }
          }
        }
      });
    }
  }

  adjustmentConfirmDialog(forRefund: boolean, event = null) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const dialogRef = this.dialog.open(OrderItemAdjustmentDialogComponent, { width: "50%" });
    dialogRef.componentInstance.item = this.selectedItems[0];
    dialogRef.componentInstance.forRefund = forRefund;
    dialogRef.componentInstance.close.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.componentInstance.refund.subscribe((result) => {
      if (result) {
        this.onAdjust.emit({
          orderCode: this.orderDetailsValue.code,
          itemId: this.selectedItems[0].id,
          amount: result.amount,
          type: result.type,
          forRefund: forRefund,
          remarks: result.remarks,
          itemCodeName: `${this.selectedItems[0].referenceCode} ${this.selectedItems[0].referenceName}`,
        });
        this.canRequestToCancel = false;
        dialogRef.close();
      }
    });
  }

  getItemLatestStatusHistory(orderItemId) {
		this.loaderMessagingService.showPageLoader(true);
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestStatusHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemStatusHistory = response;
        this.getItemAdjustmentHistory(orderItemId);
      }, this.errorHandlerService.handleError);
  }

  getItemAdjustmentHistory(orderItemId) {
    this.crudService
      .getById<OrderItemAdjustmentHistory>(getItemAdjustmentHistoryEndpoint.concat(`?orderItemId=${orderItemId}`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.selectedItemAdjustmentHistory = response;
      }, this.errorHandlerService.handleError, this.handleCompletion);
  }

	handleCompletion = (): void => {
		this.loaderMessagingService.showPageLoader(false);
  };
  
  getItemCreatedStatusDetails(item) {
    this.crudService
      .getById<OrderItemStatusHistory>(getItemLatestHistoryByStatusEndpoint.concat(`?orderItemId=${item.id}&status=PROCESSING_STATUS_4`))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if(response){
          item.createdStatusHistoryModifiedDate = response.dateModified ? response.dateModified : "";
          item.createdStatusHistoryModifiedBy = response.modifiedBy ? response.modifiedBy : "";
          item.createdStatusHistoryStatus = response.status ? response.status : "";
        }
      }, this.errorHandlerService.handleError);
  }

}
